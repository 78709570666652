import React from 'react';
import Icon from '~/ui/components/Icon';
import classNames from 'classnames';

import './detail.scss';

function Detail({
    iconName,
    iconColor,
    iconSize,
    mainContent,
    secondaryContent,
    iconClassName,
    className,
    dataTestId = 'detail'
}) {
    if (!mainContent) return null;

    function _getClassName() {
        const defaultClassName = 'detail';
        return classNames(defaultClassName, className);
    }

    return (
        <div className={_getClassName()} data-testid={`${dataTestId}`}>
            {iconName && (
                <div className="detail-icon">
                    <Icon
                        icon={iconName}
                        color={iconColor}
                        size={iconSize}
                        className={`${iconClassName}`}
                        data-testid={`${iconName}-icon`}
                    />
                </div>
            )}
            <div className="detail-content">
                <div className="detail-main" data-testid={`${dataTestId}-main`}>
                    {mainContent}
                </div>
                {secondaryContent && (
                    <div
                        className="detail-secondary"
                        data-testid={`${dataTestId}-secondary`}
                    >
                        {secondaryContent}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Detail;
