import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setIsStoppingScheduler } from '~/reducers/schedulerProgressSlice';
import tasksApi from '~/api/TasksApi';
import taskAssignmentsApi from '~/api/CourierTaskAssignmentsApi';
import courierSchedulesApi from '~/api/CourierSchedulesApi';
import { selectDate } from '~/reducers/selectedDateSlice';
import { selectClients } from '~/reducers/clientsSlice';
import constants from '~/utils/constants';

import useToastContext from './toastContext';
import { useFeaturePermissionRole } from './useFeaturePermissionRole';

export const useStopScheduler = () => {
    const dispatch = useDispatch();
    const selectedDate = useSelector(selectDate);
    const driverGroups = useSelector(selectClients);
    const { addToast } = useToastContext();
    const { t } = useTranslation(['common']);
    const { planPermissionRole } = useFeaturePermissionRole();
    const isEnabled =
        planPermissionRole === constants.userPermissionRoles.EDITOR;

    const stopScheduler = useCallback(async () => {
        if (!isEnabled) {
            console.error('stop scheduler is not enabled');

            return;
        }

        try {
            dispatch(setIsStoppingScheduler());

            // driverGroups includes the main client
            if (Object.keys(driverGroups).length === 1) {
                await tasksApi.stopScheduler();
            } else {
                const {
                    data: {
                        data: { assigner_run_id: taskAssignerId }
                    }
                } = await taskAssignmentsApi.get(null, { date: selectedDate });

                await courierSchedulesApi.stopScheduler(taskAssignerId);
            }
        } catch (e) {
            console.error(e);
            addToast({
                message: t('error:schedulerStoppingError'),
                variant: 'error'
            });
        }
    }, [selectedDate, isEnabled]);

    return {
        isEnabled,
        stopScheduler
    };
};
