import React from 'react';
import classNames from 'classnames';

import { IconButton, Text, TextOverflowTooltip } from '~/ui';

import './manage-stop-control-list-item.scss';

function ManageStopControlListItem({
    text,
    iconButtonText,
    iconButtonTextColor,
    iconButtonTextStyle,
    tooltipPlacement = 'top',
    className,
    ...extra
}) {
    function _getClassName() {
        const defaultClassName = `managestopcontrol_list-item _d-flex _ai-center`;
        return classNames(defaultClassName, className);
    }

    return (
        <div
            className={_getClassName()}
            data-testid={extra['data-testid'] || 'managestopcontrol_list-item'}
        >
            {iconButtonText && (
                <IconButton
                    disabled
                    text={iconButtonText}
                    textColor={iconButtonTextColor}
                    textStyle={iconButtonTextStyle}
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-iconbutton`
                            : 'managestopcontrol_list-item-iconbutton'
                    }
                />
            )}

            {text && (
                <TextOverflowTooltip
                    placement={tooltipPlacement}
                    content={text}
                >
                    <Text
                        className="stopitem-title _text-3 _to-ellipsis"
                        variant="12-normal"
                        data-testid={
                            extra['data-testid']
                                ? `${extra['data-testid']}-text`
                                : 'managestopcontrol_list-item-text'
                        }
                    >
                        {text}
                    </Text>
                </TextOverflowTooltip>
            )}
        </div>
    );
}

export { ManageStopControlListItem };
