import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';
import type { RootState } from '~/storeTypes';
import { ApiClient } from '~/api/types';

/**
 * The active client state
 */
type ActiveClientState = Record<ApiClient['id'], boolean>;

/**
 * Active client reducer payloads
 */
type ActiveClientPayload = ApiClient['id'][];

/**
 * Active client reducer actions
 */
type ActiveClientAction = PayloadAction<ActiveClientPayload>;

export const activeClientsSlice = createSlice({
    name: 'activeClients',
    initialState: {} as ActiveClientState,
    reducers: {
        addActiveClients: (
            state: ActiveClientState,
            action: ActiveClientAction
        ): ActiveClientState => {
            const clientIds = action.payload;
            clientIds.forEach((id) => {
                state[id] = true;
            });
            return state;
        },
        removeActiveClients: (
            state: ActiveClientState,
            action: ActiveClientAction
        ): ActiveClientState => {
            const clientIds = action.payload;
            clientIds.forEach((id) => {
                delete state[id];
            });
            return state;
        },
        resetActiveClients: (): ActiveClientState => {
            return {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {};
        });
    }
});

export const { addActiveClients, removeActiveClients, resetActiveClients } =
    activeClientsSlice.actions;
export const selectActiveClients = (state: RootState): ActiveClientState =>
    state.activeClients;
export const selectActiveClientsArray = (
    state: RootState
): ActiveClientPayload => {
    if (!state || !state.activeClients) {
        return [];
    }
    return Object.keys(state.activeClients);
};
export default activeClientsSlice.reducer;
