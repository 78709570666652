import { useRef, useEffect, useState, MutableRefObject } from 'react';

type ScrollToElementOptions = {
    /** the IntersectionObserver options for this element */
    options?: IntersectionObserverInit;

    /** additional callback for IntersectionObserver */
    observerCallback?: (entries: IntersectionObserverEntry[]) => void;
};

type HookScrollToElement = {
    /** whether the referenced element is visible on the screen */
    isElementOnScreen: boolean;

    /** scroll the referenced element into view */
    scrollToElement: (
        scrollIntoViewOptions?: ScrollIntoViewOptions | boolean
    ) => void;
};

/**
 * additional callback for IntersectionObserver
 * @name IteractionObserverCallback
 * @function
 * @param {IntersectionObserverEntry[]} entries - InteractionObserver entries
 * @private
 */

/**
 * Implements an InteractionObserver on an element to allow it to be scrolled into view.
 *
 * @category Hooks
 * @module useScrollToElement
 * @param { MutableRefObject<HTMLElement> } elementRef - the element ref
 * @param { ScrollToElementOptions } scrollToElementParams - the initialization options
 * @param { IntersectionObserverInit } scrollToElementParams.options - the IntersectionObserver options for this element
 * @param { function } scrollToElementParams.observerCallback - additional callback for IntersectionObserver
 * @returns {HookScrollToElement}
 * @example <caption>Usage</caption>
 * // import statement
 * import { useScrollToElement } from '~/hooks';
 *
 * // initialize hook
 * const elementRef = useRef()
 * const {isElementOnScreen, scrollToElement} = useScrollToElement(elementRef);
 *
 * // attach ref to element
 * <>
 *    {isElementOnScreen && (<button onClick={scrollToElement}>Scroll to current stop</button>)}
 *    <div className="current-stop" ref={elementRef}>Current Stop Details</div>
 * </>
 *
 * @example <caption>Usage with InteractionObserver options</caption>
 * // import statement
 * import { useScrollToElement } from '~/hooks';
 *
 * // initialize hook
 * const elementRef = useRef()
 * const options = {
 *    root: document.querySelector('#scrollArea'),
 *    rootMargin: '0px',
 *    threshold: 1.0
 * }
 * const {isElementOnScreen, scrollToElement} = useScrollToElement(elementRef, {options});
 *
 * @example <caption>Usage with extra callback</caption>
 * // import statement
 * import { useScrollToElement } from '~/hooks';
 *
 * // initialize hook
 * const elementRef = useRef()
 * const [isButtonVisible, setIsButtonVisible] = useState(false);
 * const observerCallback = (entries) => {
 *    const [entry] = entries;
 *    setIsButtonVisible(!entry.isIntersecting);
 * };
 * const {isElementOnScreen, scrollToElement} = useScrollToElement(elementRef, {observerCallback});
 *
 * @see [Intersection Observer API]{@link https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API}
 */
export const useScrollToElement = (
    elementRef: MutableRefObject<HTMLElement>,
    { options, observerCallback }: ScrollToElementOptions
): HookScrollToElement => {
    const [isElementOnScreen, setIsElementOnScreen] = useState(false);
    const observerRef = useRef<IntersectionObserver>(
        new IntersectionObserver(() => {})
    );

    useEffect(() => {
        observerRef.current = new IntersectionObserver((entries) => {
            const [entry] = entries;
            setIsElementOnScreen(entry?.isIntersecting || false);
            if (observerCallback) observerCallback(entries);
        }, options);
    }, []);

    useEffect(() => {
        observerRef.current.observe(elementRef?.current);

        return () => {
            observerRef.current.disconnect();
        };
    }, [elementRef]);

    /**
     * scroll the referenced element into view
     *
     * @method scrollToElement
     * @param {ScrollIntoViewOptions | boolean} scrollIntoViewOptions - the options for `Element.scrollIntoView()`
     */
    const scrollToElement = (
        scrollIntoViewOptions?: ScrollIntoViewOptions | boolean
    ) => {
        elementRef.current.scrollIntoView(scrollIntoViewOptions);
    };

    return {
        isElementOnScreen,
        scrollToElement
    };
};
