import axios from 'axios';
import constants from '~/utils/constants';
import {
    AxiosApiResponse,
    IApiResponse,
    IPostDriverAssignmentsResponse
} from './types';
import { ApiDriver } from './types/ApiDriver';

/**
 * POST /driver/<driverId>/assignments payload type
 * @category API
 */
interface TaskToDispatch {
    /**
     * Id of the task to dispatch
     */
    id: string;
    /**
     * Pickup time of the task to dispatch
     */
    pickupTime?: string;
    /**
     * Delivery time of the task to dispatch
     */
    deliveryTime?: string;
}

type ResequenceTasksResponseData = {
    id?: string;
};

type DeleteDriverResponseData = {
    id?: string;
};

/**
 * Implementations of API methods under the /driver path
 *
 * @category API
 */
export default class DriverApi {
    /**
     * Path of the API endpoint
     */
    private static readonly PATH = '/driver';

    /**
     * Sends a POST /driver request with the given data for the given client to create a new driver.
     *
     * @param {ApiDriver} params data to create a new driver
     * @param {string} clientId the target client
     * @returns {Promise<AxiosApiResponse<ApiDriver>>}
     */
    static post(
        params: ApiDriver,
        clientId = ''
    ): Promise<AxiosApiResponse<ApiDriver>> {
        return axios.post<IApiResponse>(this.PATH, params, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: clientId }
        });
    }

    /**
     * Sends a PATCH /driver/<id> request with the given data for the given client.
     *
     * @param {string} driverId the driver to patch
     * @param {ApiDriver} data the patch data
     * @param {string} clientId the target client
     * @returns {Promise<AxiosApiResponse<ApiDriver>>}
     */
    static patch(
        driverId: string,
        data: ApiDriver,
        clientId = ''
    ): Promise<AxiosApiResponse<ApiDriver>> {
        if (!driverId) {
            return Promise.reject('driver id not provided');
        }
        return axios.patch<IApiResponse>(`${this.PATH}/${driverId}`, data, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: clientId }
        });
    }

    static unassignVehicleFromDriver(
        driverId: string,
        clientId = ''
    ): Promise<AxiosApiResponse> {
        if (!driverId) {
            return Promise.reject('Missing driver Id');
        }
        return axios.patch<IApiResponse>(
            `${this.PATH}/${driverId}/update_default_vehicle`,
            { defaultVehicle: '' },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: clientId }
            }
        );
    }

    static assignDefaultVehicle(
        driverId: string,
        vehicleId: string,
        clientId = ''
    ): Promise<AxiosApiResponse> {
        if (!driverId || !vehicleId) {
            return Promise.reject('Both driver id and vehicle id are required');
        }
        return axios.patch<IApiResponse>(
            `${this.PATH}/${driverId}/update_default_vehicle`,
            { defaultVehicle: vehicleId },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: clientId }
            }
        );
    }

    /**
     * Sends a POST /driver/<driverId>/assignments requests to assign the given
     * tasks to the given driver.
     *
     * @param {string} driverId driver to assign tasks to
     * @param {Array<TaskToDispatch>} tasks ids of tasks to assign to driver
     * @returns {IPostDriverAssignmentsResponse}
     */
    static dispatchTasks(
        driverId: string,
        tasks: Array<TaskToDispatch>
    ): Promise<AxiosApiResponse<IPostDriverAssignmentsResponse>> {
        if (!driverId) {
            return Promise.reject('driver id not provided');
        }
        return axios.post<IApiResponse<IPostDriverAssignmentsResponse>>(
            `${this.PATH}/${driverId}/assignments`,
            { tasks },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }

    /**
     * Sends a PATCH /driver/<driverId>/manual_sequence request to resequence the given
     * taskIds for the given driver.
     *
     * @param {string} driverId driver whose tasks should be resequenced
     * @param {Array<string>} taskIds ids of driver tasks to be resequenced
     * @returns {Promise<AxiosApiResponse<ResequenceTasksResponseData>>}
     */
    static resequenceTasks(
        driverId: string,
        taskIds: string[]
    ): Promise<AxiosApiResponse<ResequenceTasksResponseData>> {
        if (!driverId) {
            return Promise.reject('driver id not provided');
        }

        if (!taskIds || !Array.isArray(taskIds) || !taskIds.length) {
            return Promise.reject('taskIds not provided');
        }

        return axios.patch(
            `${this.PATH}/${driverId}/manual_sequence`,
            {
                order: taskIds
            },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }

    /**
     * Sends a DELETE /driver/<driverId> request to delete a driver
     *
     * @param {string} driverId driver to be deleted
     * @returns {Promise<AxiosApiResponse<DeleteDriverResponseData>>}
     */
    static delete(
        driverId: string
    ): Promise<AxiosApiResponse<DeleteDriverResponseData>> {
        if (!driverId) {
            return Promise.reject('Missing driver Id');
        }
        return axios.delete<IApiResponse>(`${this.PATH}/${driverId}`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }
}
