export default {
    getURL() {
        const endPoint = window.location.hostname;
        let apiServer;
        switch (endPoint) {
            case 'admin-sandbox.dev.wisesys.info':
                apiServer = 'https://dev.wise-api.wisesys.info';
                break;
            case 'admin-stage.dev.wisesys.info':
                apiServer = 'https://stage.wise-api.wisesys.info';
                break;
            case 'admin-uat.dev.wisesys.info':
                apiServer = 'https://uat.wise-api.wisesys.info';
                break;
            default:
                apiServer = 'https://dev.wise-api.wisesys.info';
        }
        return apiServer;
    }
};
