import { DateTime } from 'luxon';
import { ApiAnalyticsAggregateDriversDownload } from '~/api/types';
import { IconName } from '~/ui/components/Icon/IconNames';

/**
 * Available downloadable report types
 */
export enum DownloadableReportTypes {
    AUTO_ARRIVE = 'autoArrive',
    SERVICE_TIME = 'serviceTime',
    DRIVER_RAW = 'driverRaw',
    FLEET = 'fleet',
    CUSTOM = 'custom',
    UNKNOWN = 'unknown'
}

/**
 * Available downloadable report filenames
 */
export enum DownloadableReportFilenames {
    AUTO_ARRIVE = 'auto_arrive_depart_report',
    SERVICE_TIME = 'Time_Window_Stats_Report',
    DRIVER_RAW = 'Driver_Daily_Raw_Data',
    FLEET = 'fleet_agg'
}

/**
 * Mapping of report filename prefixes to report type
 */
const DownloadableReportFilenamesByType = {
    [DownloadableReportFilenames.AUTO_ARRIVE]:
        DownloadableReportTypes.AUTO_ARRIVE,
    [DownloadableReportFilenames.SERVICE_TIME]:
        DownloadableReportTypes.SERVICE_TIME,
    [DownloadableReportFilenames.DRIVER_RAW]:
        DownloadableReportTypes.DRIVER_RAW,
    [DownloadableReportFilenames.FLEET]: DownloadableReportTypes.FLEET
};

/**
 * Available downloadable report icons
 */
export enum DownloadableReportIcons {
    AUTO_ARRIVE = 'machineLearningOptimize',
    SERVICE_TIME = 'clock',
    DRIVER_RAW = 'userLine',
    FLEET = 'vehicleEmpty',
    CUSTOM = 'setting',
    UNKNOWN = 'question'
}

/**
 * Mapping of report types to report icons
 */
const DownloadableReportIconsByType = {
    [DownloadableReportTypes.AUTO_ARRIVE]: DownloadableReportIcons.AUTO_ARRIVE,
    [DownloadableReportTypes.SERVICE_TIME]:
        DownloadableReportIcons.SERVICE_TIME,
    [DownloadableReportTypes.DRIVER_RAW]: DownloadableReportIcons.DRIVER_RAW,
    [DownloadableReportTypes.FLEET]: DownloadableReportIcons.FLEET,
    [DownloadableReportTypes.CUSTOM]: DownloadableReportIcons.CUSTOM,
    [DownloadableReportTypes.UNKNOWN]: DownloadableReportIcons.UNKNOWN
};

/**
 * Available downloadable report categories
 */
export enum DownloadableReportCategories {
    CORE = 'core',
    CUSTOM = 'custom',
    UNKNOWN = 'unknown'
}

/**
 * Mapping of report types to report categories
 */
const DownloadableReportCategoriesByType = {
    [DownloadableReportTypes.AUTO_ARRIVE]: DownloadableReportCategories.CORE,
    [DownloadableReportTypes.SERVICE_TIME]: DownloadableReportCategories.CORE,
    [DownloadableReportTypes.DRIVER_RAW]: DownloadableReportCategories.CORE,
    [DownloadableReportTypes.FLEET]: DownloadableReportCategories.CORE,
    [DownloadableReportTypes.CUSTOM]: DownloadableReportCategories.CUSTOM,
    [DownloadableReportTypes.UNKNOWN]: DownloadableReportCategories.UNKNOWN
};

/**
 * DownloadableReportItem data class
 *
 * @category Data Classes
 *
 * @example
 * import { DownloadableReportItem } from '~/data-classes/cost/DownloadableReportItem';
 *
 * const srcData = {};
 * const reportItem = new DownloadableReportItem(srcData);
 *
 */
export class DownloadableReportItem {
    /**
     * The API source data
     * @type {ApiAnalyticsAggregateDriversDownload}
     */
    private readonly report: ApiAnalyticsAggregateDriversDownload;

    // No constructor JSDoc to avoid duplicates in generated docs
    // https://github.com/jsdoc/jsdoc/issues/1775
    constructor(reportItem: ApiAnalyticsAggregateDriversDownload) {
        this.report = reportItem;
    }

    /**
     * The downloadable report AWS key
     */
    get key(): string {
        return this.report.Key;
    }

    /**
     * The downloadable report filename
     */
    get filename(): string {
        const [filename] = this.key.split('/').reverse();
        return filename;
    }

    /**
     * The downloadable report type
     */
    get type(): DownloadableReportTypes {
        const reportMatch = Object.keys(DownloadableReportFilenamesByType).find(
            (key) => this.filename.startsWith(key)
        ) as DownloadableReportFilenames;

        return reportMatch
            ? DownloadableReportFilenamesByType[reportMatch]
            : DownloadableReportTypes.UNKNOWN;
    }

    /**
     * The downloadable report icon
     */
    get icon(): IconName {
        const typeMatch = Object.keys(DownloadableReportIconsByType).find(
            (key) => this.type === key
        ) as DownloadableReportTypes;

        return DownloadableReportIconsByType[typeMatch];
    }

    /**
     * The downloadable report category
     */
    get category(): DownloadableReportCategories {
        const typeMatch = Object.keys(DownloadableReportCategoriesByType).find(
            (key) => this.type === key
        ) as DownloadableReportTypes;

        return DownloadableReportCategoriesByType[typeMatch];
    }

    /**
     * Whether the downloadable report is a core report
     */
    get isCoreReport(): boolean {
        return this.category === DownloadableReportCategories.CORE;
    }

    /**
     * Whether the downloadable report item is an auto arrive report
     */
    get isAutoArriveReport(): boolean {
        return this.type === DownloadableReportTypes.AUTO_ARRIVE;
    }

    /**
     * Whether the downloadable report item is a service time report
     */
    get isServiceTimeReport(): boolean {
        return this.type === DownloadableReportTypes.SERVICE_TIME;
    }

    /**
     * Whether the downloadable report item is a driver raw report
     */
    get isDriverRawReport(): boolean {
        return this.type === DownloadableReportTypes.DRIVER_RAW;
    }

    /**
     * Whether the downloadable report item is a fleet report
     */
    get isFleetReport(): boolean {
        return this.type === DownloadableReportTypes.FLEET;
    }

    /**
     * Whether the downloadable report item is a custom report
     */
    get isCustomReport(): boolean {
        return this.type === DownloadableReportTypes.CUSTOM;
    }

    /**
     * The downloadable report date in DateTime
     */
    get reportDate(): DateTime {
        const [reportDate] = this.filename.match(/\d{4}-\d{2}-\d{2}/g) || [];
        return DateTime.fromISO(reportDate);
    }

    /**
     * The last modified date in DateTime
     */
    get lastModified(): DateTime {
        return DateTime.fromISO(this.report.LastModified);
    }

    /**
     * The size in KB
     */
    get size(): number {
        return this.report.Size;
    }

    /**
     * The AWS bucket link
     */
    get link(): string {
        return this.report.link;
    }

    /**
     * The AWS bucket link's hostname
     */
    get linkHostname(): string {
        const [hostname] = this.link.split(this.key);
        return hostname.slice(0, hostname.length - 1);
    }

    /**
     * The AWS bucket link's path, including search params
     */
    get linkPath(): string {
        const path = this.link.slice(this.linkHostname.length);
        return path;
    }

    /**
     * Whether the downloadable report item is within the defined report dates
     */
    isWithinReportDates(filterReportDates: DateTime[] = []): boolean {
        if (!Array.isArray(filterReportDates))
            throw new TypeError('should be an array of DateTime objects');

        return (
            filterReportDates.length > 0 &&
            filterReportDates.some((item) => {
                const itemDate = item.toISODate();
                return itemDate === this.reportDate.toISODate();
            })
        );
    }

    /**
     * Serializes this class back to JSON
     * @returns {ApiAnalyticsAggregateDriversDownload}
     */
    toJSON(): ApiAnalyticsAggregateDriversDownload {
        return this.report;
    }
}
