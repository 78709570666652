import { useReducer } from 'react';
import { createSlice } from '@reduxjs/toolkit';

export default function useAddress(data) {
    const initialState = {
        addressLine1: data?.addressLine1 || '',
        addressLine2: data?.addressLine2 || '',
        city: data?.city || '',
        state: data?.state || '',
        zipcode: data?.zipcode || '',
        country: data?.country || '',
        longitude: data?.location?.lng.toString() || '',
        latitude: data?.location?.lat.toString() || ''
    };

    const addressFormSlice = createSlice({
        name: 'addressForm',
        initialState,
        reducers: {
            changeValue: (state, action) => {
                state[action.payload.name] = action.payload.value;
            }
        }
    });

    const { changeValue } = addressFormSlice.actions;
    const addressReducer = addressFormSlice.reducer;

    const [state, dispatch] = useReducer(addressReducer, initialState);

    return {
        values: state,
        handleAddressChange: (name, value) =>
            dispatch(changeValue({ name, value }))
    };
}
