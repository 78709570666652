import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import DepotsApi from '~/api/DepotsApi';
import { selectDepots, setDepots } from '~/reducers/depotsSlice';

export const useGetDepots = () => {
    const [depotsList, setDepotsList] = useState({});
    const dispatch = useDispatch();
    const storedDepots = useSelector(selectDepots);

    const depotsEmpty = useMemo(() => {
        return _.isEmpty(storedDepots);
    }, [storedDepots]);

    const fetchAllDepots = async () => {
        try {
            const depotsResponse = await DepotsApi.get();
            const depots = depotsResponse.data.data;
            const depotsObj = Object.fromEntries(
                Object.values(depots).map((depot) => [depot.id, depot])
            );
            setDepotsList(depotsObj);
            dispatch(setDepots(depotsObj));
        } catch (e) {
            setDepotsList({});
            dispatch(setDepots({}));
            console.error(e);
        }
    };

    useEffect(() => {
        if (!depotsEmpty) return setDepotsList(storedDepots);
        fetchAllDepots();
    }, []);

    return { depotsList, fetchAllDepots };
};
