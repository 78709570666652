import colors from '~/ui/theme/colors';

function makeCircle(mapsApi, lat, lng, radius) {
    return new mapsApi.Circle({
        strokeColor: colors.palette.ocean,
        strokeOpacity: 1,
        strokeWeight: 1,
        fillColor: colors.palette.ocean,
        fillOpacity: 0.3,
        center: {
            lat,
            lng
        },
        radius
    });
}

function makePolygon(mapsAPI, hull, color = colors.palette.ocean) {
    return new mapsAPI.Polygon({
        paths: hull,
        strokeColor: color,
        strokeOpacity: 1,
        strokeWeight: 1,
        fillColor: color,
        fillOpacity: 0.3
    });
}
function drawMapObject(mapObject, mapInstance) {
    mapObject.setMap(mapInstance);
}
function removeMapObject(mapObject) {
    mapObject.setMap(null);
}
function getMapObjectFromAll(allMapObjects, id) {
    return allMapObjects[id];
}

function drawAllMapObjects(mapInstance, allObjects) {
    for (const id in allObjects) {
        const mapObject = getMapObjectFromAll(allObjects, id);
        drawMapObject(mapObject, mapInstance);
    }
}

function removeAllMapObjects(allMapObjects) {
    for (const id in allMapObjects) {
        const mapObject = getMapObjectFromAll(allMapObjects, id);
        removeMapObject(mapObject);
    }
}

function highlightPolygon(polygon, opacity = 0.6) {
    polygon.setOptions({
        fillOpacity: opacity
    });
}
function unHighlightPolygon(polygon, opacity = 0.3) {
    polygon.setOptions({
        fillOpacity: opacity
    });
}
function emphasizePolygon(isShowingRoutePolygons, polygon, mapInstance) {
    if (isShowingRoutePolygons) {
        highlightPolygon(polygon);
    } else {
        drawMapObject(polygon, mapInstance);
    }
}
function unEmphasizePolygon(isShowingRoutePolygons, polygon) {
    if (isShowingRoutePolygons) {
        unHighlightPolygon(polygon);
    } else {
        removeMapObject(polygon);
    }
}

function drawCircleOnMap(mapInstance, mapsApi, location, radius) {
    const circle = makeCircle(mapsApi, location.lat, location.lng, radius);
    drawMapObject(circle, mapInstance);
    return circle;
}

export const mapDrawingUtils = {
    makeCircle,
    makePolygon,
    drawMapObject,
    removeMapObject,
    getMapObjectFromAll,
    drawAllMapObjects,
    removeAllMapObjects,
    highlightPolygon,
    unHighlightPolygon,
    emphasizePolygon,
    unEmphasizePolygon,
    drawCircleOnMap
};
