import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';
import type { RootState } from '~/storeTypes';

/**
 * The selected card driver state
 */
type SelectedCardDriverState = {
    isShowDriverChangeOptionSelect: boolean;
};

const defaultSelectedCardDriver: SelectedCardDriverState = {
    isShowDriverChangeOptionSelect: true
};

export const selectedCardDriverSlice = createSlice({
    name: 'selectedCardDriver',
    initialState: defaultSelectedCardDriver,
    reducers: {
        /**
         * set whether to show DriverChangeOptionSelect or not
         * @method setIsShowDriverChangeOptionSelect
         * @param {SelectedCardDriverState} state - the current state
         * @param {PayloadAction<boolean>} action - the reducer's action object
         * @returns {SelectedCardDriverState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setIsShowDriverChangeOptionSelect } from '~/reducers/selectedCardDriverSlice';
         * import { useDispatch } from 'react-redux';
         *
         * const dispatch = useDispatch();
         * dispatch(setIsShowDriverChangeOptionSelect(true));
         */
        setIsShowDriverChangeOptionSelect: (
            state: SelectedCardDriverState,
            action: PayloadAction<boolean>
        ): SelectedCardDriverState => {
            state.isShowDriverChangeOptionSelect = action.payload;
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return defaultSelectedCardDriver;
        });
    }
});

export const { setIsShowDriverChangeOptionSelect } =
    selectedCardDriverSlice.actions;

/**
 * selects the current state of DriverChangeOptionSelect whether it is displayed or not
 * @method
 * @returns {boolean} the current state of DriverChangeOptionSelect
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectIsShowDriverChangeOptionSelect } from '~/reducers/selectedCardDriverSlice';
 * import { useSelector } from 'react-redux';
 *
 * // get the current DriverChangeOptionSelect state
 * const isShowDriverChangeOptionSelect = useSelector(selectIsShowDriverChangeOptionSelect);
 */
export const selectIsShowDriverChangeOptionSelect = (
    state: RootState
): boolean => state.selectedCardDriver.isShowDriverChangeOptionSelect;

export default selectedCardDriverSlice.reducer;
