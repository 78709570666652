import _ from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import dateUtils, { DAYS_OF_WEEK } from '~/utils/date-utils';
import ShiftBlock from '~/components/DriverVehicleManagementPage/DriverVehicleDetailDrawer/DriverDetail/ShiftBlock';

function addMissingDays(shifts) {
    for (const key in DAYS_OF_WEEK) {
        if (!shifts[key]) {
            shifts[key] = [];
        }
    }
    return shifts;
}

function createShiftListByDayOfWeek(shifts) {
    return Object.keys(shifts).reduce((acc, current) => {
        const days = [parseInt(current, 10)];
        const shiftSlots = shifts[current];
        if (_.isEmpty(shiftSlots)) {
            return acc;
        }
        const shift = _.isArray(shiftSlots) ? shiftSlots : [shiftSlots];
        const matchingShiftIndex = acc.findIndex((item) =>
            _.isEqual(item.shift, shift)
        );
        if (matchingShiftIndex === -1) {
            acc.push({ days, shift, id: uuidV4() });
            return acc;
        }
        const matchingShift = acc[matchingShiftIndex];
        matchingShift.days = [...matchingShift.days, ...days];
        return acc;
    }, []);
}

function transformShiftEndToDuration(timeslot) {
    const { start, end } = timeslot;
    const startDateTime = DateTime.fromFormat(start, 'T');
    const endDateTime = DateTime.fromFormat(end, 'T');
    return {
        start,
        duration: dateUtils.getDurationString(startDateTime, endDateTime)
    };
}

export const useDriverShifts = (initialShifts) => {
    const [shifts, setShifts] = useState(
        createShiftListByDayOfWeek(initialShifts)
    );
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);

    /**
     * Edits shift element with matching id with provided data
     * @param {string} id shift id
     * @param {string} newData object containing days and shift with start and end time
     */
    const handleShiftChange = (id, newData) => {
        const newShifts = shifts.map((item) => {
            if (item.id !== id) return item;

            return { ...item, ...newData };
        });
        setShifts(newShifts);
    };

    /**
     * Remove shift element with matching id from shifts array
     * @param {string} id shift id
     */
    const handleShiftBlockRemove = (id) => {
        const newShifts = _.cloneDeep(shifts);
        const removedShift = newShifts.find((shift) => shift.id === id);
        const removedIndex = newShifts.indexOf(removedShift);
        newShifts.splice(removedIndex, 1);
        setShifts(newShifts);
    };

    /**
     * Add a new empty shift element to shifts array
     */
    const handleAddNewShiftBlock = () => {
        const newShifts = _.cloneDeep(shifts);
        const newShiftBlock = {
            id: uuidV4(),
            days: [],
            shift: [{ start: '', end: '' }]
        };
        newShifts.push(newShiftBlock);
        setShifts(newShifts);
    };

    /**
     * Add a slot to the shift with matching id in shifts array
     * @param {string} id shift id
     */
    const handleAddNewSlot = (id) => {
        const newShifts = shifts.map((item) => {
            if (item.id !== id) return item;
            return {
                ...item,
                shift: [...item.shift, { start: '', end: '' }]
            };
        });
        setShifts(newShifts);
    };

    /**
     * Remove a slot from the shift with matching id in shifts array
     * @param {string} id shift id
     */
    const handleShiftSlotRemove = (id) => {
        const newShifts = shifts.map((item) => {
            const isEdited = item.id === id;
            if (isEdited) {
                item.shift = item.shift.slice(0, -1);
            }
            return item;
        });
        setShifts(newShifts);
    };

    /**
     * Returns shifts object that can used as `shiftTimes` field of driver object
     * @returns {Object} contains info on individual shift times with start and duration fields
     */
    const getShiftsToPersist = () => {
        const shiftsToPersist = shifts.reduce((acc, item) => {
            const selectedDays = item.days;
            const shiftSlots = item.shift;
            const shiftsWithDuration = shiftSlots.map(
                transformShiftEndToDuration
            );
            selectedDays.forEach((day) => {
                if (acc[day]) {
                    acc[day] = [...acc[day], ...shiftsWithDuration];
                } else {
                    acc[day] = shiftsWithDuration;
                }
            });
            return acc;
        }, {});
        return addMissingDays(shiftsToPersist);
    };

    /**
     * Returns an array of ShiftBlock components, 1 for each shift in shifts array
     * @returns {Array} ShiftBlock components that can be rendered
     */
    const renderShifts = () => {
        return shifts.map((item) => {
            const { days, shift, id } = item;
            return (
                <ShiftBlock
                    id={id}
                    key={id}
                    data={{
                        days,
                        shift
                    }}
                    onChange={handleShiftChange}
                    onShiftBlockRemove={handleShiftBlockRemove}
                    onAddNewSlot={handleAddNewSlot}
                    onShiftSlotRemove={handleShiftSlotRemove}
                />
            );
        });
    };

    useEffect(() => {
        const validateSave = () => {
            const isDisabled = shifts.some((item) => {
                const isEmpty = !item.days.length;
                const isSomeSlotsWithoutStartOrEnd = item.shift.some((slot) => {
                    return !slot.start || !slot.end;
                });

                return isEmpty || isSomeSlotsWithoutStartOrEnd;
            });
            setIsSaveDisabled(isDisabled);
        };
        validateSave();
    }, [shifts, isSaveDisabled]);

    return {
        shifts,
        isSaveDisabled,
        handleShiftChange,
        handleShiftBlockRemove,
        handleAddNewShiftBlock,
        handleAddNewSlot,
        handleShiftSlotRemove,
        getShiftsToPersist,
        renderShifts
    };
};
