import React from 'react';
import classNames from 'classnames';
import { RouteCardText } from '~/ui';
import localizationUtils from '~/utils/localization-utils';

function RouteCardAddress({ address, icon, className, ...extra }) {
    function _getClassName() {
        const defaultClassName = 'routecard-address';
        return classNames(defaultClassName, className);
    }

    return (
        <RouteCardText
            className={_getClassName()}
            icon={icon}
            data-testid={extra['data-testid'] || 'routecard-address'}
        >
            {localizationUtils.formatAddress(address)}
        </RouteCardText>
    );
}

export default RouteCardAddress;
