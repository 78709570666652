import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setCurrentPage } from '~/reducers/currentPageSlice';
import constants from '~/utils/constants';

const defaultPrevPage = constants.url.MAP;

export const usePageNavigation = () => {
    const [prevPage, setPrevPage] = useState(defaultPrevPage);
    const history = useHistory();
    const dispatch = useDispatch();

    /**
     * Sets the current page into a new page, setting the previous page to `history`
     * @param {String} page - the pathname of the new page
     * @param {Object} routeChangeOptions - optional object for adding params to
     */
    const goToPage = useCallback((page) => {
        dispatch(setCurrentPage(page));

        // only add to the history when pathname changed or no state
        const noState = !history?.location?.state;
        const isChangedPathname =
            history?.location?.pathname && history.location.pathname !== page;

        if (noState || isChangedPathname) {
            history.push({
                pathname: page,
                state: history.location
            });
        }
    }, []);

    /**
     * Sets the current page to the previous page set in `history`
     */
    const goToPrevPage = useCallback(() => {
        if (history?.location?.state) {
            // go back to the previous history location state
            history.goBack();
        } else {
            // go back to the predefined previous page (fallback)
            goToPage(prevPage);
        }
    }, [prevPage]);

    useEffect(() => {
        // set previous page's pathname to state as a fallback
        if (history?.location?.state?.pathname) {
            setPrevPage(history.location.state.pathname);
            goToPage(history.location.pathname);
        }
    }, [history]);

    return { goToPage, goToPrevPage };
};
