import { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import driversApi from '~/api/DriversApi';
import { store } from '~/store';
import {
    selectOldestLastLocationUpdate,
    updateDriversLocations
} from '~/reducers/driversLocationsSlice';
import { updateLiveDriverLatestLocationUpdate } from '~/reducers/liveDriversSlice';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { selectSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';

import {
    selectHasIsolatedRoutes,
    selectViewCardDetails
} from '~/reducers/mapSettingsSlice';

import { useMapUtils } from './useMapUtils';

import constants from '~/utils/constants';
import { idUtils } from '~/utils/id-utils';

export const useLocationUpdateInterval = (mapRouteMode) => {
    const dispatch = useDispatch();
    const locationUpdateInterval = useRef(null);
    const [driversInInterval, setDriversInInterval] = useState([]);
    const selectedDrawerCardId = useSelector(selectSelectedDrawerCardId);
    const selectedMapRoutes = useSelector(selectSelectedMapRoutes);
    const viewCardDetails = useSelector(selectViewCardDetails);
    const hasIsolatedRoutes = useSelector(selectHasIsolatedRoutes);

    const { isLiveRouteMode } = useMapUtils();

    function clearLocationInterval() {
        clearInterval(locationUpdateInterval.current);
        locationUpdateInterval.current = null;
    }

    const debounceSetDriversInInterval = useCallback(
        _.debounce((newDriverIds) => {
            if (!_.isEqual(newDriverIds, driversInInterval)) {
                clearLocationInterval();
                setDriversInInterval(newDriverIds);
            }
        }, constants.timings.SET_CONTENT),
        [driversInInterval]
    );

    const getAndStoreLocationUpdates = useCallback(async () => {
        try {
            const oldestLastLocationTime = selectOldestLastLocationUpdate(
                store.getState(),
                driversInInterval
            );
            const locationUpdatesRes = await driversApi.getLocationUpdates(
                driversInInterval,
                oldestLastLocationTime
            );
            const locationUpdates = locationUpdatesRes.data.data;
            dispatch(updateDriversLocations(locationUpdates));
            dispatch(updateLiveDriverLatestLocationUpdate(locationUpdates));
        } catch (err) {
            console.error('Failed to get location history:', err);
        }
    }, [driversInInterval]);

    useEffect(() => {
        if (isLiveRouteMode) {
            if (hasIsolatedRoutes && selectedMapRoutes.length > 0) {
                let selectedDrivers = [];
                // single driver selected
                selectedDrivers = selectedDrawerCardId
                    ? [selectedDrawerCardId]
                    : selectedDrivers;
                // multiple drivers selected
                selectedDrivers =
                    viewCardDetails && !selectedDrawerCardId
                        ? selectedMapRoutes
                        : selectedDrivers;
                // compile ids for interval
                const newDriverIds = selectedDrivers.map((clientDriverId) =>
                    idUtils.splitCombinedId(clientDriverId).pop()
                );
                debounceSetDriversInInterval(newDriverIds);
            } else {
                debounceSetDriversInInterval([]);
            }
        }
    }, [
        isLiveRouteMode,
        selectedDrawerCardId,
        selectedMapRoutes,
        viewCardDetails,
        hasIsolatedRoutes
    ]);

    useEffect(() => {
        if (driversInInterval.length && isLiveRouteMode) {
            getAndStoreLocationUpdates();
            if (mapRouteMode === constants.mapRouteModes.DISPATCHED) {
                locationUpdateInterval.current = setInterval(() => {
                    getAndStoreLocationUpdates();
                }, constants.timings.GET_WEB_INTERVAL);
            } else {
                clearLocationInterval();
            }
        } else {
            clearLocationInterval();
        }
    }, [isLiveRouteMode, driversInInterval]);

    useEffect(() => {
        if (mapRouteMode !== constants.mapRouteModes.DISPATCHED) {
            clearLocationInterval();
        }
    }, [mapRouteMode]);
};
