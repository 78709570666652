export default {
    getURL() {
        const endPoint = window.location.hostname;
        let socketServer;
        switch (endPoint) {
            case 'admin-uat.dev.wisesys.info':
                socketServer = 'wss://uat.socket.wisesys.info';
                break;
            case 'admin-stage.dev.wisesys.info':
                socketServer = 'wss://stage.socket.wisesys.info';
                break;
            case 'admin-sandbox.dev.wisesys.info':
                socketServer = 'wss://dev.socket.wisesys.info';
                break;
            default:
                socketServer = 'wss://dev.socket.wisesys.info';
        }
        return socketServer;
    }
};
