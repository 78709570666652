import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

export const appGlobalsSlice = createSlice({
    name: 'appGlobals',
    initialState: {
        isAppInitialized: false
    },
    reducers: {
        setAppInitialized: (state, action) => {
            state.isAppInitialized = action.payload;
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {
                isAppInitialized: false
            };
        });
    }
});

export const { setAppInitialized } = appGlobalsSlice.actions;
export const selectAppInitialized = (state) =>
    state.appGlobals.isAppInitialized;
