function checkIsDepotUnique(depots, planStop) {
    for (const planStopDepot of depots) {
        const { markerCoordinates: depotCoordinates } = planStopDepot;
        const { markerCoordinates } = planStop;
        const hasSameCoordinates = checkCoordinatesMatch(
            depotCoordinates,
            markerCoordinates
        );
        if (hasSameCoordinates) {
            return false;
        }
    }
    return true;
}

function checkCoordinatesMatch(a, b) {
    return a.lat === b.lat && a.lng === b.lng;
}

// https://tools.ietf.org/html/rfc7946#section-3.2
function getGeoJSONFeaturePoint(lat, lng, properties = {}) {
    return {
        type: 'Feature',
        properties,
        geometry: {
            type: 'Point',
            coordinates: [lng, lat]
        }
    };
}

export const markerUtils = {
    checkIsDepotUnique,
    getGeoJSONFeaturePoint
};
