import React from 'react';
import {
    ClusterMarker,
    DragMarker,
    RouteMarker,
    StopMarker,
    LiveStopMarker,
    theme
} from '~/ui';
import { idUtils } from '~/utils/id-utils';
import constants from '~/utils/constants';
import { Task } from '~/data-classes';

/** @typedef {import('~/data-classes/task').TaskDerivedStopData} TaskDerivedStopData */

const unplannedCSS = {
    backgroundColor: theme.colors['galaxy-500'],
    opacity: 0.8,
    color: theme.colors.comet,
    fontWeight: 500,
    fontSize: '1.4rem'
};

const unplannedStopNumber = '?';

function _getAdjustedCoordinates(coordinatesOffset, coordinates) {
    const offset = 0.0001;
    const { lat, lng } = coordinates;
    let newLat = lat;
    const combinedId = idUtils.getCombinedId(newLat, lng);
    if (!coordinatesOffset[combinedId]) {
        coordinatesOffset[combinedId] = offset;
    } else {
        newLat -= coordinatesOffset[combinedId];
        coordinatesOffset[combinedId] += offset;
    }
    return {
        lat: newLat,
        lng
    };
}

function makeLiveStopMarker(
    liveStop,
    stopNumber,
    mapRouteMode,
    onDemandDispatchMarkerEventHandler,
    driverColor,
    emittedEventHandler
) {
    const { id, location, isHighPriority, isCanceled } = liveStop;

    let liveStopStatus = constants.liveStopMarkerStatus.default;

    if (isHighPriority) {
        liveStopStatus = constants.liveStopMarkerStatus.priority;
    } else if (isCanceled) {
        liveStopStatus = constants.liveStopMarkerStatus.canceled;
    }

    return (
        <LiveStopMarker
            sequenceNumber={stopNumber}
            stopData={liveStop}
            status={liveStopStatus}
            mapRouteMode={mapRouteMode}
            key={id}
            lat={location.lat}
            lng={location.lng}
            onDemandDispatchMarkerEventHandler={
                onDemandDispatchMarkerEventHandler
            }
            emittedEventHandler={emittedEventHandler}
            colorCSS={driverColor}
        />
    );
}

function makeStopMarker(
    geoJSONFeature,
    coordinatesOffset,
    emittedEventHandler
) {
    const planStop = geoJSONFeature.properties;
    const {
        isPlanned,
        isTwoPart,
        stopName,
        markerCoordinates,
        clientRouteTaskId,
        type,
        taskId
    } = planStop;

    const adjustedCoordinates = _getAdjustedCoordinates(
        coordinatesOffset,
        markerCoordinates
    );
    let key = clientRouteTaskId;
    let { stopNumber, colorCSS } = planStop;
    if (!isPlanned) {
        colorCSS = unplannedCSS;
        stopNumber = unplannedStopNumber;
    }
    if (!isPlanned && isTwoPart) {
        key = idUtils.getCombinedId(type, taskId);
    }
    return (
        <StopMarker
            key={key}
            lat={adjustedCoordinates.lat}
            lng={adjustedCoordinates.lng}
            number={stopNumber}
            label={stopName?.toLowerCase()}
            emittedEventHandler={emittedEventHandler}
            colorCSS={colorCSS}
            isPlanned={isPlanned}
            data={planStop}
        />
    );
}

function makeUnassignedPlanStopMarker(
    unassignedPlanTasks,
    emittedEventHandler
) {
    return unassignedPlanTasks.reduce((markers, task) => {
        const unassignedTask = new Task(task);
        if (unassignedTask.isPickup) {
            markers.push(
                makeUnassignedStopMarker(
                    emittedEventHandler,
                    unassignedTask.pickupStopData
                )
            );
        }
        if (unassignedTask.isDelivery) {
            markers.push(
                makeUnassignedStopMarker(
                    emittedEventHandler,
                    unassignedTask.deliveryStopData
                )
            );
        }
        return markers;
    }, []);
}

/**
 * @param {TaskDerivedStopData} stopData
 * @param {Function} emittedEventHandler
 * @returns {StopMarker}
 */
function makeUnassignedStopMarker(emittedEventHandler, stopData) {
    const { clientRouteTaskId, lat, lng, label, isPlanned } = stopData;

    return (
        <StopMarker
            key={clientRouteTaskId}
            lat={lat}
            lng={lng}
            number={unplannedStopNumber}
            label={label}
            colorCSS={unplannedCSS}
            isPlanned={isPlanned}
            data={stopData}
            emittedEventHandler={emittedEventHandler}
        />
    );
}

/**
 * Creates a StopMarker for use in on demand dispatch.
 * @param {TaskDerivedStopData} stopData
 * @param {Function} emittedEventHandler
 * @returns {StopMarker}
 */
function makeOnDemandDispatchStopMarker(stopData, emittedEventHandler) {
    return (
        <StopMarker
            key={stopData.clientRouteTaskId}
            lat={stopData.lat}
            lng={stopData.lng}
            number={unplannedStopNumber}
            label={stopData.label}
            colorCSS={unplannedCSS}
            isPlanned={stopData.isPlanned}
            isHighPriority={stopData.isHighPriority}
            data={stopData}
            mapRouteMode={constants.mapRouteModes.DISPATCHED}
            emittedEventHandler={emittedEventHandler}
        />
    );
}

function makeClusterMarker(
    superCluster,
    geoJSONFeature,
    superClusterIndex,
    emittedEventHandler
) {
    const clusterId = geoJSONFeature.properties.cluster_id;
    const leaf = superCluster.getLeaves(geoJSONFeature.id, 1, 0)[0];
    const expansionZoom = superCluster.getClusterExpansionZoom(
        geoJSONFeature.properties.cluster_id
    );
    const numClustered = geoJSONFeature.properties.point_count;
    const id = idUtils.getCombinedId(superClusterIndex, clusterId);
    const planStop = leaf.properties;
    const [lng, lat] = leaf.geometry.coordinates;
    let { colorCSS } = planStop;
    if (!planStop.isPlanned) {
        colorCSS = unplannedCSS;
    }
    return (
        <ClusterMarker
            key={id}
            numClustered={numClustered}
            colorCSS={colorCSS}
            superClusterIndex={superClusterIndex}
            clusterId={clusterId}
            expansionZoom={expansionZoom}
            emittedEventHandler={emittedEventHandler}
            id={id}
            isPlanned={planStop.isPlanned}
            lat={lat}
            lng={lng}
        />
    );
}

function makeRouteMarker(routeLevelData, emittedEventHandler) {
    const { clientRouteId, markerCoordinates, colorCSS } = routeLevelData;

    return (
        <RouteMarker
            key={clientRouteId}
            routeLevelData={routeLevelData}
            emittedEventHandler={emittedEventHandler}
            lat={markerCoordinates.lat}
            lng={markerCoordinates.lng}
            colorCSS={colorCSS}
            selectable
        />
    );
}

function makeDragMarker(location, content) {
    return (
        <DragMarker lat={location.lat} lng={location.lng}>
            {content}
        </DragMarker>
    );
}

export const markerMaker = {
    makeLiveStopMarker,
    makeStopMarker,
    makeOnDemandDispatchStopMarker,
    makeClusterMarker,
    makeRouteMarker,
    makeDragMarker,
    makeUnassignedPlanStopMarker
};
