import React, { useState } from 'react';
import { DrawingManager, Map, Maps, Polygon } from './types';
import { MapContext } from './MapContext';

type Props = {
    children: React.ReactChild;
};

export const MapContextProvider = ({ children }: Props): JSX.Element => {
    const [activePolygon, setActivePolygon] = useState<Polygon | null>(null);
    const [drawingManagerInstance, setDrawingManagerInstance] =
        useState<DrawingManager | null>(null);
    const [isDrawingMode, setIsDrawingMode] = useState<boolean>(false);
    const [mapInstance, setMapInstance] = useState<Map | null>(null);
    const [mapsApi, setMapsApi] = useState<Maps | null>(null);

    return (
        <MapContext.Provider
            value={{
                activePolygon,
                drawingManagerInstance,
                isDrawingMode,
                mapInstance,
                mapsApi,
                setActivePolygon,
                setDrawingManagerInstance,
                setIsDrawingMode,
                setMapInstance,
                setMapsApi
            }}
        >
            {children}
        </MapContext.Provider>
    );
};
