import _ from 'lodash';
import {
    Address,
    ApiInventoryItem,
    ApiTask,
    Coordinates,
    DeliveryTask,
    PickupTask,
    ServiceWindow,
    TaskStatus
} from '~/api/types';
import constants from '~/utils/constants';
import { idUtils } from '~/utils/id-utils';

/**
 * @typedef {Object} TypeAgnosticStopData
 * @property {string} clientRouteId the related task's client route id
 * @property {boolean} isTwoPart indicates the related task is two part
 * @property {string} routeDate route date for related task
 * @property {string} taskId id of related task
 * @property {boolean} isPlanned indicates that related task is planned
 * @property {boolean} isHighPriority indicates that related task is high priority
 * @property {Function} toJSON serializing function for underlying task
 * @todo replace with TS type definitions for StopMarker props
 */

/**
 * @typedef {Object} TypeSpecificStopData
 * @property {string} clientRouteTaskId unique identifier for this derived stop
 * @property {Number} lat latitude for this derived stop
 * @property {Number} lng longitude for this derived stop
 * @property {string} label label for this stop
 * @todo replace with TS type definitions for StopMarker props
 */

/**
 * @typedef {{...TypeAgnosticStopData, ...TypeSpecificStopData}} TaskDerivedStopData
 * @todo replace with TS type definitions for StopMarker props
 */

/**
 * Task data class
 *
 * @category Data Classes
 *
 * @example
 * import Task from '~/data-classes/task/Task';
 *
 * const srcData = {};
 * const task = new Task(srcData);
 *
 */
export class Task {
    /**
     * The API source data
     * @type {ApiTask}
     */
    private readonly apiTask: ApiTask;

    // No constructor JSDoc to avoid duplicates in generated docs
    // https://github.com/jsdoc/jsdoc/issues/1775
    constructor(apiTask: ApiTask) {
        this.apiTask = apiTask;
    }

    /**
     * the task ID
     * @type {string}
     */
    get id(): string {
        return this.apiTask.id;
    }

    /**
     * the task route ID
     * @type {string}
     */
    get routeId(): string {
        return this.apiTask.routeId || 'unplanned';
    }

    /**
     * the task client ID
     * @type {string}
     */
    get clientId(): string {
        return this.apiTask.client;
    }

    /**
     * the combined client route task id
     * @type {string}
     */
    get clientRouteTaskId(): string {
        return idUtils.getCombinedId(this.clientId, this.routeId, this.id);
    }

    /**
     * the task name
     * @type {string}
     */
    get name(): string {
        return this.apiTask.name;
    }

    /**
     * the task labels array
     * @type {string[]}
     */
    get labels(): string[] {
        return this.apiTask.labels;
    }

    /**
     * determine whether this task is unassigned
     * @type {boolean}
     */
    get isUnassigned(): boolean {
        return this.apiTask.status === TaskStatus.UNASSIGNED;
    }

    /**
     * determine whether this task is dispatched
     * @type {boolean}
     */
    get isDispatched(): boolean {
        return this.apiTask.status === TaskStatus.ASSIGNED;
    }

    /**
     * determine whether this task is in progress
     * @type {boolean}
     */
    get isInProgress(): boolean {
        return this.apiTask.status === TaskStatus.IN_PROGRESS;
    }

    /**
     * determine whether this task is completed
     * @type {boolean}
     */
    get isCompleted(): boolean {
        return this.apiTask.status === TaskStatus.COMPLETED;
    }

    /**
     * determine whether this task is cancelled
     * @type {boolean}
     */
    get isCancelled(): boolean {
        return this.apiTask.status === TaskStatus.FAILED;
    }

    /**
     * determine whether this task is planned
     * @type {boolean}
     */
    get isPlanned(): boolean {
        return this.isUnassigned && this.routeId !== 'unplanned';
    }

    /**
     * determine whether the task is for pickup
     * @type {boolean}
     */
    get isPickup(): boolean {
        const pickupTask = this.apiTask as PickupTask;
        return Boolean(pickupTask.pickupLocation);
    }

    /**
     * determine whether the task is for delivery
     * @type {boolean}
     */
    get isDelivery(): boolean {
        const deliveryTask = this.apiTask as DeliveryTask;
        return Boolean(deliveryTask.deliveryLocation);
    }

    /**
     * determine whether the task is for both pickup and delivery
     * @type {boolean}
     */
    get isTwoPart(): boolean {
        return this.isPickup && this.isDelivery;
    }

    /**
     * determine whether the task is high priority
     * @type {boolean}
     */
    get isHighPriority(): boolean {
        return this.apiTask.props.priority === constants.priorityCodes.HIGH;
    }

    /**
     * the task pickup location name
     * @type {(string | undefined)}
     */
    get pickupLocationName(): string | undefined {
        return this.pickupTask?.pickupLocation.name as string;
    }

    /**
     * the task pickup address object
     * @type {Address}
     */
    get pickupLocationAddress(): Address {
        return _.pick(this.pickupTask?.pickupLocation, [
            'addressLine1',
            'addressLine2',
            'city',
            'state',
            'zipcode'
        ]) as Address;
    }

    /**
     * the pickup location latitude and longitude
     * @type {(Coordinates | undefined)}
     * @todo write address TS type definitions which will contain the TS coordinate definition
     */
    get pickupLocationLatLng(): Coordinates | undefined {
        return this.pickupTask?.pickupLocation.location as Coordinates;
    }

    /**
     * the task pickup time as ISO date-time string
     * @type {(string | undefined)}
     */
    get pickupTime(): string | null {
        return this.pickupTask?.props.pickupTime ?? null;
    }

    /**
     * the task pickup service time as ISO duration string
     * @type {(string | undefined)}
     */
    get pickupServiceTime(): string | undefined {
        return this.pickupTask?.props.pickupServiceTime;
    }

    /**
     * the task pickup window
     * the task's location may have more than 1 service windows.
     * @type {ServiceWindow}
     */
    get pickupWindow(): ServiceWindow {
        const serviceWindows = this.pickupTask?.props.pickupWindow || [];
        return serviceWindows.length === 1 ? serviceWindows[0] : serviceWindows;
    }

    /**
     * the task pickup inventory array
     * @type {(Array<Record<string, unknown>> | undefined)}
     */
    get pickupInventory(): Array<ApiInventoryItem> | undefined {
        return this.pickupTask?.props.pickupInventory;
    }

    /**
     * the task original pickup labels
     * @type {string[]}
     */
    get pickupLabels(): string[] {
        return this.pickupTask?.props.originalPickup?.labels || this.labels;
    }

    /**
     * the task delivery location name
     * @type {string}
     */
    get deliveryLocationName(): string {
        return (
            (this.deliveryTask?.deliveryLocation.name as string) || this.name
        );
    }

    /**
     * the task delivery address object
     * @type {Address}
     */
    get deliveryLocationAddress(): Address {
        return _.pick(this.deliveryTask?.deliveryLocation, [
            'addressLine1',
            'addressLine2',
            'city',
            'state',
            'zipcode'
        ]) as Address;
    }

    /**
     * the delivery location latitude and longitude
     * @type {(Coordinates | undefined)}
     */
    get deliveryLocationLatLng(): Coordinates | undefined {
        return this.deliveryTask?.deliveryLocation.location as Coordinates;
    }

    /**
     * the task delivery time as ISO date-time string
     * @type {(string | null)}
     */
    get deliveryTime(): string | null {
        return this.deliveryTask?.props.deliveryTime ?? null;
    }

    /**
     * the task delivery service time as ISO duration string
     * @type {(string | undefined)}
     */
    get deliveryServiceTime(): string | undefined {
        return this.deliveryTask?.props.deliveryServiceTime;
    }

    /**
     * the task delivery window
     * the task's location may have more than 1 service windows.
     * @type {ServiceWindow}
     */
    get deliveryWindow(): ServiceWindow {
        const serviceWindows = this.deliveryTask?.props.deliveryWindow || [];
        return serviceWindows.length === 1 ? serviceWindows[0] : serviceWindows;
    }

    /**
     * the task delivery inventory array
     * @type {(Array<Record<string, unknown>> | undefined)}
     */
    get deliveryInventory(): Array<ApiInventoryItem> | undefined {
        return this.deliveryTask?.props.deliveryInventory;
    }

    /**
     * the task original delivery labels
     * @type {string[]}
     */
    get deliveryLabels(): string[] {
        return this.deliveryTask?.props.originaldelivery?.labels || this.labels;
    }

    /**
     * the route date for this task
     * @type {string}
     */
    get routeDate(): string {
        return this.apiTask.routeDate;
    }

    /**
     * the data that is common between the pickup and delivery parts required for use with StopMarker
     * @type {TypeAgnosticStopData}
     * @todo write TS type definitions for StopMarker `data` props
     */
    get typeAgnosticStopData(): Record<string, unknown> {
        return {
            clientRouteId: idUtils.getCombinedId(this.clientId, this.routeId),
            isTwoPart: this.isTwoPart,
            routeDate: this.routeDate,
            taskId: this.id,
            isPlanned: this.isPlanned,
            isHighPriority: this.isHighPriority,
            toJSON: () => this.toJSON
        };
    }

    /**
     * the data that is required for use with StopMarker for the pickup part
     * @type {TaskDerivedStopData}
     * @todo write TS type definitions for StopMarker `data` props
     */
    get pickupStopData(): Record<string, unknown> {
        return {
            ...this.typeAgnosticStopData,
            clientRouteTaskId: idUtils.getCombinedId(
                this.clientId,
                this.routeId,
                constants.taskTypes.PICKUP,
                this.id
            ),
            lat: this.pickupLocationLatLng?.lat,
            lng: this.pickupLocationLatLng?.lng,
            label: this.pickupLocationName
        };
    }

    /**
     * the data that is required for use with StopMarker for the delivery part
     * @type {TaskDerivedStopData}
     * @todo write TS type definitions for StopMarker `data` props
     */
    get deliveryStopData(): Record<string, unknown> {
        return {
            ...this.typeAgnosticStopData,
            clientRouteTaskId: idUtils.getCombinedId(
                this.clientId,
                this.routeId,
                constants.taskTypes.DELIVERY,
                this.id
            ),
            lat: this.deliveryLocationLatLng?.lat,
            lng: this.deliveryLocationLatLng?.lng,
            label: this.deliveryLocationName
        };
    }

    /**
     * Narrows the type of the underlying source {@link ApiTask}
     * to {@link PickupTask}. Returns `null` if the task has no pickup
     * part.
     * @type {(PickupTask | null)}
     */
    get pickupTask(): PickupTask | null {
        return this.isPickup ? (this.apiTask as PickupTask) : null;
    }

    /**
     * Narrows the type of the underlying source {@link ApiTask}
     * to {@link DeliveryTask}. Returns `null` if the task has no delivery
     * part.
     * @type {(DeliveryTask | null)}
     */
    get deliveryTask(): DeliveryTask | null {
        return this.isDelivery ? (this.apiTask as DeliveryTask) : null;
    }

    /**
     * the 2-part task id
     * @type {string}
     */
    get twoPartId(): string {
        return this.apiTask.twoPartId || this.id;
    }

    /**
     * Serializes this class back to JSON
     * @returns {ApiTask}
     */
    toJSON(): ApiTask {
        return this.apiTask;
    }
}
