import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectMainClient } from '~/reducers/mainClientSlice';
import fileUtils from '~/utils/file-utils';

export const useDownloadTaskTemplate = () => {
    const { t } = useTranslation(['taskUpload']);
    const mainClient = useSelector(selectMainClient);

    const fileName = t('taskUploadTemplateFileName', {
        clientName: mainClient.name
    });

    const excelData = [
        {
            Departure_Date_Time_: '10/08/2020',
            Priority_: '1',
            Sequence_Number_: '1',
            Route_ID_: 'AB1',
            Task_ID_: '67002',
            Trailer_ID_: 'T27721',
            Trailer_Type_: 'End_Load_22',
            Service_Time_: '00:18:32',
            Open_Time_: '6:00',
            Close_Time_: '20:00',
            TW1_Open_Time_: '5:00',
            TW1_Close_Time_: '21:00',
            TW2_Open_Time_: '10:00',
            TW2_Close_Time_: '13:00',
            Location_ID_: 'Wise Systems',
            Description_: 'Headquarters',
            Address_Line1_: '84 Sherman Street',
            City_: 'Cambridge',
            State_: 'MA',
            Zip_Code_: '02140',
            CEs_: '10',
            Weight_: '1000',
            Latitude_: '33.2280276',
            Longitude_: '-96.9840259',
            Phone_Number_: '857-327-5802',
            Contact_Email_: 'support@wisesystems.com',
            Premise_Type_: 'OffPremise'
        }
    ];

    const excelOptions = {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary'
    };

    const downloadTaskTemplate = () => {
        const newExcelFile = fileUtils.createExcelSheet(
            excelData,
            excelOptions,
            fileName
        );

        return saveAs(
            new Blob(
                [
                    fileUtils.string2ArrayBuffer(
                        XLSX.write(newExcelFile, excelOptions)
                    )
                ],
                {
                    type: 'application/octet-stream'
                }
            ),
            fileName
        );
    };

    return {
        downloadTaskTemplate
    };
};
