import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    selectLatestCepResult,
    selectIsSchedulerRunning,
    selectIsLatestCepResultForMainClient
} from '~/reducers/schedulerProgressSlice';
import constants from '~/utils/constants';
import useToastContext from './toastContext';

export const useShowToastWithSchedulerRunningError = () => {
    const { t } = useTranslation('error');
    const { addToast } = useToastContext();
    const { data: { stage: cepStage } = {} } =
        useSelector(selectLatestCepResult) || {};
    const isLatestCepResultForMainClient = useSelector(
        selectIsLatestCepResultForMainClient
    );
    const isSchedulerRunning = useSelector(selectIsSchedulerRunning);
    const isFirstRender = useRef(true);
    const isSchedulerRunningError =
        isLatestCepResultForMainClient &&
        cepStage === constants.schedulerKeys.schedulerProgressError;

    useEffect(() => {
        const isAddToast =
            !isFirstRender.current &&
            !isSchedulerRunning &&
            isSchedulerRunningError;
        isFirstRender.current = false;

        if (!isAddToast) return;

        addToast({
            message: t('schedulerRunningError'),
            variant: 'error'
        });
    }, [isSchedulerRunning]);
};
