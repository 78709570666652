import React from 'react';
import classNames from 'classnames';
import { Icon } from '~/ui';
import './routecard-labels.scss';

const maxNumOfLabels = 3;

function RouteCardLabels({
    labels,
    icon,
    iconColor = 'galaxy-500',
    className,
    ...extra
}) {
    function _getIcon() {
        if (!icon || !icon.length) return null;
        return (
            <Icon
                icon={icon}
                color={iconColor}
                marginRight="0.4rem"
                data-testid={
                    extra['data-testid']
                        ? `${extra['data-testid']}-icon`
                        : 'routecard-labels-icon'
                }
            />
        );
    }

    function _getLabels() {
        const overMax = labels.length > maxNumOfLabels;
        return (
            <ul className="routecard-labels-list _d-flex _ai-center _jc-flex-start _fw-wrap">
                {labels.reduce((all, item, idx) => {
                    if (overMax && idx > maxNumOfLabels) return all;
                    const displayedMaxLabels =
                        overMax && idx === maxNumOfLabels;
                    const labelItem = displayedMaxLabels
                        ? `${labels.length - maxNumOfLabels}+`
                        : item;
                    const labelTestId = displayedMaxLabels ? 'over' : 'label';
                    all.push(
                        <li
                            key={labelItem}
                            data-testid={
                                extra['data-testid']
                                    ? `${extra['data-testid']}-${labelTestId}`
                                    : `routecard-labels-${labelTestId}`
                            }
                        >
                            <span>{labelItem}</span>
                        </li>
                    );
                    return all;
                }, [])}
            </ul>
        );
    }

    function _getClassName() {
        const defaultClassName = 'routecard-labels _d-flex';
        return classNames(defaultClassName, className);
    }

    if (!labels || !labels.length) return null;
    return (
        <div
            className={_getClassName()}
            data-testid={extra['data-testid'] || 'routecard-labels'}
        >
            {_getIcon()}
            {_getLabels()}
        </div>
    );
}

RouteCardLabels.maxNumOfLabels = maxNumOfLabels;

export default RouteCardLabels;
