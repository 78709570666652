import React from 'react';
import { useTranslation } from 'react-i18next';
import PT from 'prop-types';
import classNames from 'classnames';
import { Alert, Collapsible, LabelInput } from '~/ui';

import './AddressForm.scss';

function AddressForm(props) {
    const { values, onChange = () => {}, ...extra } = props;
    const { t } = useTranslation(['taskManagement', 'common']);

    const addressInputs = [
        { name: 'addressLine1', label: t('editAddress.address') },
        { name: 'addressLine2', placeholder: t('editAddress.address2') },
        { name: 'city', label: t('editAddress.city') },
        { name: 'state', label: t('editAddress.province') },
        { name: 'zipcode', label: t('editAddress.postalCode') },
        { name: 'country', label: t('editAddress.country') }
    ];

    const coordinateInputs = [
        {
            name: 'latitude',
            label: t('editAddress.latitude'),
            isMandatory: false
        },
        {
            name: 'longitude',
            label: t('editAddress.longitude'),
            isMandatory: false
        }
    ];

    function renderAsteriskLabel(label) {
        if (!label) return null;
        return (
            <span>
                {label}
                <span className="addressform-asterisk">*</span>
            </span>
        );
    }

    /**
     * Renders the input with LabelInput
     * @param {Object} input
     * @param {String} input.name - Input name for the value
     * @param {String} input.label - Label content
     * @param {Boolean} input.isMandatory - Display asterisk for mandatory field
     * @param {String} input.placeholder - Placeholder content (same as label by default)
     * @returns {JSX.Element}
     */
    function renderLabelInput({
        name,
        label,
        isMandatory = true,
        placeholder
    }) {
        return (
            <LabelInput
                key={name}
                className={`addressform-input_${name}`}
                label={isMandatory ? renderAsteriskLabel(label) : label}
                placeholder={placeholder || label}
                value={values[name]}
                onChange={({ target: { value } }) => onChange(name, value)}
                data-testid={`labelinput-${name}`}
            />
        );
    }

    return (
        <div
            className={classNames('_d-grid addressform', extra.className)}
            data-testid={extra['data-testid'] || 'address-form'}
        >
            {addressInputs.map((addressInput) =>
                renderLabelInput(addressInput)
            )}
            <Collapsible
                className="addressform-collapsible"
                textClose={t('editAddress.textClose')}
                textOpen={t('editAddress.textOpen')}
                iconClose="chevronDown"
                iconOpen="chevronUp"
                iconColor="meteor"
                iconPosition="left"
                data-testid="addressform-collapsible"
            >
                <div className="_d-grid addressform-coords">
                    <Alert
                        variant="info"
                        icon="infoLine"
                        iconSize="m"
                        iconColor="meteor"
                        className="addressform-coords_alert"
                        data-testid="addressform-info"
                    >
                        {t('editAddress.coordinateAlert')}
                    </Alert>
                    {coordinateInputs.map((coordinateInput) =>
                        renderLabelInput(coordinateInput)
                    )}
                </div>
            </Collapsible>
        </div>
    );
}

AddressForm.propTypes = {
    /** Values of each input */
    values: PT.objectOf(PT.string),
    /** OnChange event when an input value is changed */
    onChange: PT.func
};

export default AddressForm;
