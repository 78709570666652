import React from 'react';
import PT from 'prop-types';

import { IconButton } from '~/ui';
import './routecard-actionbutton.scss';
import classNames from 'classnames';

function RouteCardActionButton({
    className,
    disabled,
    visible = true,
    text,
    icon,
    iconSize = 'm',
    iconColor = 'meteor',
    iconStyle,
    tooltipMessage,
    tooltipPlacement = 'bottom',
    onClick = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    ...extra
}) {
    function _getClassName() {
        const defaultClassName =
            'routecard-actionbutton _d-flex _fd-column _ai-center';
        const conditionalClassName = {
            'routecard-actionbutton_disabled': disabled
        };
        return classNames(defaultClassName, conditionalClassName, className);
    }

    return (
        <div
            className={_getClassName()}
            data-testid={extra['data-testid'] || 'routecard-actionbutton'}
        >
            {icon && (
                <IconButton
                    className="routecard-actionbutton_icon"
                    style={iconStyle}
                    disabled={disabled}
                    visible={visible}
                    icon={icon}
                    iconSize={iconSize}
                    iconColor={iconColor}
                    tooltipMessage={tooltipMessage}
                    tooltipPlacement={tooltipPlacement}
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}_icon`
                            : 'routecard-actionbutton_icon'
                    }
                />
            )}

            {text && (
                <div
                    className="routecard-actionbutton_text _text-2"
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}_text`
                            : 'routecard-actionbutton_text'
                    }
                >
                    {text}
                </div>
            )}
        </div>
    );
}

RouteCardActionButton.propTypes = {
    /** additional css classes to attach to this component */
    className: PT.string
};

export default RouteCardActionButton;
