import axios from 'axios';
import constants from '~/utils/constants';

/**
 * Implementations of the API methods under the `/drivers` path
 *
 * @category API
 */
export default class DriversApi {
    /**
     * Path of the API endpoint
     */
    private static readonly path = '/drivers';

    // TODO: Remove these after this block is fully typed
    /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
    static get() {
        return axios.get(this.path, {
            params: {
                extent: 'vehicle',
                limit: 1000
            },
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }
    /* eslint-enable @typescript-eslint/explicit-module-boundary-types */

    // TODO: Remove these after this block is fully typed
    /* eslint-disable @typescript-eslint/no-explicit-any */
    /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
    static getLocationUpdates(driverIds: any, after: any) {
        if (!driverIds || driverIds.length === 0) {
            return Promise.reject('driver ids not provided');
        }

        return axios.get(`${this.path}/locations`, {
            params: {
                driver_ids: driverIds,
                after
            },
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */
    /* eslint-enable @typescript-eslint/explicit-module-boundary-types */
}
