/* eslint-disable camelcase */

import axios from 'axios';

import constants from '~/utils/constants';
import { AxiosApiResponse } from './types';

interface PostCourierSchedulesParams {
    taskAssignerId?: string;
    driverGroupId?: string;
    close_routes?: boolean;
}

/** Response when no `driverGroupId` is provided in params */
interface PostCourierSchedulesResponseData1 {
    driverGroupSchedulerIds?: {
        driverGroupId?: string;
        schedulerId?: string;
        error?: unknown;
    }[];
}

/** Response when a `driverGroupId` is provided in params */
interface PostCourierSchedulesResponseData2 {
    driverGroupSchedulerId?: string;
}

type PostCourierSchedulesResponseData =
    | PostCourierSchedulesResponseData1
    | PostCourierSchedulesResponseData2;

interface StopSchedulerResponseData {
    message?: string;
}

/**
 * Implementations of API methods under the `/courier_schedules` path
 *
 * @category API
 */
export default class CourierSchedulesApi {
    /**
     * Path of the API endpoint
     */
    private static readonly path = '/courier_schedules';

    static post({
        taskAssignerId,
        driverGroupId,
        close_routes
    }: PostCourierSchedulesParams): Promise<
        AxiosApiResponse<PostCourierSchedulesResponseData>
    > {
        if (!taskAssignerId || !driverGroupId) {
            return Promise.reject('missing taskAssignerID or driverGroupId');
        }

        return axios.post(
            this.path,
            { taskAssignerId, driverGroupId, close_routes },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }

    static stopScheduler(
        taskAssignerId: string
    ): Promise<AxiosApiResponse<StopSchedulerResponseData>> {
        if (!taskAssignerId) {
            return Promise.reject('missing taskAssignerID');
        }

        return axios.post(
            `${this.path}?action=stop`,
            { taskAssignerId },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }
}
