import generalUtils from './utils/general-utils';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE
} from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

import { activeClientsSlice } from '~/reducers/activeClientsSlice';
import { addTaskSlice } from '~/reducers/addTaskSlice';
import { appGlobalsSlice } from '~/reducers/appGlobalsSlice';
import { loadTasksSlice } from '~/reducers/loadTasksSlice';
import { availableDriversIdSlice } from '~/reducers/availableDriversIdSlice';
import { bookingMetricsSlice } from '~/reducers/bookingMetricsSlice';
import { bookingsByClientSlice } from '~/reducers/bookingsByClientSlice';
import { clientsSlice } from '~/reducers/clientsSlice';
import { clientTemplateDataSlice } from '~/reducers/clientTemplateDataSlice';
import { currentPageSlice } from '~/reducers/currentPageSlice';
import { customersSlice } from '~/reducers/customersSlice';
import { customerSearchResultsSlice } from '~/reducers/customerSearchResultsSlice';
import { dataLoadingSlice } from './reducers/dataLoadingSlice';
import { depotsSlice } from './reducers/depotsSlice';
import { detectWebSolutionSlice } from '~/reducers/detectWebSolutionSlice';
import { driversByClientSlice } from '~/reducers/driversByClientSlice';
import { driversLocationsSlice } from '~/reducers/driversLocationsSlice';
import { filteredDataCountSlice } from '~/reducers/filteredDataCountSlice';
import { filteredOutRoutesSlice } from '~/reducers/filteredOutRoutesSlice';
import { forecastSlice } from '~/reducers/forecastSlice';
import { geofenceSlice } from '~/reducers/geofenceSlice';
import { hiddenRoutesSlice } from '~/reducers/hiddenRoutesSlice';
import { lastPlanMapCenterSlice } from '~/reducers/lastPlanMapCenterSlice';
import { lastPlanMapZoomSlice } from '~/reducers/lastPlanMapZoomSlice';
import { editHistoryByClientSlice } from '~/reducers/editHistoryByClientSlice';
import { liveDriversSlice } from '~/reducers/liveDriversSlice';
import { mainClientSlice } from '~/reducers/mainClientSlice';
import { mapDrawerSettingsSlice } from './reducers/mapDrawerSettingsSlice';
import { mapSettingsSlice } from './reducers/mapSettingsSlice';
import { mapSideBarSlice } from '~/reducers/mapSideBarSlice';
import { onDemandDispatchTaskCountSlice } from '~/reducers/onDemandDispatchTaskCountSlice';
import { pendingJobsSlice } from '~/reducers/pendingJobsSlice';
import { planClientsLevelData } from '~/reducers/planClientsLevelDataSlice';
import { planRoutesLevelData } from '~/reducers/planRoutesLevelDataSlice';
import { planStopsLevelData } from '~/reducers/planStopsLevelDataSlice';
import { processIndicatorSlice } from '~/reducers/processIndicatorSlice';
import { routePlanSummaryMetricsSlice } from '~/reducers/routePlanSummaryMetricsSlice';
import { routePlansByDateSlice } from '~/reducers/routePlansByDateSlice';
import { routesDrawerFilterSlice } from './reducers/routesDrawerFilterSlice';
import { routesDrawerSortSlice } from './reducers/routesDrawerSortSlice';
import { schedulerProgressSlice } from '~/reducers/schedulerProgressSlice';
import { selectedCardDriverSlice } from '~/reducers/selectedCardDriverSlice';
import { selectedDateSlice } from '~/reducers/selectedDateSlice';
import { selectedDepotIdSlice } from '~/reducers/selectedDepotIdSlice';
import { selectedDrawerCardDataSlice } from '~/reducers/selectedDrawerCardDataSlice';
import { selectedDrawerCardIdSlice } from '~/reducers/selectedDrawerCardIdSlice';
import { selectedDriverVehicleRowId } from './reducers/selectedDriverVehicleRowIdSlice';
import { selectedMapRoutesSlice } from '~/reducers/selectedMapRoutesSlice';
import { selectedMapStopsSlice } from '~/reducers/selectedMapStopsSlice';
import { selectedTaskFiltersSlice } from '~/reducers/selectedTaskFiltersSlice';
import { selectedTaskIdsSlice } from '~/reducers/selectedTaskIdsSlice';
import { selectedTaskRowIdSlice } from '~/reducers/selectedTaskRowIdSlice';
import { selectedUserGroupIdSlice } from '~/reducers/selectedUserGroupIdSlice';
import { taskMetricsSlice } from '~/reducers/taskMetricsSlice';
import { tasksSlice } from './reducers/tasksSlice';
import { taskUploadSlice } from './reducers/taskUploadSlice';
import { toastsSlice } from '~/reducers/toastsSlice';
import { unassignedPlanTaskCountSlice } from '~/reducers/unassignedPlanTaskCountSlice';
import { userGroupsByIdSlice } from '~/reducers/userGroupsByIdSlice';
import { userGroupsForCurrentUserSlice } from '~/reducers/userGroupsForCurrentUserSlice';
import { usersByClientSlice } from '~/reducers/usersByClientSlice';
import { usersByIdSlice } from '~/reducers/usersByIdSlice';
import { webColorsSlice } from './reducers/webColorsSlice';
import { uploadResultsSlice } from '~/reducers/uploadResultsSlice';
import { vehiclesSlice } from '~/reducers/vehiclesSlice';
import { webSolutionSlice } from '~/reducers/webSolutionSlice';

// keep `reducers` sorted alphabetically
// to help find them from `redux-devTools` list
const reducers = combineReducers({
    activeClients: activeClientsSlice.reducer,
    addTask: addTaskSlice.reducer,
    appGlobals: appGlobalsSlice.reducer,
    availableDriversId: availableDriversIdSlice.reducer,
    bookingMetrics: bookingMetricsSlice.reducer,
    bookingsByClient: bookingsByClientSlice.reducer,
    clients: clientsSlice.reducer,
    clientTemplateData: clientTemplateDataSlice.reducer,
    currentPage: currentPageSlice.reducer,
    customers: customersSlice.reducer,
    customerSearchResults: customerSearchResultsSlice.reducer,
    dataLoading: dataLoadingSlice.reducer,
    depots: depotsSlice.reducer,
    detectWebSolution: detectWebSolutionSlice.reducer,
    driversByClient: driversByClientSlice.reducer,
    driversLocations: driversLocationsSlice.reducer,
    editHistoryByClient: editHistoryByClientSlice.reducer,
    filteredDataCount: filteredDataCountSlice.reducer,
    filteredOutRoutes: filteredOutRoutesSlice.reducer,
    forecast: forecastSlice.reducer,
    geofence: geofenceSlice.reducer,
    hiddenRoutes: hiddenRoutesSlice.reducer,
    lastPlanMapCenter: lastPlanMapCenterSlice.reducer,
    lastPlanMapZoom: lastPlanMapZoomSlice.reducer,
    liveDrivers: liveDriversSlice.reducer,
    loadTasks: loadTasksSlice.reducer,
    mainClient: mainClientSlice.reducer,
    mapDrawerSettings: mapDrawerSettingsSlice.reducer,
    mapSettings: mapSettingsSlice.reducer,
    mapSideBar: mapSideBarSlice.reducer,
    onDemandDispatchTaskCount: onDemandDispatchTaskCountSlice.reducer,
    pendingJobs: pendingJobsSlice.reducer,
    planClientsLevelData: planClientsLevelData.reducer,
    planRoutesLevelData: planRoutesLevelData.reducer,
    planStopsLevelData: planStopsLevelData.reducer,
    processIndicator: processIndicatorSlice.reducer,
    routePlanSummaryMetrics: routePlanSummaryMetricsSlice.reducer,
    routePlansByDate: routePlansByDateSlice.reducer,
    routesDrawerFilter: routesDrawerFilterSlice.reducer,
    routesDrawerSort: routesDrawerSortSlice.reducer,
    schedulerProgress: schedulerProgressSlice.reducer,
    selectedCardDriver: selectedCardDriverSlice.reducer,
    selectedDate: selectedDateSlice.reducer,
    selectedDepotId: selectedDepotIdSlice.reducer,
    selectedDrawerCardData: selectedDrawerCardDataSlice.reducer,
    selectedDrawerCardId: selectedDrawerCardIdSlice.reducer,
    selectedDriverVehicleRowId: selectedDriverVehicleRowId.reducer,
    selectedMapRoutes: selectedMapRoutesSlice.reducer,
    selectedMapStops: selectedMapStopsSlice.reducer,
    selectedTaskFilters: selectedTaskFiltersSlice.reducer,
    selectedTaskIds: selectedTaskIdsSlice.reducer,
    selectedTaskRowId: selectedTaskRowIdSlice.reducer,
    selectedUserGroupId: selectedUserGroupIdSlice.reducer,
    taskMetrics: taskMetricsSlice.reducer,
    tasks: tasksSlice.reducer,
    taskUpload: taskUploadSlice.reducer,
    toasts: toastsSlice.reducer,
    unassignedPlanTaskCount: unassignedPlanTaskCountSlice.reducer,
    userGroupsById: userGroupsByIdSlice.reducer,
    userGroupsForCurrentUser: userGroupsForCurrentUserSlice.reducer,
    usersByClient: usersByClientSlice.reducer,
    usersById: usersByIdSlice.reducer,
    uploadResults: uploadResultsSlice.reducer,
    vehicles: vehiclesSlice.reducer,
    webColors: webColorsSlice.reducer,
    webSolution: webSolutionSlice.reducer
});

export type ReduxState = ReturnType<typeof reducers>;

const createNoopStorage = () => {
    return {
        getItem() {
            return Promise.resolve(null);
        },
        setItem(_key: unknown, value: unknown) {
            return Promise.resolve(value);
        },
        removeItem() {
            return Promise.resolve();
        }
    };
};

// Workaround for failing to create sync storage.
// More about workaround: https://github.com/vercel/next.js/discussions/15687#discussioncomment-45319
const storage =
    typeof window !== 'undefined'
        ? createWebStorage('local')
        : createNoopStorage();

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        'customerSearchResults',
        'schedulerProgress',
        'uploadResults',
        'processIndicator',
        'depots',
        'driversByClient',
        'tasks',
        'vehicles',
        'loadTasks',
        'webSolution'
    ]
};

const persistedReducer = persistReducer(persistConfig, reducers);

// updated middleware callback per [Redux Toolkit getDefaultMiddleware](https://redux-toolkit.js.org/api/getDefaultMiddleware)
/** @TODO Danny: Figure out how to type this correctly */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const defaultMiddleware: any = (
    getDefaultMiddleware: CurriedGetDefaultMiddleware<ReduxState>
) => {
    return getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    });
};

// enable devTools only for `local`, `stage`, and `sandbox` environments
const isDevToolsEnabled = generalUtils.isFlaggedFeatureDisplayed();

const store = configureStore({
    devTools: isDevToolsEnabled,
    reducer: persistedReducer,
    middleware: defaultMiddleware
});

const persistor = persistStore(store);

export { store, persistor };
