import PT from 'prop-types';
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import { Icon, IconButton, useClickOutside } from '~/ui';
import './modal.scss';

function Modal({
    title,
    children,
    variant = 'small',
    className,
    overlayClassName,
    icon,
    iconColor,
    iconSize = 'm',
    hasCustomHeader = false,
    hasAutoHide = true,
    hasCloseButton = true,
    onHide = () => {}
}) {
    const domElement = document.getElementById('modal-root');
    const ref = useRef();

    function autoHide() {
        return hasAutoHide && onHide();
    }

    useClickOutside(ref, autoHide);

    function _getClassName() {
        const defaultClassName = 'modal';
        const conditionalClasses = {
            [`${defaultClassName}_${variant}`]: variant
        };
        return classNames(defaultClassName, conditionalClasses, className);
    }

    // Modal will be rendered under modal-root div
    return ReactDOM.createPortal(
        <div className={classNames('overlay', overlayClassName)}>
            <div className={_getClassName()} ref={ref}>
                <div className="_d-flex _fd-column modal-content-wrapper">
                    {hasCustomHeader ? (
                        <>{children}</>
                    ) : (
                        <>
                            <div className="modal-header">
                                <div className="modal-title _d-flex _ai-center">
                                    {icon && (
                                        <Icon
                                            icon={icon}
                                            size={iconSize}
                                            color={iconColor}
                                        />
                                    )}
                                    <span>{title}</span>
                                </div>
                                {hasCloseButton && (
                                    <IconButton
                                        className="icon-wrapper"
                                        icon="cancel"
                                        iconColor="galaxy-800"
                                        onClick={onHide}
                                        data-testid="modal-close-icon"
                                    />
                                )}
                            </div>
                            {children}
                        </>
                    )}
                </div>
            </div>
        </div>,
        domElement
    );
}

Modal.propTypes = {
    /* Modal content */
    children: PT.node,
    /* Hide on click outside of modal */
    hasAutoHide: PT.bool,
    /* Has a close button */
    hasCloseButton: PT.bool,
    /* Modal title */
    title: PT.node,
    /* Hide modal function */
    onHide: PT.func,
    /* Style variant */
    variant: PT.oneOf(['small', 'large', 'dialogue', 'small-dialogue', 'date']),
    /* Class name */
    className: PT.string
};

export default Modal;
