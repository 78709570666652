/* eslint-disable camelcase */

import axios from 'axios';
import { AxiosApiResponse } from './types';

interface GetWebMultiClientParams {
    client_ids?: string[];
}

interface GetWebMultiClientResponseData {
    message?: string;
    success?: boolean;
    clientIds?: string[];
}

type GetWebMultiClientResponse =
    AxiosApiResponse<GetWebMultiClientResponseData>;

/**
 * Implementation of API methods from the /web/multiclient endpoint.
 *
 * @category API
 * @module api/web-multiclient
 *
 * @example
 * import { webMulticlientAPI } from '~/api/web-multiclient';
 */
export class WebMultiClientApi {
    /**
     * the API endpoint path
     */
    private static readonly path = '/web/multiclient';

    /**
     * Key for react-query
     */
    static readonly REACT_QUERY_KEY = 'web_multiclient';

    /**
     * Places a GET /web/multiclient request with the given client ids
     * as query parameters. Client ids are optional.
     * @method get
     * @static
     * @param clientIds
     * @return {Promise<never>|Promise<AxiosResponse<any>>}
     *
     * @example
     * const getWebMulticlient = async (activeClientIds) => {
     *     try {
     *         await webMulticlientAPI.get(activeClientIds);
     *     } catch (err) {
     *         console.error('Failed to get web messages:', err);
     *     }
     * };
     */
    static get(clientIds: string[]): Promise<GetWebMultiClientResponse> {
        const params: GetWebMultiClientParams = {};
        if (clientIds && clientIds.length) {
            if (!Array.isArray(clientIds)) {
                return Promise.reject('clientIds must be an array');
            }
            params.client_ids = clientIds;
        }
        return axios.get(this.path, {
            params
        });
    }
}
