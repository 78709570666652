import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormikContext } from 'formik';

export type Item = {
    id: string;
    name: string;
};

type ReturnProps = {
    handleChange: (items: Item[]) => void;
    dropdownRef: unknown;
    errorMessage?: string;
    isErrorState: boolean;
    onHide: () => void;
    onToggle: () => void;
    isDropDownDisplayed: boolean;
    initialValues: unknown;
    isSubmitting: boolean;
};

type Props = {
    inputFieldId: string;
    items?: Item[];
};

export const useHandlersAndState = ({
    inputFieldId,
    items
}: Props): ReturnProps => {
    const {
        errors,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        touched,
        values
    } = useFormikContext<Record<string, string>>();
    const [isDropDownDisplayed, setIsDropDownDisplayed] = useState(false);
    const errorMessage = errors[inputFieldId];
    const isWithError = Boolean(errorMessage);
    const isTouched = Boolean(touched[inputFieldId]);
    const isErrorState = isTouched && isWithError;
    const isFirstRender = useRef(true);
    const dropdownRef = useRef();

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (!isDropDownDisplayed && !isTouched) setFieldTouched(inputFieldId);
    }, [isDropDownDisplayed, inputFieldId]);

    const onHide = useCallback(() => {
        if (isDropDownDisplayed) setIsDropDownDisplayed(false);
    }, [isDropDownDisplayed]);

    const initialValues = useMemo(() => {
        const initialValue = items?.find(
            ({ id }) => id === values[inputFieldId]
        );

        return initialValue ? [initialValue] : [];
    }, [items]);

    const onToggle = () => {
        setIsDropDownDisplayed((isCurrentStateVisible) => {
            return !isCurrentStateVisible;
        });
    };

    const handleChange = (selectedItems: Item[]) => {
        const [item] = selectedItems;
        const { id } = item || {};

        setFieldValue(inputFieldId, id);
    };

    return {
        dropdownRef,
        errorMessage,
        isErrorState,
        handleChange,
        onHide,
        onToggle,
        isDropDownDisplayed,
        initialValues,
        isSubmitting
    };
};
