import React, { useState, useCallback, ChangeEvent } from 'react';

import { filterNilFromObj } from '~/utils/object-utils';

import { usePropsState } from './usePropsState';

type InputTypes = HTMLInputElement | HTMLTextAreaElement;
export type OnInputChange<E = HTMLInputElement, T = void> = (
    e: ChangeEvent<E>
) => T;

interface BaseUseControlledInputArgs {
    /**
     * The initial input value
     */
    initialValue?: string;

    /**
     * Whether or not to update the output value whenever the `initialValue` changes
     */
    updateStateWithArg?: boolean;
}

const defaultBaseUseControlledInputArgs: Required<BaseUseControlledInputArgs> =
    {
        initialValue: '',
        updateStateWithArg: false
    };

interface UseControlledInputArgs extends BaseUseControlledInputArgs {
    validator?: (s: string) => boolean;
    onValueChange?: (s: string) => void;
}

/**
 * A hook that formalizes an api for controlled inputs, providing input value, onChange function, and
 * a setState override for the value
 */
export function useControlledInput<E extends InputTypes = HTMLInputElement>(
    args: UseControlledInputArgs = defaultBaseUseControlledInputArgs
): [string, OnInputChange<E>, React.Dispatch<React.SetStateAction<string>>] {
    const { updateStateWithArg, initialValue, validator, onValueChange } = {
        ...defaultBaseUseControlledInputArgs,
        ...filterNilFromObj(args)
    };

    const useStateHook = updateStateWithArg ? usePropsState : useState;
    const [inputState, setInputState] = useStateHook(initialValue);

    const onInputStateChange = useCallback(
        (value: string) => {
            onValueChange?.(value);
            setInputState(value);
        },
        [onValueChange, setInputState]
    );

    const onInputChange = useCallback(
        (e: ChangeEvent<E>) => {
            const isValid = validator?.(e.target.value) ?? true;
            if (isValid) {
                onInputStateChange(e.target.value);
            }

            return e.target.value;
        },
        [validator, onInputStateChange]
    );

    return [inputState, onInputChange, setInputState];
}

interface UseControlledCheckboxInputArgs {
    initialValue?: boolean;
    updateStateWithArg?: boolean;
}

const defaultUseControlledCheckboxInputArgs: Required<UseControlledCheckboxInputArgs> =
    {
        initialValue: false,
        updateStateWithArg: false
    };

export function useControlledCheckboxInput<E extends HTMLInputElement>(
    args: UseControlledCheckboxInputArgs = defaultUseControlledCheckboxInputArgs
): [boolean, OnInputChange<E>] {
    const { updateStateWithArg, initialValue } = {
        ...defaultUseControlledCheckboxInputArgs,
        ...filterNilFromObj(args)
    };

    const useStateHook = updateStateWithArg ? usePropsState : useState;
    const [inputState, setInputState] = useStateHook(initialValue);

    const onInputStateChange = useCallback(
        (e: ChangeEvent<E>) => {
            const { checked } = e.target;
            setInputState(checked);
            return checked;
        },
        [setInputState]
    );

    return [inputState, onInputStateChange];
}

export function useControlledIntInput<E extends InputTypes = HTMLInputElement>(
    args: BaseUseControlledInputArgs = defaultBaseUseControlledInputArgs
): [string, OnInputChange<E>, React.Dispatch<React.SetStateAction<string>>] {
    const completeBaseArgs = {
        ...defaultBaseUseControlledInputArgs,
        ...filterNilFromObj(args)
    };
    return useControlledInput({ ...completeBaseArgs, validator: intValidator });
}

export function useControlledPositiveIntInput<
    E extends InputTypes = HTMLInputElement
>(
    args: BaseUseControlledInputArgs = defaultBaseUseControlledInputArgs
): [string, OnInputChange<E>, React.Dispatch<React.SetStateAction<string>>] {
    const completeBaseArgs = {
        ...defaultBaseUseControlledInputArgs,
        ...filterNilFromObj(args)
    };
    return useControlledInput({
        ...completeBaseArgs,
        validator: positiveIntValidator
    });
}

export function useNonNegativeIntInput<E extends InputTypes = HTMLInputElement>(
    args: BaseUseControlledInputArgs = defaultBaseUseControlledInputArgs
): [string, OnInputChange<E>, React.Dispatch<React.SetStateAction<string>>] {
    const completeBaseArgs = {
        ...defaultBaseUseControlledInputArgs,
        ...filterNilFromObj(args)
    };
    return useControlledInput({
        ...completeBaseArgs,
        validator: nonNegativeIntValidator
    });
}

export function useControlledFloatInput<
    E extends InputTypes = HTMLInputElement
>(
    args: BaseUseControlledInputArgs = defaultBaseUseControlledInputArgs
): [string, OnInputChange<E>, React.Dispatch<React.SetStateAction<string>>] {
    const completeBaseArgs = {
        ...defaultBaseUseControlledInputArgs,
        ...filterNilFromObj(args)
    };
    return useControlledInput({
        ...completeBaseArgs,
        validator: floatValidator
    });
}

/* Validators */

export function intValidator(s: string): boolean {
    return !s.length || /^-?\d*$/.test(s);
}

export function positiveIntValidator(s: string): boolean {
    return !s.length || /^(?!0)\d*$/.test(s);
}

export function nonNegativeIntValidator(s: string): boolean {
    return !s.length || /^\d*$/.test(s);
}

export function floatValidator(s: string): boolean {
    return !s.length || /^-?\d*(\.\d*)?$/.test(s);
}

export function nonNegativeFloatValidator(
    s: string,
    maxNumberOfDecimals?: number
): boolean {
    if (!s.length) return true;

    const isRestricted =
        maxNumberOfDecimals === 0 ||
        Boolean(maxNumberOfDecimals && maxNumberOfDecimals >= 0);

    if (!isRestricted) return /^\d*(\.\d*)?$/.test(s);

    return RegExp(String.raw`^\d*(\.\d{0,${maxNumberOfDecimals}})?$`).test(s);
}

export function threeDecimalPlacesNonNegativeFloatValidator(
    value: string
): boolean {
    return nonNegativeFloatValidator(value, 3);
}

/* Commit validators */

export function intCommitValidator(s: string): boolean {
    return !s.length || /^-?\d+$/.test(s);
}

export function positiveIntCommitValidator(s: string): boolean {
    return !s.length || /^(?!0)\d+$/.test(s);
}

export function nonNegativeIntCommitValidator(s: string): boolean {
    return !s.length || /^\d+$/.test(s);
}

export function floatCommitValidator(s: string): boolean {
    return !s.length || /^-?(\d+(\.\d*)?|\.\d+)$/.test(s);
}

export function nonNegativeFloatCommitValidator(s: string): boolean {
    return !s.length || /^(\d+(\.\d*)?|\.\d+)$/.test(s);
}
