import _ from 'lodash';
import {
    isAssignmentAssigned,
    isAssignmentAtRisk,
    isAssignmentCanceled,
    isAssignmentCompleted,
    isAssignmentEarly,
    isAssignmentInProgress,
    isAssignmentLate,
    isAssignmentOnTime
} from '~/utils/assignment-utils';
import constants from '~/utils/constants';
import dateUtils from '~/utils/date-utils';

/** @typedef {import('~/api/types').Address} Address */
/** @typedef {import('~/api/types').Coordinates} Coordinates */
/** @typedef {import('~/api/types').ServiceWindow} ServiceWindow */

/**
 * LiveStop data class
 *
 * @category Data Classes
 *
 * @example
 * import { LiveStop } from '~/data-classes';
 *
 * const srcData = {};
 * const liveStop = new LiveStop(srcData);
 */
class LiveStop {
    /**
     * the `LiveStop` constructor
     * @param {Object} liveStop - the source live stop data
     */
    constructor(liveStop) {
        this._liveStop = liveStop;
    }

    /**
     * the live stop ID
     * @type {string}
     */
    get id() {
        return this._liveStop.id;
    }

    /**
     * the live stop name
     * @type {string}
     */
    get name() {
        return this._liveStop.name;
    }

    /**
     * @borrows LiveStop#name as LiveStop#stopName
     */
    get stopName() {
        /** aliased to share a common property with PlanStop. */
        return this._liveStop.stopname;
    }

    /**
     * the live stop status code
     * @type {number}
     */
    get status() {
        return this._liveStop.status;
    }

    /**
     * the live stop delay code
     * @type {number}
     */
    get delay() {
        return this._liveStop.delay;
    }

    /**
     * whether the live stop has an inventory exception
     * @type {boolean}
     */
    get hasInventoryException() {
        return this._liveStop.hasInventoryException;
    }

    /**
     * the live stop task type
     * @type {string}
     */
    get type() {
        return this._liveStop.type;
    }

    /**
     * whether the live stop is a depot
     * @type {boolean}
     */
    get isDepot() {
        return this._liveStop.isDepot;
    }

    /**
     * whether the live stop is a pickup task
     * @type {boolean}
     */
    get isPickup() {
        return this.type === constants.taskTypes.PICKUP;
    }

    /**
     * whether the live stop is a delivery task
     * @type {boolean}
     */
    get isDelivery() {
        return this.type === constants.taskTypes.DELIVERY;
    }

    /**
     * whether the live stop is a high-priority task
     * @type {boolean}
     */
    get isHighPriority() {
        return this._liveStop.priority === constants.priorityCodes.HIGH;
    }

    /**
     * the live stop estimated arrival time
     * @type {Date}
     */
    get arrivalTime() {
        return this._liveStop.arrivalTime;
    }

    /**
     * the live stop estimated service time expressed as ISO duration string
     * @type {string}
     */
    get serviceTime() {
        return this._liveStop.serviceTime;
    }

    /**
     * whether the live stop service time is estimated by machine learning (ML)?
     * @type {boolean}
     */
    get isMLServiceTime() {
        return this._liveStop.serviceTimeML;
    }

    /**
     * the live stop completed time
     * @type {Date}
     */
    get completedAt() {
        return this._liveStop.completedAt;
    }

    /**
     * the live stop address object
     * @type {Address}
     */
    get address() {
        return _.pick(this._liveStop.location, [
            'addressLine1',
            'addressLine2',
            'city',
            'state',
            'zipcode'
        ]);
    }

    /**
     * the live stop location object
     * @type {Coordinates}
     */
    get location() {
        return this._liveStop.location.location;
    }

    /**
     * the live stop labels
     * @type {string[]}
     */
    get labels() {
        return this._liveStop.labels;
    }

    /**
     * the live stop service window
     * @type {ServiceWindow}
     */
    get serviceWindow() {
        return dateUtils.getShiftTime(this.arrivalTime, this.serviceTime);
    }

    /**
     * whether the live stop arrival is early
     * @type {boolean}
     */
    get isEarly() {
        return isAssignmentEarly(this.delay);
    }

    /**
     * whether the live stop arrival is on-time
     * @type {boolean}
     */
    get isOnTime() {
        return isAssignmentOnTime(this.delay);
    }

    /**
     * whether the live stop arrival is at-risk
     * @type {boolean}
     */
    get isAtRisk() {
        return isAssignmentAtRisk(this.delay);
    }

    /**
     * whether the live stop arrival is late
     * @type {boolean}
     */
    get isLate() {
        return isAssignmentLate(this.delay);
    }

    /**
     * whether the live stop is completed
     * @type {boolean}
     */
    get isCompleted() {
        return this._liveStop.isCompleted || isAssignmentCompleted(this.status);
    }

    /**
     * whether the live stop is assigned a driver
     * @type {boolean}
     */
    get isAssigned() {
        return isAssignmentAssigned(this.status);
    }

    /**
     * whether the live stop is in-progress
     * @type {boolean}
     */
    get isInProgress() {
        return isAssignmentInProgress(this.status);
    }

    /**
     * whether the live stop is cancelled
     * @type {boolean}
     */
    get isCanceled() {
        return isAssignmentCanceled(this.status);
    }

    /**
     * the live stop task Id
     * @type {boolean}
     */
    get taskId() {
        return this._liveStop.task;
    }

    /**
     * whether the live stop is the current stop for this route
     * @type {boolean}
     */
    get isCurrentStop() {
        return this._liveStop.isCurrentStop || false;
    }

    /**
     * Serializes this class back to JSON
     * @returns {Object}
     */
    toJSON() {
        return this._liveStop;
    }
}

export default LiveStop;
