import React from 'react';
import classNames from 'classnames';
import { Icon, useClickOutside } from '~/ui';
import './DropDownMenu.scss';
import { IconSize } from '~/ui/components/Icon/IconSizes';

type IconProps = React.ComponentProps<typeof Icon>;

interface DropDownMenuProps {
    title?: {
        name?: string | React.ReactNode;
        icon?: IconProps['icon'];
        iconColor?: string;
    };
    styles?: Record<string, string>;
    isOpen?: boolean;
    toggle?: () => void;
    close?: () => void;
    icon?: IconProps['icon'];
    position?: 'down' | 'down-right' | 'left';
    customDropDownButton?: React.ComponentType;
    children?: React.ReactNode;
    'data-testid'?: string;
}

export const menuTitleTextDataTestIdSuffix = 'menutitle-text';

const DropDownMenu = React.forwardRef<HTMLDivElement, DropDownMenuProps>(
    function DropDownMenu(
        {
            title,
            styles,
            children,
            isOpen,
            toggle,
            close,
            icon,
            position = 'down',
            customDropDownButton,
            ...extra
        },
        ref
    ) {
        const dropdownStyles = styles || {
            container: 'dropdown-flex',
            menuTitle: 'dropdown-flex-item',
            menuTitleText: ''
        };
        const menuTitle = title || {};
        let dropDownItems = 'dropdown-hide-items';
        let rotate = '';

        if (isOpen) {
            dropDownItems = classNames(
                dropdownStyles.menuItems,
                position,
                'dropdown-show-items'
            );
            rotate = '_t-rotate-180';
        }

        useClickOutside(ref, close);

        const DropDownButtonComponent = customDropDownButton;

        return (
            <div
                className="dropdown _p-relative _d-inline-block"
                ref={ref}
                data-testid={extra['data-testid'] || 'dropdown'}
            >
                <div
                    className={dropdownStyles.container}
                    onClick={toggle}
                    onKeyDown={toggle}
                    role="button"
                    tabIndex={0}
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-button`
                            : 'dropdown-button'
                    }
                >
                    {DropDownButtonComponent ? (
                        <DropDownButtonComponent />
                    ) : (
                        <div
                            className={dropdownStyles.menuTitle}
                            data-testid={
                                extra['data-testid']
                                    ? `${extra['data-testid']}-menutitle`
                                    : 'dropdown-menutitle'
                            }
                        >
                            {menuTitle.icon && (
                                <Icon
                                    className="dropdown-icon"
                                    icon={menuTitle.icon}
                                    color={menuTitle.iconColor}
                                    data-testid={
                                        extra['data-testid']
                                            ? `${extra['data-testid']}-menutitle-icon`
                                            : 'dropdown-menutitle-icon'
                                    }
                                />
                            )}
                            <span
                                className={dropdownStyles.menuTitleText}
                                data-testid={
                                    extra['data-testid']
                                        ? `${extra['data-testid']}-${menuTitleTextDataTestIdSuffix}`
                                        : 'dropdown-menutitle-text'
                                }
                            >
                                {menuTitle.name}
                            </span>
                        </div>
                    )}
                    <div>
                        {icon !== undefined && (
                            <Icon
                                className={`${rotate} ${dropdownStyles.icon}`}
                                icon={icon}
                                color={dropdownStyles.dropdownToggleColor}
                                size={
                                    (dropdownStyles.iconSize as IconSize) || 'm'
                                }
                                data-testid={
                                    extra['data-testid']
                                        ? `${extra['data-testid']}-icon`
                                        : 'dropdown-icon'
                                }
                            />
                        )}
                    </div>
                </div>
                <div
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-items`
                            : 'dropdown-items'
                    }
                    className={dropDownItems}
                >
                    {children}
                </div>
            </div>
        );
    }
);

export default DropDownMenu;
