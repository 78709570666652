import { IPrivateApiFields } from './CommonTypes';

/**
 * String enum of possible client types.
 * @category API Types
 */
enum ClientType {
    STANDARD = 'standard',
    DRIVER_GROUP = 'driver-group'
}

/**
 * Type for API client
 * @category API Types
 */
export type ApiClient = IPrivateApiFields & {
    /**
     * the client's id
     */
    id: string;
    /**
     * the client's name
     */
    name: string;
    /**
     * the client's address id
     */
    address: string;
    /**
     * the client's domain
     */
    domain: string;
    /**
     * the client's preferences
     */
    preferences: IClientPreferences;
    /**
     * the client's courier preferences
     * @todo refine client courier preferences type definitions
     */
    courierPreferences: Record<string, unknown>;
    /**
     * the client's notification preferences
     * @todo refine client notification preferences type definitions
     */
    notifications: Record<string, string | number | boolean>;
    /**
     * the client's onboarding information
     * @todo refine onboarding type definitions
     */
    onboarding: Record<string, unknown>;
    /**
     * the client's code
     */
    code: string;
    /**
     * the client's timezone (TZ database name, like America/New_York)
     */
    timezone: string;
    /**
     * indicates if a client is a driver_group or a standard client
     */
    type: ClientType;
    /**
     * client's related driver groups
     */
    // eslint-disable-next-line camelcase
    driver_groups: Array<string>;
};

interface IClientPreferences {
    maxRouteDuration: number;
    maxTripDuration: number;
    enableCapacityConstraints: boolean;
    lockClusterInitialSolution: boolean;
    balanceBasedOnDuration: boolean;
    balanceBasedOnStops: boolean;
    balanceBasedOnMileage: boolean;
    balanceBasedOnVolume: boolean;
    enableGoogleApiEta: boolean;
    enableGoogleDirectionsApi: boolean;
    shippingUnits: string;
    enableRouteBuilder: boolean;
    enableScheduler: boolean;
    costPerUnitDistance: number;
    machineLearning: number;
    acceptDelays: boolean;
    acceptEarly: boolean;
    enableDriverTracks: boolean;
    enableClusterInitialSolution: boolean;
    enableDriverShiftTime: boolean;
    useOriginalStopOrder: boolean;
    maxTaskOnRoute: number;
    enableRtaOnEdit: boolean;
    enableTrafficTimes: boolean;
    enableMultiTripOptimization: boolean;
    enableTruckRoutes: boolean;
    createExtraVehicles: boolean;
    enableDriverSequenceML: boolean;
    enableDriverSequenceMLGeo: boolean;
    avoidDifficultTurns: boolean;
    avoidTolls: boolean;
    enableHighPrecisionETA: boolean;
    enableLocationGrid: boolean;
    enableGooglePlacesGeocoding: boolean;
    allowApproximateGeocoding: boolean;
    enableContainerizedScheduler: boolean;
    usePreCluster: boolean;
    enableTaskConsolidation: boolean;
    enableScheduledNotifications: boolean;
    pickupDeliveryModel: string;
    useDeliveryTWForTwoPart: boolean;
    useAllAvailableDrivers: boolean;
    limitReorder: number;
    zoneBasedRouting: string;
    sendRtaNotification: boolean;
    disableInProgressAssignmentStatus: boolean;
    enableTwoPartTaskEditing: boolean;
    maxRouteLengthMeter: number;
    balanceBasedOnItems: boolean;
    enableDriverBooking: boolean;
    addDepotsOnDemandToInactiveDriver: boolean;
    enableDriverManagement: boolean;
    enableParallelScheduler: boolean;
    improveRoutesAttractiveness: boolean;
    startRouteImmediately: boolean;
    penalizeWaitTimeProportionally: boolean;
    penalizeLastEdge: boolean;
    minimizeRouteCrossingsInRTA: boolean;
    minimizeWaitingTimes: boolean;
    useCustomCost: boolean;
}
