import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import driversApi from '~/api/DriversApi';
import {
    selectDriversByClient,
    setDriversByClient
} from '~/reducers/driversByClientSlice';
import { idUtils } from '~/utils/id-utils';
import { colorUtils } from '~/utils/color-utils';

export const useGetDriverList = () => {
    const [driversList, setDriversList] = useState([]);
    const dispatch = useDispatch();
    const driversByClient = useSelector(selectDriversByClient);

    const driversEmpty = useMemo(() => {
        return _.isEmpty(driversByClient);
    }, [driversByClient]);

    const assignColorsToDrivers = (drivers) => {
        if (!drivers) return;
        for (const driver of drivers) {
            // auto-assign web colors by driver
            // TODO:
            // + in a future improvement, user-defined driver color will be set to the driver response
            // + for now, we will auto-assign web colors for each driver
            const { client, id } = driver;
            const clientDriverId = idUtils.getCombinedId(client, id);
            const isWebColorAssignedForId =
                colorUtils.isWebColorAssignedForId(clientDriverId);

            if (!isWebColorAssignedForId)
                colorUtils.assignWebColorsToDriver(clientDriverId);
        }
    };

    const fetchDriversByClient = async () => {
        try {
            const driversResponse = await driversApi.get();
            const drivers = driversResponse.data.data;
            const driversByClientIds = drivers.reduce((result, driver) => {
                if (!result[driver.client]) {
                    result[driver.client] = [];
                }
                result[driver.client].push(driver);
                return result;
            }, {});
            setDriversList(drivers);
            dispatch(setDriversByClient(driversByClientIds));
            return drivers;
        } catch (e) {
            setDriversList([]);
            dispatch(setDriversByClient({}));
            console.error(e);
        }
    };

    useEffect(() => {
        if (!driversEmpty) {
            const list = Object.values(driversByClient).reduce(
                (aggregator, item) => {
                    aggregator = [...aggregator, ...item];
                    return aggregator;
                },
                []
            );
            setDriversList(list);
        } else {
            (async () => {
                try {
                    const drivers = await fetchDriversByClient();
                    assignColorsToDrivers(drivers);
                } catch (e) {
                    console.error(e);
                }
            })();
        }
    }, []);

    return { driversList };
};
