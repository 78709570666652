import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

export const depotsSlice = createSlice({
    name: 'depots',
    initialState: {},
    reducers: {
        setDepots: (state, action) => {
            return action.payload;
        },
        resetDepots: () => {
            return {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {};
        });
    }
});

export const { setDepots, resetDepots } = depotsSlice.actions;

export const selectDepots = (state) => state.depots;

export default depotsSlice.reducer;
