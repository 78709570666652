import axios from 'axios';
import constants from '~/utils/constants';

/**
 * Sets the global axios base url and headers
 * @param {object} mcwAppInit
 */
export function initAxios(mcwAppInit) {
    if (!mcwAppInit) {
        throw new Error('missing app init');
    }
    axios.defaults.baseURL = mcwAppInit.data.apiUrl;
    axios.defaults.headers.common = Object.assign(
        axios.defaults.headers.common,
        {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${mcwAppInit.requestHeaders.authorization}`,
            'X-OAuth-IDToken': mcwAppInit.requestHeaders['x-oauth-idtoken'],
            [constants.requestHeaders.WISE_CLIENT_ID]:
                mcwAppInit.requestHeaders[
                    constants.requestHeaders.WISE_CLIENT_ID
                ]
        }
    );
}
