import { DateTime, Duration } from 'luxon';
import dateUtils, { DAYS_OF_WEEK } from '~/utils/date-utils';
import i18n from '~/i18n';

/**
 * Driver Utilities
 *
 * @category Utils
 * @module utils/driverUtils
 *
 * @example
 * import driverUtils from '~/utils/driver-utils';
 */

/**
 * Check if the driver is available on selected date
 *
 * @param {Object} driver with shift times
 * @param {String} date in ISO format
 * @returns {boolean} is driver available on selected date
 */
function isDriverAvailableOnSelectedDate(driver, date) {
    const dateISO = dateUtils.convertToISODateOnly(date);
    const weekday = dateUtils.getIntWeekDay(date);
    if (driver.props.shiftExceptions?.[dateISO]) {
        return Boolean(driver.props.shiftExceptions[dateISO].length);
    }
    return Boolean(driver.props.shiftTimes[weekday]?.length);
}

/**
 * Format US/Canada phone number
 *
 * @param {string} phoneString phone string
 * @returns {string} formatted phone number (example: (555) 123-4567)
 * @todo find a more international solution
 */
function formatPhoneNumber(phoneString) {
    //  Filter only numbers from the input
    const cleaned = `${phoneString}`.replace(/\D/g, '');

    //  Check if the input is of correct
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        //  Remove the matched extension code
        //  Change this to format for any country code.
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join(
            ''
        );
    }
    return phoneString;
}

function populateShiftTimes(shiftTimes) {
    const result = {};
    for (const [key, slots] of Object.entries(shiftTimes)) {
        const slotWithEndTime = slots
            .map((slot) => {
                const start = slot?.start;
                const duration = slot?.duration;

                if (
                    !DateTime.fromISO(start).isValid ||
                    !Duration.fromISO(duration).isValid
                ) {
                    return { start: '', end: '' };
                }

                return dateUtils.getShiftTime(start, duration);
            })
            .filter(Boolean);
        if (slotWithEndTime.length) {
            result[key] = slotWithEndTime;
        }
    }
    return result;
}

const areShiftTimesInvalid = (shifts) => {
    for (const key in shifts) {
        const [shiftTime] = shifts[key];
        if (!shiftTime.start.length) return true;
    }
};

function formatShiftTimeByDayOfWeek(shiftTimes, format = 'number') {
    const result = {};
    if (!shiftTimes) return result;
    for (const [key, value] of Object.entries(shiftTimes)) {
        value.forEach((v) => {
            let formattedKey = key;
            if (format === 'day') {
                const translationKey = DAYS_OF_WEEK[key]
                    ? `daysOfWeek.abbreviated.${DAYS_OF_WEEK[key]}`
                    : 'N/A';
                formattedKey = i18n.t(translationKey, {
                    ns: 'common'
                });
            }
            const startTime = DateTime.fromISO(v.start).toLocaleString({
                hour: '2-digit',
                minute: '2-digit'
            });
            const endTime = DateTime.fromISO(v.end).toLocaleString({
                hour: '2-digit',
                minute: '2-digit'
            });
            const string = `${startTime} - ${endTime}`;
            const currentCount = result[string];
            if (currentCount?.length) {
                currentCount.push(formattedKey);
                result[string] = currentCount;
            } else {
                result[string] = [formattedKey];
            }
        });
    }
    return result;
}

function filterDriverData(driver) {
    if (!driver) return {};
    return {
        id: driver.id,
        firstname: driver.firstname,
        lastname: driver.lastname,
        telephone: formatPhoneNumber(driver.telephone),
        profile: driver.profile,
        shiftTimes: populateShiftTimes(driver.props?.shiftTimes),
        clientId: driver.client
    };
}

function areShiftsEmpty(shifts) {
    for (const key in shifts) {
        if (shifts[key].length) {
            return false;
        }
    }
    return true;
}

export default {
    isDriverAvailableOnSelectedDate,
    populateShiftTimes,
    formatShiftTimeByDayOfWeek,
    formatPhoneNumber,
    filterDriverData,
    areShiftsEmpty,
    areShiftTimesInvalid
};
