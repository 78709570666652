import i18n from '~/i18n';
import localizationUtils from '~/utils/localization-utils';
import { store } from '~/store';
import { setSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { makeMarkerPopup } from './makeMarkerPopup';

const getDelayConfig = (stop) => {
    const { isEarly, isOnTime, isAtRisk, isLate } = stop;
    if (isEarly)
        return {
            icon: 'clockFill',
            color: 'earth',
            text: 'markerPopup:status.early'
        };
    if (isOnTime)
        return {
            icon: 'clockFill',
            color: 'earth',
            text: 'markerPopup:status.onTime'
        };
    if (isAtRisk)
        return {
            icon: 'clockFill',
            color: 'venus',
            text: 'markerPopup:status.atRisk'
        };
    if (isLate)
        return {
            icon: 'clockFill',
            color: 'mars',
            text: 'markerPopup:status.late'
        };

    return { icon: null, color: null, text: null };
};

/**
 * Creates a `MarkerPopup` component for plan stops
 * @method makeStopPopup
 * @memberof module:utils/map/popupMaker
 * @param {*} payload - the plan stop data
 * @param {*} emittedEventHandler - the map emitted event handler
 * @returns {MarkerPopup}
 */
export const makeStopPopup = (payload, emittedEventHandler) => {
    const { location, stopMarkerData: planStop } = payload;

    const { address, arrivalTime, stopName, labels, taskId } = planStop;

    const {
        icon: stopIcon,
        color: stopColor,
        text: stopText
    } = getDelayConfig(planStop);

    const etaTime = i18n.t('common:time.estimatedTime', {
        time: arrivalTime
    });

    const onClickStopDetailButton = () => {
        store.dispatch(resetSelectedMapStops());
        store.dispatch(setSelectedTaskRowId(taskId));
    };

    const popupPayload = {
        className: 'stop-popup',
        testId: 'stop-popup',
        location,
        stopData: {
            stopColor,
            stopText: i18n.t(stopText),
            stopName,
            stopIcon,
            address: localizationUtils.formatAddress(address),
            etaTime,
            labels,
            stopDetailButtonText: i18n.t('markerPopup:button.moreDetail'),
            onClickStopDetailButton
        }
    };

    return makeMarkerPopup(popupPayload, emittedEventHandler);
};
