import React, { useState, useRef } from 'react';
import { Icon, IconButton, TextInput } from '~/ui';
import classNames from 'classnames';
import './EditableText.scss';

function EditableText({
    mainContent,
    handleSubmit,
    handleCancel = () => {},
    index = 0,
    isEditable = false,
    isEditMode = false,
    showEditIcon = false,
    ...extra
}) {
    const [isTextInput, setIsTextInput] = useState(isEditMode);
    const textInputRef = useRef(null);

    function _getClassName() {
        const defaultClassNames = 'editabletext _d-flex';
        const conditionalClasses = {
            editabletext_enabled: isEditable,
            '_jc-space-between': mainContent,
            '_jc-flex-end': !mainContent
        };
        return classNames(defaultClassNames, conditionalClasses);
    }

    function onClickCheck() {
        if (textInputRef.current.value !== mainContent) {
            const { value } = textInputRef.current;
            handleSubmit({ index, value });
        } else {
            handleCancel();
        }
        setIsTextInput(false);
    }

    function onClickCancel() {
        handleCancel();
        setIsTextInput(false);
    }

    function onClickDetail() {
        if (isEditable) setIsTextInput(!isTextInput);
    }

    function getMainContent() {
        return (
            <div className="_fd-column edit-mode">
                <TextInput
                    ref={textInputRef}
                    value={mainContent}
                    width="fullWidth"
                    variant="edit"
                    data-testid="editabletext-input"
                />
                <div className="_d-flex _jc-flex-end _mb-1">
                    <div className="_d-inline _ml-2">
                        <IconButton
                            className="editabletext-button"
                            icon="cancel"
                            iconSize="s"
                            iconColor="meteor"
                            onClick={onClickCancel}
                        />
                    </div>
                    <div className="_d-inline _ml-2">
                        <IconButton
                            className="editabletext-button"
                            icon="checkLine"
                            iconSize="s"
                            onClick={onClickCheck}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            {isTextInput ? (
                getMainContent()
            ) : (
                <span
                    className={_getClassName()}
                    onClick={onClickDetail}
                    onKeyDown={onClickDetail}
                    role="button"
                    tabIndex={0}
                    data-testid={extra['data-testid'] || 'editabletext'}
                >
                    {mainContent}
                    {showEditIcon && (
                        <Icon
                            data-testid={
                                `${extra['data-testid']}-icon` ||
                                'editabletext-icon'
                            }
                            iconSize="m"
                            className="editabletext-icon"
                            icon="edit"
                        />
                    )}
                </span>
            )}
        </>
    );
}

export default EditableText;
