import React, { useState } from 'react';
import { IconButton } from '~/ui';

import './accordion.scss';

function Accordion({
    title = '',
    overrideClickHandler = null,
    disabled = false,
    className,
    children
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    function _handleClick() {
        const clickHandler = overrideClickHandler || _toggleIsOpen;
        clickHandler();
    }

    function _toggleIsOpen() {
        setIsOpen(!isOpen);
    }

    function _getClassName() {
        let boxClassName = 'accordion';
        boxClassName =
            (isOpen && `${boxClassName} accordion_open`) || boxClassName;

        boxClassName =
            (!isOpen && isHovered && `${boxClassName} accordion_hover`) ||
            boxClassName;

        boxClassName =
            (disabled && `${boxClassName} accordion_disabled`) || boxClassName;
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    return (
        <div
            className={_getClassName()}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="accordion-top">
                {title && <div className="accordion-title">{title}</div>}
                <IconButton
                    className="icon-button_clear accordion-button"
                    disabled={disabled}
                    icon={isOpen ? 'minus' : 'plus'}
                    iconSize="s"
                    iconColor="galaxy-800"
                    onClick={_handleClick}
                />
            </div>
            {children && <div className="accordion-bottom">{children}</div>}
        </div>
    );
}

export default Accordion;
