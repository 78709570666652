/**
 * Tests if the provided string contains both lowercase and uppercase letters
 *
 * @param {String} value
 * @returns {Boolean} true if value contains at least 1 lowercase and 1 uppercase letter
 */
function hasLowercaseAndUppercase(value) {
    return /(?=.*[A-Z])/.test(value) && /(?=.*[a-z])/.test(value);
}

/**
 * Tests if the provided string contains at least 1 number
 *
 * @param {String} value
 * @returns {Boolean} true if value contains one or more number
 */
function hasNumber(value) {
    return /(?=.*[0-9])/.test(value);
}

/**
 * Tests if the provided string contains at least 1 special character
 *
 * @param {String} value
 * @returns {Boolean} true if value contains one or more special characters
 */
function hasSpecialCharacter(value) {
    return /(?=.*[#$%&'()*+,-./:;<=>!?@[\]^_`{|}~])/.test(value);
}
/**
 * Tests if the provided string is a valid staticRouteIds
 *
 * @param {String} staticId
 * @returns {Boolean} true if staticRouteIds is valid
 */
function isStaticRouteIdsValid(staticId) {
    return /^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/.test(staticId);
}

/**
 * Remove formatting from phone numbers
 *
 * @param {String} phoneNumber
 * @returns {String} phone number without brackets or spacing
 */
function removePhoneNumberFormatting(phoneNumber) {
    return phoneNumber.replace(/([\s()-])/g, '');
}

export default {
    hasLowercaseAndUppercase,
    hasNumber,
    hasSpecialCharacter,
    isStaticRouteIdsValid,
    removePhoneNumberFormatting
};
