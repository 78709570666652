import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeSelectedMapStop } from '~/reducers/selectedMapStopsSlice';
import { selectIsOpenUnassignedTasksDrawer } from '~/reducers/mapDrawerSettingsSlice';
import { IconButton, ManageStopControlListItem, theme } from '~/ui';

function ResequenceModalStopList({
    selectedStop,
    handleDragStart,
    handleDragOver,
    handleDrop,
    stopIdPropertyName,
    tooltipPlacement = 'top'
}) {
    const isOpenUnassignedTasksDrawer = useSelector(
        selectIsOpenUnassignedTasksDrawer
    );
    const dispatch = useDispatch();
    const { stopName, stopNumber = '?' } = selectedStop;
    const stopId = selectedStop[stopIdPropertyName];

    let iconButtonClassName = 'resequencemodal-stopitem-left';
    let iconButtonTextColor = theme.colors['galaxy-800'];

    if (stopNumber === '?') {
        iconButtonClassName = `${iconButtonClassName} unassigned-stop-item`;
        iconButtonTextColor = theme.colors.comet;
    }

    function _removeStop() {
        if (!isOpenUnassignedTasksDrawer) {
            dispatch(removeSelectedMapStop(stopId));
            return;
        }

        const { pickupStopData, deliveryStopData, isPickup } = selectedStop;

        dispatch(
            removeSelectedMapStop(
                isPickup
                    ? pickupStopData.clientRouteTaskId
                    : deliveryStopData.clientRouteTaskId
            )
        );
    }

    return (
        <div
            className="resequencemodal-stopitem"
            draggable="true"
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            id={stopId}
        >
            <ManageStopControlListItem
                className={iconButtonClassName}
                text={stopName}
                iconButtonText={stopNumber}
                iconButtonTextColor={iconButtonTextColor}
                tooltipPlacement={tooltipPlacement}
            />
            <div className="_d-flex stopitem-buttongroup">
                <IconButton
                    className="icon-button_clear stopitem-button"
                    onClick={_removeStop}
                    icon="cancel"
                    iconColor="galaxy-800"
                />
                <IconButton
                    className="icon-button_clear stopitem-button"
                    icon="iconDragger"
                    iconColor="galaxy-800"
                />
            </div>
        </div>
    );
}

export default ResequenceModalStopList;
