import i18n from '~/i18n';
import dateUtils from '~/utils/date-utils';
import localizationUtils from '~/utils/localization-utils';
import { store } from '~/store';
import { setSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { makeMarkerPopup } from './makeMarkerPopup';

const getDelayConfig = (stop) => {
    const { isAtRisk, isLate, isCanceled, isDelivery, isPickup, isCompleted } =
        stop;

    if (isAtRisk && !isCompleted)
        return {
            icon: 'clockFill',
            color: 'venus',
            text: 'markerPopup:status.atRisk'
        };
    if (isLate && !isCompleted)
        return {
            icon: 'clockFill',
            color: 'mars',
            text: 'markerPopup:status.late'
        };
    if (isCanceled && isDelivery)
        return {
            icon: 'infoCircle',
            color: 'saturn-500',
            text: 'markerPopup:status.cancelledDelivery'
        };
    if (isCanceled && isPickup)
        return {
            icon: 'infoCircle',
            color: 'saturn-500',
            text: 'markerPopup:status.cancelledPickup'
        };

    return { icon: null, color: null, text: null };
};

/**
 * Creates a `MarkerPopup` component for dispatched/live stops
 * @method makeDispatchedStopPopup
 * @memberof module:utils/map/popupMaker
 * @param {*} payload - the dispatched stop data
 * @param {*} emittedEventHandler - the map emitted event handler
 * @returns {MarkerPopup}
 */
export const makeDispatchedStopPopup = (payload, emittedEventHandler) => {
    const { location, stopMarkerData: liveStop } = payload;

    const {
        address,
        arrivalTime,
        stopName,
        labels,
        taskId,
        isCanceled,
        isCompleted,
        completedAt
    } = liveStop;

    const {
        icon: stopIcon,
        color: stopColor,
        text: stopText
    } = getDelayConfig(liveStop);

    const etaTime = i18n.t('common:time.estimatedTime', {
        time: dateUtils.getLocalizedTime(arrivalTime)
    });

    const completedTime = dateUtils.getLocalizedTime(completedAt);

    const useCompletedTime = isCanceled || isCompleted;

    const onClickStopDetailButton = () => {
        store.dispatch(resetSelectedMapStops());
        store.dispatch(setSelectedTaskRowId(taskId));
    };

    const popupPayload = {
        className: 'dispatched-stop-popup',
        testId: 'dispatched-stop-popup',
        location,
        stopData: {
            stopColor,
            stopText: i18n.t(stopText),
            stopName,
            stopIcon,
            address: localizationUtils.formatAddress(address),
            etaTime: useCompletedTime ? completedTime : etaTime,
            labels,
            stopDetailButtonText: i18n.t('markerPopup:button.moreDetail'),
            onClickStopDetailButton
        }
    };

    return makeMarkerPopup(popupPayload, emittedEventHandler);
};
