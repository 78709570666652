export const enum SortDir {
    ASC = 'ASC',
    DESC = 'DESC'
}

/**
 * Parameters used for pagination-related requests
 */
export interface PaginationParams {
    page?: number;
    limit?: number;
    sortdir?: SortDir;
}

/**
 * The typical response data associated when making a paginated api request
 */
export interface PaginationMetadata {
    limit?: number;
    page?: number;
    sortdir?: SortDir;
    startAt?: number;
    total?: number;
}
