import _ from 'lodash';
import { idUtils } from '~/utils/id-utils';
import { taskDataFactory } from '~/utils/data-factory';
/**
 * Map Mode Utilities, Dispatched Routes Mode
 *
 * @category Utils
 * @module utils/map/map-planstop-utils
 *
 * @example
 * import { mapPlanStopUtils } from '~/utils/map/map-planstop-utils';
 */

/**
 * Map selected ids to stops data
 * @param {Object[]} planStops - array
 * @param {String[]} selectedMapStopsIds - array of string
 * @returns {{
 *  selectedStops: Object[],
 *  firstSelectedClientRouteId: string,
 *  unselectedStops:Object[] }} Object containing first selected id, selected stops data and unselected stops data
 * */
function mapIdtoStopData(planStops, selectedMapStopsIds) {
    const stopIdToStopMap = _.keyBy(planStops, 'clientRouteTaskId');
    const selectedStops = selectedMapStopsIds
        .map((stopId) => {
            const splitId = idUtils.splitCombinedId(stopId);
            // the incoming `selectedMapStopIds` for `UnassignedTasksItem`
            // is in the format: clientId_routeId_taskType_taskId
            // rearrange to `clientId_routeId_taskId`
            const newId =
                splitId.length > 3
                    ? idUtils.getCombinedId(
                          splitId[0], // clientId
                          splitId[1], // routeId || `unplanned`
                          splitId[3] // taskId
                      )
                    : stopId;
            return stopIdToStopMap[newId];
        })
        .filter(Boolean);

    const [firstSelectedStop] = selectedStops;
    const { clientRouteId: firstSelectedClientRouteId } =
        firstSelectedStop || {};

    const unselectedStops = _filterUnSelectedStops(
        planStops,
        selectedMapStopsIds,
        firstSelectedClientRouteId
    );
    const allStops = _.sortBy(
        [...selectedStops, ...unselectedStops],
        'stopNumber'
    );

    return {
        allStops,
        firstSelectedStop,
        selectedStops,
        firstSelectedClientRouteId,
        unselectedStops
    };
}

/**
 * Get unselected stops
 * @param {Object[]} planStops - array
 * @param {String[]} selectedMapStopsIds - array of string
 * @param {String} firstSelectedClientRouteId - string
 * @returns {Object[]} array
 * @private
 * */
function _filterUnSelectedStops(
    planStops,
    selectedMapStopsIds,
    firstSelectedClientRouteId
) {
    return planStops.filter(
        ({ clientRouteId, clientRouteTaskId }) =>
            clientRouteId === firstSelectedClientRouteId &&
            !selectedMapStopsIds.includes(clientRouteTaskId)
    );
}

/**
 * Get Boolean value for different selected route stops.
 * Is truthy value if stops from different routes are selected
 * @param {String} selectedClientRouteId - string
 * @param {Object[]} selectedPlanStops - array
 * @returns {Boolean} boolean
 * */
function isDifferentRoutesStopSelected(
    selectedClientRouteId,
    selectedPlanStops
) {
    return (
        Boolean(selectedClientRouteId) &&
        selectedPlanStops.some(
            ({ clientRouteId }) => selectedClientRouteId !== clientRouteId
        )
    );
}

/**
 * Drag and Drop to reorder items in a list
 * @param {Object} DragEvent - object
 * @param {Object[]} stops - array
 * @param {String} stopIdPropertyName - string
 * @param {Number} dragStartIndex - number
 * @param {Number} index - number
 * @param {Boolean} isOpenUnassignedTasksDrawer - boolean
 * @returns {String[]} array of string
 * */
function processStopsReorder(
    e,
    stops,
    stopIdPropertyName,
    dragStartIndex,
    index,
    isOpenUnassignedTasksDrawer = false
) {
    e.stopPropagation();
    const stopsArray = [...stops];
    const dragStartStopItem = stopsArray[dragStartIndex];

    stopsArray.splice(dragStartIndex, 1);
    stopsArray.splice(index, 0, dragStartStopItem);

    return stopsArray.map((stop) => {
        if (isOpenUnassignedTasksDrawer) {
            return stop.isPickup
                ? stop.pickupStopData.clientRouteTaskId
                : stop.deliveryStopData.clientRouteTaskId;
        }
        return stop[stopIdPropertyName];
    });
}

/**
 * Map selected stop ids to tasks data
 * @param {Object[]} selectedMapStops - array
 * @param {Object} tasksData - object
 * @returns {Object[]} array of object
 * */
function mapIdtoTasksData(selectedMapStops, tasksData) {
    return selectedMapStops
        .map((id) => {
            // the incoming id is in the format: {{clientId}}_{{routeId}}_{{taskType}}_{{taskId}}
            const taskId = idUtils.splitCombinedId(id).pop();

            if (!tasksData[taskId]) return;

            // track the uniquely assembled 2-part task id
            const currentTaskData = { ...tasksData[taskId], twoPartId: id };
            const onDemandDispatchStop =
                taskDataFactory.makeOnDemandDispatchStop(currentTaskData);
            return onDemandDispatchStop;
        })
        .filter(Boolean);
}

export const mapPlanStopUtils = {
    mapIdtoStopData,
    isDifferentRoutesStopSelected,
    processStopsReorder,
    mapIdtoTasksData
};
