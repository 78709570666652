import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    replaceSelectedMapStop,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';
import { selectIsOpenUnassignedTasksDrawer } from '~/reducers/mapDrawerSettingsSlice';

import ResequenceModalStopList from '~/components/MapPage/ResequenceModal/ResequenceModalStopList';
import {
    useUnassignStops,
    useOnDemandDispatchUnassignTasks,
    useToastContext
} from '~/hooks';

import { mapPlanStopUtils } from '~/utils/map';
import {
    ManageStopControlMultiple,
    ManageStopControlMenu,
    StopModalMenuButton
} from '~/ui';
import constants from '~/utils/constants';

function MultiStopsControl({
    selectedPlanStops,
    stopIdPropertyName,
    disableResequencing = false,
    footerButtonTitle,
    footerButtonIcon,
    isLiveStopControl,
    onSwitchView,
    onClickFooterButton = () => {}
}) {
    const dispatch = useDispatch();
    const isOpenUnassignedTasksDrawer = useSelector(
        selectIsOpenUnassignedTasksDrawer
    );
    const selectedMapStopIds = useSelector(selectSelectedMapStops);
    const [dragStartIndex, setDragStartIndex] = useState(null);

    const { t } = useTranslation('translation');
    const { unassignStops, isUnassignStopsAllowed } = useUnassignStops();
    const { cancelTasks, unassignTasks } = useOnDemandDispatchUnassignTasks();
    const { addToast } = useToastContext();

    function _handleClickLiveStopUnassign() {
        unassignTasks({
            onSuccess: () => {
                addToast({
                    message: t('PlanStopsControl.toast.onSuccessUnassign', {
                        count: selectedMapStopIds.length
                    }),
                    variant: 'success'
                });
            },
            onError: (error) => {
                if (error?.data || error?.response?.data) {
                    console.error(error);
                }
                addToast({
                    message: t('PlanStopsControl.toast.onErrorUnassign', {
                        count: selectedMapStopIds.length
                    }),
                    variant: 'error'
                });
            }
        });
    }

    function _handleClickLiveStopCancel() {
        cancelTasks({
            onSuccess: () => {
                addToast({
                    message: t('PlanStopsControl.toast.onSuccessCancel', {
                        count: selectedMapStopIds.length
                    }),
                    variant: 'success'
                });
            },
            onError: (error) => {
                if (error?.data || error?.response?.data) {
                    console.error(error);
                }
                addToast({
                    message: t('PlanStopsControl.toast.onErrorCancel', {
                        count: selectedMapStopIds.length
                    }),
                    variant: 'error'
                });
            }
        });
    }

    const _handleDrop = (e, index) => {
        dispatch(
            replaceSelectedMapStop(
                mapPlanStopUtils.processStopsReorder(
                    e,
                    selectedPlanStops,
                    stopIdPropertyName,
                    dragStartIndex,
                    index,
                    isOpenUnassignedTasksDrawer
                )
            )
        );
    };

    function _getStopList() {
        return selectedPlanStops.map((selectedStop, index) => (
            <ResequenceModalStopList
                key={selectedStop[stopIdPropertyName]}
                stopIdPropertyName={stopIdPropertyName}
                selectedStop={selectedStop}
                handleDragStart={() => setDragStartIndex(index)}
                handleDragOver={(e) => e.preventDefault()}
                handleDrop={(e) => _handleDrop(e, index)}
            />
        ));
    }

    return (
        <ManageStopControlMultiple
            className="multistopcontrol"
            controlTitle={t('PlanStopsControl.title.no_of_stops_selected', {
                length: selectedMapStopIds.length
            })}
            controlHelp={t('PlanStopsControl.title.selection_info')}
            stopList={_getStopList()}
            footerButtonTitle={footerButtonTitle}
            footerButtonIcon={footerButtonIcon}
            onClickFooterButton={onClickFooterButton}
        >
            <ManageStopControlMenu>
                <StopModalMenuButton
                    disabled={disableResequencing}
                    buttonIcon="iconReorder"
                    buttonText={t('PlanStopsControl.button.resequence')}
                    onClick={() =>
                        onSwitchView(constants.stopsControl.views.RESEQUENCE)
                    }
                    showButtonArrow
                />
            </ManageStopControlMenu>
            <div className="_d-flex _ai-center">
                <span className="_text-3-alt">
                    {t('PlanStopsControl.title.selected_stops_info')}
                </span>
            </div>
            <ManageStopControlMenu>
                {/**
                 * @todo remove `disabled` once AT2-437 and AT2-438 are resolved
                 * @see [Add reassign API]{@link https://wisesys.atlassian.net/browse/AT2-437}
                 * @see [Add reassign hook]{@link https://wisesys.atlassian.net/browse/AT2-438}
                 */}
                <StopModalMenuButton
                    buttonIcon="iconRouteFill"
                    buttonText={t('PlanStopsControl.button.reassign')}
                    showButtonArrow
                    disabled={isLiveStopControl}
                    onClick={() =>
                        onSwitchView(constants.stopsControl.views.REASSIGN)
                    }
                />
                {isLiveStopControl && (
                    <StopModalMenuButton
                        onClick={_handleClickLiveStopUnassign}
                        buttonIcon="unassign"
                        buttonText={t('PlanStopsControl.button.unassign')}
                    />
                )}
                {isLiveStopControl && (
                    <StopModalMenuButton
                        onClick={_handleClickLiveStopCancel}
                        buttonIcon="iconRouteless"
                        buttonText={t('PlanStopsControl.button.cancel')}
                    />
                )}
                {!isLiveStopControl && (
                    <StopModalMenuButton
                        disabled={!isUnassignStopsAllowed}
                        onClick={unassignStops}
                        buttonIcon="iconRouteless"
                        buttonText={t('PlanStopsControl.button.unassign')}
                    />
                )}
            </ManageStopControlMenu>
        </ManageStopControlMultiple>
    );
}

export default MultiStopsControl;
