import axios from 'axios';
import constants from '~/utils/constants';
import {
    AxiosApiResponse,
    ApiClient,
    ExtentParams,
    PaginationParams,
    PaginationMetadata
} from './types';

type GetClientsParams = PaginationParams & ExtentParams;

/**
 * Implementations of API methods under the /clients path
 *
 * @category API
 */
export default class ClientsApi {
    /**
     * Path of the API endpoint
     */
    private static readonly clientsPath = '/clients';

    private static readonly clientPath = '/client';

    static get(
        params: GetClientsParams = { page: 0, limit: 2500 }
    ): Promise<AxiosApiResponse<ApiClient[], PaginationMetadata>> {
        return axios.get(this.clientsPath, {
            params,
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }

    static update(
        clientId: string,
        body: Partial<ApiClient>
    ): Promise<AxiosApiResponse<{ id: string }, PaginationMetadata>> {
        return axios.put(`${this.clientPath}/${clientId}`, body, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }
}
