import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { idUtils } from '~/utils/id-utils';
import { colorUtils } from '~/utils/color-utils';
import driversApi from '~/api/DriversApi';
import driverUtils from '~/utils/driver-utils';
import {
    addAvailableDriverId,
    resetAvailableDriversId,
    selectAvailableDriversId
} from '~/reducers/availableDriversIdSlice';
import { selectDate } from '~/reducers/selectedDateSlice';
import { setDriversByClient } from '~/reducers/driversByClientSlice';

const updateFetchedDrivers = ({ drivers, selectedDate, dispatch }) => {
    for (const driver of drivers) {
        // auto-assign web colors by driver
        // TODO:
        // + in a future improvement, user-defined driver color will be set to the driver response
        // + for now, we will auto-assign web colors for each driver
        const { client, id } = driver;
        const clientDriverId = idUtils.getCombinedId(client, id);
        const isWebColorAssignedForId =
            colorUtils.isWebColorAssignedForId(clientDriverId);

        if (!isWebColorAssignedForId)
            colorUtils.assignWebColorsToDriver(clientDriverId);

        const isAvailable = driverUtils.isDriverAvailableOnSelectedDate(
            driver,
            selectedDate
        );

        if (isAvailable) dispatch(addAvailableDriverId(driver.id));
    }
};

export const useFetchDrivers = () => {
    const dispatch = useDispatch();
    const [totalDrivers, setTotalDrivers] = useState(0);
    const [availableDriversCount, setAvailableDriversCount] = useState(0);
    const availableDriversId = useSelector(selectAvailableDriversId);
    const selectedDate = useSelector(selectDate);

    useEffect(() => {
        (async () => {
            try {
                const {
                    data: { data: responseData }
                } = await driversApi.get();
                const drivers = responseData;
                const driversByClientIds = drivers.reduce((result, driver) => {
                    if (!result[driver.client]) {
                        result[driver.client] = [];
                    }
                    result[driver.client].push(driver);
                    return result;
                }, {});

                dispatch(setDriversByClient(driversByClientIds));
                dispatch(resetAvailableDriversId());
                setTotalDrivers(drivers.length);
                updateFetchedDrivers({
                    drivers,
                    dispatch,
                    selectedDate
                });
            } catch (e) {
                dispatch(setDriversByClient({}));
                console.error(e);
            }
        })();
    }, [selectedDate]);

    useEffect(() => {
        if (availableDriversId)
            setAvailableDriversCount(Object.keys(availableDriversId).length);
    }, [availableDriversId]);

    return {
        availableDriversCount,
        totalDrivers
    };
};
