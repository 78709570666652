import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import constants from '~/utils/constants';
import { getUserRoleForFeature } from '~/utils/getUserRoleForFeature';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { selectUserGroupsForCurrentUser } from '~/reducers/userGroupsForCurrentUserSlice';

export const useFeaturePermissionRole = () => {
    const mainClient = useSelector(selectMainClient);
    const associatedUserGroups = useSelector(selectUserGroupsForCurrentUser);
    const [driverPermissionRole, setDriverPermissionRole] = useState(
        constants.userPermissionRoles.NO_ACCESS
    );
    const [planPermissionRole, setPlanPermissionRole] = useState(
        constants.userPermissionRoles.NO_ACCESS
    );
    const [taskManagementPermissionRole, setTaskManagementPermissionRole] =
        useState(constants.userPermissionRoles.NO_ACCESS);

    useEffect(() => {
        const driverFeature = mainClient?.preferences?.enableDriverManagement
            ? constants.userPermissionFeatures.DRIVER_MANAGEMENT
            : constants.userPermissionFeatures.DRIVER_BOOKING;
        const planFeature = constants.userPermissionFeatures.PLANNING;
        const taskManagementFeature =
            constants.userPermissionFeatures.TASK_MANAGEMENT;

        const driverRole = getUserRoleForFeature(
            associatedUserGroups,
            driverFeature
        );
        const planRole = getUserRoleForFeature(
            associatedUserGroups,
            planFeature
        );
        const taskManagementRole = getUserRoleForFeature(
            associatedUserGroups,
            taskManagementFeature
        );

        setDriverPermissionRole(driverRole);
        setPlanPermissionRole(planRole);
        setTaskManagementPermissionRole(taskManagementRole);
    }, [associatedUserGroups]);

    return {
        driverPermissionRole,
        planPermissionRole,
        taskManagementPermissionRole
    };
};
