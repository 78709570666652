import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

import constants, { isValueInConstantObject } from '~/utils/constants';
import type { RootState } from '~/storeTypes';

/**
 * All map marker modes
 */
type MapMarkerMode =
    typeof constants.mapMarkerModes[keyof typeof constants.mapMarkerModes];
/**
 * All map route modes
 */
type MapRouteMode =
    typeof constants.mapRouteModes[keyof typeof constants.mapRouteModes];

/**
 * Map route modes for which specific map settings exist
 */
type ConfigurableMapRouteMode = typeof constants.mapRouteModes[keyof Omit<
    typeof constants.mapRouteModes,
    'EMPTY'
>];

/**
 * Map settings that are settable per map route mode
 */
type PerTabMapSettings = {
    showZonePolygons: boolean;
    showRoutePolygons: boolean;
    showRoutePopup: boolean;
    showRouteLabel: boolean;
    showStopPopup: boolean;
    showStopNumber: boolean;
    showStopLabel: boolean;
    showDriverPopup: boolean;
    showDriverLabel: boolean;
    showDriverLines: boolean;
};

/**
 * Global map settings
 */
type MapSettingsState = {
    hasIsolatedRoutes: boolean;
    mapMarkerMode: MapMarkerMode;
    mapRouteMode: MapRouteMode;
    shouldFitPlanMapToBounds: boolean;
    redirectedToMapPage: boolean;
    isClusteringStops: boolean;
    isClusteringToggleEnabled: boolean;
    isMultipleCardSelectEnabled: boolean;
    viewCardDetails: boolean;
    [constants.mapRouteModes.PLAN]: PerTabMapSettings;
    [constants.mapRouteModes.DISPATCHED]: PerTabMapSettings;
    [constants.mapRouteModes.COMPLETED]: PerTabMapSettings;
};

/**
 * Reducer action to set a given setting under map route mode `mode` to the given `value`.
 */
type SetTabSpecificSettingAction = PayloadAction<{
    /**
     * Map route mode for which to set the setting
     */
    mode: ConfigurableMapRouteMode;
    /**
     * New setting value
     */
    value: boolean;
}>;

/**
 * Redux slice for various map-related settings
 * @category Reducers
 * @module mapSettingsSlice
 */
export const defaultPerTabMapSettings: PerTabMapSettings = {
    showZonePolygons: false,
    showRoutePolygons: false,
    showRoutePopup: false,
    showRouteLabel: false,
    showStopPopup: false,
    showStopNumber: false,
    showStopLabel: false,
    showDriverPopup: false,
    showDriverLabel: false,
    showDriverLines: false
};

export const defaultGlobalMapSettings: MapSettingsState = {
    hasIsolatedRoutes: false,
    mapMarkerMode: constants.mapMarkerModes.ROUTES,
    mapRouteMode: constants.mapRouteModes.EMPTY,
    shouldFitPlanMapToBounds: false,
    redirectedToMapPage: false,
    isClusteringStops: true,
    isClusteringToggleEnabled: true,
    isMultipleCardSelectEnabled: false,
    viewCardDetails: false,
    [constants.mapRouteModes.PLAN]: { ...defaultPerTabMapSettings },
    [constants.mapRouteModes.DISPATCHED]: {
        ...defaultPerTabMapSettings,
        showDriverLines: true,
        showStopNumber: true
    },
    [constants.mapRouteModes.COMPLETED]: {
        ...defaultPerTabMapSettings,
        showStopNumber: true
    }
};

/**
 * apply map settings on a per-tab basis
 * @private
 * @param {ConfigurableMapRouteMode} mapRouteMode - the map route mode
 * @param {string} setting - the map setting to update
 * @param {MapSettingsState} state - the current state
 * @param {(boolean|string)} value - the map setting value to set, type depends on setting
 * @returns {MapSettingsState} the updated state
 */
const applyToPerTabSettings = (
    mapRouteMode: ConfigurableMapRouteMode,
    setting: keyof PerTabMapSettings,
    state: MapSettingsState,
    value: boolean | string
): MapSettingsState => {
    if (
        !Object.values(constants.mapRouteModes).includes(mapRouteMode) ||
        !Object.keys(defaultPerTabMapSettings).includes(setting) ||
        !_.isBoolean(value) ||
        typeof value !== typeof state[mapRouteMode][setting]
    ) {
        return state;
    }
    const modeSettings = {
        ...state[mapRouteMode],
        [setting]: value
    };
    return { ...state, [mapRouteMode]: modeSettings };
};

export const mapSettingsSlice = createSlice({
    name: 'mapSettings',
    initialState: defaultGlobalMapSettings,
    reducers: {
        /**
         * set the current map mode setting for showing zone polygons
         * @method setShowZonePolygons
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowZonePolygons } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update zone polygons setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowZonePolygons({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowZonePolygons: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showZonePolygons',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing route polygons
         * @method setShowRoutePolygons
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowRoutePolygons } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update route polygons setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowRoutePolygons({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowRoutePolygons: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showRoutePolygons',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing route popups
         * @method setShowRoutePopup
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowRoutePopup } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update route popups setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowRoutePopup({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowRoutePopup: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showRoutePopup',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing route labels
         * @method setShowRouteLabel
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowRouteLabel } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update route labels setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowRouteLabel({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowRouteLabel: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showRouteLabel',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing stop popups
         * @method setShowStopPopup
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowStopPopup } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update stop popups setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowStopPopup({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowStopPopup: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showStopPopup',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing stop numbers
         * @method setShowStopNumber
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowStopNumber } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update stop numbers setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowStopNumber({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowStopNumber: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showStopNumber',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing stop labels
         * @method setShowStopLabel
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowStopLabel } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update stop labels setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowStopLabel({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowStopLabel: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showStopLabel',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing driver popups
         * @method setShowDriverPopup
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowDriverPopup } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update driver popups setting for dispatched route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowDriverPopup({ mode: constants.mapRouteModes.DISPATCHED, value: true }));
         */
        setShowDriverPopup: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showDriverPopup',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing driver labels
         * @method setShowDriverLabel
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowDriverLabel } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update driver labels setting for dispatched route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowDriverLabel({ mode: constants.mapRouteModes.DISPATCHED, value: true }));
         */
        setShowDriverLabel: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showDriverLabel',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing driver lines
         * @method setShowDriverLines
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowDriverLines } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update driver lines setting for dispatched route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowDriverLines({ mode: constants.mapRouteModes.DISPATCHED, value: true }));
         */
        setShowDriverLines: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showDriverLines',
                state,
                action.payload.value
            ),
        /**
         * set the global map setting whether the map has isolated routes
         * @method setHasIsolatedRoutes
         * @param {MapSettingsState} state - the current state
         * @param {PayloadAction<boolean>} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setHasIsolatedRoutes } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // update isolated routes setting to `true`
         * const dispatch = useDispatch();
         * dispatch(setHasIsolatedRoutes(true);
         */
        setHasIsolatedRoutes: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, hasIsolatedRoutes: action.payload };
        },
        /**
         * set the global map setting identifying the map marker mode
         * @method setMapMarkerMode
         * @param {MapSettingsState} state - the current state
         * @param {PayloadAction<MapMarkerMode>} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setMapMarkerMode } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update map marker mode to `STOPS_CLUSTERS`
         * const dispatch = useDispatch();
         * dispatch(setMapMarkerMode(constants.mapMarkerModes.STOPS_CLUSTERS));
         */
        setMapMarkerMode: (
            state: MapSettingsState,
            action: PayloadAction<MapMarkerMode>
        ): MapSettingsState => {
            if (
                !isValueInConstantObject<MapMarkerMode>(
                    'mapMarkerModes',
                    action.payload
                )
            ) {
                return state;
            }
            return { ...state, mapMarkerMode: action.payload };
        },
        /**
         * set the global map setting identifying the map route mode
         * @method setMapRouteMode
         * @param {MapSettingsState} state - the current state
         * @param {PayloadAction<MapRouteMode>} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setMapRouteMode } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update map route mode to `COMPLETED`
         * const dispatch = useDispatch();
         * dispatch(setMapRouteMode(constants.mapRouteModes.COMPLETED));
         */
        setMapRouteMode: (
            state: MapSettingsState,
            action: PayloadAction<MapRouteMode>
        ): MapSettingsState => {
            if (
                !isValueInConstantObject<MapRouteMode>(
                    'mapRouteModes',
                    action.payload
                )
            ) {
                return state;
            }
            return { ...state, mapRouteMode: action.payload };
        },
        /**
         * set the global map setting whether the map should fit markers to map bounds
         * @method setShouldFitPlanMapToBounds
         * @param {MapSettingsState} state - the current state
         * @param {PayloadAction<boolean>} action - the reducer's action object
         * @param {MapSettingsState} action.payload - the reducer's payload
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShouldFitPlanMapToBounds } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // set markers to fit to bounds
         * const dispatch = useDispatch();
         * dispatch(setShouldFitPlanMapToBounds(true));
         */
        setShouldFitPlanMapToBounds: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, shouldFitPlanMapToBounds: action.payload };
        },
        /**
         * set the global map setting whether the user was redirected to map page
         * @method setRedirectedToMapPage
         * @param {MapSettingsState} state - the current state
         * @param {PayloadAction<boolean>} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setRedirectedToMapPage } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // identify that the user was redirected to map page
         * const dispatch = useDispatch();
         * dispatch(setRedirectedToMapPage(true));
         */
        setRedirectedToMapPage: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            state.redirectedToMapPage = action.payload;
            return state;
        },
        /**
         * set the global map setting whether the map is clustering stops
         * @method setIsClusteringStops
         * @param {MapSettingsState} state - the current state
         * @param {PayloadAction<boolean>} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setIsClusteringStops } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // identify that the map is clustering stops
         * const dispatch = useDispatch();
         * dispatch(setIsClusteringStops(true));
         */
        setIsClusteringStops: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            state.isClusteringStops = action.payload;
            return state;
        },
        /**
         * set the global map setting whether the map clustering toggle is enabled
         * @method setIsClusteringToggleEnabled
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {boolean} action.payload - the reducer's payload
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setIsClusteringToggleEnabled } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // identify that the map clustering toggle is enabled
         * const dispatch = useDispatch();
         * dispatch(setIsClusteringToggleEnabled(true));
         */
        setIsClusteringToggleEnabled: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            state.isClusteringToggleEnabled = action.payload;
            return state;
        },
        /**
         * set the global map setting whether the map is allowing multiple cards to be selected from the drawers
         * @method setIsMultipleCardSelectEnabled
         * @param {MapSettingsState} state - the current state
         * @param {PayloadAction<boolean>} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setIsMultipleCardSelectEnabled } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // identify that the map is allowing multiple cards to be selected from the drawers
         * const dispatch = useDispatch();
         * dispatch(setIsMultipleCardSelectEnabled(true));
         */
        setIsMultipleCardSelectEnabled: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            state.isMultipleCardSelectEnabled = action.payload;
            return state;
        },
        /**
         * set the global map setting whether the map drawer is set to view card details
         * @method setViewCardDetails
         * @param {MapSettingsState} state - the current state
         * @param {PayloadAction<boolean>} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setViewCardDetails } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // identify the map drawer is set to view card details
         * const dispatch = useDispatch();
         * dispatch(setViewCardDetails(true));
         */
        setViewCardDetails: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            state.viewCardDetails = action.payload;
            return state;
        },
        /**
         * Reset most global map settings back to default values, persisting select settings
         * @method resetMapState
         * @param {MapSettingsState} state the current state
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { resetMapState } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // reset map state
         * const dispatch = useDispatch();
         * dispatch(resetMapState());
         */
        resetMapState: (state: MapSettingsState): MapSettingsState => {
            const persistSettings = _.pick(state, [
                constants.mapRouteModes.PLAN,
                constants.mapRouteModes.DISPATCHED,
                constants.mapRouteModes.COMPLETED,
                'isClusteringToggleEnabled',
                'isClusteringStops',
                'mapRouteMode'
            ]);
            return {
                ...defaultGlobalMapSettings,
                ...persistSettings
            };
        },
        /**
         * reset the all global map settings back to default values
         * @method resetMapSettings
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { resetMapSettings } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // reset map settings
         * const dispatch = useDispatch();
         * dispatch(resetMapSettings());
         */
        resetMapSettings: (): MapSettingsState => {
            return defaultGlobalMapSettings;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return defaultGlobalMapSettings;
        });
    }
});

export const {
    setShowZonePolygons,
    setShowRoutePolygons,
    setShowRoutePopup,
    setShowRouteLabel,
    setShowStopPopup,
    setShowStopNumber,
    setShowStopLabel,
    setShowDriverPopup,
    setShowDriverLabel,
    setShowDriverLines,
    setHasIsolatedRoutes,
    setMapMarkerMode,
    setMapRouteMode,
    setShouldFitPlanMapToBounds,
    setRedirectedToMapPage,
    setIsClusteringStops,
    setIsClusteringToggleEnabled,
    setIsMultipleCardSelectEnabled,
    setViewCardDetails,
    resetMapSettings,
    resetMapState
} = mapSettingsSlice.actions;

/**
 * selects the current map mode setting for showing zone polygons
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowZonePolygons } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showZonePolygons = useSelector(selectShowZonePolygons(constants.mapRouteModes.PLAN));
 */
export const selectShowZonePolygons =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showZonePolygons;

/**
 * selects the current map mode setting for showing route polygons
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowRoutePolygons } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showRoutePolygons = useSelector(selectShowRoutePolygons(constants.mapRouteModes.PLAN));
 */
export const selectShowRoutePolygons =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showRoutePolygons;

/**
 * selects the current map mode setting for showing route popups
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowRoutePopup } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showRoutePopup = useSelector(selectShowRoutePopup(constants.mapRouteModes.PLAN));
 */
export const selectShowRoutePopup =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showRoutePopup;

/**
 * selects the current map mode setting for showing route labels
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowRouteLabel } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showRouteLabel = useSelector(selectShowRouteLabel(constants.mapRouteModes.PLAN));
 */
export const selectShowRouteLabel =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showRouteLabel;

/**
 * selects the current map mode setting for showing stop popups
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowStopPopup } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showStopPopup = useSelector(selectShowStopPopup(constants.mapRouteModes.PLAN));
 */
export const selectShowStopPopup =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showStopPopup;

/**
 * selects the current map mode setting for showing stop numbers
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowStopNumber } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showStopNumber = useSelector(selectShowStopNumber(constants.mapRouteModes.PLAN));
 */
export const selectShowStopNumber =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showStopNumber;

/**
 * selects the current map mode setting for showing stop labels
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowStopLabel } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showStopLabel = useSelector(selectShowStopLabel(constants.mapRouteModes.PLAN));
 */
export const selectShowStopLabel =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showStopLabel;

/**
 * selects the current map mode setting for showing driver popups
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowDriverPopup } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showDriverPopup = useSelector(selectShowDriverPopup(constants.mapRouteModes.DISPATCHED));
 */
export const selectShowDriverPopup =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showDriverPopup;

/**
 * selects the current map mode setting for showing driver labels
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowDriverLabel } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showDriverLabel = useSelector(selectShowDriverLabel(constants.mapRouteModes.DISPATCHED));
 */
export const selectShowDriverLabel =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showDriverLabel;

/**
 * selects the current map mode setting for showing driver lines
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowDriverLines } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showDriverLines = useSelector(selectShowDriverLines(constants.mapRouteModes.DISPATCHED));
 */
export const selectShowDriverLines =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showDriverLines;

/**
 * selects the current global map setting whether the map should fit markers to map bounds
 * @method
 * @returns {boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectHasIsolatedRoutes } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const hasIsolatedRoutes = useSelector(selectHasIsolatedRoutes());
 */
export const selectHasIsolatedRoutes = (state: RootState): boolean =>
    state.mapSettings.hasIsolatedRoutes;

/**
 * selects the current global map setting identifying the map marker mode
 * @method
 * @returns {string} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectMapMarkerMode } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const mapMarkerMode = useSelector(selectMapMarkerMode());
 */
export const selectMapMarkerMode = (state: RootState): MapMarkerMode =>
    state.mapSettings.mapMarkerMode;

/**
 * selects the current global map setting identifying the map route mode
 * @method
 * @returns {string} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectMapRouteMode } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const mapRouteMode = useSelector(selectMapRouteMode());
 */
export const selectMapRouteMode = (state: RootState): MapRouteMode =>
    state.mapSettings.mapRouteMode;

/**
 * selects the current global map setting whether the map should fit markers to map bounds
 * @method
 * @returns {boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShouldFitPlanMapToBounds } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const shouldFitPlanMapToBounds = useSelector(selectShouldFitPlanMapToBounds());
 */
export const selectShouldFitPlanMapToBounds = (state: RootState): boolean =>
    state.mapSettings.shouldFitPlanMapToBounds;

/**
 * selects the current global map setting whether the user was redirected to map page
 * @method
 * @returns {boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectRedirectedToMapPage } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const redirectedToMapPage = useSelector(selectRedirectedToMapPage());
 */
export const selectRedirectedToMapPage = (state: RootState): boolean =>
    state.mapSettings.redirectedToMapPage;

/**
 * selects the current global map setting whether the map is clustering stops
 * @method
 * @returns {boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectIsClusteringStops } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const isClusteringStops = useSelector(selectIsClusteringStops());
 */
export const selectIsClusteringStops = (state: RootState): boolean =>
    state.mapSettings.isClusteringStops;

/**
 * selects the current global map setting whether the map clustering toggle is enabled
 * @method
 * @returns {boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectIsClusteringToggleEnabled } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const isClusteringToggleEnabled = useSelector(selectIsClusteringToggleEnabled());
 */
export const selectIsClusteringToggleEnabled = (state: RootState): boolean =>
    state.mapSettings.isClusteringToggleEnabled;

/**
 * selects the current global map setting whether the map is allowing multiple cards to be selected from the drawers
 * @method
 * @returns {boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectIsMultipleCardSelectEnabled } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const isMultipleCardSelectEnabled = useSelector(selectIsMultipleCardSelectEnabled());
 */
export const selectIsMultipleCardSelectEnabled = (state: RootState): boolean =>
    state.mapSettings.isMultipleCardSelectEnabled;

/**
 * selects the current global map setting whether the map drawer is set to view card details
 * @method
 * @returns {boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectViewCardDetails } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const viewCardDetails = useSelector(selectViewCardDetails());
 */
export const selectViewCardDetails = (state: RootState): boolean =>
    state.mapSettings.viewCardDetails;

export default mapSettingsSlice.reducer;
