import React from 'react';
import constants from '~/utils/constants';
import { UndoButton } from '~/ui/components/UndoButton';
import { useUndoScheduleAction } from '~/hooks';

const {
    APPEND_ROUTE,
    REASSIGN_STOP,
    REASSOCIATE_DRIVER_VEHICLE,
    RESEQUENCE_STOP,
    UNASSIGN_STOP
} = constants.processIndicator;
const supportedTypes = [
    APPEND_ROUTE,
    REASSIGN_STOP,
    REASSOCIATE_DRIVER_VEHICLE,
    RESEQUENCE_STOP,
    UNASSIGN_STOP
];

type IndicatorType = typeof supportedTypes[number];

type ClickHandler = () => void;

type ReturnProps = {
    getUndoButton?: (
        indicatorType: IndicatorType
    ) => React.ReactNode | undefined;
    getUndoButtonHandler?: (
        indicatorType: IndicatorType
    ) => ClickHandler | undefined;
};

export const useUndoRouteEdit = (): ReturnProps => {
    const { undoScheduleAction } = useUndoScheduleAction();
    const undoButtonHandler = () => {
        undoScheduleAction().catch((e) => console.error(e));
    };

    const getUndoButtonHandler = (indicatorType: IndicatorType) => {
        if (supportedTypes.includes(indicatorType)) {
            return undoButtonHandler;
        }
    };

    const getUndoButton = (indicatorType: IndicatorType) => {
        const handler = getUndoButtonHandler(indicatorType);

        if (!handler) {
            return undefined;
        }

        return <UndoButton clickHandler={undoButtonHandler} />;
    };

    return {
        getUndoButton,
        getUndoButtonHandler
    };
};
