import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { RouteCardText } from '~/ui';
import dateUtils from '~/utils/date-utils';

function RouteCardEta({ stopData, icon, className, ...extra }) {
    const { t } = useTranslation('common');

    const timeWindow = useMemo(() => {
        if (!stopData || stopData.isCanceled) {
            return '';
        }

        const {
            arrivalTime,
            completedAt,
            serviceWindow,
            isUnassigned,
            isCompleted,
            isInProgress
        } = stopData;

        const parsedArrival = dateUtils.getLocalizedTime(arrivalTime);
        const parsedCompleted = dateUtils.getLocalizedTime(completedAt);
        const parsedServiceWindow = {
            start: dateUtils.getLocalizedTime(serviceWindow?.start),
            end: dateUtils.getLocalizedTime(serviceWindow?.end)
        };

        if (isCompleted && completedAt) {
            return parsedCompleted;
        }

        if (isUnassigned && serviceWindow) {
            return t('time.timeWindow', parsedServiceWindow);
        }

        if (isInProgress && serviceWindow) {
            return t('time.estimatedTimeWindow', parsedServiceWindow);
        }

        return t('time.estimatedTime', { time: parsedArrival });
    }, [stopData]);

    function _getClassName() {
        const defaultClassName = 'routecard-eta';
        return classNames(defaultClassName, className);
    }

    return (
        <RouteCardText
            className={_getClassName()}
            icon={icon}
            data-testid={extra['data-testid'] || 'routecard-eta'}
        >
            {timeWindow}
        </RouteCardText>
    );
}

export default RouteCardEta;
