import React from 'react';
import PT from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/ui';
import './routecard-metric.scss';

function RouteCardMetric({
    className,
    value,
    valueColor,
    maxValue,
    icon,
    iconSize = 'xs',
    iconColor,
    icons = [],
    ...extra
}) {
    const { t } = useTranslation('translation');

    function _getClassName() {
        const defaultClassName = 'routecard-metric _d-flex _ai-center';
        const conditionalClasses = {
            [`routecard-metric_${valueColor}`]: valueColor
        };
        return classNames(defaultClassName, conditionalClasses, className);
    }

    function _getIcons() {
        if (icons && icons.length > 0) {
            return icons.map((item, idx) => {
                const {
                    icon: itemIcon,
                    iconSize: itemIconSize,
                    iconColor: itemIconColor
                } = item;
                const key = `${itemIcon}-${itemIconSize}-${itemIconColor}-${idx}`;
                if (!itemIcon) return null;
                return (
                    <Icon
                        key={key}
                        icon={itemIcon}
                        size={itemIconSize}
                        color={itemIconColor}
                        data-testid={
                            extra['data-testid']
                                ? `${extra['data-testid']}_icon`
                                : 'routecard-metric_icon'
                        }
                    />
                );
            });
        }

        if (icon) {
            return (
                <Icon
                    icon={icon}
                    size={iconSize}
                    color={iconColor}
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}_icon`
                            : 'routecard-metric_icon'
                    }
                />
            );
        }
    }

    return (
        <div
            className={_getClassName()}
            data-testid={extra['data-testid'] || 'routecard-metric'}
        >
            {(icon || icons) && _getIcons()}
            {value && (
                <span
                    className="routecard-metric_value"
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}_value`
                            : 'routecard-metric_value'
                    }
                >
                    {maxValue
                        ? t('valueOfMaxValue', { value, maxValue })
                        : value}
                </span>
            )}
        </div>
    );
}

RouteCardMetric.propTypes = {
    /** additional css classes to attach to this component */
    className: PT.string
};

export default RouteCardMetric;
