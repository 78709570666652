import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import AnalyticsApi from '~/api/AnalyticsApi';
import { DownloadableReports } from '~/data-classes/report';

/**
 * Maximum number of downloadable reports to retrieve from AnalyticsApi
 */
const REPORTS_API_LIMIT = 60;

export const useAggregateDriversDownloads = (
    limit = REPORTS_API_LIMIT,
    reactQueryOptions: Omit<UseQueryOptions, 'queryKey' | 'queryFn'> = {}
): UseQueryResult => {
    // get API response
    const getDownloadbleReports = async (returnLimit: number) => {
        const {
            data: { data }
        } = await AnalyticsApi.getAggregateDriversDownloads(returnLimit);

        return new DownloadableReports(data);
    };

    // initial empty data to prevent errors on first load
    const defaultReactQueryOptions = {
        initialData: new DownloadableReports([])
    };

    // merge query options
    const mergedOptions: Omit<UseQueryOptions, 'queryKey' | 'queryFn'> = {
        ...defaultReactQueryOptions,
        ...reactQueryOptions
    };

    // return the full useQuery Object
    return useQuery(
        [AnalyticsApi.REACT_QUERY_KEY, limit],
        () => getDownloadbleReports(limit),
        mergedOptions
    );
};
