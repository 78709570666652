import React from 'react';
import ZoneEditModal from '~/components/ZoneManagementPage/ZoneList/ZoneEditModal';
import { useZones } from '~/hooks/useZones';
import { mapUtils } from '~/utils/map-utils';
import { useMapContext } from '~/components/ZoneManagementPage/MapContext';

type AddZoneData = {
    name: string;
    assignedVehicles: string[] | [];
};

type UseZoneAddModalReturnValues = {
    ZoneAddModal: React.FC;
};

export const useZoneAddModal = (): UseZoneAddModalReturnValues => {
    const { createZone } = useZones();

    const { activePolygon, setActivePolygon } = useMapContext();

    const removePolygonFromMap = () => {
        activePolygon?.setMap(null);
        setActivePolygon(null);
    };

    const handleZoneCreate = async (zoneData: AddZoneData) => {
        const zoneArgs = {
            ...zoneData,
            coordinates: mapUtils.googlePolygonToGeoJSONPolygon(activePolygon)
        };
        try {
            await createZone(zoneArgs);
        } catch (e) {
            console.error(e);
        } finally {
            removePolygonFromMap();
        }
    };

    const ZoneAddModal = (): JSX.Element => {
        return (
            <>
                {activePolygon && (
                    <ZoneEditModal
                        onSave={handleZoneCreate}
                        zone={{
                            name: '',
                            assignedVehicles: []
                        }}
                        onCancel={removePolygonFromMap}
                        open={Boolean(activePolygon)}
                    />
                )}
            </>
        );
    };

    return {
        ZoneAddModal
    };
};
