import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    mapStopIdsToLiveStops,
    useResequenceDriverTasks
} from '~/hooks/useResequenceDriverTasks';
import { useMapUtils } from '~/hooks';
import {
    resetSelectedMapStops,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';
import { selectDispatchedDrivers } from '~/reducers/liveDriversSlice';

import { ManageStopControl } from '~/ui';
import { ResequenceModal } from '~/components/MapPage/ResequenceModal';
import ReassignModal from '~/components/MapPage/ReassignModal';
import SingleStopControl from '~/components/MapPage/PlanStopsControl/SingleStopControl';
import MultiStopsControl from '~/components/MapPage/PlanStopsControl/MultiStopsControl';
import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import '~/components/MapPage/PlanStopsControl/plan-stops-control.scss';

import constants from '~/utils/constants';

const { views } = constants.stopsControl;
const stopIdPropertyName = 'id';

function LiveStopsControl() {
    const dispatch = useDispatch();
    const dispatchedDrivers = useSelector(selectDispatchedDrivers);
    const selectedMapStopsIds = useSelector(selectSelectedMapStops);
    const [activeView, setActiveView] = useState(views.MAIN);
    const { resequenceDriverTasks, isResequenceAllowed } =
        useResequenceDriverTasks();
    const { mapRouteMode } = useMapUtils();
    const { routesLevelData } = usePlanMapPropsContext();

    const { t } = useTranslation('translation');
    const isMultiStopsSelected = selectedMapStopsIds.length > 1;

    const {
        driver,
        firstSelectedStop,
        selectedStops = [],
        unselectedStops = []
    } = useMemo(() => {
        return mapStopIdsToLiveStops({
            dispatchedDrivers,
            selectedMapStopsIds
        });
    }, [dispatchedDrivers, mapRouteMode, selectedMapStopsIds]);

    useEffect(() => {
        if (!selectedMapStopsIds.length && activeView !== views.MAIN) {
            setActiveView(views.MAIN);
        }
    }, [selectedMapStopsIds, activeView]);

    const deselectButtonClickHandler = useCallback(() => {
        dispatch(resetSelectedMapStops());
    }, [dispatch]);

    const onCloseHandler = useCallback(() => setActiveView(views.MAIN), []);

    const onResequence = useCallback(
        (selectedStopId, isInsertAfter) => {
            const selectedStop = driver?.schedule.find(
                ({ id }) => id === selectedStopId
            );

            if (!selectedStop) {
                console.warn('Stop not selected');
                return;
            }

            const newStopNumber = driver.schedule.indexOf(selectedStop);
            resequenceDriverTasks({ newStopNumber, isInsertAfter });
        },
        [driver, resequenceDriverTasks]
    );

    if (!firstSelectedStop) return null;

    const unselectedStopsWithStatusZero = unselectedStops.filter(
        ({ status }) => status === 0
    );

    return (
        <ManageStopControl className="planstopscontrol">
            {activeView === views.MAIN && (
                <>
                    {isMultiStopsSelected ? (
                        <MultiStopsControl
                            selectedPlanStops={selectedStops}
                            stopIdPropertyName={stopIdPropertyName}
                            footerButtonTitle={t(
                                'PlanStopsControl.button.deselectAll'
                            )}
                            footerButtonIcon="iconClose"
                            onClickFooterButton={deselectButtonClickHandler}
                            isLiveStopControl
                            onSwitchView={setActiveView}
                        />
                    ) : (
                        <SingleStopControl
                            stopData={firstSelectedStop}
                            footerButtonTitle={t(
                                'PlanStopsControl.button.deselect'
                            )}
                            footerButtonIcon="iconClose"
                            onClickFooterButton={deselectButtonClickHandler}
                            isLiveStopControl
                            disableResequencing={!isResequenceAllowed}
                            onSwitchView={setActiveView}
                        />
                    )}
                </>
            )}
            {activeView === views.RESEQUENCE && (
                <ResequenceModal
                    clickHandler={onCloseHandler}
                    onResequence={onResequence}
                    stopIdPropertyName={stopIdPropertyName}
                    isLiveStopControl
                    disableResequencing={!isResequenceAllowed}
                    selectedStopsData={selectedStops}
                    unselectedStopsData={unselectedStopsWithStatusZero}
                />
            )}
            {activeView === views.REASSIGN && (
                <ReassignModal
                    onClose={onCloseHandler}
                    routes={routesLevelData}
                    selectedStops={selectedStops}
                    stopIdPropertyName={stopIdPropertyName}
                />
            )}
        </ManageStopControl>
    );
}

export default LiveStopsControl;
