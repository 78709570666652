import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
    selectShowDriverLabel,
    selectShowDriverPopup
} from '~/reducers/mapSettingsSlice';
import { selectSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';

import { liveRoutesUtils } from '~/utils/live-routes-utils';
import constants from '~/utils/constants';

import { Icon, DriverMarkerPopup } from '~/ui/';

import './driver-marker.scss';

export function getExceptionIcons(driverData) {
    let rightPosition = 0;
    const extraIconRightShift = 0.4;
    const exceptionIcons = [];

    if (driverData.numAtRiskExceptions) {
        exceptionIcons.push({
            icon: 'delayFill',
            iconColor: 'saturn',
            position: `${rightPosition}rem`,
            value: driverData.numAtRiskExceptions
        });
        rightPosition += extraIconRightShift;
    }

    if (driverData.numLateExceptions) {
        exceptionIcons.push({
            icon: 'delayFill',
            iconColor: 'mars',
            position: `${rightPosition}rem`,
            value: driverData.numLateExceptions
        });
        rightPosition += extraIconRightShift;
    }

    if (driverData.numInventoryExceptions) {
        exceptionIcons.push({
            icon: 'warningFill3',
            iconColor: 'saturn',
            position: `${rightPosition}rem`,
            value: driverData.numInventoryExceptions
        });
    }

    return exceptionIcons;
}

function DriverMarker({ driverData, colorCSS }) {
    const [showPopup, setShowPopup] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const showDriverLabel = useSelector(
        selectShowDriverLabel(constants.mapRouteModes.DISPATCHED)
    );
    const showDriverPopup = useSelector(
        selectShowDriverPopup(constants.mapRouteModes.DISPATCHED)
    );
    const selectedMapRoutes = useSelector(selectSelectedMapRoutes);

    useEffect(() => {
        // update when selecting multiple
        if (liveRoutesUtils.isSelectingMultipleCards()) {
            setIsSelected(
                selectedMapRoutes.includes(driverData.clientDriverId)
            );
            return;
        }

        // reset when selecting singles
        setIsSelected(false);
    }, [selectedMapRoutes]);

    if (!driverData) {
        return null;
    }

    function _handleMouseEnter() {
        setShowPopup(showDriverPopup && true);
    }

    function _handleMouseLeave() {
        setShowPopup(false);
    }

    function _handleClick() {
        const toggleSelected = !isSelected;
        setIsSelected(toggleSelected);
        liveRoutesUtils.handleDriverSelect(toggleSelected, driverData);
    }

    function _getClassName() {
        let boxClassName = 'drivermarker';
        boxClassName =
            (isSelected && `${boxClassName} drivermarker_selected`) ||
            boxClassName;
        return boxClassName;
    }

    return (
        <button
            type="button"
            className={_getClassName()}
            onMouseEnter={_handleMouseEnter}
            onMouseLeave={_handleMouseLeave}
            onFocus={_handleMouseEnter}
            onClick={_handleClick}
        >
            <div className="drivermarker_icon-group _p-relative">
                <span className="drivermarker-circle" style={colorCSS}>
                    {!isSelected && driverData.initials}
                    {isSelected && <Icon color="white" icon="checkmark" />}
                </span>
                {getExceptionIcons(driverData).map((iconData) => {
                    return (
                        <Icon
                            key={iconData.position}
                            className="drivermarker_icon _p-absolute"
                            icon={iconData.icon}
                            color={iconData.iconColor}
                            size="s"
                            style={{ right: iconData.position }}
                        />
                    );
                })}
            </div>
            {showDriverLabel && (
                <span className="drivermarker-label">{driverData.name}</span>
            )}
            {showPopup && (
                <DriverMarkerPopup
                    driverData={driverData}
                    exceptionData={getExceptionIcons(driverData)}
                />
            )}
        </button>
    );
}

export default DriverMarker;
