import { setCepResult } from '~/reducers/schedulerProgressSlice';
import {
    getAllTasks,
    addNewTask,
    removeTasksById
} from '~/reducers/tasksSlice';
import { store } from '~/store';
import { removePendingJob } from '~/reducers/pendingJobsSlice';
import { planEditMetricsService } from '~/services/planEditMetricsService';
import { notifyReceivedWebSolution } from '~/reducers/detectWebSolutionSlice';
import throttleRequestsHandler from '~/utils/socket/throttle-requests';
import dateUtils from '~/utils/date-utils';
import generalUtils from '~/utils/general-utils';
import i18n from '~/i18n';
import { addToast } from '~/reducers/toastsSlice';
import { resetSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import {
    addOnDemandDispatchTask,
    removeOnDemandDispatchTask
} from '~/reducers/onDemandDispatchTaskCountSlice';
import { WebMultiClientApi } from '~/api/WebMultiClientApi';
import AddressApi from '~/api/AddressApi';
import { addUploadResult } from '~/reducers/uploadResultsSlice';
import { setCustomerSearchResults } from '~/reducers/customerSearchResultsSlice';
import { setWebSolution } from '~/reducers/webSolutionSlice';

// @todo: remove once AT2-375 is resolved
async function _getAddressData(addressId) {
    const {
        data: { data: addressData }
    } = await AddressApi.get(addressId);

    return addressData;
}

function _getRouteDateFromWebSolution(solution) {
    return solution.data.wiseRoutes.routeDate;
}

const dispatchWebSolution = (payloadData) => {
    store.dispatch(setWebSolution(payloadData));
};

function handleWebSolution(payload) {
    const storeState = store.getState();
    const jobId = payload.data.job;
    const { data: payloadData } = payload;

    if (storeState.pendingJobs && storeState.pendingJobs[jobId]) {
        dispatchWebSolution(payloadData);
        store.dispatch(removePendingJob(jobId));
        store.dispatch(notifyReceivedWebSolution());
    } else {
        // TODO: https://wisesys.atlassian.net/browse/MCW-115 [DanCross 2021-11-10]
        const taskRouteDate = _getRouteDateFromWebSolution(payload.data);
        const routeDate = dateUtils.convertToISODateOnly(
            storeState.selectedDate
        );
        if (routeDate === taskRouteDate) {
            dispatchWebSolution(payloadData);
            store.dispatch(notifyReceivedWebSolution());
            throttleRequestsHandler.clearRequestThrottle();
        }
    }
}

function handleCepResults(payload) {
    store.dispatch(setCepResult(payload));
}

function handleUpload(payload) {
    store.dispatch(addUploadResult(payload));
}

async function handleDirectRouteImpact(payload) {
    const storeState = store.getState();
    const { selectedDate } = storeState;
    if (!selectedDate) {
        return;
    }
    const routeDate = dateUtils.convertToISODateOnly(selectedDate);
    // check if clientId_schedulerTaskId matches up
    try {
        const clientData = storeState.planClientsLevelData;
        const clientTaskCount = clientData.reduce((result, client) => {
            if (client.clientId === payload.clientId) {
                result = client.stats.numTasks;
            }
            return result;
        }, 0);
        throttleRequestsHandler.setRequest(
            payload.clientId,
            routeDate,
            clientTaskCount
        );
        await planEditMetricsService.addMetricsToStore(
            payload.clientId,
            payload.data.schedulerTaskId
        );
    } catch (e) {
        console.warn(e);
    }
}

function handlePairingExecuted(payload) {
    const { pairing, unPairedDeliveryIds, unPairedPickupIds } =
        payload.data.pairing;

    const counts = {
        pairsCreatedCount: pairing.length,
        deliveryTasksRemainedCount: unPairedDeliveryIds?.length,
        pickupTasksRemainedCount: unPairedPickupIds?.length
    };

    store.dispatch(
        addToast({
            message: i18n.t('taskManagement:pairedUnassignedTasks', counts),
            variant: 'info'
        })
    );
    store.dispatch(getAllTasks({ routeDate: payload.date }));
    store.dispatch(resetSelectedTaskIds());
}

function _handleSingleOnDemandTaskChange(action, routeDate) {
    const dateCheck = !!routeDate && !dateUtils.isToday(routeDate);
    if (dateCheck) {
        return;
    }
    store.dispatch(action());
}

async function handleOnDemandTaskAdded(data) {
    const { routeDate, deliveryLocation, pickupLocation } = data;
    const newTaskData = { ...data };

    // update deliveryLocation
    // @todo: remove once AT2-375 is resolved
    if (deliveryLocation && generalUtils.isValidUUID(deliveryLocation)) {
        newTaskData.deliveryLocation = await _getAddressData(deliveryLocation);
    }

    // update pickupLocation
    // @todo: remove once AT2-375 is resolved
    if (pickupLocation && generalUtils.isValidUUID(pickupLocation)) {
        newTaskData.pickupLocation = await _getAddressData(pickupLocation);
    }

    _handleSingleOnDemandTaskChange(addOnDemandDispatchTask, routeDate);
    store.dispatch(addNewTask(newTaskData));
}

function handleOnDemandTaskRemoved(data) {
    const { routeDate, id } = data;
    _handleSingleOnDemandTaskChange(removeOnDemandDispatchTask, routeDate);
    store.dispatch(removeTasksById([id]));
}

function handleOnDemandTaskAddedBulk() {
    const storeState = store.getState();
    const { activeClients } = storeState;
    // Results are expected via socket, no onfulfilled needed.
    WebMultiClientApi.get(activeClients).then(null, (err) =>
        console.error('Unexpected error while getting web', err)
    );
}

function handleCustomerSearch(payload) {
    store.dispatch(setCustomerSearchResults(payload.data));
}

function handleCustomerSearchError() {
    store.dispatch(
        addToast({
            message: i18n.t('error:customerSearchError'),
            variant: 'error'
        })
    );
}

export default {
    handleWebSolution,
    handleCepResults,
    handleCustomerSearch,
    handleCustomerSearchError,
    handleDirectRouteImpact,
    handlePairingExecuted,
    handleOnDemandTaskAdded,
    handleOnDemandTaskAddedBulk,
    handleOnDemandTaskRemoved,
    handleUpload
};
