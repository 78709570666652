import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from './common-actions';

type State = {
    requestProps?: unknown[] | null;
    requestId?: string | null;
    lastFetchMilliseconds?: number;
};

/**
 * Redux slice for getAllTasks fetch update
 * @category Reducers
 * @module loadTasksSlice
 */

export const loadTasksSlice = createSlice({
    name: 'loadTasks',
    initialState: {},
    reducers: {
        /**
         * Update the state of the loadTasksSlice
         * @method updateState
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Object} action.payload - state would be updated with values in object
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * import { useDispatch } from 'react-redux';
         * import { selectState } from '~/reducers/loadTasksSlice';
         *
         * const dispatch = useDispatch();
         * dispatch(updateState({ requestId: '1qws-123' }));
         */
        updateState: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {};
        });
    }
});

export const { updateState } = loadTasksSlice.actions;

/**
 * Selects the saved state
 * @method selectState
 * @param {Object} state - the global redux state
 * @returns {Object} the current redux state
 * @example <caption>Usage</caption>
 * import { useSelector } from 'react-redux';
 * import { selectState } from '~/reducers/loadTasksSlice';
 *
 * const { requestProps, requestId, lastFetchMilliseconds } = useSelector(selectState);
 */
export const selectState = (state: { loadTasks: State }): State =>
    state.loadTasks;
