import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';
import constants from '~/utils/constants';

const isTaskUpload = ({ type }) => type === constants.taskUpload.TYPE;

export const uploadResultsSlice = createSlice({
    name: 'uploadResults',
    initialState: {},
    reducers: {
        addUploadResult: (state, action) => {
            const { payload } = action;
            const { clientId, status, type } = payload;

            if (!state) {
                state = {};
            }

            if (!state[clientId]) {
                state[clientId] = [];
            }

            if (status !== constants.taskUpload.status.ACCEPTED) {
                state[clientId].push(payload);
                return state;
            }

            const newList = state[clientId].filter((message) => {
                return message.type !== type;
            });

            newList.push(payload);
            state[clientId] = newList;
        },
        clearTaskUploadResults: (state, action) => {
            const { payload: clientId } = action;

            if (!state) {
                state = {};
            }

            if (!state[clientId]) {
                state[clientId] = [];
            }

            state[clientId] = state[clientId].filter(
                (result) => !isTaskUpload(result)
            );
        },
        resetUploadResults: () => {
            return {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {};
        });
    }
});

export const { addUploadResult, clearTaskUploadResults, resetUploadResults } =
    uploadResultsSlice.actions;

export const selectUploadResults = (state) => {
    return state.uploadResults;
};

export const selectTaskUploadResultsByClientId = (clientId) => (state) => {
    return state.uploadResults[clientId]?.filter(isTaskUpload) || [];
};

export default uploadResultsSlice.reducer;
