import { store } from '~/store';
import { EditRouteHistoryApi } from '~/api/EditRouteHistoryApi';
import { setEditHistoryByClient } from '~/reducers/editHistoryByClientSlice';

async function addMetricsToStore(clientId, schedulerTaskId) {
    if (!clientId) {
        return Promise.reject('missing clientId');
    }
    if (!schedulerTaskId) {
        return Promise.reject('missing schedulerTaskId');
    }
    try {
        const editHistory = await EditRouteHistoryApi.getEditHistory(
            clientId,
            schedulerTaskId
        );
        store.dispatch(
            setEditHistoryByClient({
                clientId,
                data: editHistory.data.data
            })
        );
    } catch (e) {
        console.warn(e);
    }
}

export const planEditMetricsService = {
    addMetricsToStore
};
