import axios from 'axios';
import constants from '~/utils/constants';
import {
    AxiosApiResponse,
    IApiResponse,
    PaginationParams,
    ApiVehicle
} from './types';

/**
 * Implementations of API methods under the /vehicle path
 *
 * @category API
 */
type GetVehiclesParams = PaginationParams;
export class VehicleApi {
    /**
     * Path of the single-vehicle API endpoint
     */
    private static readonly vehiclePath = '/vehicle';

    /**
     * Path of the multi-vehicle API endpoint
     */
    private static readonly vehiclesPath = '/vehicles';

    /**
     * Sends a PUT /vehicle/<id> request with the given data
     *
     * @param {string} vehicleId the vehicle to update
     * @param {ApiVehicle} data properties to change
     * @returns {Promise<AxiosApiResponse<ApiVehicle>>}
     */
    static put(
        vehicleId: string,
        data: ApiVehicle
    ): Promise<AxiosApiResponse<ApiVehicle>> {
        if (!vehicleId) {
            return Promise.reject('vehicle id not provided');
        }
        return axios.put<IApiResponse>(
            `${this.vehiclePath}/${vehicleId}`,
            data,
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }

    /**
     * Sends a POST /vehicle request with the given data
     *
     * @param {ApiVehicle} data properties to save
     * @returns {Promise<AxiosApiResponse<ApiVehicle>>}
     */
    static post(data: ApiVehicle): Promise<AxiosApiResponse<ApiVehicle>> {
        return axios.post<IApiResponse>(`${this.vehiclePath}`, data, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }

    static get(
        params: GetVehiclesParams = {
            limit: 1000
        }
    ): Promise<AxiosApiResponse> {
        return axios.get(this.vehiclesPath, {
            params,
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }
}
