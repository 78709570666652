import React from 'react';
import PT from 'prop-types';
import classNames from 'classnames';

import './routecard.scss';

const RouteCard = React.forwardRef(function RouteCard(
    {
        className,
        children,
        isRouteSelected,
        isRouteHidden,
        onMouseEnter,
        onMouseLeave,
        ...extra
    },
    ref
) {
    function _getClassName() {
        const conditionalClasses = {
            routecard_selected: isRouteSelected,
            routecard_off: isRouteHidden
        };
        return classNames('routecard _d-flex', conditionalClasses, className);
    }

    return (
        <div
            className={_getClassName()}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={ref}
            data-testid={extra['data-testid'] || 'routecard'}
        >
            {children}
        </div>
    );
});

RouteCard.propTypes = {
    /** additional css classes to attach to this component */
    className: PT.string
};

export default RouteCard;
