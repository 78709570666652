import React, { useCallback, useState } from 'react';
import { PageContext } from './PageContext';

type Props = {
    children: React.ReactNode;
};

export const PageContextProvider = ({ children }: Props): JSX.Element => {
    const [activeZoneId, setActiveZoneId] = useState<string | null>(null);
    const toggleActiveZoneId = useCallback(
        (newZoneId: string) => {
            if (activeZoneId !== newZoneId) {
                setActiveZoneId(newZoneId);
                return;
            }

            if (activeZoneId !== null) {
                setActiveZoneId(null);
            }
        },
        [activeZoneId]
    );

    return (
        <PageContext.Provider
            value={{
                activeZoneId,
                setActiveZoneId,
                toggleActiveZoneId
            }}
        >
            {children}
        </PageContext.Provider>
    );
};
