import React, { createContext, useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SinglePlanEditApi from '~/api/SinglePlanEditApi';
import { selectClients } from '~/reducers/clientsSlice';
import {
    resetSelectedMapStops,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';
import { addProcessIndicator } from '~/reducers/processIndicatorSlice';
import constants from '~/utils/constants';

const PlanMapPropsContext = createContext({
    planStops: [],
    routesLevelData: [],
    handleLoadingData: () => {},
    editPermissions: false
});

export const usePlanMapPropsContext = () => useContext(PlanMapPropsContext);

export const PlanMapPropsContextProvider = ({
    children,
    planStops = [],
    routesLevelData = [],
    handleLoadingData = () => {},
    editPermissions = false
}) => {
    const dispatch = useDispatch();
    const clients = useSelector(selectClients);
    const selectedMapStopsIds = useSelector(selectSelectedMapStops);
    const { t } = useTranslation('translation');

    const [mapInstance, setMapInstance] = useState(null);
    const [mapsAPI, setMapsAPI] = useState(null);

    const handleStopResequencing = useCallback(
        ({ newStopNumber, isInsertAfter = true }) => {
            const stopIdToStopMap = Object.fromEntries(
                planStops.map((stop) => [stop.clientRouteTaskId, stop])
            );

            const selectedPlanStops = selectedMapStopsIds
                .map((clientRouteTaskId) => stopIdToStopMap[clientRouteTaskId])
                .filter(Boolean);

            const [firstSelectedPlanStop] = selectedPlanStops;
            const selectedClient = clients[firstSelectedPlanStop?.clientId];
            const selectedClientRouteId = firstSelectedPlanStop?.clientRouteId;

            if (
                !selectedClient ||
                selectedPlanStops.length !== selectedMapStopsIds.length ||
                selectedPlanStops.some(
                    ({ clientRouteId }) =>
                        // all stops must be in the same route
                        clientRouteId !== selectedClientRouteId
                )
            )
                return;

            let hasUpdate = false;
            const reorderedTaskIds = [];
            const selectedPlanStopNumbers = new Set(
                selectedPlanStops.map(({ stopNumber }) => stopNumber)
            );
            const routePlanStops = Object.values(stopIdToStopMap)
                .filter(
                    ({ clientRouteId }) =>
                        clientRouteId === selectedClientRouteId
                )
                .sort(
                    (firstStop, secondStop) =>
                        firstStop.stopNumber - secondStop.stopNumber
                );

            routePlanStops.forEach(({ stopId, stopNumber, isDepot }) => {
                const canAddStop =
                    !isDepot && !selectedPlanStopNumbers.has(stopNumber);

                if (stopNumber !== newStopNumber) {
                    if (canAddStop) reorderedTaskIds.push(stopId);

                    return;
                }

                hasUpdate = true;

                if (canAddStop && isInsertAfter) {
                    reorderedTaskIds.push(stopId);
                }

                reorderedTaskIds.push(
                    ...selectedPlanStops.map((stop) => stop.stopId)
                );

                if (canAddStop && !isInsertAfter) {
                    reorderedTaskIds.push(stopId);
                }
            });

            if (!hasUpdate) return;

            const numOfSelectedStops = selectedPlanStops.length;
            const processIndicatorState = {
                message: t('ResequenceStop', {
                    count: numOfSelectedStops
                }),
                type: constants.processIndicator.RESEQUENCE_STOP,
                payload: numOfSelectedStops,
                inProgress: true,
                error: false,
                position: 'center'
            };
            (async () => {
                dispatch(addProcessIndicator(processIndicatorState));
                try {
                    await SinglePlanEditApi.resequenceTasks({
                        clientId: firstSelectedPlanStop.clientId,
                        date: firstSelectedPlanStop.routeDate,
                        routeId: firstSelectedPlanStop.routeId,
                        taskIds: reorderedTaskIds,
                        clientPreferences: selectedClient.preferences
                    });
                    dispatch(resetSelectedMapStops());
                } catch (e) {
                    console.error(e);
                    dispatch(
                        addProcessIndicator({
                            ...processIndicatorState,
                            inProgress: false,
                            error: true
                        })
                    );
                }
            })();
        },
        [clients, dispatch, planStops, selectedMapStopsIds]
    );

    return (
        <PlanMapPropsContext.Provider
            value={{
                planStops,
                routesLevelData,
                handleLoadingData,
                editPermissions,
                handleStopResequencing,
                mapInstance,
                setMapInstance,
                mapsAPI,
                setMapsAPI
            }}
        >
            {children}
        </PlanMapPropsContext.Provider>
    );
};
