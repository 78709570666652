import React from 'react';
import PropTypes from 'prop-types';
import { Icon, TooltipButton } from '~/ui';

import './stop-modal-menu-button.scss';

function StopModalMenuButton({
    buttonIcon,
    buttonText,
    buttonStopText = null,
    disabled = false,
    showButtonArrow = false,
    onClick = () => {},
    ...extra
}) {
    return (
        <div
            className="stopmodalmenubutton _d-flex"
            data-testid={extra['data-testid'] || 'stopmodalmenubutton'}
        >
            <TooltipButton
                className="stopmodalmenubutton_button _d-flex _ai-center _jc-space-between"
                disabled={disabled}
                onClick={onClick}
                data-testid={
                    extra['data-testid']
                        ? `${extra['data-testid']}_button`
                        : 'stopmodalmenubutton_button'
                }
            >
                <span
                    className="stopmodalmenubutton_button-text _d-flex _ai-center _jc-center _text-3"
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}_button-text`
                            : 'stopmodalmenubutton_button-text'
                    }
                >
                    <Icon
                        icon={buttonIcon}
                        size="s"
                        color="meteor"
                        data-testid={
                            extra['data-testid']
                                ? `${extra['data-testid']}_button-icon`
                                : 'stopmodalmenubutton_button-icon'
                        }
                    />
                    <span
                        data-testid={
                            extra['data-testid']
                                ? `${extra['data-testid']}_button-label`
                                : 'stopmodalmenubutton_button-label'
                        }
                    >
                        {buttonText}
                    </span>
                </span>
                {(buttonStopText || showButtonArrow) && (
                    <span className="stopmodalmenubutton_button-stop _d-flex _ai-center _jc-center _text-3">
                        <span
                            data-testid={
                                extra['data-testid']
                                    ? `${extra['data-testid']}_button-stop-text`
                                    : 'stopmodalmenubutton_button-stop-text'
                            }
                        >
                            {buttonStopText}
                        </span>
                        {showButtonArrow && (
                            <Icon
                                icon="chevronDown"
                                size="s"
                                color="galaxy-500"
                                sx={{ transform: 'rotate(-90deg)' }}
                                data-testid={
                                    extra['data-testid']
                                        ? `${extra['data-testid']}_button-stop-icon`
                                        : 'stopmodalmenubutton_button-stop-icon'
                                }
                            />
                        )}
                    </span>
                )}
            </TooltipButton>
        </div>
    );
}

StopModalMenuButton.propTypes = {
    /** The button icon */
    buttonIcon: PropTypes.string.isRequired,
    /** The button text */
    buttonText: PropTypes.string.isRequired,
    /** The button text identifying selected stop(s) */
    buttonStopText: PropTypes.string,
    /** Used to disable button */
    disabled: PropTypes.bool,
    /** Used to render an arrow on the button */
    showButtonArrow: PropTypes.bool,
    /** Attach a handler when clicking the button */
    onClick: PropTypes.func
};

export default StopModalMenuButton;
