/* eslint-disable camelcase */
import axios from 'axios';
import constants from '~/utils/constants';

import {
    ApiDriver,
    ApiRoutePlanRouteRoute,
    ApiStop,
    ApiTask,
    ApiVehicle,
    AxiosApiResponse,
    PaginationMetadata
} from './types';

interface GetMetricsResponseData {
    schedulerId?: string;
    numberOfEdits?: number;
    modifiedTaskIds?: string[];
    modifiedRouteIds?: string[];
    modifiedDriverIds?: string[];
    modifiedVehicleIds?: string[];
    cumulativeImpact?: {
        driverShiftCloseTimeViolation?: number;
        maxTaskViolation?: number;
        numberOfExtraNeededVehicles?: number;
        numberOfOpenRoutes?: number;
        numberOfTasks?: number;
        numberOfUnassignedTasks?: number;
        numberOfVehicleTypeViolation?: number;
        numberOfViolatedHardTW?: number;
        numberOfViolatedTW?: number;
        routeDurationViolation?: number;
        sizeViolation?: number;
        timeOnRoad?: number;
        totalCapacity?: {
            volume?: number;
            weight?: number;
        };
        totalDelay?: number;
        totalMeters?: number;
        totalServiceTime?: number;
        totalTravelTime?: number;
        totalWaitingTime?: number;
        weightViolation?: number;
    };
}

interface LocationRouteInfoRoute extends ApiRoutePlanRouteRoute {
    id?: string;
}

export interface LocationRouteInfo {
    route?: LocationRouteInfoRoute;
    driver?: ApiDriver;
    vehicle?: ApiVehicle;
}

export type GetEditHistoryResponseData = {
    id?: string;
    action?: string;
    change_data?: {
        taskId?: string;
        taskIds?: string[];
        tasks?: string[];
        routeId?: string;
        routeIds?: string[];
        driverId?: string;
        driverId1?: string;
        driverId2?: string;
        oldRouteId?: string;
        newRouteId?: string;
        vehicleId?: string;
        [key: string]: unknown;
    };
    old_route_plan_id?: string;
    new_route_plan_id?: string;
    supplementaryData?: {
        tasks?: ApiTask[];
        stops?: ApiStop[];
        origin?: LocationRouteInfo;
        destination?: LocationRouteInfo;
    };
}[];

type GetEditHistoryResponse = AxiosApiResponse<
    GetEditHistoryResponseData,
    PaginationMetadata
>;

/**
 * Implementations of API methods under the `/editroutehistory` path
 *
 * @category API
 */
export class EditRouteHistoryApi {
    /**
     * Path of the endpoint
     */
    private static readonly path = '/editroutehistory';

    static getMetrics(
        clientId: string,
        schedulerId: string
    ): Promise<AxiosApiResponse<GetMetricsResponseData>> {
        if (!clientId) {
            return Promise.reject('Missing clientId');
        }
        if (!schedulerId) {
            return Promise.reject('Missing schedulerId');
        }
        return axios.get(`${this.path}/metrics?schedulerId=${schedulerId}`, {
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    }

    static getEditHistory(
        clientId: string,
        schedulerId: string,
        limit = 5
    ): Promise<GetEditHistoryResponse> {
        if (!clientId) {
            return Promise.reject('Missing clientId');
        }
        if (!schedulerId) {
            return Promise.reject('Missing schedulerId');
        }
        return axios.get(
            `${this.path}?limit=${limit}&status=undoable&scheduler_id=${schedulerId}`,
            {
                headers: {
                    [constants.requestHeaders.WISE_CLIENT_ID]: clientId
                }
            }
        );
    }
}
