import classNames from 'classnames';
import React, { useState } from 'react';
import { IconButton } from '~/ui';
import PT from 'prop-types';

import './detail-drawer-item.scss';

function DetailDrawerItem({ title, children, ...extra }) {
    const [isOpen, setIsOpen] = useState(true);

    function showDetails() {
        setIsOpen(!isOpen);
    }

    function _getItemClassName() {
        const conditionalClasses = {
            'detaildrawer-item_open': isOpen
        };
        return classNames(
            'detaildrawer-item',
            conditionalClasses,
            extra.itemClassName
        );
    }

    function _getIconButtonClassName() {
        const defaultClassName = 'drawer-button';
        const conditionalClasses = {
            '_t-rotate-180': isOpen
        };
        return classNames(defaultClassName, conditionalClasses);
    }

    return (
        <div className={classNames(extra.className)}>
            <div className="detaildrawer-item-divider _mb-3" />
            <div
                className={_getItemClassName()}
                data-testid="detaildrawer-item"
            >
                <div className="_d-flex _jc-space-between _ai-center _mb-3">
                    <span className="heading-2">{title}</span>
                    <IconButton
                        className={_getIconButtonClassName()}
                        icon="chevronDown"
                        size="xs"
                        color="galaxy-800"
                        onClick={showDetails}
                        data-testid="chevronDown-icon"
                    />
                </div>
                <div
                    className="detaildrawer-item-body"
                    data-testid="detaildrawer-item-body"
                >
                    {children}
                </div>
            </div>
        </div>
    );
}

DetailDrawerItem.propTypes = {
    title: PT.string,
    children: PT.node
};

export default DetailDrawerItem;
