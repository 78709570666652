import React from 'react';
import classNames from 'classnames';

import { ManageStopControlMenu, StopModalMenuButton } from '~/ui';

import './manage-stop-control-multiple.scss';

function ManageStopControlMultiple({
    stopList,
    children,
    className,
    controlTitle,
    controlHelp,
    footerButtonTitle,
    footerButtonIcon,
    onClickFooterButton = () => {},
    ...extra
}) {
    function _getClassName() {
        const defaultClassName = `managestopcontrol_multiple _d-flex _fd-column`;
        return classNames(defaultClassName, className);
    }

    if (!stopList) return null;

    return (
        <div
            className={_getClassName()}
            data-testid={extra['data-testid'] || 'managestopcontrol-multiple'}
        >
            <div className="managestopcontrol_main">
                {controlTitle && (
                    <span
                        className="_text-2"
                        data-testid={
                            extra['data-testid']
                                ? `${extra['data-testid']}-title`
                                : 'managestopcontrol-multiple-title'
                        }
                    >
                        {controlTitle}
                    </span>
                )}
                {controlHelp && (
                    <div className="_d-flex _ai-center">
                        <span
                            className="_text-3-alt"
                            data-testid={
                                extra['data-testid']
                                    ? `${extra['data-testid']}-help`
                                    : 'managestopcontrol-multiple-help'
                            }
                        >
                            {controlHelp}
                        </span>
                    </div>
                )}
                <div
                    className="managestopcontrol_stop-list"
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-list`
                            : 'managestopcontrol-multiple-list'
                    }
                >
                    {stopList}
                </div>
            </div>
            {children}
            {footerButtonTitle && (
                <ManageStopControlMenu
                    className="managestopcontrol_footer"
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-footer`
                            : 'managestopcontrol-multiple-footer'
                    }
                >
                    <StopModalMenuButton
                        buttonIcon={footerButtonIcon}
                        buttonText={footerButtonTitle}
                        onClick={onClickFooterButton}
                        data-testid={
                            extra['data-testid']
                                ? `${extra['data-testid']}-footer_button`
                                : 'managestopcontrol-multiple-footer_button'
                        }
                    />
                </ManageStopControlMenu>
            )}
        </div>
    );
}

export { ManageStopControlMultiple };
