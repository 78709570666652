/* eslint-disable no-unused-vars */
import { useQuery } from 'react-query';

import DriverApi from '~/api/DriverApi';
import SinglePlanEditApi from '~/api/SinglePlanEditApi';

import constants from '~/utils/constants';
import dateUtils from '~/utils/date-utils';

const assignTasktoDriver = async (dispatchData) => {
    if (!dispatchData) return Promise.reject('Missing dispatchData');

    const { selectedDate, clientId, driverId, tasksToDispatch, isLiveDriver } =
        dispatchData;

    // live drivers need to use the dispatch tasks API
    if (isLiveDriver) {
        const { data: dispatchTasksResponse } = await DriverApi.dispatchTasks(
            driverId,
            tasksToDispatch
        );
        return { payload: dispatchData, data: dispatchTasksResponse.data };
    }

    // non-live drivers need to use the augment route API
    const { data: augmentRouteResponse } = await SinglePlanEditApi.augmentRoute(
        clientId,
        {
            date: dateUtils.convertToISODateOnly(selectedDate),
            taskIds: tasksToDispatch.map((task) => task.id),
            driverId
        }
    );

    return { payload: dispatchData, data: augmentRouteResponse.data };
};

export const useOnDemandDispatchAssignTasks = (
    dispatchData,
    reactQueryOptions = {}
) => {
    // get API response
    return useQuery(
        [constants.reactQueryKeys.TASKS_ASSIGN, dispatchData],
        () => assignTasktoDriver(dispatchData),
        reactQueryOptions
    );
};
