/* eslint-disable camelcase */ // for API payloads using snake_case
import _ from 'lodash';
import { ApiClient, ApiUser, UserRoles } from '~/api/types';
import userGroupsAPI from '~/api/user-groups';
import { UsersApi } from '~/api/UsersApi';
import constants from '~/utils/constants';

// @todo MCW-996 https://wisesys.atlassian.net/browse/MCW-996
// formalize user groups and permission types. These types currently contain
// the strict minimum definitions to make the type checks pass. More properties
// may exist.
type UserPermission = {
    client_id: string;
    permission: { role: string; feature: string }[];
};
type UserGroup = { name: string; client_access: Array<UserPermission> };
type NewFeatureRequest = { id: string; client_access: Array<UserPermission> };

/**
 * User Group Utilities
 *
 * @category Utils
 * @module utils/userGroupUtils
 *
 * @example
 * import { migrateAdminUserRoleToPermissionGroup } from '~/utils/user-groups-utils';
 *
 * //make a RBAC admin user into a feature based administrator user
 * await migrateAdminUserRoleToPermissionGroup(user, client);
 *
 */

/**
 * Generates an admin group with all editor permissions on all core features
 * @param {string} name
 * @param {ApiUser} user
 * @param {ApiClient[]} clients
 * @returns {UserGroup[]}
 */
function generateAdminGroup(
    name: string,
    user: ApiUser,
    clients: ApiClient[]
): UserGroup[] {
    const clientsById = clients.reduce<Record<string, ApiClient>>((res, c) => {
        res[c.id] = c;
        return res;
    }, {});

    const clientAccessByClientId = user.access.reduce<
        Record<string, UserPermission>
    >((result, a) => {
        const client = clientsById[a.client];
        if (a.roles.includes(UserRoles.ADMINISTRATOR) && client) {
            const clientAccess: UserPermission = {
                client_id: a.client,
                permission: [
                    {
                        role: constants.userPermissionRoles.EDITOR,
                        feature:
                            constants.userPermissionFeatures.USER_MANAGEMENT
                    },
                    {
                        role: constants.userPermissionRoles.EDITOR,
                        feature:
                            constants.userPermissionFeatures.DRIVER_MANAGEMENT
                    },
                    {
                        role: constants.userPermissionRoles.EDITOR,
                        feature: constants.userPermissionFeatures.PLANNING
                    },
                    {
                        role: constants.userPermissionRoles.EDITOR,
                        feature:
                            constants.userPermissionFeatures.TASK_MANAGEMENT
                    }
                ]
            };
            if (client.preferences.enableDriverBooking) {
                clientAccess.permission.push({
                    role: constants.userPermissionRoles.EDITOR,
                    feature: constants.userPermissionFeatures.DRIVER_BOOKING
                });
            }
            result[a.client] = clientAccess;
        }
        return result;
    }, {});

    return [
        {
            name,
            client_access: Object.values(clientAccessByClientId)
        }
    ];
}

/**
 * Creates the request payload for a new admin user group containing the given user
 * @param {Pick<NewFeatureRequest, 'id'>} group
 * @param {string} adminGroupName
 * @param {ApiUser} user
 * @param {ApiClient[]} clients
 * @returns {NewFeatureRequest}
 */
function createRequestForAddingNewFeature(
    group: Pick<NewFeatureRequest, 'id'>,
    adminGroupName: string,
    user: ApiUser,
    clients: ApiClient[]
): NewFeatureRequest[] {
    const { id } = group;
    const [requestPayload] = generateAdminGroup(adminGroupName, user, clients);

    return [
        {
            id,
            client_access: requestPayload.client_access
        }
    ];
}

/**
 * Checks if the user already has sufficient admin permissions
 * @param {UserGroup} existingAdminGroup
 * @param {string} adminGroupName
 * @param {ApiUser} user
 * @param {ApiClient[]} clients
 * @returns {boolean}
 */
function shouldUpdateExistingAdminUserGroup(
    existingAdminGroup: UserGroup,
    adminGroupName: string,
    user: ApiUser,
    clients: ApiClient[]
): boolean {
    if (!existingAdminGroup) return false;
    const [newAdminGroup] = generateAdminGroup(adminGroupName, user, clients);
    const featuresInExistingAdminGroup =
        existingAdminGroup.client_access.flatMap((client) =>
            client.permission.map((permission) => permission.feature)
        );
    const featuresInNewAdminGroup = newAdminGroup.client_access.flatMap(
        (client) => client.permission.map((permission) => permission.feature)
    );

    const containsNewFeature = _.isEqual(
        featuresInExistingAdminGroup,
        featuresInNewAdminGroup
    );
    return !containsNewFeature;
}

/**
 * Adds the user to the admin group or creates an admin group for that user if no admin group exists.
 * This is a no-op if the user is already part of a group with sufficient admin permissions.
 * This is a no-op if the user does not have the administrator role in the RBAC system.
 * @param {ApiUser} userDetails
 * @param {ApiClient[]} clients
 * @returns {Promise<void[]>}
 */
export function migrateAdminUserRoleToPermissionGroup(
    userDetails: ApiUser,
    clients: ApiClient[]
): Promise<void[]> {
    const adminGroupName = 'admin';
    const queries = userDetails.access.map(async (access) => {
        if (!access.roles.includes(UserRoles.ADMINISTRATOR)) {
            return;
        }
        const userGroupResponse = await userGroupsAPI.get({
            name: [adminGroupName]
        });
        const existingAdminGroup = userGroupResponse?.data.data.successes[0];

        const adminUserGroup = shouldUpdateExistingAdminUserGroup(
            existingAdminGroup,
            adminGroupName,
            userDetails,
            clients
        )
            ? (
                  await userGroupsAPI.patch(
                      createRequestForAddingNewFeature(
                          existingAdminGroup,
                          adminGroupName,
                          userDetails,
                          clients
                      )
                  )
              )?.data.data.successes[0]
            : existingAdminGroup ||
              (
                  await userGroupsAPI.post(
                      generateAdminGroup(adminGroupName, userDetails, clients)
                  )
              )?.data.data.successes[0];
        const associationResponse =
            await UsersApi.getUserToUserGroupAssociations({
                user_id: [userDetails.id],
                user_group_id: [adminUserGroup.id]
            });
        if (associationResponse?.data?.data?.successes?.length) {
            return;
        }
        await userGroupsAPI.addUserToUserGroup(
            userDetails.id,
            adminUserGroup.id
        );
    });
    return Promise.all(queries);
}
