import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';
import type { RootState } from '~/storeTypes';

import { RoutePlanSummaryMetricsState } from './types';

const defaultMetricsState = {
    numRoutes: 0,
    numTasks: 0,
    numUnplannedTasks: 0,
    numUsedDrivers: 0,
    numTotalDrivers: 0,
    numUnits: 0,
    duration: 0
};

export const routePlanSummaryMetricsSlice = createSlice({
    name: 'routePlanSummaryMetrics',
    initialState: { ...defaultMetricsState },
    reducers: {
        setRoutePlanSummaryMetrics: (state, action) => {
            return action.payload;
        },
        resetRoutePlanSummaryMetrics: () => {
            return { ...defaultMetricsState };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {
                numRoutes: 0,
                numTasks: 0,
                numUnplannedTasks: 0,
                numUsedDrivers: 0,
                numTotalDrivers: 0,
                numUnits: 0,
                duration: 0
            };
        });
    }
});

export const { setRoutePlanSummaryMetrics, resetRoutePlanSummaryMetrics } =
    routePlanSummaryMetricsSlice.actions;

export const selectRoutePlanSummaryMetrics = (
    state: RootState
): RoutePlanSummaryMetricsState => state.routePlanSummaryMetrics;

export default routePlanSummaryMetricsSlice.reducer;
