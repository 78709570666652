import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import { FlexLayout, Icon, Text } from '~/ui';
import { ToastVariants } from './ToastVariants';

const variantStyles = {
    [ToastVariants.INFO]: {
        icon: 'infoCircle',
        iconColor: 'sky'
    },
    [ToastVariants.WARNING]: {
        icon: 'warningFill',
        iconColor: 'venus'
    },
    [ToastVariants.ERROR]: {
        icon: 'declinedFill',
        iconColor: 'mars'
    },
    [ToastVariants.SUCCESS]: {
        icon: 'checked',
        iconColor: 'earth'
    }
};

const FADEIN_TIMEOUT = 10;
const CLOSING_DURATION = 500;

const Toast = React.forwardRef(function Toast(
    { duration = 3000, message, variant = 'info', sx, onRemove, ...extra },
    ref
) {
    const [transition, setTransition] = useState({
        opacity: 0
    });
    const selectedStyle = variantStyles[variant];

    useEffect(() => {
        // on load fade in
        const fadeInTimeout = setTimeout(
            () =>
                setTransition({
                    opacity: 1,
                    height: 'unset',
                    transition: 'all 0.8s ease'
                }),
            FADEIN_TIMEOUT
        );

        // on unload fade out
        const fadeOutTimeout = setTimeout(
            () =>
                setTransition({
                    opacity: 0,
                    height: 'unset',
                    transition: 'all ease',
                    transitionDuration: `${CLOSING_DURATION}ms`
                }),
            duration
        );

        // remove after fade out
        const onRemoveTimeout = setTimeout(
            () => onRemove?.(),
            duration + CLOSING_DURATION
        );

        return () => {
            [fadeInTimeout, fadeOutTimeout, onRemoveTimeout].forEach(
                clearTimeout
            );
        };
    }, []);

    return (
        <FlexLayout
            ref={ref}
            alignItems="center"
            sx={{
                height: 0,
                backgroundColor: 'neptune-800',
                borderRadius: '0.8rem',
                padding: '1rem 1.5rem',
                ...transition,
                ...sx
            }}
            {...extra}
        >
            <Icon
                color={selectedStyle.iconColor}
                icon={selectedStyle.icon}
                size="s"
                marginRight="0.8rem"
            />
            <Text color="comet" variant="14-normal">
                {message}
            </Text>
        </FlexLayout>
    );
});

Toast.variants = ToastVariants;

Toast.propTypes = {
    /** Duration of Toast */
    duration: PT.number,
    /** Toast message */
    message: PT.string,
    /** Toast style variant */
    variant: PT.oneOf(Object.values(ToastVariants)),
    /** Toast remove function - called when the toast wants to be removed */
    onRemove: PT.func
};

export default Toast;
