import {
    useTable,
    useSortBy,
    usePagination,
    useGlobalFilter,
    useAsyncDebounce,
    useRowSelect
} from 'react-table';
import { useTranslation } from 'react-i18next';
import React, { useRef } from 'react';
import PT from 'prop-types';

import { Button, Icon } from '~/ui';
import TableToolbar from '~/ui/components/Table/TableToolbar';

import './Table.scss';

function Table({
    columns,
    tableData,
    noDataComponent,
    globalFilterFn,
    stickyTopOffset = 0,
    searchBarPlaceholder,
    renderTableRow,
    getRowProps = () => ({}),
    editEnabled,
    footer,
    className,
    disableToolbar = false,
    disableStickyHeader = false,
    toolbarCustomComponent,
    toolbarFilter
}) {
    const { t } = useTranslation(['driverBooking', 'common']);
    const theadRef = useRef(null);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canNextPage,
        setPageSize,
        state: { pageSize, globalFilter: searchText },
        setGlobalFilter: setSearchText
    } = useTable(
        {
            columns,
            data: tableData,
            globalFilter: globalFilterFn,
            footer
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect
    );

    const setSearchTextDebounced = useAsyncDebounce((value) => {
        setSearchText(value);
    }, 200);

    function tableHeaderStyle(customOffset) {
        // headers borders disappear when scrolling, need 0.1rem offset to cover the space
        const toolbarOffset = disableToolbar
            ? '-0.1rem'
            : 'var(--dimension-table-toolbar-height) - 0.1rem';

        const offset = `${customOffset ?? 0}rem`;

        return {
            top: `calc(${offset} + ${toolbarOffset})`
        };
    }

    const TableRow = renderTableRow;

    const renderSortArrow = (column) => {
        if (column.canSort) {
            const styles = {
                color: column.isSorted ? 'neptune-800' : 'galaxy-400'
            };
            if (!column.isSortedDesc) {
                styles.transform = 'rotate(180deg)';
            }

            return <Icon icon="arrowDown" sx={styles} />;
        }
        return null;
    };

    return (
        /* eslint-disable react/jsx-props-no-spreading */
        <div className={`_fd-column ${className}`} data-testid="table">
            {!disableToolbar && (
                <TableToolbar
                    searchText={searchText}
                    setSearchText={setSearchText}
                    setSearchTextDebounced={setSearchTextDebounced}
                    searchBarPlaceholder={searchBarPlaceholder}
                    stickyTopOffset={stickyTopOffset}
                    disableStickyHeader={disableStickyHeader}
                    customComponent={toolbarCustomComponent}
                    filter={toolbarFilter}
                />
            )}
            <table {...getTableProps()} className="table">
                <thead
                    className={`_header-4-alt table-header ${
                        disableStickyHeader ? '' : 'table-header_sticky'
                    }`}
                    style={tableHeaderStyle(stickyTopOffset)}
                    ref={theadRef}
                >
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    className="table-header-cell"
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps({
                                            style: column.sx
                                        })
                                    )}
                                >
                                    <div
                                        className={`_d-flex _ai-center table-column-header ${
                                            column.isSorted
                                                ? 'table-column-header_sorted'
                                                : ''
                                        }`}
                                    >
                                        {column.render('Header')}
                                        {renderSortArrow(column)}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <TableRow
                                key={row.id}
                                rowProps={row.getRowProps(getRowProps(row))}
                                displayedData={row.values}
                                searchText={searchText}
                                editEnabled={editEnabled}
                            />
                        );
                    })}
                </tbody>
            </table>

            {noDataComponent && page.length <= 0 && noDataComponent()}

            <div
                className="_d-flex _jc-center _ai-center _mt-5"
                style={footer.sx}
            >
                <div className="_fd-column _ai-center table-footer">
                    <Button
                        variant="label"
                        type="compact"
                        disabled={!canNextPage}
                        onClick={() => setPageSize(pageSize + 10)}
                    >
                        {t('seeMoreLabel')}
                    </Button>
                    <span>
                        {t('showingXOfYLabel', {
                            x: page.length,
                            y: tableData.length
                        })}
                    </span>
                </div>
            </div>
        </div>
    );
}

Table.propTypes = {
    /** table columns */
    columns: PT.arrayOf(PT.object).isRequired,
    /** table data */
    tableData: PT.arrayOf(PT.object).isRequired,
    /** component rendered when no data in the table */
    noDataComponent: PT.func,
    /** filter function for search */
    globalFilterFn: PT.func,
    /** top offset (in rem) for table header when scrolling */
    stickyTopOffset: PT.number,
    /** search bar placeholder text */
    searchBarPlaceholder: PT.string,
    /** table row component */
    renderTableRow: PT.func,
    /** passes props for table row */
    getRowProps: PT.func,
    /** enable mutable row data */
    editEnabled: PT.bool,
    /** footer details * */
    footer: PT.objectOf(PT.shape({ sx: PT.shape({ display: PT.string }) })),
    /** table className */
    className: PT.string,
    /** disable sticky header */
    disableStickyHeader: PT.bool,
    /** hides toolbar with search and filters */
    disableToolbar: PT.bool,
    /** custom component displayed in toolbar */
    toolbarCustomComponent: PT.node,
    /** custom table filter component */
    toolbarFilter: PT.node
};

Table.defaultProps = {
    footer: {}
};

export default Table;
