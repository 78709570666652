import { useDispatch } from 'react-redux';

import {
    addSelectedMapStop,
    removeSelectedMapStop
} from '~/reducers/selectedMapStopsSlice';
import { resetSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';

import { idUtils } from '~/utils/id-utils';
import constants from '~/utils/constants';

type MarkerEventPayload = {
    /**
     * the marker stop ID
     */
    id: string;

    /**
     * whether the marker is selected
     */
    isSelected: boolean;

    /**
     * whether the marker stop is part of a two-part task
     */
    isTwoPart: boolean;
};

type ParamsEmittedEventHandler = {
    /**
     * the event triggered
     */
    event: string;

    /**
     * the event payload data
     */
    payload: MarkerEventPayload;
};

type HookOnDemandDispatchMarkerEventHandler = (
    eventPayload: ParamsEmittedEventHandler
) => void;

/**
 * A custom hook that provides an emitted event handler suitable for use with
 * a stop marker emitting events described in constants.mapChildEvents. This
 * emitted event handler is meant to be used for on demand dispatch.
 *
 * @category Hooks
 * @module useOnDemandDispatchMarkerEventHandler
 * @returns {Function} the emitted event handler
 * @example <caption>Usage</caption>
 * // import statement
 * import { useOnDemandDispatchMarkerEventHandler } from '~/hooks';
 *
 * // expose methods
 * const emittedEventHandler = useOnDemandDispatchMarkerEventHandler();
 */
export const useOnDemandDispatchMarkerEventHandler =
    (): HookOnDemandDispatchMarkerEventHandler => {
        const dispatch = useDispatch();

        /**
         * Toggles the selected state of live stop markers
         *
         * @method toggleStopsSelected
         * @private
         * @param {MarkerEventPayload} payload - the event payload data
         */
        const toggleStopsSelected = (payload: MarkerEventPayload) => {
            const { id: stopId, isSelected, isTwoPart } = payload;

            const toggleForIds = [stopId];

            if (isTwoPart) {
                toggleForIds.push(idUtils.getComplementaryStopId(stopId));
            }

            dispatch(resetSelectedTaskRowId());

            for (const id of toggleForIds) {
                if (isSelected) {
                    dispatch(removeSelectedMapStop(id));
                } else {
                    dispatch(addSelectedMapStop(id));
                }
            }
        };

        /**
         * Handles marker emitted events
         *
         * @method emittedEventHandler
         * @param {object} eventPayload - the event payload
         * @param {string} eventPayload.event - the event triggered
         * @param {object} eventPayload.payload - the event payload data
         */
        const emittedEventHandler = (
            eventPayload: ParamsEmittedEventHandler
        ) => {
            const { mapChildEvents } = constants;
            const { event, payload } = eventPayload;

            switch (event) {
                case mapChildEvents.STOP_MOUSEUP:
                    toggleStopsSelected(payload as MarkerEventPayload);
                    break;
                default:
                    break;
            }
        };

        return emittedEventHandler;
    };
