import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { resetToInitialState } from '~/reducers/common-actions';

export const selectedDriverVehicleRowId = createSlice({
    name: 'selectedDriverVehicleRowId',
    initialState: '',
    reducers: {
        setSelectedDriverVehicleRowId: (state, action) => {
            if (_.isString(action.payload)) return action.payload;
            return state;
        },
        resetSelectedDriverVehicleRowId: () => {
            return '';
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return '';
        });
    }
});
export const {
    setSelectedDriverVehicleRowId,
    resetSelectedDriverVehicleRowId
} = selectedDriverVehicleRowId.actions;
export const selectDriverVehicleRowId = (state) =>
    state.selectedDriverVehicleRowId;
export default selectedDriverVehicleRowId.reducer;
