import axios from 'axios';
import constants from '~/utils/constants';
import { AxiosApiResponse } from '~/api/types';
/**
 * Implementations of API methods under the `/template` path
 *
 * @category API
 */
export default class ClientTemplateApi {
    /**
     * Path of the endpoint
     */
    private static readonly templatePath = '/template';

    static get(clientId: string): Promise<AxiosApiResponse> {
        if (!clientId) {
            return Promise.reject('Missing client Id');
        }

        return axios.get(`${this.templatePath}?client=${clientId}`, {
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    }
}
