import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import clientsAPI from '~/api/ClientsApi';
import { parseSearch, sessionManager } from '~/api/SessionManager';
import { initAxios } from '~/utils/app-utils';
import { UsersApi } from '~/api/UsersApi';
import authService from '~/auth/aws-cognito-auth-service';
import {
    resetActiveClients,
    selectActiveClientsArray
} from '~/reducers/activeClientsSlice';
import { setAppInitialized } from '~/reducers/appGlobalsSlice';
import { setClients } from '~/reducers/clientsSlice';
import { resetToInitialState } from '~/reducers/common-actions';
import { setMainClient } from '~/reducers/mainClientSlice';
import { thirdPartyEventMonitor } from '~/services/thirdPartyEventMonitor';
import constants from '~/utils/constants';
import utils from '~/utils/general-utils';
import socketInstance from '~/utils/socket/socket-instance';
import { migrateAdminUserRoleToPermissionGroup } from '~/utils/user-groups-utils';
import { usePageNavigation } from './page-navigation';
import { selectCurrentPage } from '~/reducers/currentPageSlice';
import { useUserRolesAndPermissions } from './useUserRolesAndPermissions';

export const useLogin = (setUserId, setUsername) => {
    const dispatch = useDispatch();
    const { goToPage } = usePageNavigation();
    const currentPage = useSelector(selectCurrentPage);
    const activeClients = useSelector(selectActiveClientsArray);
    const refreshTimerId = useRef(0);
    const { poolData } = authService;
    const { initUser } = useUserRolesAndPermissions();

    /**
     * Resets app state and redirects to the login page
     */
    async function logout() {
        clearInterval(refreshTimerId.current);
        await authService.logout();
        goToPage(constants.url.LOGOUT);
        dispatch(resetToInitialState());
        goToPage(constants.url.LOGIN);
    }

    /**
     * Clears the app state if the mcwAppInit data was removed from local storage
     * @param {StorageEvent} e
     */
    async function handleGlobalLogout(e) {
        const hasLoggedOut =
            e.key === constants.localStorageKeys.MCW_APP_INIT &&
            e.oldValue &&
            !e.newValue;

        if (hasLoggedOut) {
            await logout();
        }
    }

    /**
     * Connects the client to the websocket server and joins the accessible client rooms
     * @param {object} mcwAppInit
     */
    function initSocket(mcwAppInit) {
        if (!mcwAppInit) {
            throw new Error('missing app init');
        }
        socketInstance.connectToSocketServer(
            mcwAppInit.data.socketUrl,
            mcwAppInit.requestHeaders.authorization,
            logout
        );
        if (activeClients?.length) {
            dispatch(resetActiveClients());
            socketInstance.joinRooms(activeClients);
        }
    }

    /**
     * Sets the accessible clients in the store
     * @param {object} response
     * @param {string} mainClientId
     */
    function storeAllClients(response, mainClientId) {
        const clients = response.data.data;
        const clientsObjById = clients.reduce((clientsObj, client) => {
            clientsObj[client.id] = client;
            return clientsObj;
        }, {});
        dispatch(setClients(clientsObjById));
        const mainClient = clientsObjById[mainClientId];
        dispatch(setMainClient(mainClient));
    }

    /**
     * Fetches the user object for the logged in user and saves the user data to local storage
     * @param {object} mcwAppInit
     */
    async function updateUserDetails(mcwAppInit) {
        const userResponse = await UsersApi.getCurrentUser();
        mcwAppInit.data.userDetails = userResponse.data.data;
        localStorage.setItem(
            constants.localStorageKeys.MCW_APP_INIT,
            JSON.stringify(mcwAppInit)
        );
    }

    /**
     * Sets an terval to check if the access token is about to expire every 7 minutes.
     * Refreshes the tokens when needed.
     */
    function startSessionRefreshTimer() {
        clearInterval(refreshTimerId.current);
        refreshTimerId.current = setInterval(async () => {
            try {
                await authService.isSessionExpiring(poolData, initAxios);
            } catch (e) {
                console.error(e);
                await logout();
            }
        }, constants.timings.SESSION_REFRESH_INTERVAL);
    }

    /**
     * Attempts to obtain authorization tokens from Cognito or from Solar, depending on the
     * location's search paramters. Throws if none of the expected parameters are provided.
     * @returns {object} mcwAppInit
     */
    async function login() {
        const parsedSearch = parseSearch();
        if (parsedSearch.code) {
            return authService.authenticateCustomLogin(parsedSearch.code);
        }
        if (parsedSearch.origin && parsedSearch.path) {
            const tokens = await sessionManager.getTokensFromOrigin();
            if (tokens) {
                await authService.authenticateUserFromOrigin(tokens);
            }
            return JSON.parse(
                localStorage.getItem(constants.localStorageKeys.MCW_APP_INIT)
            );
        }
        throw new Error(
            'Attempted login without tokens or cross-domain origin information'
        );
    }

    /**
     * Gets tokens and initializes the app state on successfuly login
     */
    useEffect(async () => {
        try {
            const mcwAppInit =
                JSON.parse(
                    localStorage.getItem(
                        constants.localStorageKeys.MCW_APP_INIT
                    )
                ) ?? (await login());
            startSessionRefreshTimer();
            initAxios(mcwAppInit);
            initSocket(mcwAppInit);
            dispatch(setAppInitialized(true));
            await updateUserDetails(mcwAppInit);
            const { userDetails } = mcwAppInit.data;
            initUser(userDetails);
            setUserId(userDetails.id);
            setUsername(
                utils.getUserName(
                    userDetails.firstname,
                    userDetails.lastname,
                    userDetails.username
                )
            );
            const clientsResponse = await clientsAPI.get();
            await migrateAdminUserRoleToPermissionGroup(
                userDetails,
                clientsResponse.data.data
            );
            const mainClientId =
                mcwAppInit.requestHeaders[
                    constants.requestHeaders.WISE_CLIENT_ID
                ];
            storeAllClients(clientsResponse, mainClientId);
            thirdPartyEventMonitor.initialize(
                clientsResponse.data.data,
                mainClientId,
                mcwAppInit.data.userDetails.id
            );
            if (currentPage === constants.url.LOGIN) {
                goToPage(constants.url.HOME);
            }
            window.addEventListener('storage', handleGlobalLogout);
        } catch (e) {
            console.error(e);
            await logout();
        }
        return () => {
            window.removeEventListener('storage', handleGlobalLogout);
        };
    }, []);

    return {
        logout
    };
};
