import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ZoomControl from './ZoomControl';
import { MapSettingsControl } from './MapSettingsControl';
import { PlanStopsControl } from '~/components/MapPage/PlanStopsControl';
import LiveStopsControl from '~/components/MapPage/LiveStopsControl/LiveStopsControl';
import UnassignedTasksControl from '~/components/MapPage/UnassignedTasksControl';
import OnDemandDispatchMapControl from '~/ui/components/PlanMapOverlay/OnDemandDispatchMapControl/OnDemandDispatchMapControl';
import TaskDetailDrawer from '~/components/TaskManagementPage/TaskDetailDrawer/TaskDetailDrawer';
import {
    resetSelectedTaskRowId,
    selectSelectedTaskRowId
} from '~/reducers/selectedTaskRowIdSlice';

import './plan-map-overlay.scss';

import { useMapUtils } from '~/hooks';

function PlanMapOverlay({ editPermissions, map }) {
    const [showControls, setShowControls] = useState(false);
    const [showPlanStopsControl, setShowPlanStopsControl] = useState(false);
    const [showOnDemandDispatchControl, setShowOnDemandDispatchControl] =
        useState(false);
    const selectedTaskRowId = useSelector(selectSelectedTaskRowId);
    const dispatch = useDispatch();

    const { isDispatchedRouteMode, isPlanRouteMode } = useMapUtils();

    useEffect(() => {
        return map !== null ? setShowControls(true) : setShowControls(false);
    }, [map]);

    useEffect(() => {
        setShowPlanStopsControl(editPermissions && isPlanRouteMode);
        setShowOnDemandDispatchControl(
            editPermissions && isDispatchedRouteMode
        );
    }, [editPermissions, isPlanRouteMode, isDispatchedRouteMode]);

    function handleTaskDetailDrawerClose() {
        dispatch(resetSelectedTaskRowId());
    }

    return (
        <>
            <div className="planmapoverlay planmapoverlay_mapcontrols _d-flex _ai-center _fd-column _p-absolute">
                <OnDemandDispatchMapControl />
                <ZoomControl map={map} showControls={showControls} />
                <MapSettingsControl />
            </div>

            <div className="planmapoverlay planmapoverlay_stopcontrols _d-flex _p-absolute">
                {showPlanStopsControl && <PlanStopsControl />}
                {showOnDemandDispatchControl && (
                    <>
                        <LiveStopsControl />
                        <UnassignedTasksControl />
                    </>
                )}
            </div>

            {selectedTaskRowId && (
                <TaskDetailDrawer
                    isViewOnly
                    shouldRenderMapPageStyle
                    id={selectedTaskRowId}
                    handleTaskDetailDrawerClose={handleTaskDetailDrawerClose}
                />
            )}
        </>
    );
}

export default PlanMapOverlay;
