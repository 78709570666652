import React, { Suspense } from 'react';
import {
    Route,
    BrowserRouter as Router,
    Switch,
    Redirect,
    useHistory
} from 'react-router-dom';
import { Loading } from '~/ui';
import generalUtils from '~/utils/general-utils';
import constants from '~/utils/constants';

const DriverBooking = React.lazy(() => import('~/components/DriverBooking'));
const DriverManagement = React.lazy(() =>
    import('~/components/DriverManagementPage')
);
const DriverVehicleManagement = React.lazy(() =>
    import('~/components/DriverVehicleManagementPage')
);
const HomePage = React.lazy(() => import('~/components/HomePage'));
const LoginPage = React.lazy(() => import('~/components/LoginPage'));
const MainLayout = React.lazy(() => import('~/components/MainLayout'));
const MapPage = React.lazy(() => import('~/components/MapPage'));
const NotificationManagementPage = React.lazy(() =>
    import('~/components/NotificationManagementPage')
);
const CustomerManagementPage = React.lazy(() =>
    import('~/components/CustomerManagementPage')
);
const CustomerSearchPage = React.lazy(() =>
    import('~/components/CustomerSearchPage')
);
const OptimizationConfigurationPage = React.lazy(() =>
    import('~/components/OptimizationConfigurationPage')
);
const ReportsPage = React.lazy(() => import('~/components/ReportsPage'));
const SettingsPage = React.lazy(() => import('~/components/SettingsPage'));
const TaskManagementPage = React.lazy(() =>
    import('~/components/TaskManagementPage')
);
const ZoneManagementPage = React.lazy(() =>
    import('~/components/ZoneManagementPage')
);
const Unauthorized = React.lazy(() =>
    import('~/components/ErrorPage/Unauthorized')
);
const UsersAndPermissionsPage = React.lazy(() =>
    import('~/components/UsersAndPermissionsPage')
);
const CostConfigurationPage = React.lazy(() =>
    import('~/components/CostConfigurationPage')
);
const TerritoryPlannerPage = React.lazy(() =>
    import('~/components/TerritoryPlannerPage')
);

// set `REACT_APP_ENABLE_DEBUGROUTER=true` in your `.env.local` file
// to debug routes
function DebugRouter({ children, enable }) {
    const { location } = useHistory();

    if (enable) {
        console.group('DebugRouter');
        console.info('Route', `${location.pathname}${location.search}`);
        console.info('State', location.state);
        console.groupEnd();
    }

    return children;
}

function App() {
    const { url } = constants;
    return (
        <Router basename={url.ROOT}>
            <Suspense fallback={<Loading fillViewport />}>
                <DebugRouter
                    enable={generalUtils.isFlaggedFeatureDisplayed(
                        'REACT_APP_ENABLE_DEBUGROUTER'
                    )}
                >
                    <Switch>
                        <Route exact path="/">
                            <Redirect
                                to={{
                                    pathname: url.HOME,
                                    search: window.location.search || ''
                                }}
                            />
                        </Route>
                        <Route path={url.LOGIN}>
                            <LoginPage />
                        </Route>
                        <Route path={url.LOGOUT}>
                            <div />
                        </Route>
                        <Route path={url.UNAUTHORIZED}>
                            <Unauthorized />
                        </Route>
                        <Route>
                            <MainLayout>
                                <Switch>
                                    <Route path={url.HOME}>
                                        <HomePage />
                                    </Route>
                                    <Route path={url.DRIVER_BOOKING}>
                                        <DriverBooking />
                                    </Route>
                                    <Route path={url.DRIVER_MANAGEMENT}>
                                        <DriverManagement />
                                    </Route>
                                    <Route
                                        path={
                                            url.DRIVER_MANAGEMENT_DATE_AGNOSTIC
                                        }
                                    >
                                        <DriverVehicleManagement />
                                    </Route>
                                    <Route path={url.WORKFLOW}>
                                        <Redirect to={url.MAP} />
                                    </Route>
                                    <Route path={url.MAP}>
                                        <MapPage />
                                    </Route>
                                    <Route path={url.COST_CONFIGURATION}>
                                        <CostConfigurationPage />
                                    </Route>
                                    <Route
                                        exact
                                        path={url.USERS_AND_PERMISSIONS}
                                    >
                                        <UsersAndPermissionsPage />
                                    </Route>
                                    <Route path={url.TASK_MANAGEMENT}>
                                        <TaskManagementPage />
                                    </Route>
                                    <Route
                                        exact
                                        path={url.NOTIFICATION_MANAGEMENT}
                                    >
                                        <NotificationManagementPage />
                                    </Route>
                                    <Route path={url.REPORTS}>
                                        <ReportsPage />
                                    </Route>
                                    <Route path={url.SETTINGS}>
                                        <SettingsPage />
                                    </Route>
                                    <Route path={url.CUSTOMER_DETAILS}>
                                        <CustomerManagementPage />
                                    </Route>
                                    <Route path={url.CUSTOMER_MANAGEMENT}>
                                        <CustomerSearchPage />
                                    </Route>
                                    <Route
                                        exact
                                        path={url.OPTIMIZATION_CONFIGURATION}
                                    >
                                        <OptimizationConfigurationPage />
                                    </Route>
                                    <Route path={url.TERRITORY_PLANNER}>
                                        <TerritoryPlannerPage />
                                    </Route>
                                    <Route path={url.ZONE_MANAGEMENT}>
                                        <ZoneManagementPage />
                                    </Route>
                                </Switch>
                            </MainLayout>
                        </Route>
                        <Route path={[url.UNAUTHORIZED, '*']}>
                            <Unauthorized />
                        </Route>
                    </Switch>
                </DebugRouter>
            </Suspense>
        </Router>
    );
}

export default App;
