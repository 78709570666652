import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
    selectShowStopLabel,
    selectShowStopNumber
} from '~/reducers/mapSettingsSlice';

import { Icon } from '~/ui/';
import constants from '~/utils/constants';
import { selectSelectedMapStops } from '~/reducers/selectedMapStopsSlice';

import './live-stop-marker.scss';

function LiveStopMarker({
    sequenceNumber,
    stopData,
    status = constants.liveStopMarkerStatus.default,
    mapRouteMode,
    colorCSS = {},
    onDemandDispatchMarkerEventHandler = () => {},
    emittedEventHandler = () => {},
    lat,
    lng
}) {
    const {
        isAtRisk,
        isCompleted,
        isLate,
        isCanceled,
        isInProgress,
        hasInventoryException
    } = stopData;
    const selectedMapStops = useSelector(selectSelectedMapStops);
    const showStopLabel = useSelector(selectShowStopLabel(mapRouteMode));
    const showStopNumber = useSelector(selectShowStopNumber(mapRouteMode));
    const { t } = useTranslation('translation');

    const isInteractive = !(isCanceled || isInProgress);
    const isDisabled = !isInteractive;
    const { backgroundColor, color } = colorCSS;
    const isStopCompletedStyle = {
        borderColor: backgroundColor
    };

    const isSelected = useMemo(() => {
        const isStopSelected = selectedMapStops.includes(stopData.id);
        return isStopSelected;
    }, [selectedMapStops]);

    if (!stopData) {
        return null;
    }

    function _handleMouseEnter() {
        emittedEventHandler({
            event: constants.mapChildEvents.STOP_MOUSEENTER,
            payload: {
                location: {
                    lat,
                    lng
                },
                stopMarkerData: stopData
            }
        });
    }

    function getExceptionIconClass() {
        const boxClassName = 'livestopmarker-icon';

        const conditionalClasses = {
            'icon-exception':
                (isAtRisk || isLate || hasInventoryException) &&
                status === constants.liveStopMarkerStatus.priority,
            'icon-priority':
                !hasInventoryException &&
                status === constants.liveStopMarkerStatus.priority,
            'icon-default':
                !(status === constants.liveStopMarkerStatus.priority) &&
                !(status === constants.liveStopMarkerStatus.canceled)
        };
        return classNames(boxClassName, conditionalClasses);
    }

    function getExceptionIcon() {
        if (hasInventoryException) {
            return (
                <Icon
                    className={getExceptionIconClass()}
                    icon="warningFill3"
                    color="saturn"
                    size="s"
                    stroke="comet"
                />
            );
        }

        if (isAtRisk || isLate) {
            return (
                <Icon
                    className={getExceptionIconClass()}
                    icon="clockFill3"
                    color={isAtRisk ? 'venus' : 'mars'}
                    size="s"
                    stroke="comet"
                />
            );
        }
    }

    function _getDefaultMarkerStyle() {
        if (isCompleted) {
            return isStopCompletedStyle;
        }
        return colorCSS;
    }

    function getExceptionDelayClass() {
        if (isCompleted) {
            return 'livestopmarker_completed';
        }

        return '';
    }

    function getHighPriorityStopBackgroundColor() {
        if (isCompleted) {
            return 'comet';
        }
        return backgroundColor;
    }

    function getMapMarkerLabel() {
        const { isDepot, name } = stopData;
        const label = isDepot ? t('depot') : name;
        return (
            showStopLabel &&
            label && <span className="livestopmarker-label">{label}</span>
        );
    }

    function _handleOnClick() {
        const isClickable = isInteractive && !isCompleted;
        if (isClickable) {
            onDemandDispatchMarkerEventHandler({
                event: constants.mapChildEvents.STOP_MOUSEUP,
                payload: {
                    isSelected,
                    id: stopData.id,
                    selectedMapStops,
                    taskId: stopData.id,
                    stopLevelData: stopData.toJSON()
                }
            });
        }
    }

    function _getClassName() {
        const defaultClassName = `livestopmarker`;
        const conditionalClasses = {
            livestopmarker_selected: isSelected
        };
        return classNames(defaultClassName, conditionalClasses);
    }

    function getComponent() {
        switch (status) {
            case constants.liveStopMarkerStatus.canceled:
                return (
                    <button type="button" className="livestopmarker">
                        <Icon
                            icon="stopCanceled"
                            className="livestopmarker-canceled"
                            onMouseEnter={_handleMouseEnter}
                            size="l"
                        />
                        {getMapMarkerLabel()}
                    </button>
                );
            case constants.liveStopMarkerStatus.priority:
                return (
                    <button
                        type="button"
                        className="livestopmarker livestopmarker-priority"
                        onMouseEnter={_handleMouseEnter}
                        disabled={isDisabled}
                        onClick={_handleOnClick}
                    >
                        <div className="drivermarker_icon-group _p-relative">
                            <span className="_p-relative">
                                <Icon
                                    color={getHighPriorityStopBackgroundColor()}
                                    icon="stopPriority"
                                    size="xl"
                                    stroke={isCompleted ? backgroundColor : ''}
                                />
                                {showStopNumber && (
                                    <span
                                        className="livestopmarker-priority_text"
                                        style={
                                            isCompleted
                                                ? {
                                                      color: 'var(--color-galaxy-500)'
                                                  }
                                                : { color }
                                        }
                                    >
                                        {sequenceNumber}
                                    </span>
                                )}
                            </span>
                            {getExceptionIcon()}
                        </div>
                        {getMapMarkerLabel()}
                    </button>
                );
            default:
                return (
                    <button
                        type="button"
                        className={_getClassName()}
                        onMouseEnter={_handleMouseEnter}
                        disabled={isDisabled}
                        onClick={_handleOnClick}
                    >
                        <div className="livestopmarker_icon-group _p-relative">
                            <span
                                className={`livestopmarker-circle ${getExceptionDelayClass()}`}
                                style={_getDefaultMarkerStyle()}
                            >
                                {showStopNumber && sequenceNumber}
                            </span>
                            {getExceptionIcon()}
                        </div>
                        {getMapMarkerLabel()}
                    </button>
                );
        }
    }

    return getComponent();
}

export default LiveStopMarker;
