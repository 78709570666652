import React, { useEffect, useState, useMemo } from 'react';
import PT from 'prop-types';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectVehicles } from '~/reducers/vehiclesSlice';
import { selectDriverVehiclePairs } from '~/reducers/driversByClientSlice';

import { TooltipButton, useModal, LabelInput, Tooltip, Icon } from '~/ui';
import FuzzyTextSearch from '~/ui/components/FuzzyTextSearch/FuzzyTextSearch';
import AssignedVehicleItem from '~/components/ZoneManagementPage/ZoneList/AssignedVehicleItem';

import './ZoneEditModal.scss';

const ZoneEditModal = ({ onCancel, onSave, open, zone }) => {
    const { t } = useTranslation('zoneManagement', 'common');

    const { name: originalName, assignedVehicles: originalAssignedVehicles } =
        zone;

    const vehicleDriverPairs = useSelector(selectDriverVehiclePairs);
    const vehiclesData = useSelector(selectVehicles);

    const [zoneName, setZoneName] = useState(originalName);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [assignedVehicles, setAssignedVehicles] = useState([
        ...originalAssignedVehicles
    ]);
    const [filterString, setFilterString] = useState('');

    const handleUnassignVehicle = (vId) => {
        const newAssignedVehicles = assignedVehicles.filter((v) => v !== vId);
        setAssignedVehicles(newAssignedVehicles);
    };

    const handleAssignVehicle = (vId) => {
        const newAssignedVehicles = _.uniq([...assignedVehicles, vId]);
        setAssignedVehicles(newAssignedVehicles);
    };

    const handleVehicleSearchChange = (value) => {
        setFilterString(value.toLowerCase());
    };

    const handleSave = () => {
        onSave({
            name: zoneName,
            assignedVehicles
        });
    };

    const getAssignedVehiclesList = () => {
        return assignedVehicles.map((vId) => {
            return (
                <AssignedVehicleItem
                    key={`assignedvehicle-${vId}`}
                    id={vId}
                    onDelete={() => {
                        handleUnassignVehicle(vId);
                    }}
                    icon="zoneFilled"
                />
            );
        });
    };

    const searchResults = useMemo(() => {
        return Object.values(vehiclesData)
            .filter((item) => !assignedVehicles.includes(item.id))
            .filter((item) => {
                if (filterString) {
                    const { makemodel = '' } = item;
                    const assignedDriver = vehicleDriverPairs[item.id];
                    if (assignedDriver) {
                        const {
                            firstname = '',
                            lastname = '',
                            profile: { name: profileName = '' }
                        } = assignedDriver;
                        return (
                            firstname.toLowerCase().includes(filterString) ||
                            lastname.toLowerCase().includes(filterString) ||
                            profileName.toLowerCase().includes(filterString) ||
                            makemodel.toLowerCase().includes(filterString)
                        );
                    }
                    return makemodel.toLowerCase().includes(filterString);
                }
                return true;
            });
    }, [vehiclesData, filterString, assignedVehicles]);

    const {
        showModal,
        hideModal,
        modal: Modal
    } = useModal({
        onHide: () => {
            onCancel();
        },
        onShow: () => {}
    });

    useEffect(() => {
        if (open) {
            showModal();
        } else {
            hideModal();
        }
    }, [open]);

    useEffect(() => {
        const validateSave = () => {
            return (
                !zoneName ||
                (zoneName === originalName &&
                    _.isEqual(assignedVehicles, originalAssignedVehicles))
            );
        };
        setIsSaveDisabled(validateSave());
    }, [zoneName, originalName, assignedVehicles, originalAssignedVehicles]);

    return (
        <Modal
            variant="small"
            title={t('zoneEditModal.zoneInformation')}
            hasAutoHide={false}
            hasCloseButton
            className="zoneeditmodal"
            overlayClassName="zoneeditmodal-overlay"
        >
            <div
                data-testid="zoneeditmodal"
                className="zoneeditmodal-container _p-6"
            >
                <div className="zoneeditmodal-nameblock _mb-6">
                    <LabelInput
                        label={t('zoneEditModal.zoneName')}
                        value={zoneName}
                        id="zonenameeditinput"
                        onChange={(e) => setZoneName(e.target.value)}
                        data-testid="nameinput"
                        sx={{
                            height: '4rem'
                        }}
                    />
                </div>
                <div className="zoneeditmodal-assignvehiclesblock">
                    <div className="assignvehiclelabel _d-flex">
                        <span className="_mb-2 _d-inline-block">
                            {t('zoneEditModal.assignVehicles')}
                        </span>
                        <Tooltip
                            content={t('zoneEditModal.assignVehicleTooltip')}
                            placement="top"
                        >
                            <Icon
                                icon="question"
                                data-testid="assignvehicle-tooltip"
                            />
                        </Tooltip>
                    </div>
                    <FuzzyTextSearch
                        className="zoneeditmodal-searchinput"
                        placeholder={t(
                            'zoneEditModal.vehicleSearchPlaceholder'
                        )}
                        handleOnChange={handleVehicleSearchChange}
                    >
                        <div
                            className="vehiclesearchresults-container"
                            data-testid="searchresults-container"
                        >
                            {searchResults.map((result) => {
                                const { id: vehicleId } = result;
                                return (
                                    <AssignedVehicleItem
                                        key={`vehicletoAssign-${vehicleId}`}
                                        id={vehicleId}
                                        onClick={() => {
                                            handleAssignVehicle(vehicleId);
                                        }}
                                        className="zoneeditmodal-searchresultitem"
                                    />
                                );
                            })}
                        </div>
                    </FuzzyTextSearch>
                    <div
                        data-testid="assignedvehiclelist"
                        className="_mt-4 _d-flex _fd-column assignedvehiclelist"
                    >
                        {getAssignedVehiclesList()}
                    </div>
                </div>
            </div>
            <footer className="zoneedit-actions _d-flex _ai-center _jc-flex-end">
                <TooltipButton
                    variant="secondary"
                    size="l"
                    onClick={onCancel}
                    className="action-button"
                >
                    {t('common:cancel')}
                </TooltipButton>
                <TooltipButton
                    variant="primary"
                    size="l"
                    onClick={handleSave}
                    disabled={isSaveDisabled}
                    className="action-button"
                >
                    {t('common:save')}
                </TooltipButton>
            </footer>
        </Modal>
    );
};

ZoneEditModal.propTypes = {
    open: PT.bool.isRequired,
    onCancel: PT.func.isRequired,
    onSave: PT.func.isRequired,
    zone: PT.shape({
        name: PT.string.isRequired,
        assignedVehicles: PT.arrayOf(PT.string)
    }).isRequired
};

export default ZoneEditModal;
