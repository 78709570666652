import { DateTime, Duration } from 'luxon';
import i18n from '~/i18n';

/**
 * Date Localization
 *
 * @category Utils
 * @module utils/dateUtilsLocalized
 *
 * @example
 * import dateUtilsLocalized from '~/utils/date-utils-localized';
 */

/**
 * Formats the days of the week into single letter
 *
 * @param {string} dayOfWeek - non-abbreviated day of the week (example: 'Monday', 'Tuesday', ...)
 * @returns {string} - single localized letter for day of week (example: 'M', 'T', ...)
 *
 * @example
 * // returns 'M' and 'S' respectively
 * const mondayInitial = dateUtilsLocalized.formatLetterDayOfWeek('Monday');
 * const sundayInitial = dateUtilsLocalized.formatLetterDayOfWeek('Sunday');
 *
 * // returns null
 * const badString = dateUtilsLocalized.formatLetterDayOfWeek('bad string');
 */
function formatLetterDayOfWeek(dayOfWeek) {
    const datetime = DateTime.fromFormat(dayOfWeek, 'cccc').setLocale(
        i18n.language
    );
    return !datetime.invalid ? datetime.toFormat('ccccc') : null;
}

/**
 * Formats the month and year (example: February 2021)
 *
 * @param {Date} date - JS Date
 * @returns {string} - localized non-abbreviated month and year 'MMMM yyyy'
 */
function formatMonthYear(date) {
    const datetime = DateTime.fromJSDate(date).setLocale(i18n.language);
    return !datetime.invalid
        ? datetime.toLocaleString({
              month: 'long',
              year: 'numeric'
          })
        : null;
}

/**
 * Formats the date into abbreviated format (example: Thu, Feb 18)
 *
 * @param {Date} date - JS Date
 * @returns {string} - localized abbreviated date 'EEE MMM d'
 */
function formatAbbreviatedDate(date) {
    const datetime = DateTime.fromJSDate(date).setLocale(i18n.language);
    return !datetime.invalid
        ? datetime.toLocaleString({
              weekday: 'short',
              month: 'short',
              day: 'numeric'
          })
        : null;
}
/**
 * Formats the month and day (example: Feb 18)
 *
 * @param {Date} date - JS Date
 * @returns {string} - localized abbreviated date 'MMM d'
 */
function formatMonthDay(date) {
    const datetime = DateTime.fromJSDate(date).setLocale(i18n.language);
    return !datetime.invalid
        ? datetime.toLocaleString({
              month: 'short',
              day: 'numeric'
          })
        : null;
}

/**
 * Day of the week int where Sunday = 0 and Saturday = 6 (non-ISO week date)
 *
 * @param {String} date - ISO format date
 * @returns {number} day of week int
 */
function getIntWeekDay(date) {
    const day = DateTime.fromISO(date).weekday;
    // convert Sunday int
    if (day === 7) {
        return 0;
    }
    return day || null;
}

/**
 * Get duration string in ISO format "PT_H_M"
 *
 * @param {DateTime} startDateTime
 * @param {DateTime} endDateTime
 * @returns {string|null} duration
 */
function getDurationString(startDateTime, endDateTime) {
    if (
        !DateTime.isDateTime(startDateTime) ||
        !DateTime.isDateTime(endDateTime)
    ) {
        return null;
    }
    const duration = endDateTime
        .diff(startDateTime, ['hours', 'minutes'])
        .toObject();

    // when end time is on the next day e.g. 13:00pm - 05:00am
    if (duration.hours < 0 || duration.minutes < 0) {
        const dayMinutes = 24 * 60;
        const diffMinutes =
            Math.abs(duration.hours) * 60 + Math.abs(duration.minutes);
        const minDiff = dayMinutes - diffMinutes;
        duration.hours = Math.floor(minDiff / 60);
        duration.minutes = minDiff % 60;
    }

    return Duration.fromObject(duration).toISO();
}

/**
 * Adds a duration to an initial time to get a final time
 *
 * @param {String} time - ISO format
 * @param {Object} duration - Duration format { hours: _, minutes: _ }
 * @returns {string} time - local time (example: 18:00)
 */
function addDurationToTime(time, duration = { hours: 0, minutes: 0 }) {
    if (!duration || !Duration.fromObject(duration).isValid) {
        return null;
    }

    const dtFromTime = DateTime.fromFormat(time, 'T');
    const dtFromISO = DateTime.fromISO(time);
    const datetime = !dtFromTime.invalid ? dtFromTime : dtFromISO;

    return !datetime.invalid ? datetime.plus(duration).toFormat('T') : null;
}

/**
 * Get shift time object from start and duration to {start, end}
 *
 * @param {String} start - start shift time in ISO format (example: "10:00")
 * @param {String} duration - shift duration in ISO format (example: "PT8H")
 * @returns {{start: string, end: string}|null}
 */
function getShiftTime(start, duration) {
    const shiftStart = DateTime.fromISO(start);
    const shiftDuration = Duration.fromISO(duration);

    if (!shiftStart.isValid || !shiftDuration.isValid) {
        return null;
    }

    const durationObject = shiftDuration.toObject();
    const shiftEnd = addDurationToTime(start, durationObject);
    return {
        start: shiftStart.toFormat('T'),
        end: shiftEnd
    };
}

/**
 * Get localized time (example: "9:00 AM") from ISO date time
 *
 * @param {String} time - time in ISO format
 * @returns {string | null}
 */
function getLocalizedTime(time) {
    if (typeof time !== 'string') return null;

    const isLocalTime = time.match(/\d{1,2}:\d{2}(:\d{1,2})? (AM|PM)/);
    if (isLocalTime) return time;

    const timeFromIso = DateTime.fromISO(time);
    const isIsoTime = !timeFromIso.invalid;
    return isIsoTime ? timeFromIso.toFormat('t') : null;
}

/**
 * Get the formatted time window (example: "10:00 AM - 5:00 PM")
 *
 * @param {string} start - start time in ISO
 * @param {string} end - end time in ISO
 * @returns {string}
 */
function getTimeWindow(start, end) {
    const startTime = getLocalizedTime(start);
    const endTime = getLocalizedTime(end);
    return `${startTime} - ${endTime}`;
}

export default {
    formatMonthYear,
    formatMonthDay,
    formatLetterDayOfWeek,
    formatAbbreviatedDate,
    getIntWeekDay,
    getDurationString,
    addDurationToTime,
    getShiftTime,
    getLocalizedTime,
    getTimeWindow
};
