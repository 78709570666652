import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    useQuery,
    useMutation,
    useQueryClient,
    UseMutateAsyncFunction
} from 'react-query';
import {
    ZoneCoordinates,
    ZoneDataCreateWithVehicles,
    ZoneObject
} from '~/components/ZoneManagementPage/types';

import { addToast } from '~/reducers/toastsSlice';

import { zonesAPI } from '~/api/ZonesApi';
import constants from '~/utils/constants';

type ZoneDataEdit = {
    zoneId: string;
    update: {
        name?: string;
        geometry?: {
            type: string;
            coordinates: ZoneCoordinates;
        };
    };
};

type UseZonesReturnValue = {
    isFetching: boolean;
    deleteZone: UseMutateAsyncFunction<unknown, unknown, string>;
    editZone: UseMutateAsyncFunction<unknown, unknown, ZoneDataEdit>;
    createZone: UseMutateAsyncFunction<
        unknown,
        unknown,
        ZoneDataCreateWithVehicles
    >;
    data: unknown;
};

export const useZones = (): UseZonesReturnValue => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { t } = useTranslation('error');

    const { data, isFetching } = useQuery(
        constants.reactQueryKeys.ZONES,
        async () => {
            const response = await zonesAPI.get();
            return response.data.data;
        }
    );

    const { mutateAsync: deleteZone } = useMutation(
        (zoneId: string) => {
            return zonesAPI.delete([zoneId]);
        },
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(
                    constants.reactQueryKeys.ZONES
                );
            },
            onError: (error) => {
                console.error(error);
                dispatch(
                    addToast({
                        message: t('error:zoneDeleteError'),
                        variant: 'error'
                    })
                );
            }
        }
    );

    const { mutateAsync: editZone } = useMutation(
        ({ zoneId, update }: ZoneDataEdit) => {
            return zonesAPI.edit(zoneId, update);
        },
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(
                    constants.reactQueryKeys.ZONES
                );
            },
            onError: (error) => {
                console.error(error);
                dispatch(
                    addToast({
                        message: t('error:zoneEditError'),
                        variant: 'error'
                    })
                );
            }
        }
    );

    const { mutateAsync: createZone } = useMutation(
        async (zoneToCreate: ZoneDataCreateWithVehicles) => {
            // create zone
            let createdZone: ZoneObject;
            try {
                createdZone = await zonesAPI.create(zoneToCreate);
            } catch (e) {
                console.error(e);
                return Promise.reject('zoneCreationError');
            }

            // create zone/vehicle associations
            if (zoneToCreate.assignedVehicles?.length) {
                try {
                    const associations = zoneToCreate.assignedVehicles.map(
                        (vehicleId) => {
                            return { vehicleId, zoneId: createdZone.id };
                        }
                    );
                    await zonesAPI.createVehicleAssociation(associations);
                } catch (e) {
                    console.error(e);
                    return Promise.reject('zoneVehicleAssociationCreateError');
                }
            }
            return Promise.resolve(createdZone);
        },
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('zones');
            },
            onError: (error) => {
                console.error(error);
                dispatch(
                    addToast({
                        message: t(`error:${error}`),
                        variant: 'error'
                    })
                );
                return queryClient.invalidateQueries('zones');
            }
        }
    );

    return { data, isFetching, deleteZone, editZone, createZone };
};
