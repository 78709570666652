import React from 'react';
import { useTranslation } from 'react-i18next';

import { DropDownMenuItem, DropDownMenu } from '~/ui/components/DropDownMenu';
import { useDropDownMenuHandler } from '~/hooks';
import { useMapContext } from '../MapContext';

export const AddZoneButton = () => {
    const { t } = useTranslation('zoneManagement');
    const { isOpen, onClose, onToggle, ref } = useDropDownMenuHandler();
    const { isDrawingMode, setIsDrawingMode } = useMapContext();

    if (isDrawingMode) return null;

    const handleDrawZoneMenuItemClick = () => {
        onClose();
        setIsDrawingMode(true);
    };

    return (
        <div
            className="zonemanagement-addzone _p-absolute"
            data-testid="add-zone-container"
        >
            <DropDownMenu
                title={{ name: t('addZoneButton.title'), icon: 'zoneAdd' }}
                ref={ref}
                icon="chevronDown"
                isOpen={isOpen}
                toggle={onToggle}
                close={onClose}
                styles={{
                    container:
                        'zonemanagement-addzone-dropdown _fd-row _ai-center _p-absolute',
                    menuTitle:
                        '_d-flex _ai-center zonemanagement-addzone-dropdown-title',
                    menuTitleText: '_ml-3'
                }}
            >
                <DropDownMenuItem menuItem={{ disabled: true }}>
                    <span>{t('addZoneButton.importZoneFile')}</span>
                </DropDownMenuItem>
                <DropDownMenuItem
                    data-testid="draw-zone-menu-item"
                    menuItem={{ onClick: handleDrawZoneMenuItemClick }}
                >
                    <span>{t('addZoneButton.drawZone')}</span>
                </DropDownMenuItem>
            </DropDownMenu>
        </div>
    );
};
