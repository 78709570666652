import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';

import {
    resetSelectedMapStops,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';
import { selectSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';

import TasksApi from '~/api/TasksApi';
import {
    ApiAssignment,
    AxiosApiResponse,
    ApiTaskScheduler,
    ReactQuerySideEffects
} from '~/api/types';

import { useWebInterval } from '../useWebInterval';

type HookOnDemandDispatchUnassignTasks = {
    /** whether either dispatch function is busy */
    isUnassignCancelBusy: boolean;
    /** cancels selected tasks from a route */
    cancelTasks: (sideEffects: ReactQuerySideEffects) => void;
    /** unassigns selected tasks from a route */
    unassignTasks: (sideEffects: ReactQuerySideEffects) => void;
};

/**
 * Implements unassign and cancel tasks for selected drivers
 *
 * @category Hooks
 * @module useOnDemandDispatchUnassignTasks
 * @returns {HookOnDemandDispatchUnassignTasks}
 * @example <caption>Usage</caption>
 * // import statement
 * import { useOnDemandDispatchUnassignTasks } from '~/hooks';
 *
 * // expose constant and methods
 * const { isUnassignCancelBusy, cancelTasks, unassignTasks } = useOnDemandDispatchUnassignTasks();
 *
 */
export const useOnDemandDispatchUnassignTasks =
    (): HookOnDemandDispatchUnassignTasks => {
        const [isUnassignCancelBusy, setIsUnassignCancelBusy] = useState(false);
        const [selectedTaskIds, setSelectedTaskIds] = useState([]);
        const selectedMapStops = useSelector(selectSelectedMapStops);
        const selectedDrawerCardData = useSelector(
            selectSelectedDrawerCardData
        );
        const dispatch = useDispatch();
        const { refetch } = useWebInterval();

        const {
            isLoading: isLoadingUnassignTasks,
            mutateAsync: doUnassignTasksAsync
        } = useMutation(
            (
                taskIds: string[]
            ): Promise<AxiosApiResponse<ApiTaskScheduler>> => {
                return TasksApi.unassignTasks(taskIds);
            },
            {
                onSuccess: () => {
                    dispatch(resetSelectedMapStops());
                }
            }
        );

        const { isLoading: isLoadingCancelTasks, mutateAsync: doCancelTasks } =
            useMutation(
                (
                    taskIds: string[]
                ): Promise<AxiosApiResponse<ApiTaskScheduler>> => {
                    return TasksApi.cancelTasks(taskIds);
                },
                {
                    onSuccess: () => {
                        dispatch(resetSelectedMapStops());
                        refetch();
                    }
                }
            );

        useEffect(() => {
            const taskIds = selectedDrawerCardData?.schedule
                ? selectedDrawerCardData.schedule
                      .filter((item: ApiAssignment) =>
                          selectedMapStops.includes(item.id)
                      )
                      .map((item: ApiAssignment) => item.task)
                : [];

            setSelectedTaskIds(taskIds);
        }, [selectedMapStops, selectedDrawerCardData]);

        useEffect(() => {
            const isFetching = isLoadingCancelTasks || isLoadingUnassignTasks;
            setIsUnassignCancelBusy(isFetching);
        }, [isLoadingCancelTasks, isLoadingUnassignTasks]);

        /**
         * Cancel selected tasks from driver route
         *
         * Note:
         * + selected tasks are taken from `redux` selectedMapStopsSlice and selectedDrawerCardDataSlice
         *
         * @method cancelTasks
         * @param {ReactQuerySideEffects} sideEffects - side effect callbacks
         */
        const cancelTasks = async (sideEffects: ReactQuerySideEffects = {}) => {
            const {
                onSuccess: onSuccessCancelTasks,
                onError: onErrorCancelTasks
            } = sideEffects;

            try {
                const successData = await doCancelTasks(selectedTaskIds);
                if (onSuccessCancelTasks) onSuccessCancelTasks(successData);
            } catch (error) {
                let message;
                if (error instanceof Error) {
                    message = error.message;
                } else {
                    message = String(error);
                }
                console.error(message);
                if (onErrorCancelTasks) onErrorCancelTasks(error);
            }
        };

        /**
         * Unassign selected tasks from driver route
         *
         * Note:
         * + selected tasks are taken from `redux` selectedMapStopsSlice and selectedDrawerCardDataSlice
         *
         * @method unassignTasks
         * @param {ReactQuerySideEffects} sideEffects - side effect callbacks
         */
        const unassignTasks = async (
            sideEffects: ReactQuerySideEffects = {}
        ) => {
            const {
                onSuccess: onSuccessUnassignTasks,
                onError: onErrorUnassignTasks
            } = sideEffects;

            try {
                const successData = await doUnassignTasksAsync(selectedTaskIds);

                if (onSuccessUnassignTasks) onSuccessUnassignTasks(successData);
            } catch (error) {
                let message;
                if (error instanceof Error) {
                    message = error.message;
                } else {
                    message = String(error);
                }
                console.error(message);
                if (onErrorUnassignTasks) onErrorUnassignTasks(error);
            }
        };

        return {
            isUnassignCancelBusy,
            cancelTasks,
            unassignTasks
        };
    };
