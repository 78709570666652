import axios from 'axios';
import constants from '~/utils/constants';
import generalUtils from '~/utils/general-utils';
import { AxiosApiResponse } from './types';

interface EvaluateSchedulesResponseData {
    routeId?: string;
    driverId?: string;
    vehicleId?: string;
    tasks?: string[];
    taskIds?: string[];
    taskId?: string;
    schedulerTaskId?: string;
}

interface ClientPreferences {
    /* Date in ISO format */
    date?: string;
    driverId?: string;
    driverId1?: string;
    driverId2?: string;
    newRouteId?: string;
    oldRouteId?: string;
    routeId?: string;
    routeIds?: string[];
    taskIds?: string[];
    stopIds?: string[];
    type?: typeof constants.productTypes.SCHEDULER;
    vehicleId?: string;
    numOfLockedTasks?: number;
    routeActions?: unknown;
}

type UnassignTasksArgs = Pick<
    ClientPreferences,
    'date' | 'routeId' | 'taskIds'
>;

type UnassignTasksResponse = AxiosApiResponse<EvaluateSchedulesResponseData>;

type AugmentRouteArgs = Pick<
    ClientPreferences,
    'date' | 'driverId' | 'taskIds'
>;

type AugmentRouteResponse = AxiosApiResponse<EvaluateSchedulesResponseData>;

interface ResequenceTasksArgs
    extends Pick<ClientPreferences, 'date' | 'routeId' | 'taskIds'> {
    clientPreferences?: ClientPreferences;
    clientId?: string;
}

interface ResequenceTasksResponseData extends EvaluateSchedulesResponseData {
    newStopOrder?: string[];
}

type ResequenceTasksResponse = AxiosApiResponse<ResequenceTasksResponseData>;

interface OptimizeRoutesParams
    extends Pick<ClientPreferences, 'date' | 'routeIds'> {
    clientPreferences?: ClientPreferences;
    clientId?: string;
}

type OptimizeRoutesArgs = OptimizeRoutesParams;

interface OptimizeRoutesResponseData extends EvaluateSchedulesResponseData {
    routeIds?: string[];
}

type OptimizeRoutesResponse = AxiosApiResponse<OptimizeRoutesResponseData>;

interface ReassignTasksArgs
    extends Pick<ClientPreferences, 'date' | 'taskIds' | 'oldRouteId'> {
    clientId?: string;
    clientPreferences?: ClientPreferences;
    selectedDriverId?: string;
    selectedRouteId?: string;
}

type ReassignTasksResponse = AxiosApiResponse<EvaluateSchedulesResponseData>;

/**
 * Implementations of API methods under the `/schedules/evaluate` path
 *
 * @category API
 */
export default class SinglePlanEditApi {
    /**
     * Path of the single plan edit endpoint
     */
    private static readonly path = '/schedules/evaluate?action=';

    static unassignTasks(
        clientId: string,
        { date, routeId, taskIds }: UnassignTasksArgs
    ): Promise<UnassignTasksResponse> {
        if (!clientId) {
            return Promise.reject('Missing clientId');
        }
        if (!date) {
            return Promise.reject('Missing date');
        }
        if (!routeId) {
            return Promise.reject('Missing routeId');
        }
        if (!taskIds || !Array.isArray(taskIds) || !taskIds.length) {
            return Promise.reject('Missing taskIds');
        }

        const params: ClientPreferences = {
            date,
            routeId,
            taskIds,
            type: constants.productTypes.SCHEDULER
        };

        return axios.post(`${this.path}trim&accept`, params, {
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    }

    static augmentRoute(
        clientId: string,
        args: AugmentRouteArgs
    ): Promise<AugmentRouteResponse> {
        if (!clientId) {
            return Promise.reject('Missing clientId');
        }
        if (!args.date) {
            return Promise.reject('Missing date');
        }
        if (!args.driverId) {
            return Promise.reject('Missing driverId');
        }

        if (
            !args.taskIds ||
            !Array.isArray(args.taskIds) ||
            !args.taskIds.length
        ) {
            return Promise.reject('Missing taskIds');
        }

        const params: ClientPreferences = {
            ...args,
            type: constants.productTypes.SCHEDULER
        };

        return axios.post(`${this.path}augment&accept`, params, {
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    }

    static resequenceTasks({
        clientId,
        clientPreferences,
        date,
        routeId,
        taskIds
    }: ResequenceTasksArgs): Promise<ResequenceTasksResponse> {
        if (!clientId) {
            return Promise.reject('Missing clientId');
        }
        if (!date) {
            return Promise.reject('Missing date');
        }
        if (!routeId) {
            return Promise.reject('Missing routeId');
        }
        if (!taskIds || !Array.isArray(taskIds) || !taskIds.length) {
            return Promise.reject('Missing taskIds');
        }

        const params: ClientPreferences = {
            ...clientPreferences,
            date,
            routeId,
            stopIds: taskIds,
            type: constants.productTypes.SCHEDULER
        };

        return axios.post(`${this.path}resequence&accept`, params, {
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    }

    static optimizeRoutes({
        clientId,
        clientPreferences,
        date,
        routeIds
    }: OptimizeRoutesArgs): Promise<OptimizeRoutesResponse> {
        if (!clientId) return Promise.reject('Missing clientId');

        if (!date) return Promise.reject('Missing date');

        if (!routeIds?.length || !Array.isArray(routeIds))
            return Promise.reject('Missing routeIds');

        const params: ClientPreferences = {
            ...clientPreferences,
            date,
            routeIds,
            type: constants.productTypes.SCHEDULER
        };

        return axios.post(`${this.path}optimize&accept`, params, {
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    }

    static reassignTasks({
        clientId,
        clientPreferences,
        date,
        taskIds,
        oldRouteId,
        selectedDriverId,
        selectedRouteId
    }: ReassignTasksArgs): Promise<ReassignTasksResponse> {
        if (!clientId) return Promise.reject('Missing clientId');

        if (!date) return Promise.reject('Missing date');

        if (!oldRouteId) return Promise.reject('Missing oldRouteId');

        if (!taskIds?.length || !Array.isArray(taskIds))
            return Promise.reject('Missing taskIds');

        const isNewRouteIdValid =
            selectedRouteId && generalUtils.isValidUUID(selectedRouteId);
        const newRouteId = (isNewRouteIdValid && selectedRouteId) || '';
        const driverId = (!isNewRouteIdValid && selectedDriverId) || '';

        const params: ClientPreferences = {
            ...clientPreferences,
            date,
            driverId,
            newRouteId,
            oldRouteId,
            taskIds,
            type: constants.productTypes.SCHEDULER
        };

        return axios.post(`${this.path}reassign&accept`, params, {
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    }
}
