/* eslint-disable no-restricted-globals */
import io from 'socket.io-client';
import constants from '~/utils/constants';

import { store } from '~/store';
import {
    addActiveClients,
    removeActiveClients
} from '~/reducers/activeClientsSlice';
import { updateLiveDrivers } from '~/reducers/liveDriversSlice';
import { setOnDemandDispatchTaskCount } from '~/reducers/onDemandDispatchTaskCountSlice';

import socketMessageHandler from '~/utils/socket/socket-message-handler';
import filterSocketMessages from '~/utils/socket/filter-socket-message';

let socket;
const joinedRooms = new Set();
// todo: join with socket-instance.js
async function _handleSocketMessage(message) {
    const { message: socketEvent, clientId, schedulerTaskId, data } = message;
    if (schedulerTaskId && !data.schedulerTaskId) {
        data.schedulerTaskId = schedulerTaskId;
    }
    switch (socketEvent) {
        case constants.socketCustom.SOLUTION:
            socketMessageHandler.handleWebSolution({ data, clientId });
            break;
        case constants.socketCustom.CEP_RESULTS:
            handleCEPMessage(data, clientId);
            break;
        case constants.socketCustom.CUSTOMER_SEARCH:
            socketMessageHandler.handleCustomerSearch({ data });
            break;
        case constants.socketCustom.CUSTOMER_SEARCH_ERROR:
            socketMessageHandler.handleCustomerSearchError();
            break;
        case constants.socketCustom.DIRECT_ROUTE_IMPACT:
            await socketMessageHandler.handleDirectRouteImpact({
                data,
                clientId
            });
            break;
        case constants.socketCustom.DRIVERS:
            store.dispatch(updateLiveDrivers({ drivers: data, clientId }));
            break;
        case constants.socketCustom.METRICS:
            store.dispatch(
                setOnDemandDispatchTaskCount(data.tasks.activeTasks.unassigned)
            );
            break;
        case constants.socketCustom.JOIN_ROOMS:
            store.dispatch(addActiveClients([clientId]));
            break;
        case constants.socketCustom.LEAVE_ROOMS:
            store.dispatch(removeActiveClients([clientId]));
            break;
        case constants.socketCustom.PAIRING_EXECUTED:
            socketMessageHandler.handlePairingExecuted({ data });
            break;
        case constants.socketCustom.ON_DEMAND_TASK_ADDED:
            await socketMessageHandler.handleOnDemandTaskAdded(data);
            break;
        case constants.socketCustom.ON_DEMAND_TASK_ADDED_BULK:
            socketMessageHandler.handleOnDemandTaskAddedBulk();
            break;
        case constants.socketCustom.ON_DEMAND_TASK_REMOVED:
            socketMessageHandler.handleOnDemandTaskRemoved(data);
            break;
        case constants.socketCustom.UPLOAD:
            socketMessageHandler.handleUpload(message);
            break;
        default:
            // socketEvent ignored
            break;
    }
}

function handleCEPMessage(data, clientId) {
    data = filterSocketMessages.filterCepResults({
        data,
        clientId
    });
    socketMessageHandler.handleCepResults({ data, clientId });
}

function connectToServer(payload) {
    socket = io(payload.socketUrl, {
        transports: ['websocket'],
        auth: { token: payload.accessToken }
    });

    socket.on(constants.socketReserved.ERROR, (error) => {
        console.warn('Socket Error', error);
        socket.close();
    });

    socket.on(constants.socketReserved.CONNECT_ERROR, (error) => {
        console.warn('Socket Connect Error', error);

        if (error.message === constants.socketErrors.JWT_EXPIRED) {
            payload.logout();
            return;
        }

        socket.close();
        joinedRooms.clear();
    });

    socket.on(constants.socketReserved.CONNECT, () => {});
    socket.on(constants.socketCustom.MESSAGE, _handleSocketMessage);
}

function joinClientRooms(payload) {
    for (const clientId of payload.clientIds) {
        if (!joinedRooms.has(clientId)) {
            socket.emit(constants.socketCustom.JOIN_ROOMS, clientId);
            joinedRooms.add(clientId);
        }
    }
}

function leaveRooms(payload) {
    for (const clientId of payload.clientIds) {
        if (joinedRooms.has(clientId)) {
            socket.emit(constants.socketCustom.LEAVE_ROOMS, clientId);
            joinedRooms.delete(clientId);
        }
    }
}

function leaveAllRooms() {
    if (socket) {
        // notify the server about leaving the room so that server leaves too
        // in order to re-establish connection on logout/login
        const payload = {
            clientIds: Array.from(joinedRooms)
        };
        leaveRooms(payload);
    }
}

function disconnectFromServer() {
    if (!socket) return;

    leaveAllRooms();
    socket.close();
}

export default {
    connectToServer,
    disconnectFromServer,
    leaveAllRooms,
    joinClientRooms,
    leaveRooms
};
