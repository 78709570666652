import axios from 'axios';
import constants from '~/utils/constants';
import {
    ApiAddress,
    AxiosApiResponse,
    ExtentParams,
    PaginationMetadata,
    PaginationParams
} from '~/api/types';

/**
 * Implementations of the API methods under the `/depots` path
 *
 * @category API
 */

type GetDepotsParams = PaginationParams | ExtentParams;

interface ApiDepots {
    hq?: boolean;
    address?: ApiAddress;
    id?: string;
}
export default class DepotsApi {
    /**
     * Path of the API endpoint
     */
    private static readonly depotsPath = '/depots';

    private static readonly depotPath = '/depot';

    static get(
        params: GetDepotsParams = {
            limit: 1000,
            extent: 'address'
        }
    ): Promise<AxiosApiResponse<ApiDepots, PaginationMetadata>> {
        return axios.get(this.depotsPath, {
            params,
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }

    static post(payload: {
        address: string;
    }): Promise<AxiosApiResponse<ApiDepots>> {
        return axios.post(this.depotPath, payload, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }

    static delete(id: string): Promise<AxiosApiResponse<ApiDepots>> {
        if (!id) {
            return Promise.reject('depot id not provided');
        }
        return axios.delete(`${this.depotPath}/${id}`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }
}
