import { useCallback, useRef, useState } from 'react';

export const useDropDownMenuHandler = () => {
    const ref = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const onToggle = useCallback(() => {
        setIsOpen((state) => !state);
    }, []);
    const onClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    return {
        ref,
        isOpen,
        onToggle,
        onClose
    };
};
