import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AddTaskState {
    isOpenAddTaskModal: boolean;
}

const initialState: AddTaskState = {
    isOpenAddTaskModal: false
};

export const addTaskSlice = createSlice({
    name: 'addTask',
    initialState,
    reducers: {
        setIsOpenAddTaskModal: (
            state: AddTaskState,
            action: PayloadAction<boolean>
        ) => {
            state.isOpenAddTaskModal = action.payload;
        }
    }
});

export const { setIsOpenAddTaskModal } = addTaskSlice.actions;

/* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
export const selectIsOpenAddTaskModal = (state: any): boolean =>
    state.addTask.isOpenAddTaskModal;
export default addTaskSlice.reducer;
