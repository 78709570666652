import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TooltipButton, TextInput, IconButton } from '~/ui';
import PasswordReminderText from '~/ui/components/PasswordReminderText';
import inputFieldUtils from '~/utils/input-field-utils';

import './EditablePassword.scss';

const criteriaCheckInitialState = {
    letterCase: false,
    hasNumber: false,
    hasSpecialCharacter: false
};

const EditablePassword = ({ value, onSubmit, onCancel = () => {} }) => {
    const { t } = useTranslation(['common']);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [currentPassword, setCurrentPassword] = useState(value);
    const [isPasswordCriteriaMet, setIsPasswordCriteriaMet] = useState(
        criteriaCheckInitialState
    );

    const isPasswordDirty = value !== currentPassword;

    const handleShowPassword = () => {
        setIsPasswordShown(!isPasswordShown);
    };

    function validatePassword(password) {
        const isCriteriaMet = {
            letterCase: inputFieldUtils.hasLowercaseAndUppercase(password),
            hasNumber: inputFieldUtils.hasNumber(password),
            hasSpecialCharacter: inputFieldUtils.hasSpecialCharacter(password)
        };
        setIsPasswordCriteriaMet(isCriteriaMet);
    }

    const handleChange = (password) => {
        setCurrentPassword(password);
    };

    const resetState = () => {
        setCurrentPassword(value);
        setIsPasswordShown(false);
        setIsPasswordCriteriaMet(criteriaCheckInitialState);
    };

    const handleSubmit = () => {
        resetState();
        onSubmit(currentPassword);
    };

    const handleCancel = () => {
        resetState();
        onCancel();
    };

    useEffect(() => {
        validatePassword(currentPassword);
    }, [currentPassword]);

    useEffect(() => {
        const isSaveValid =
            currentPassword &&
            isPasswordCriteriaMet.letterCase &&
            isPasswordCriteriaMet.hasNumber &&
            isPasswordCriteriaMet.hasSpecialCharacter;

        setIsSaveDisabled(!isSaveValid);
    }, [isPasswordCriteriaMet]);

    return (
        <div className="editablepassword-container">
            <TextInput
                value={value}
                width="fullWidth"
                variant="edit"
                data-testid="editabletext-input"
                type={isPasswordShown ? 'text' : 'password'}
                onChange={handleChange}
            />
            <PasswordReminderText
                reminderText={t('password.passwordRequirement.letterCase')}
                isDirty={isPasswordDirty}
                isPasswordValid={isPasswordCriteriaMet.letterCase}
            />
            <PasswordReminderText
                reminderText={t('password.passwordRequirement.numeric')}
                isDirty={isPasswordDirty}
                isPasswordValid={isPasswordCriteriaMet.hasNumber}
            />
            <PasswordReminderText
                reminderText={t(
                    'password.passwordRequirement.specialCharacter'
                )}
                isDirty={isPasswordDirty}
                isPasswordValid={isPasswordCriteriaMet.hasSpecialCharacter}
            />
            <div className="_d-flex _jc-flex-end _mb-1">
                <div className="_d-inline _ml-2">
                    <IconButton
                        className="editabletext-button"
                        icon="cancel"
                        iconSize="s"
                        iconColor="meteor"
                        onClick={handleCancel}
                    />
                </div>
                <div className="_d-inline _ml-2">
                    <IconButton
                        className="editabletext-button"
                        icon="checkLine"
                        iconSize="s"
                        disabled={isSaveDisabled}
                        onClick={handleSubmit}
                    />
                </div>
            </div>
            <TooltipButton
                variant="link"
                className="showpassword-button"
                onClick={handleShowPassword}
            >
                {isPasswordShown ? t('hidePassword') : t('showPassword')}
            </TooltipButton>
        </div>
    );
};

export default EditablePassword;
