/** @jsxRuntime classic */
// known bug - see : https://github.com/system-ui/theme-ui/issues/1160
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { memo } from 'react';
import PT from 'prop-types';

const Highlight = memo(
    ({ inputText, searchText, color, fontWeight, className }) => {
        let displayText;
        const idx = inputText.toLowerCase().indexOf(searchText?.toLowerCase());

        if (idx >= 0) {
            displayText = [
                inputText.substring(0, idx),
                <span key="highlight" sx={{ color, fontWeight }}>
                    {inputText.substring(idx, idx + searchText.length)}
                </span>,
                inputText.substring(idx + searchText.length)
            ];
        } else {
            displayText = inputText;
        }

        return <span className={className}>{displayText}</span>;
    }
);

Highlight.propTypes = {
    /** The full text to be shown */
    inputText: PT.string,
    /** The part of `inputText` to be highlighted */
    searchText: PT.string,
    /** The color of highlight */
    color: PT.string,
    /** The weight of highlight */
    fontWeight: PT.string,
    /** Additional class if needed */
    className: PT.string
};

export default Highlight;
