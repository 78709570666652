import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Icon, useClickOutside } from '~/ui';
import AddressForm from '~/ui/components/AddressForm';

import tasksApi from '~/api/TasksApi';
import useAddress from '~/hooks/useAddress';
import { selectTasks, updateTaskById } from '~/reducers/tasksSlice';
import { addToast } from '~/reducers/toastsSlice';
import constants from '~/utils/constants';
import taskUtils from '~/utils/task-utils';

import './EditTaskAddressModal.scss';

const EditTaskAddressModal = React.forwardRef(function EditTaskAddressModal(
    { taskId, isDelivery, onHide },
    ref
) {
    const { t } = useTranslation(['taskManagement', 'common']);
    const domElement = document.getElementById('modal-root');
    const dispatch = useDispatch();

    const allTasks = useSelector(selectTasks);
    const taskAddress = isDelivery
        ? allTasks[taskId].deliveryLocation
        : allTasks[taskId].pickupLocation;
    const { values: inputs, handleAddressChange } = useAddress(taskAddress);

    useClickOutside(ref, onHide);

    async function onSave() {
        const property = isDelivery
            ? constants.taskTypes.DELIVERY_LOCATION
            : constants.taskTypes.PICKUP_LOCATION;
        const previousValue = allTasks[taskId][property];
        const updatedValue = {
            name: previousValue.name,
            addressLine1: inputs.addressLine1,
            addressLine2: inputs.addressLine2,
            city: inputs.city,
            state: inputs.state,
            zipcode: inputs.zipcode,
            country: inputs.country
        };

        // only update the coordinates if they have been altered, otherwise
        // we fetch new coordinates based on the input address
        if (
            String(previousValue.location.lng) !== inputs.longitude ||
            String(previousValue.location.lat) !== inputs.latitude
        ) {
            updatedValue.location = {
                lng: parseFloat(inputs.longitude),
                lat: parseFloat(inputs.latitude)
            };
        }

        try {
            await tasksApi.update(taskId, {
                [property]: updatedValue
            });
            const getResponse = await tasksApi.getTask(taskId, {
                extent: property
            });
            const updatedTask = getResponse.data.data;
            const taskStatus = taskUtils.getTaskStatus(updatedTask);
            dispatch(
                updateTaskById({
                    taskId,
                    value: { ...updatedTask, taskStatus }
                })
            );
            onHide();
        } catch (error) {
            console.error(error);
            dispatch(
                addToast({
                    message: t('error:taskEditError'),
                    variant: 'error'
                })
            );
        }
    }

    return ReactDOM.createPortal(
        <div className="overlay">
            <div className="modal" ref={ref}>
                <div className="modal-header">
                    <span>{t('editAddress.title')}</span>
                    <button
                        type="button"
                        className="icon-wrapper"
                        onClick={onHide}
                    >
                        <Icon icon="cancel" color="galaxy-800" />
                    </button>
                </div>
                <div className="editaddress-content">
                    <AddressForm
                        className="editaddress-inputs"
                        values={inputs}
                        onChange={(name, value) =>
                            handleAddressChange(name, value)
                        }
                    />
                    <div className="_d-flex _jc-flex-end">
                        <Button
                            variant="secondary"
                            marginRight="1rem"
                            onClick={onHide}
                        >
                            {t('common:cancel')}
                        </Button>
                        <Button onClick={onSave}>{t('common:save')}</Button>
                    </div>
                </div>
            </div>
        </div>,
        domElement
    );
});

export default EditTaskAddressModal;
