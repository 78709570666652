import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';
import { planDataFactory } from '~/utils/data-factory';

function _indexExistingRouteLevelData(
    routeLevelDataElement,
    planRoutesLevelData
) {
    let foundIndex = -1;
    let duplicateIndex = -1;

    const planRoute = planDataFactory.makePlanRoute(routeLevelDataElement);

    for (let index = 0; index < planRoutesLevelData.length; index++) {
        const currentPlanRoute = planDataFactory.makePlanRoute(
            planRoutesLevelData[index]
        );
        const hasSameClientRouteId =
            planRoute.clientRouteId === currentPlanRoute.clientRouteId;
        const hasSameDriverId = planRoute.driverId
            ? planRoute.driverId === currentPlanRoute.driverId
            : false;
        const hasSameVehicleId = planRoute.vehicleId
            ? planRoute.vehicleId === currentPlanRoute.vehicleId
            : false;

        // When reassigning all the planned stops from one route to another
        // the API (or maybe it's CEP) changes the routeId from a UUID to an integer.
        // This makes it so the routeId is no longer trustworthy, and can no longer
        // be matched up by clientRouteId
        // when changing drivers, planRoute has same driver & vehicle but a different clientRouteId,
        // so we need to remove obsolete clientRouteId
        if (hasSameClientRouteId) {
            foundIndex = index;
        }

        if (!hasSameClientRouteId && hasSameDriverId && hasSameVehicleId) {
            duplicateIndex = index;
        }

        if (foundIndex > -1 && duplicateIndex > -1) {
            break;
        }
    }

    return [foundIndex, duplicateIndex];
}

export const planRoutesLevelData = createSlice({
    name: 'planRoutesLevelData',
    initialState: [],
    reducers: {
        setPlanRoutesLevelData: (state, action) => {
            if (!Array.isArray(action.payload)) {
                return state;
            }
            return action.payload;
        },
        addPlanRoutesLevelData: (state, action) => {
            if (!Array.isArray(action.payload)) {
                return state;
            }
            for (const routeLevelDataElement of action.payload) {
                const [indexExisting, duplicateIndex] =
                    _indexExistingRouteLevelData(routeLevelDataElement, state);

                if (indexExisting !== -1) {
                    state.splice(indexExisting, 1, routeLevelDataElement);
                } else {
                    state.push(routeLevelDataElement);
                }

                if (duplicateIndex !== -1) {
                    state.splice(duplicateIndex, 1);
                }
            }
            return state;
        },
        resetPlanRoutesLevelData: () => {
            return [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return [];
        });
    }
});

export const {
    setPlanRoutesLevelData,
    addPlanRoutesLevelData,
    resetPlanRoutesLevelData
} = planRoutesLevelData.actions;

export const selectPlanRoutesLevelData = (state) => state.planRoutesLevelData;

export const selectPlannedLevelData = (state) => {
    return Object.values(state.planRoutesLevelData).filter(
        (plannedData) => plannedData.route.isPlanned
    );
};

export const selectUnplannedRouteData = (state) => {
    return Object.values(state.planRoutesLevelData).find(
        (item) => item.route.routeId === 'unplanned'
    );
};

export default planRoutesLevelData.reducer;
