import { useEffect } from 'react';

import { useZones } from '~/hooks';
import constants from '~/utils/constants';

import { useMapContext } from '../MapContext';
import { usePageContext } from '../PageContext';

export const useDisplayZonesOnMap = (): void => {
    const { mapInstance } = useMapContext();
    const { activeZoneId } = usePageContext();
    const { data: zonesData } = useZones();
    const zonesList = (zonesData || []) as Record<string, unknown>[];
    const { data: mapData } = mapInstance || {};

    const removeAllZones = () => {
        mapData?.forEach((feature) => {
            mapData.remove(feature);
        });
    };

    const drawZones = () => {
        if (!mapData) return;

        removeAllZones();

        zonesList.forEach((zone) => {
            mapData.addGeoJson(zone);
        });
    };

    useEffect(() => {
        mapData?.setStyle(constants.zoneMapPolygon.defaultStyle);
    }, [mapData]);

    useEffect(drawZones, [zonesList, mapData]);

    useEffect(() => {
        if (!mapData) return;

        mapData.revertStyle();

        if (!activeZoneId) return;

        mapData.forEach((feature) => {
            const isActive = feature.getId() === activeZoneId;
            mapData.overrideStyle(feature, {
                ...(isActive && {
                    fillOpacity: 0.5,
                    clickable: true,
                    zIndex: 1,
                    editable: true
                }),
                ...(!isActive && {
                    fillColor: constants.zoneMapPolygon.fadedFillColor,
                    zIndex: 0
                })
            });
        });
    }, [activeZoneId, mapData]);
};
