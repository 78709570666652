import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { resetToInitialState } from '~/reducers/common-actions';

export const vehiclesSlice = createSlice({
    name: 'vehicles',
    initialState: {},
    reducers: {
        setVehicles: (state, action) => {
            return action.payload;
        },
        updateVehicle: (state, action) => {
            const { id, data } = action.payload;
            if (!_.isObject(data)) return state;
            return { ...state, [id]: data };
        },
        addVehicle: (state, action) => {
            const vehicle = action.payload;
            const { id } = vehicle;
            return { ...state, [id]: vehicle };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {};
        });
    }
});
export const { addVehicle, setVehicles, updateVehicle } = vehiclesSlice.actions;

export const selectVehicles = (state) => state.vehicles;
export const selectVehicleById = (id) => (state) => state.vehicles[id];

export default vehiclesSlice.reducer;
