import axios from 'axios';
import constants from '~/utils/constants';
import { AxiosApiResponse, IApiResponse } from '~/api/types';

/**
 * Implementations of API methods under the /admin path
 *
 * @category API
 */
export default class AdminApi {
    /**
     * Path of the API endpoint
     */
    private static readonly PATH = '/admin';

    /**
     * Sends a POST /admin?action=upload_schedule_file to upload a schedule file
     *
     * @param {string} clientId target client
     * @param {File} file to upload
     * @returns {IApiResponse}
     */
    static uploadScheduleFile(
        clientId: string,
        file: File
    ): Promise<AxiosApiResponse> {
        if (!clientId) {
            return Promise.reject('Missing clientId');
        }
        if (!file) {
            return Promise.reject('Missing file');
        }

        const formData = new FormData();
        formData.append('file', file);

        return axios.post<IApiResponse>(
            `${this.PATH}?action=upload_schedule_file`,
            formData,
            {
                headers: {
                    [constants.requestHeaders.WISE_CLIENT_ID]: clientId,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }

    /**
     * Sends a POST /admin?action=upload_third_party_file
     *
     * @param {string} staticIds static route ids - separated by commas (100, 200)
     * @param {Array<string>} dates start and end dates range
     * @returns {IApiResponse}
     */
    static uploadThirdPartyFile(
        staticIds: string,
        dates: string[]
    ): Promise<AxiosApiResponse> {
        if (!dates || !Array.isArray(dates) || !dates.length) {
            return Promise.reject('dates not provided');
        }

        return axios.post<IApiResponse>(
            `${this.PATH}?action=upload_third_party_file`,
            { staticIds, dates },
            {
                headers: {
                    [constants.requestHeaders.WISE_CLIENT_ID]: ''
                }
            }
        );
    }
}
