import { ServiceWindow, TwoPartServiceWindow } from '~/api/types';
import { Task } from './Task';

/**
 * On-Demand Dispatch Stop data class
 *
 * @category Data Classes
 *
 * @example
 * import { OnDemandDispatchStop } from '~/data-classes';
 *
 * const srcData = {};
 * const task = new OnDemandDispatchStop(srcData);
 *
 */
export class OnDemandDispatchStop extends Task {
    /**
     * the task id
     * @type {string}
     */
    get taskId(): string {
        return this.id;
    }

    /**
     * the task name
     * @type {string}
     */
    get stopName(): string {
        return this.name;
    }

    /**
     * the task location
     * @type {object}
     */
    get location(): Record<string, unknown> {
        if (this.isTwoPart) {
            return {
                pickupLocation: this.pickupLocationAddress,
                deliveryLocation: this.deliveryLocationAddress
            };
        }

        return this.isPickup
            ? this.pickupLocationAddress
            : this.deliveryLocationAddress;
    }

    /**
     * the task service window
     * @type {(ServiceWindow | TwoPartServiceWindow)}
     */
    get serviceWindow(): ServiceWindow | TwoPartServiceWindow {
        if (this.isTwoPart) {
            return {
                pickup: this.pickupWindow,
                delivery: this.deliveryWindow
            };
        }

        return this.isPickup ? this.pickupWindow : this.deliveryWindow;
    }
}
