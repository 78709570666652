import React from 'react';
import './routecard-text.scss';

import { IconButton } from '~/ui';

function RouteCardText({
    className,
    text,
    children,
    icon,
    iconColor = 'galaxy-500',
    ...extra
}) {
    function _getClassName() {
        let boxClassName = 'routecard-text';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    function _getIcon() {
        if (!icon || !icon.length) return null;
        return (
            <IconButton
                icon={icon}
                iconColor={iconColor}
                disabled
                data-testid={
                    extra['data-testid']
                        ? `${extra['data-testid']}-icon`
                        : 'routecard-text-icon'
                }
            />
        );
    }

    return (
        <div
            className={_getClassName()}
            data-testid={extra['data-testid'] || 'routecard-text'}
        >
            {(children || icon) && (
                <div className="routecard-text_group _d-flex _ai-center">
                    {_getIcon()}
                    <span
                        data-testid={
                            extra['data-testid']
                                ? `${extra['data-testid']}-children`
                                : 'routecard-text-children'
                        }
                    >
                        {children}
                    </span>
                </div>
            )}
            {text}
        </div>
    );
}

export default RouteCardText;
