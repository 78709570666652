import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { addProcessIndicator } from '~/reducers/processIndicatorSlice';
import SinglePlanEditApi from '~/api/SinglePlanEditApi';
import { selectMainClient } from '~/reducers/mainClientSlice';
import constants from '~/utils/constants';
import { useMapUtils } from './useMapUtils';

export const useOptimizeRoutes = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');
    const mainClient = useSelector(selectMainClient);
    const [isOptimizingRoutes, setIsOptimizingRoutes] = useState(false);
    const { isPlanRouteMode } = useMapUtils();

    const isRouteOptimizationAllowed = isPlanRouteMode && mainClient;

    const optimizeRoutes = useCallback(
        async ({ clientId, routeIds, date }) => {
            if (!isRouteOptimizationAllowed) {
                console.warn('route optimization not allowed');

                return;
            }

            const processIndicatorState = {
                message: t('OptimizingRoute', {
                    count: routeIds.length
                }),
                inProgress: true,
                error: false,
                position: 'center',
                payload: routeIds.length,
                type: constants.processIndicator.OPTIMIZE_ROUTE
            };
            const { preferences: clientPreferences } = mainClient;

            try {
                setIsOptimizingRoutes(true);
                dispatch(addProcessIndicator(processIndicatorState));
                await SinglePlanEditApi.optimizeRoutes({
                    clientId,
                    clientPreferences,
                    date,
                    routeIds
                });
            } catch (e) {
                console.error(e);
                dispatch(
                    addProcessIndicator({
                        ...processIndicatorState,
                        inProgress: false,
                        error: true
                    })
                );
            }

            setIsOptimizingRoutes(false);
        },
        [mainClient, dispatch, isRouteOptimizationAllowed, t]
    );

    return { isOptimizingRoutes, optimizeRoutes };
};
