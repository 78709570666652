import React from 'react';
import classNames from 'classnames';
import './ButtonLink.scss';

const ButtonLink = ({ children, onClick = () => {}, className, sx = {} }) => {
    const classes = classNames(className, 'buttonlink');
    return (
        <button
            onClick={onClick}
            style={sx}
            type="button"
            className={classes}
            data-testid="buttonlink"
        >
            {children}
        </button>
    );
};

export default ButtonLink;
