import { DateTime } from 'luxon';
import { DownloadableReportItem } from './DownloadableReportItem';
import { ApiAnalyticsAggregateDriversDownload } from '~/api/types';

/**
 * DownloadableReports data class
 *
 * @category Data Classes
 *
 * @example
 * import { DownloadableReports } from '~/data-classes/cost/DownloadableReports';
 *
 * const srcData = [];
 * const reports = new DownloadableReports(srcData);
 *
 */
export class DownloadableReports {
    /**
     * The API source data
     * @type {ApiAnalyticsAggregateDriversDownload[]}
     */
    private readonly initReports: ApiAnalyticsAggregateDriversDownload[];

    private readonly rawReports: DownloadableReportItem[];

    private filteredReports: DownloadableReportItem[];

    // No constructor JSDoc to avoid duplicates in generated docs
    // https://github.com/jsdoc/jsdoc/issues/1775
    constructor(initReports: ApiAnalyticsAggregateDriversDownload[]) {
        this.initReports = initReports;

        this.rawReports = this.initReports.map(
            (report) => new DownloadableReportItem(report)
        );

        this.filteredReports = [];
    }

    get reports(): DownloadableReportItem[] {
        return this.filteredReports.length > 0
            ? this.filteredReports
            : this.rawReports;
    }

    filterReportsByDates(filterReportDates: DateTime[] = []): this {
        this.filteredReports = this.rawReports.filter((report) =>
            report.isWithinReportDates(filterReportDates)
        );

        return this;
    }

    groupReportsByDate(): Record<string, DownloadableReportItem[]> | null {
        const reportsByDate = this.reports.reduce((allReports, report) => {
            const reportDate = report.reportDate.toISODate();

            if (!allReports[reportDate]) {
                allReports[reportDate] = [];
            }

            allReports[reportDate].push(report);

            return allReports;
        }, {} as Record<string, DownloadableReportItem[]>);

        return Object.keys(reportsByDate).length > 0 ? reportsByDate : null;
    }

    groupReportsByType(): Record<string, DownloadableReportItem[]> | null {
        const reportsByType = this.reports.reduce((allReports, report) => {
            const reportType = report.type;

            if (!allReports[reportType]) {
                allReports[reportType] = [];
            }

            allReports[reportType].push(report);

            return allReports;
        }, {} as Record<string, DownloadableReportItem[]>);

        return Object.keys(reportsByType).length > 0 ? reportsByType : null;
    }

    /**
     * Serializes this class back to JSON
     * @returns {ApiAnalyticsAggregateDriversDownload[]}
     */
    toJSON(): ApiAnalyticsAggregateDriversDownload[] {
        return this.initReports;
    }
}
