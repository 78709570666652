import React, { CSSProperties, MouseEventHandler } from 'react';
import classNames from 'classnames';
import { Tooltip } from '~/ui';
import { TooltipPlacement } from '~/ui/components/Tooltip';
import './tooltipbutton.scss';
import {
    TooltipButtonVariants,
    TooltipButtonVariant
} from './TooltipButtonVariants';
import { TooltipButtonSizes, TooltipButtonSize } from './TooltipButtonSizes';

type Props = {
    /** Used to disable button */
    disabled?: boolean;
    /** Used to explicitly show / hide the button */
    visible?: boolean;
    /** Used to set additional class names to the button */
    className?: string;
    /** Styling */
    style?: CSSProperties | undefined;
    /** Used to set the button size */
    size?: TooltipButtonSize;
    /** Used to set the button variant */
    variant?: TooltipButtonVariant;
    /** Used to add a tooltip for the button */
    tooltipMessage?: string;
    /** Determine placement position of the button tooltip */
    tooltipPlacement?: TooltipPlacement;
    /** Used to update the CSS property `align-items` for the button */
    alignItems?: string;
    /** Used to update the CSS property `flex-direction` for the button */
    flexDirection?: string;
    /** Used to update the CSS property `flex-wrap` for the button */
    flexWrap?: string;
    /** Used to update the CSS property `justify-content` for the button */
    justifyContent?: string;
    /** Attach a handler when clicking the button */
    onClick?: MouseEventHandler<HTMLButtonElement>;
    /** Attach a handler when the mouse enters the button */
    onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
    /** Attach a handler when the mouse leaves the button */
    onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
    /** Child nodes */
    children?: React.ReactNode;
    /** Test Id for unit testing */
    'data-testid'?: string;
};

function TooltipButton({
    children,
    className,
    style = {},
    disabled = false,
    size = 'default',
    variant = 'default',
    visible = true,
    tooltipMessage = '',
    tooltipPlacement = 'bottom',
    alignItems = 'center',
    flexDirection,
    flexWrap,
    justifyContent = 'center',
    onClick,
    onMouseEnter,
    onMouseLeave,
    ...extra
}: Props): JSX.Element | null {
    function getClassName() {
        const defaultClassName = 'tooltipbutton';
        const conditionalClasses = {
            [`${defaultClassName}_${size}`]: size,
            [`${defaultClassName}_${variant}`]: variant,
            '_d-flex': true,
            [`_fd-${flexDirection}`]: flexDirection,
            [`_fw-${flexWrap}`]: flexWrap,
            [`_jc-${justifyContent}`]: justifyContent,
            [`_ai-${alignItems}`]: alignItems
        };
        return classNames(defaultClassName, conditionalClasses, className);
    }

    if (!visible) return null;

    return (
        <Tooltip content={tooltipMessage} placement={tooltipPlacement}>
            <button
                className={getClassName()}
                type="button"
                style={style}
                disabled={disabled}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                data-testid={extra['data-testid'] || 'tooltipbutton'}
            >
                {children}
            </button>
        </Tooltip>
    );
}

TooltipButton.sizes = TooltipButtonSizes;

TooltipButton.variants = TooltipButtonVariants;

export default TooltipButton;
