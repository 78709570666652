import * as XLSX from 'xlsx';

function downloadFileBlob(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    downloadFileUrl(url, filename);
}

function downloadFileUrl(url, filename) {
    const downloadLink = document.createElement('a');
    downloadLink.style.display = 'none';
    downloadLink.href = url;
    downloadLink.setAttribute('download', filename);
    downloadLink.setAttribute('target', '_blank');
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

function createExcelSheet(data, options, name) {
    const sheets = {};
    sheets[name] = XLSX.utils.json_to_sheet(data, options);
    return {
        SheetNames: [name],
        Sheets: sheets
    };
}

function string2ArrayBuffer(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
        // eslint-disable-next-line no-bitwise
        view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
}

export default {
    downloadFileBlob,
    downloadFileUrl,
    createExcelSheet,
    string2ArrayBuffer
};
