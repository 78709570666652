import axios from 'axios';
import constants from '~/utils/constants';
import { ApiTask, AxiosApiResponse } from './types';

interface ReassignPlannedTaskParams {
    taskId: string;
    driverId: string;
}

type ReassignPlannedTaskResponseData1 = ApiTask;

interface ReassignPlannedTaskResponseData2 {
    newTask?: ApiTask;
    schedulerTaskIds?: {
        clientId?: string;
        schedulerTaskId?: string;
    }[];
}

type ReassignPlannedTaskResponseData =
    | ReassignPlannedTaskResponseData1
    | ReassignPlannedTaskResponseData2;

type ReassignPlannedTaskResponse =
    AxiosApiResponse<ReassignPlannedTaskResponseData>;

interface ShiftRouteParams {
    routeId1: string;
    driverId1ClientId: string;
    driverId2: string;
    routeDate: string;
}

interface AppendRouteParams {
    routeId: string;
    routeDate: string;
    driverId: string;
    vehicleId: string;
    type: string;
}

export type AppendRouteResponseData = {
    data: {
        routeId: string;
        driverId: string;
        schedulerTaskId: string;
        tasks: string[];
        vehicleId: string;
    };
};

type AppendRouteResponse = AxiosApiResponse<AppendRouteResponseData>;

type ShiftRouteResponseData1 = ApiTask;

interface ShiftRouteResponseData2 {
    newTask?: ApiTask;
    schedulerTaskIds?: {
        clientId?: string;
        schedulerTaskId?: string;
    }[];
}

type ShiftRouteResponseData = ShiftRouteResponseData1 | ShiftRouteResponseData2;

type ShiftRouteResponse = AxiosApiResponse<ShiftRouteResponseData>;

interface ReassignTasksParams {
    taskIds: string[];
    driverId: string;
    routeId?: string;
    clientId?: string;
}

interface ReassignTasksResponseData {
    schedulerTaskIds?: {
        clientId?: string;
        schedulerTaskId?: string;
    }[];
    taskIds?: string[];
}

type ReassignTasksResponse = AxiosApiResponse<ReassignTasksResponseData>;

/**
 * @category API
 */
export class MultiPlanEditApi {
    static reassignPlannedTask({
        taskId,
        driverId
    }: ReassignPlannedTaskParams): Promise<ReassignPlannedTaskResponse> {
        if (!taskId) {
            return Promise.reject('Missing task ID');
        }
        if (!driverId) {
            return Promise.reject('Missing target driver ID');
        }
        return axios.patch(
            `/task/${taskId}?action=move_route`,
            {
                driverId
            },
            {
                headers: {
                    [constants.requestHeaders.WISE_CLIENT_ID]: ''
                }
            }
        );
    }

    static undoScheduleAction({
        clientId,
        schedulerId
    }: {
        clientId: string;
        schedulerId: string;
    }): Promise<AxiosApiResponse> {
        if (!clientId) {
            return Promise.reject('Missing client ID');
        }

        if (!schedulerId) {
            return Promise.reject('Missing scheduler ID');
        }

        return axios.post(
            '/schedules/undo',
            {
                schedulerId
            },
            {
                headers: {
                    [constants.requestHeaders.WISE_CLIENT_ID]: clientId
                }
            }
        );
    }

    static shiftRoute({
        routeId1,
        driverId1ClientId,
        driverId2,
        routeDate
    }: ShiftRouteParams): Promise<ShiftRouteResponse> {
        if (!routeId1) {
            return Promise.reject('Missing source route ID');
        }
        if (!driverId1ClientId) {
            return Promise.reject('Missing source client ID');
        }
        if (!driverId2) {
            return Promise.reject('Missing target driver ID');
        }
        if (!routeDate) {
            return Promise.reject('Missing route date');
        }

        return axios.post(
            '/schedules/evaluate?action=move_route',
            {
                routeId1,
                driverId1ClientId,
                driverId2,
                routeDate
            },
            {
                headers: {
                    [constants.requestHeaders.WISE_CLIENT_ID]: ''
                }
            }
        );
    }

    static appendRoute({
        routeDate,
        type,
        routeId,
        driverId,
        vehicleId,
        ...rest
    }: AppendRouteParams): Promise<AppendRouteResponse> {
        if (!routeId) {
            return Promise.reject('Missing route ID');
        }
        if (!type) {
            return Promise.reject('Missing type');
        }
        if (!driverId) {
            return Promise.reject('Missing target driver ID');
        }
        if (!vehicleId) {
            return Promise.reject('Missing target vehicle ID');
        }
        if (!routeDate) {
            return Promise.reject('Missing route date');
        }

        return axios.post(
            '/schedules/evaluate?action=append&accept',
            {
                date: routeDate,
                type,
                routeId,
                driverId,
                vehicleId,
                ...rest
            },
            {
                headers: {
                    [constants.requestHeaders.WISE_CLIENT_ID]: ''
                }
            }
        );
    }

    static reassignTasks({
        taskIds,
        driverId,
        routeId,
        clientId
    }: ReassignTasksParams): Promise<ReassignTasksResponse> {
        if (!taskIds) {
            return Promise.reject('Missing task IDs');
        }
        if (!Array.isArray(taskIds)) {
            return Promise.reject('task IDs must be array');
        }
        if (!taskIds.length) {
            return Promise.reject('task IDs array cannot be empty');
        }
        if (!driverId) {
            return Promise.reject('Missing target driver ID');
        }
        return axios.patch(
            '/tasks?action=move_route',
            {
                taskIds,
                driverId,
                routeId,
                clientId
            },
            {
                headers: {
                    [constants.requestHeaders.WISE_CLIENT_ID]: ''
                }
            }
        );
    }
}
