import axios from 'axios';

import { AxiosApiResponse, IApiResponse } from './types';
import dateUtils from '~/utils/date-utils';
import constants from '~/utils/constants';
import generalUtils from '~/utils/general-utils';
import { ApiRoute } from './types/ApiRoute';
import { DateConstructorInput } from '~/utils/date-utils-converters';

interface CloseAllRoutesParams {
    /* Date in ISO format */
    routeDate?: string;
}

interface UncloseAllRoutesParams {
    /* Date in ISO format */
    routeDate?: string;
}

interface CloseAllRoutesResponseData {
    modifiedRoutesCount?: number;
}

interface UncloseAllRoutesResponseData {
    modifiedRoutesCount?: number;
}

/**
 * Implemenations of API methods under the `/route` or `/routes` paths
 *
 * @category API
 */
export class RoutesApi {
    /**
     * Path of the single-route API endpoint
     */
    private static readonly routePath = '/route';

    /**
     * Path of the multi-route API endpoint
     */
    private static readonly routesPath = '/routes';

    static getRouteById(routeId: string): Promise<AxiosApiResponse<ApiRoute>> {
        if (!routeId) {
            return Promise.reject('missing route id');
        }

        return axios.get(`${this.routePath}/${routeId}`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }

    /**
     * Sends a PATCH /route/:routeId request to unassign a currently dispatched route.
     *
     * @param {string} routeId the route id
     */
    static unassignRouteById(routeId: string): Promise<AxiosApiResponse> {
        if (!routeId || !generalUtils.isValidUUID(routeId)) {
            return Promise.reject(new Error('Missing or invalid route id'));
        }

        return axios.patch<IApiResponse>(
            `${this.routePath}/${routeId}?action=unassign`
        );
    }

    static closeAllRoutes(
        inputRouteDate: DateConstructorInput
    ): Promise<AxiosApiResponse<CloseAllRoutesResponseData>> {
        if (!inputRouteDate) {
            return Promise.reject('missing date');
        }

        const params: CloseAllRoutesParams = {
            routeDate:
                dateUtils.convertToISODateOnly(inputRouteDate) ?? undefined
        };

        return axios.patch(`${this.routesPath}?action=close`, params, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }

    static uncloseAllRoutes(
        inputRouteDate: DateConstructorInput
    ): Promise<AxiosApiResponse<UncloseAllRoutesResponseData>> {
        if (!inputRouteDate) {
            return Promise.reject('missing date');
        }

        const params: UncloseAllRoutesParams = {
            routeDate:
                dateUtils.convertToISODateOnly(inputRouteDate) ?? undefined
        };

        return axios.patch(`${this.routesPath}?action=unclose`, params, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }
}
