import React from 'react';

import { Header } from '../Header';
import { ZoneList } from '../ZoneList';
import { MapSection } from '../MapSection';
import './ZoneManagementPageLayout.scss';
import { useGetDriverList, useGetVehicles } from '~/hooks';
import { useZoneAddModal } from '~/hooks/useZoneAddModal';

function ZoneManagementPageLayout(): JSX.Element {
    // load driver and vehicle data into Redux if not yet there
    useGetVehicles();
    useGetDriverList();

    const { ZoneAddModal } = useZoneAddModal();

    return (
        <div
            className="zonemanagement _fd-column _ai-center"
            data-testid="zone-management"
        >
            <Header />
            <div
                className="zonemanagement-body _d-flex"
                data-testid="zone-management-body"
            >
                <ZoneList />
                <MapSection />
            </div>
            <ZoneAddModal />
        </div>
    );
}

export default ZoneManagementPageLayout;
