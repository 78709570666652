import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { VehicleApi } from '~/api/VehicleApi';
import { selectVehicles, setVehicles } from '~/reducers/vehiclesSlice';

export const useGetVehicles = () => {
    const [vehiclesData, setVehiclesData] = useState({});
    const dispatch = useDispatch();
    const selectedVehicles = useSelector(selectVehicles);

    const isVehiclesEmpty = _.isEmpty(selectedVehicles);

    const getVehiclesData = async () => {
        try {
            const dataResponse = await VehicleApi.get();
            const {
                data: { data: vehicles }
            } = dataResponse;
            const vehiclesById = vehicles.reduce((acc, vehicle) => {
                acc[vehicle.id] = vehicle;
                return acc;
            }, {});
            setVehiclesData(vehiclesById);
            dispatch(setVehicles(vehiclesById));
        } catch (e) {
            console.error(e);
            setVehiclesData({});
            dispatch(setVehicles({}));
        }
    };

    useEffect(() => {
        if (!isVehiclesEmpty) return setVehiclesData(selectedVehicles);
        getVehiclesData();
    }, []);

    return { vehiclesData };
};
