import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

export const taskUploadSlice = createSlice({
    name: 'taskUpload',
    initialState: {
        isOpenTaskUploadModal: false
    },
    reducers: {
        setIsOpenTaskUploadModal: (state, action) => {
            state.isOpenTaskUploadModal = action.payload;
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {
                isOpenTaskUploadModal: false
            };
        });
    }
});

export const { setIsOpenTaskUploadModal } = taskUploadSlice.actions;
export const selectIsOpenTaskUploadModal = (state) =>
    state.taskUpload.isOpenTaskUploadModal;
export default taskUploadSlice.reducer;
