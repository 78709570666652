import React from 'react';
import {
    Button,
    Detail,
    MarkerPopup,
    RouteCardMetric,
    RouteCardTitle
} from '~/ui';

const getLabels = (stopLabels) => {
    if (stopLabels.length === 0) return;
    const maxLabelsDisplayed = 2;
    const displayedLabels =
        stopLabels.length > maxLabelsDisplayed
            ? stopLabels.slice(0, maxLabelsDisplayed)
            : stopLabels;
    return (
        <>
            {displayedLabels.map((label, i) => {
                const key = `label_${i}`;
                return (
                    <div key={key} className="label" data-testid="label">
                        {label}
                    </div>
                );
            })}
            {stopLabels.length > maxLabelsDisplayed && (
                <div className="label" data-testid="label">{`+${
                    stopLabels.length - maxLabelsDisplayed
                }`}</div>
            )}
        </>
    );
};

const getHeaderContent = ({
    testId,
    stopColor,
    stopText,
    stopName,
    stopIcon = 'clockFill'
}) => {
    return (
        <>
            {stopColor && stopText && (
                <RouteCardMetric
                    icon={stopIcon}
                    iconSize="m"
                    iconColor={stopColor}
                    value={stopText}
                    valueColor={stopColor}
                    data-testid={testId && `${testId}-status`}
                />
            )}
            <RouteCardTitle
                title={stopName}
                data-testid={testId && `${testId}-title`}
            />
        </>
    );
};

const getBodyContent = ({
    testId,
    address,
    etaTime,
    labels,
    stopDetailButtonText,
    onClickStopDetailButton = () => {}
}) => {
    return (
        <>
            <Detail
                iconName="pin"
                iconSize="m"
                iconColor="galaxy-500"
                mainContent={address}
                className="_text-3 _d-flex _ai-flex-start"
                dataTestId={testId && `${testId}-address`}
            />
            <Detail
                iconName="clock"
                iconSize="m"
                iconColor="galaxy-500"
                mainContent={etaTime}
                className="_text-3 _d-flex _ai-flex-end"
                dataTestId={testId && `${testId}-time`}
            />
            {labels.length > 0 && (
                <Detail
                    iconName="union"
                    iconSize="m"
                    iconColor="galaxy-500"
                    className="_text-3 _d-flex _ai-flex-start"
                    dataTestId={testId && `${testId}-labels`}
                    mainContent={
                        <div
                            className="_d-flex _fw-wrap _p-relative"
                            style={{
                                rowGap: '0.8rem',
                                columnGap: '0.8rem',
                                cursor: 'default'
                            }}
                        >
                            {getLabels(labels)}
                        </div>
                    }
                />
            )}

            <Button
                variant="secondary"
                className="_jc-center _ai-center"
                sx={{ display: 'flex' }}
                onClick={onClickStopDetailButton}
                data-testid={testId && `${testId}-button`}
            >
                <span>{stopDetailButtonText}</span>
            </Button>
        </>
    );
};

/**
 * Creates a `MarkerPopup` component
 * @method makeMarkerPopup
 * @private
 * @memberof module:utils/map/popupMaker
 * @param {*} payload - the stop data
 * @param {*} emittedEventHandler - the map emitted event handler
 * @returns {MarkerPopup}
 */
export const makeMarkerPopup = (payload, emittedEventHandler) => {
    const { className, location, stopData, testId } = payload;
    return (
        <MarkerPopup
            className={className}
            headerContent={getHeaderContent({ ...stopData, testId })}
            bodyContent={getBodyContent({ ...stopData, testId })}
            bodyClassName="_fd-column"
            emittedEventHandler={emittedEventHandler}
            lat={location.lat}
            lng={location.lng}
            data-testid={testId}
        />
    );
};
