import axios, { AxiosResponse } from 'axios';

import generalUtils from '~/utils/general-utils';
import dateUtils from '~/utils/date-utils';
import constants from '~/utils/constants';
import { BaseConfig } from './types/Base';
import {
    ApiAssignment,
    ApiTask,
    ApiTaskScheduler,
    AxiosApiResponse,
    DeliveryTask,
    ExtentParams,
    IApiResponse,
    PaginationMetadata,
    PaginationParams,
    PickupTask,
    TaskStatus
} from './types';
import { DateConstructorInput } from '~/utils/date-utils-converters';

/**
 * GET /tasks payload type
 * @category API
 */
interface GetTasksParams extends PaginationParams, ExtentParams {
    /**
     * The date of the tasks we want to fetch
     */
    date?: DateConstructorInput;

    /**
     * A key, comma-separated list of keys, or array of keys designating related entities that
     * should be loaded with the task
     *
     * @example
     * // return each `Task` with the related pickupLocations and deliveryLocations
     * extent: 'pickupLocation,deliveryLocation'
     */
    extent?: string | string[];

    status?: TaskStatus[];
}

interface GetTasksConfig extends BaseConfig {
    params?: Omit<GetTasksParams, 'date'> & {
        date?: string;
    };
}

interface TaskMetrics {
    canceled?: number;
    completed?: number;
    dispatched?: number;
    planned?: number;
    total?: number;
    unassigned?: number;
}

interface StartSchedulerParams {
    date?: string;
    rerun?: boolean;
}

interface StartSchedulerArgs extends Omit<StartSchedulerParams, 'date'> {
    date?: DateConstructorInput;
}

type StartSchedulerResponse = AxiosApiResponse<ApiTaskScheduler>;

type StopSchedulerResponse = AxiosApiResponse<ApiTaskScheduler>;

export type AddOrUpdateTaskData = DeepPartial<
    ApiTask & {
        deliveryEuid?: string;
        pickupEuid?: string;
    }
>;

interface AddTaskResponseFailData {
    index: number;
    error: string;
}

interface AddTaskResponseData {
    /* Array of successfully added task ids */
    success: string[];
    fail: AddTaskResponseFailData[];
}

type AddTaskResponse = AxiosApiResponse<AddTaskResponseData>;

type UpdateResponse = AxiosApiResponse<ApiTaskScheduler>;

type DeleteTaskResponse = AxiosApiResponse<ApiTask>;

interface DeleteTasksResponseData {
    deletedCount?: number;
}

type DeleteTasksResponse = AxiosApiResponse<DeleteTasksResponseData>;

interface TaskLockTasksResponseData {
    modifiedTasksCount: number;
}

type TaskLockTasksResponse = AxiosApiResponse<TaskLockTasksResponseData>;

interface OrderLockTasksResponseData {
    modifiedTasksCount: number;
}

type OrderLockTasksResponse = AxiosApiResponse<OrderLockTasksResponseData>;

interface UnlockTasksResponseData {
    modifiedTasksCount: number;
}

type UnlockTasksResponse = AxiosApiResponse<UnlockTasksResponseData>;

interface UnassignTaskResponseData {
    message: string;
}

interface JoinTasksParams {
    /**
     * The ID of the base task
     */
    id: string;

    /**
     * The ID of the task to join into the base task
     */
    joinedTask: string;
}

interface SplitTaskResponseData {
    delivery?: DeliveryTask;
    pickup?: PickupTask;
}

type UnassignTaskResponse = AxiosApiResponse<UnassignTaskResponseData>;

/* eslint-disable camelcase */
interface GetMetricsParams {
    date?: DateConstructorInput;
    start_date?: DateConstructorInput;
    end_date?: DateConstructorInput;
}
/* eslint-enable camelcase */

/* eslint-disable camelcase */
interface GetMetricsConfig extends BaseConfig {
    params?: Omit<GetMetricsParams, 'date' | 'start_date' | 'end_date'> & {
        date?: string[];
        start_date?: string;
        end_date?: string;
    };
}
/* eslint-enable camelcase */

interface GetMetricsResponseData {
    [key: string]: TaskMetrics;
}

type GetMetricsResponse = AxiosApiResponse<GetMetricsResponseData>;

interface GeneratePairingsParams {
    taskIds?: string[];
    unassignedTaskIds?: string[];
}

interface GenerateDepotPairsParams {
    taskIds?: string[];
}

interface GenerateDepotPairsResponseData {
    tasksModifiedCount?: number;
    unacceptedTaskIds?: string[];
}

type GenerateDepotPairsResponse =
    AxiosApiResponse<GenerateDepotPairsResponseData>;

/**
 * GET /tasks/{id} payload type
 * @category API
 */
type GetTaskParams = ExtentParams;

/**
 * Supported suggest metrics used for sorting suggestions
 * @category API
 */
export enum TasksApiErrors {
    MISSING_DRIVER_ID = 'Missing driverId parameter',
    MISSING_DATE = 'Missing date parameter',
    MISSING_TASKIDS = 'Missing taskIds parameter',
    INVALID_DRIVER_ID = 'Invalid driverId parameter, must be a UUID string',
    INVALID_DATE = 'Invalid date parameter, must be ISO-formatted string (eg yyyy-mm-dd)',
    INVALID_TASKIDS = 'Invalid taskIds parameter, must be an array of UUID strings'
}

/**
 * Implementations of API methods under the `/task` or `/tasks` paths
 *
 * @category API
 */
export default class TasksApi {
    /**
     * Path of the single task api endpoint
     */
    private static readonly taskPath = '/task';

    /**
     * Path of the multi-task api endpoint
     */
    private static readonly tasksPath = '/tasks';

    /**
     * Sends a GET /tasks request with the provided params
     */
    static get(
        params: GetTasksParams = {
            page: 0,
            limit: 1,
            date: undefined,
            extent: undefined,
            status: undefined
        }
    ): Promise<AxiosApiResponse<TaskMetrics, PaginationMetadata>> {
        const config: GetTasksConfig = {
            params: {
                ...params,
                date: params.date
                    ? dateUtils.convertToISODateOnly(params.date) ?? undefined
                    : undefined
            },
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        };

        return axios.get(this.tasksPath, config);
    }

    static getTask(
        id: string,
        params: GetTaskParams = {}
    ): Promise<AxiosApiResponse<ApiTask>> {
        return axios.get(`${this.taskPath}/${id}`, {
            params,
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }

    static getTaskAssignments(
        id: string
    ): Promise<AxiosApiResponse<ApiAssignment[], PaginationMetadata>> {
        return axios.get(`${this.taskPath}/${id}/assignments`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }

    static getMetrics(
        params: GetMetricsParams = {}
    ): Promise<GetMetricsResponse> {
        const isoDate = params.date
            ? dateUtils.convertToISODateOnly(params.date) ?? undefined
            : undefined;
        const isoStartDate = params.start_date
            ? dateUtils.convertToISODateOnly(params.start_date) ?? undefined
            : undefined;
        const isoEndDate = params.end_date
            ? dateUtils.convertToISODateOnly(params.end_date) ?? undefined
            : undefined;
        const config: GetMetricsConfig = {
            params: {
                ...params,
                date: isoDate ? [isoDate] : undefined,
                start_date: isoStartDate,
                end_date: isoEndDate
            },
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        };

        return axios.get(`${this.tasksPath}/metrics`, config);
    }

    static startScheduler(
        args: StartSchedulerArgs
    ): Promise<StartSchedulerResponse> {
        const dateArg = args.date;
        if (!dateArg) {
            return Promise.reject('missing date');
        }
        const params: StartSchedulerParams = {
            ...args,
            date: dateUtils.convertToISODateOnly(dateArg) ?? undefined
        };

        return axios.post(`${this.tasksPath}?action=lr_schedule`, params, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }

    static stopScheduler(): Promise<StopSchedulerResponse> {
        return axios.post(
            `${this.tasksPath}?action=lr_stop`,
            {},
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }

    static joinTasks({
        id,
        joinedTask
    }: JoinTasksParams): Promise<AxiosApiResponse<ApiTask>> {
        return axios.post(
            `${this.taskPath}/${id}?action=join`,
            { joinedTask },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }

    static splitTask(
        id: string
    ): Promise<AxiosApiResponse<SplitTaskResponseData>> {
        return axios.post(
            `${this.taskPath}/${id}?action=split`,
            {},
            { headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' } }
        );
    }

    /**
     * @returns api response with pairingRunId
     */
    static generatePairings({
        taskIds,
        unassignedTaskIds
    }: GeneratePairingsParams): Promise<AxiosApiResponse<string>> {
        if (!taskIds?.length) return Promise.reject('missing task ids');

        return axios.post(
            `${this.tasksPath}/pairings?action=generate`,
            { taskIds, unassignedTaskIds },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }

    static generateDepotPairs({
        taskIds
    }: GenerateDepotPairsParams): Promise<GenerateDepotPairsResponse> {
        if (!taskIds?.length) return Promise.reject('missing task ids');
        return axios.post(
            `${this.tasksPath}/pairings?action=depot_pair`,
            { taskIds },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }

    static addTask(payload: AddOrUpdateTaskData): Promise<AddTaskResponse> {
        return axios.put(`${this.taskPath}`, payload, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }

    static update(
        id: string,
        payload: Record<string, unknown>
    ): Promise<UpdateResponse> {
        return axios.patch(
            `${this.taskPath}/${id}`,
            { ...payload },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }

    static deleteTask(id: string): Promise<DeleteTaskResponse> {
        return axios.delete(`${this.taskPath}/${id}`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }

    static deleteTasks(taskIds: string[]): Promise<DeleteTasksResponse> {
        return axios.delete(`${this.tasksPath}`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' },
            data: { taskIds }
        });
    }

    /**
     * Locks all tasks identified by the provided `taskIds`
     *
     * @param {string[]} taskIds
     * @returns {Promise<TaskLockTasksResponse>}
     */
    static taskLockTasks(taskIds: string[]): Promise<TaskLockTasksResponse> {
        return axios.patch(`${this.tasksPath}?action=lock`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' },
            taskIds
        });
    }

    /**
     * Order locks all tasks identified by the provided `taskIds`
     *
     * @param {string[]} taskIds
     * @returns {Promise<OrderLockTasksResponse>}
     */
    static orderLockTasks(taskIds: string[]): Promise<OrderLockTasksResponse> {
        return axios.patch(`${this.tasksPath}?action=lock_order`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' },
            taskIds
        });
    }

    /**
     * Unlocks all tasks identified by the provided `taskIds`
     *
     * @param {string[]} taskIds
     * @returns {Promise<UnlockTasksResponse>}
     */
    static unlockTasks(
        taskIds: string[]
    ): Promise<AxiosResponse<UnlockTasksResponse>> {
        return axios.patch(`${this.tasksPath}?action=unlock`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' },
            taskIds
        });
    }

    static unassignTask(
        taskId: string
    ): Promise<AxiosResponse<UnassignTaskResponse>> {
        return axios.patch(`${this.taskPath}/${taskId}?action=unassign`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }

    /**
     * Bulk unassign specific tasks
     *
     * @param {string} taskIds - the task IDs
     * @returns {Promise<ApiTaskScheduler>}
     */
    static unassignTasks(
        taskIds: string[]
    ): Promise<AxiosApiResponse<ApiTaskScheduler>> {
        if (!taskIds) {
            return Promise.reject(TasksApiErrors.MISSING_TASKIDS);
        }

        const validIds =
            Array.isArray(taskIds) &&
            !!taskIds.length &&
            taskIds.every((taskId) => generalUtils.isValidUUID(taskId));
        if (!validIds) {
            return Promise.reject(TasksApiErrors.INVALID_TASKIDS);
        }

        return axios.patch<IApiResponse>(
            `${this.tasksPath}?action=unassign`,
            { taskIds },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }

    /**
     * Bulk cancel specific tasks
     *
     * @param {string[]} taskIds - the task IDs
     * @returns {Promise<ApiTaskScheduler>}
     */
    static cancelTasks(
        taskIds: string[]
    ): Promise<AxiosApiResponse<ApiTaskScheduler>> {
        if (!taskIds) {
            return Promise.reject(TasksApiErrors.MISSING_TASKIDS);
        }

        const validIds =
            Array.isArray(taskIds) &&
            !!taskIds.length &&
            taskIds.every((taskId) => generalUtils.isValidUUID(taskId));
        if (!validIds) {
            return Promise.reject(TasksApiErrors.INVALID_TASKIDS);
        }

        return axios.patch(
            `${this.tasksPath}?action=cancel`,
            { taskIds },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }

    /**
     * Bulk reassign specific tasks
     *
     * @param {string[]} taskIds - the task IDs
     * @param {string} driverId - the driver ID to reassign tasks to
     * @returns {Promise<ApiTaskScheduler>}
     */
    static reassignTasks(
        taskIds: string[],
        driverId: string
    ): Promise<AxiosApiResponse<ApiTaskScheduler>> {
        if (!taskIds) {
            return Promise.reject(TasksApiErrors.MISSING_TASKIDS);
        }

        const validIds =
            Array.isArray(taskIds) &&
            !!taskIds.length &&
            taskIds.every((taskId) => generalUtils.isValidUUID(taskId));
        if (!validIds) {
            return Promise.reject(TasksApiErrors.INVALID_TASKIDS);
        }

        if (!driverId) {
            return Promise.reject(TasksApiErrors.MISSING_DRIVER_ID);
        }

        if (!generalUtils.isValidUUID(driverId)) {
            return Promise.reject(TasksApiErrors.INVALID_DRIVER_ID);
        }

        return axios.patch(
            `${this.tasksPath}?action=reassign`,
            { taskIds, driver: driverId },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }

    /**
     * Accepts assigned tasks and dispatches driver
     *
     * @param {string} driverId - the driver ID
     * @returns {Promise<ApiTaskScheduler>}
     */
    static acceptAssignedTasks(
        driverId: string
    ): Promise<AxiosApiResponse<ApiTaskScheduler>> {
        if (!driverId) {
            return Promise.reject(TasksApiErrors.MISSING_DRIVER_ID);
        }

        if (!generalUtils.isValidUUID(driverId)) {
            return Promise.reject(TasksApiErrors.INVALID_DRIVER_ID);
        }

        return axios.post<IApiResponse>(
            `${this.tasksPath}?action=accept_assigned`,
            { driverId },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }

    /**
     * Accepts assigned tasks and dispatches driver
     *
     * @param {string} date - an ISO-formatted date string (eg yyyy-mm-dd)
     * @returns {Promise<ApiTaskScheduler>}
     */
    static dispatchAllRoutes(
        date: string
    ): Promise<AxiosApiResponse<ApiTaskScheduler>> {
        if (!date) {
            return Promise.reject(TasksApiErrors.MISSING_DATE);
        }

        if (
            !dateUtils.checkIsDateStringValidDate(date) ||
            !dateUtils.checkIsYearMonthDayFormat(date)
        ) {
            return Promise.reject(TasksApiErrors.INVALID_DATE);
        }

        return axios.post<IApiResponse>(
            `${this.tasksPath}?action=lr_accept`,
            { date },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }
}
