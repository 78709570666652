import _ from 'lodash';

const constants = {
    socketReserved: {
        CONNECT: 'connect',
        CONNECT_ERROR: 'connect_error',
        ERROR: 'error'
    },
    socketCustom: {
        JOIN_ROOMS: 'interstellar-room-join',
        LEAVE_ROOMS: 'interstellar-room-leave',
        MESSAGE: 'message',
        SOLUTION: 'web-solution',
        UNPLANNED_TASKS: 'web-unplanned-tasks',
        CEP_RESULTS: 'cep-results',
        CUSTOMER_SEARCH: 'customer-search',
        CUSTOMER_SEARCH_ERROR: 'customer-search-error',
        DIRECT_ROUTE_IMPACT: 'direct-route-impact',
        COMMAND_NOT_FOUND: 'command-not-found',
        DRIVERS: 'drivers',
        METRICS: 'metrics',
        PAIRING_EXECUTED: 'pairing-executed',
        ON_DEMAND_TASK_ADDED: 'on-demand-task-added',
        ON_DEMAND_TASK_ADDED_BULK: 'on-demand-task-added-bulk',
        ON_DEMAND_TASK_REMOVED: 'on-demand-task-removed',
        UPLOAD: 'upload'
    },
    socketErrors: {
        JWT_EXPIRED: 'jwt expired'
    },
    entityStates: {
        UNPLANNED: 'unplanned'
    },
    taskStatus: {
        UNASSIGNED: 0,
        DISPATCHED: 1,
        IN_PROGRESS: 2,
        COMPLETED: 3,
        CANCELLED: 4,
        PLANNED: 5
    },
    inventoryItemStatus: {
        UNCONFIRMED: 'unconfirmed',
        CONFIRMED: 'confirmed',
        CANCELLED: 'cancelled',
        PARTIAL: 'partial',
        TRY_LATER: 'try-later'
    },
    taskTypes: {
        PICKUP: 'pickup',
        DELIVERY: 'delivery',
        PICKUP_LOCATION: 'pickupLocation',
        DELIVERY_LOCATION: 'deliveryLocation',
        PICKUP_INVENTORY: 'pickupInventory',
        DELIVERY_INVENTORY: 'deliveryInventory',
        PICKUP_WINDOW: 'pickupWindow',
        DELIVERY_WINDOW: 'deliveryWindow',
        DEPOT: 'depot'
    },
    mapMarkerModes: {
        STOPS_CLUSTERS: 'stops-clusters',
        ROUTES: 'routes'
    } as const,
    mapDrawingModes: {
        POLYGON: 'polygon'
    },
    mapMarkerLabels: {
        PARKING: 'P'
    },
    mapRouteModes: {
        EMPTY: 'empty',
        PLAN: 'plan',
        DISPATCHED: 'dispatched',
        COMPLETED: 'completed'
    },
    mapChildEvents: {
        CLUSTER_MOUSEENTER: 'cluster-marker-mouseenter',
        CLUSTER_MOUSELEAVE: 'cluster-marker-mouseleave',
        CLUSTER_DRAGSTART: 'cluster-marker-dragstart',
        CLUSTER_CLICK: 'cluster-marker-click',
        CLUSTER_MOUSEUP: 'cluster-marker-mouseup',
        CHANGE_MARKER_MODE: 'change-marker-mode',
        ROUTE_MOUSEENTER: 'route-marker-mouseenter',
        ROUTE_MOUSELEAVE: 'route-marker-mouseleave',
        POPUP_MOUSEENTER: 'popup-mouseenter',
        POPUP_MOUSELEAVE: 'popup-mouseleave',
        STOP_MOUSEENTER: 'stop-marker-mouseenter',
        STOP_DRAGSTART: 'stop-marker-dragstart',
        STOP_MOUSEUP: 'stop-marker-mouseup'
    },
    mapEvents: {
        POLYGON_COMPLETE: 'polygoncomplete'
    },
    mapLibraries: {
        DRAWING: 'drawing'
    },
    mapOptionSettings: {
        MAP_MODE_DEFAULT: 'default',
        MAP_MODE_SIMPLE: 'simple',
        MAP_MODE_STATIC: 'static',
        GESTURE_HANDLING_AUTO: 'auto',
        GESTURE_HANDLING_OFF: 'none',
        DEFAULT_GOOGLE_MAP_KEY: 'AIzaSyA8LK2FU5VJ1ugpcfQHyIiDLkUQ270RIoc',
        DEFAULT_ZOOM: 14,
        DEFAULT_CENTER: {
            lat: 42.3884516,
            lng: -71.1343216
        },
        MAX_ZOOM: 18,
        MIN_ZOOM: 3,
        SELECTED_DRAWER_POINT_OFFSET: 0.03,
        SELECTED_DRAWER_CENTER_PAN: {
            x: -150, // half the width of 1 open route drawer
            y: -56 // half the height of navbar and actionbar
        }
    },
    url: {
        ROOT: '/',
        COST_CONFIGURATION: '/cost-configuration',
        CUSTOMER_DETAILS: '/customer-management/customer-details',
        CUSTOMER_MANAGEMENT: '/customer-management',
        DOWNLOADS: '/downloads',
        DRIVER_BOOKING: '/driver-booking',
        DRIVER_MANAGEMENT_DATE_AGNOSTIC: '/driver-vehicle-management',
        DRIVER_MANAGEMENT: '/driver-management',
        HOME: '/home',
        LOGIN: '/login',
        LOGOUT: '/logout',
        MAP: '/map',
        NOTIFICATION_MANAGEMENT: '/notification-management',
        OPTIMIZATION_CONFIGURATION: '/optimization-configuration',
        REPORTS: '/reports',
        SETTINGS: '/settings',
        TASK_MANAGEMENT: '/task-management',
        TERRITORY_PLANNER: '/territory-planner',
        UNAUTHORIZED: '/401',
        USERS_AND_PERMISSIONS: '/users-and-permissions',
        WORKFLOW: '/workflow',
        ZONE_MANAGEMENT: '/zone-management'
    },
    driverPhase: {
        Initial: 'Initial', // no data is available
        DataGathered: 'DataGathered', // estimated count available
        InProgress: 'InProgress', // confirmed < estimated
        CompletedNotMarked: 'CompletedNotMarked', // confirmed === estimated but not yet clicked
        Marked: 'Marked' // marked
    },
    taskPhase: {
        Initial: 'Initial', // no data is present
        DataGathered: 'DataGathered', // received count is present
        Marked: 'Marked' // marked
    },
    routePhase: {
        Initial: 'Initial', // not all previous steps are marked
        CanBeClicked: 'CanBeClicked', // both driver and task are marked
        Scheduling: 'Scheduling', // scheduler is running
        Marked: 'Marked', // marked
        InitialNoDriverAccess: 'InitialNoDriverAccess',
        CanBeClickedNoPlanAccess: 'CanBeClickedNoPlanAccess',
        InitialNoDriverOrPlanAccess: 'InitialNoDriverOrPlanAccess'
    },
    bookingNotifications: {
        COMPONENT_NAME: 'booking',
        TYPE: 'push_notification'
    },
    bookingStatus: {
        PENDING: 'pending',
        CONFIRMED: 'confirmed',
        DECLINED: 'declined',
        CANCELED: 'canceled',
        WITHDRAWN: 'withdrawn'
    },
    userPermissionFeatures: {
        DRIVER_BOOKING: 'driver_booking',
        DRIVER_MANAGEMENT: 'driver_management',
        PLANNING: 'planning',
        USER_MANAGEMENT: 'user_management',
        TASK_MANAGEMENT: 'task_management',
        MOBILE_MANAGER: 'mobile_manager',
        MOBILE_MERCHANDISER: 'mobile_merchandiser'
    },
    userPermissionRoles: {
        EDITOR: 'editor',
        NO_ACCESS: 'no_access',
        VIEWER: 'viewer'
    },
    timings: {
        SET_CONTENT: 300,
        TEXT_DEBOUNCE: 300,
        MARKER_MOVE_THROTTLE: 50,
        MAP_CHANGE: 125,
        MESSAGE_CHANGE: 3000,
        GET_WEB_INTERVAL: 60000,
        ROUTE_CARD_CLICK: 1000,
        PROCESS_INDICATOR: 500,
        COURIER_SCHEDULER_INTERVAL: 2000,
        SESSION_REFRESH_INTERVAL: 420000,
        IGNORE_CEP_RESULT: 5000,
        IGNORE_DUPLICATE_FETCH: 1000
    },
    productTypes: {
        SCHEDULER: 'Scheduler',
        SOLAR: 'Solar',
        INTERSTELLAR: 'Interstellar'
    },
    localStorageKeys: {
        MCW_APP_INIT: 'mcwAppInit',
        ENABLE_FEATURE_FLAGS: 'enableFeatureFlags'
    },
    workflowEvents: {
        DRIVER_BOOKING_COMPLETED: 'driver_booking_completed',
        DRIVER_BOOKING_UNCOMPLETED: 'driver_booking_uncompleted',
        TASK_VERIFICATION_COMPLETED: 'task_verification_completed',
        TASK_VERIFICATION_UNCOMPLETED: 'task_verification_uncompleted',
        OPTIMIZATION_COMPLETED: 'optimization_completed',
        OPTIMIZATION_UNCOMPLETED: 'optimization_uncompleted',
        DRIVER_BOOKING_REMINDER_NOTIFICATION_SENT:
            'driver_booking_reminder_notification_sent'
    },
    vehicleTypes: {
        SIDE_LOADER: 'truck_sideload',
        END_LOADER: 'truck_endload'
    },
    violationsDefaultTitles: {
        EVERY_ROUTE_ASSIGNED: 'Violations.EveryRouteIsAssignedToADriver',
        EVERY_DRIVER_ASSIGNED: 'Violations.EveryDriverIsAssignedToARoute',
        TIME_WINDOWS: 'Violations.TimeWindows',
        ORDERS_PER_VEHICLE_TYPE: 'Violations.OrdersPerVehicleType',
        VEHICLE_CAPACITY: 'Violations.VehicleCapacity',
        ROUTE_DURATION: 'Violations.RouteDuration',
        PACKAGES_PER_ROUTE: 'Violations.PackagesPerRoute',
        DRIVER_SHIFT_TIMES: 'Violations.DriverShiftTimes',
        WEIGHT_PER_VEHICLE: 'Violations.WeightPerVehicle'
    },
    violationsViolatedTitles: {
        EVERY_ROUTE_ASSIGNED: 'Violations.RoutesWithNoDrivers',
        EVERY_DRIVER_ASSIGNED: 'Violations.DriversWithNoRoutes',
        TIME_WINDOWS: 'Violations.TimeWindowIsViolated',
        ORDERS_PER_VEHICLE_TYPE: 'Violations.VehicleTypeDoesNotMatchOrder',
        VEHICLE_CAPACITY: 'Violations.VehicleIsOverloadedVolume',
        ROUTE_DURATION: 'Violations.RouteExceedsMaxDuration',
        PACKAGES_PER_ROUTE: 'Violations.RouteExceedsMaxPackages',
        DRIVER_SHIFT_TIMES: 'Violations.DriverShiftTimesViolated',
        WEIGHT_PER_VEHICLE: 'Violations.VehicleIsOverloadedWeight'
    },
    violations: {
        DANGER: 'danger',
        WARNING: 'warning',
        ROUTE_VIOLATION: 'editImpact:Violations.RouteViolation',
        ROUTE_STOP_VIOLATION: 'editImpact:Violations.RouteStopViolation',
        DRIVER_VIOLATION: 'editImpact:Violations.DriverViolation'
    },
    sortOptionKeys: {
        LENGTH: 'sortLength',
        DURATION: 'sortDuration',
        STOP_COUNT: 'sortStopCount',
        ITEM_COUNT: 'sortItemCount',
        ROUTE_NAME: 'sortRouteName'
    },
    sortOrder: {
        ASC: 'asc',
        DESC: 'desc'
    },
    // TODO: Remove report template constants [Sabby: 08/02/2021]
    reportTemplate: {
        EXPORT: 'intelcomRoutePlan',
        DOWNLOAD: 'routePlanDefault'
    },
    schedulerKeys: {
        schedulerProgressDone: 'done',
        closeAllRoutes: 'close',
        uncloseAllRoutes: 'unclose',
        lockRoutes: 'lockRoutes',
        schedulerProgressError: 'error'
    },
    environments: {
        PROD: 'prod',
        UAT: 'uat',
        DEMO: 'demo',
        STAGE: 'stage',
        SANDBOX: 'sandbox',
        DEV: 'dev'
    },
    statusCodes: {
        CONFLICT: 409,
        UNPROCESSABLE: 422
    },
    requestHeaders: {
        WISE_CLIENT_ID: 'wise-client-id'
    },
    priorityCodes: {
        HIGH: 1,
        MEDIUM: 2,
        LOW: 3
    },
    filterOptionKeys: {
        HAS_GROUP: 'filterHasGroup',
        WITH_ISSUES: 'filterWithIssues',
        WITH_LATE_STOP: 'filterWithLateStop',
        WITH_STOP_RISK: 'filterWithStopRisk',
        WITH_FAILED_DELIVERY: 'filterWithFailedDelivery',
        WITH_CANCELED_DELIVERY: 'filterWithCanceledDelivery',
        ACTIVE_ROUTES: 'filterActiveRoutes',
        INACTIVE_ROUTES: 'filterInactiveRoutes'
    },
    liveStopMarkerStatus: {
        default: 'liveStopMarkerDefault',
        priority: 'liveStopMarkerPriority',
        canceled: 'liveStopMarkerCanceled'
    },
    onDemandDispatch: {
        API_RESPONSE_LIMIT: 150,
        CONTROL_DISPATCH_LIMIT: 5
    },
    apiErrorMessages: {
        // manual pair validation
        HAVE_DIFF_VEHICLE_IDS: 'Both tasks must have the same vehicleEid',
        // time windows validation
        PICKUP_DELIVERY_TIME_WINDOWS:
            'The start pickup time is after the end of delivery time.'
    },
    mapsDrawingStyles: {
        DRIVER_PATH_STROKE_WEIGHT: 4
    },
    reactQueryClientOptions: {
        default: {
            queries: {
                // retries on error responses
                retry: 1,
                // delay before retry (milliseconds)
                retryDelay: 5000,
                // refetch when user refocuses on browser window
                refetchOnWindowFocus: false,
                // how long to keep successful data fresh before refetching (milliseconds)
                staleTime: 0
            }
        },
        test: {
            queries: {
                // retries on error responses
                retry: false
            }
        }
    },
    reactQueryKeys: {
        ADDRESS_BY_ID: 'address_by_id',
        CUSTOM_COST_MATRIX: 'custom_cost_matrix',
        TASKS_ASSIGN: 'tasks/assign',
        TASKS_BULK_SUGGEST: 'tasks/bulk_suggest',
        TASKS: 'tasks',
        TASKS_METRICS: 'tasks/metrics',
        TASKS_PAIR_OPTIMAL: 'tasks/pair_optimal',
        TASKS_PAIR_MANUAL: 'tasks/pair_manual',
        TASKS_PAIR_DEPOT: 'tasks/pair_depot',
        USER_GROUPS: 'user_groups',
        ZONES: 'zones',
        ZONE_VEHICLE_ASSOCIATION: 'zones_vehicle_association',
        ZONES_CUSTOM_COST_CONFIG: 'zones_custom_cost_config'
    },
    stopsControl: {
        views: {
            RESEQUENCE: 'resequenceView',
            REASSIGN: 'reassignView',
            MAIN: 'mainView'
        }
    },
    processIndicator: {
        REASSIGN_STOP: 'reassign_stop',
        UNASSIGN_STOP: 'unassign_stop',
        RESEQUENCE_STOP: 'resequence_stop',
        REASSOCIATE_DRIVER_VEHICLE: 'reassociate_driver_vehicle',
        APPEND_ROUTE: 'append_route',
        OPTIMIZE_ROUTE: 'optimize_route',
        TASK_UPLOAD: 'task_upload',
        UNDO: 'undo'
    },
    externalProcessIndicators: {
        task_upload: true
    },
    taskUpload: {
        TYPE: 'task_upload',
        status: {
            COMPLETE: 'Processing Complete',
            ACCEPTED: 'Schedule Accepted - Processing',
            FAILED: 'Upload failed',
            STATS: 'Schedule Stats',
            TASK_CREATED: 'Task Created'
        }
    },
    zoneMap: {
        DEFAULT_ZOOM: 12
    },
    customerMap: {
        DEFAULT_RADIUS: '100',
        DEFAULT_ZOOM: 16,
        SLIDER_MAX: '200',
        SLIDER_MIN: '50'
    },
    customerSearch: {
        searchTermLength: 3
    },
    mapView: {
        type: {
            DEFAULT: 'roadmap',
            SATELLITE: 'satellite'
        }
    },
    zoneMapPolygon: {
        defaultStyle: {
            fillColor: '#5EA2FF',
            fillOpacity: 0.3,
            strokeColor: '#3282FF',
            strokeOpacity: 1,
            strokeWeight: 3,
            clickable: false
        },
        fadedFillColor: '#BFD8FB'
    },
    addNewDriver: {
        FIRSTNAME: 'firstname',
        LASTNAME: 'lastname',
        PASSWORD: 'password',
        loginTypes: {
            USERNAME: 'username',
            TELEPHONE: 'telephone'
        },
        DEFAULT_SHIFTS: {
            0: [{ start: '08:00', end: '18:00' }],
            1: [{ start: '08:00', end: '18:00' }],
            2: [{ start: '08:00', end: '18:00' }],
            3: [{ start: '08:00', end: '18:00' }],
            4: [{ start: '08:00', end: '18:00' }],
            5: [{ start: '08:00', end: '18:00' }],
            6: [{ start: '08:00', end: '18:00' }]
        },
        emptyDriverFields: {
            firstname: '',
            lastname: '',
            username: '',
            telephone: '',
            password: ''
        },
        defaultErrorState: {
            firstname: false,
            lastname: false,
            username: false,
            telephone: false,
            showErrorMessage: false
        }
    },
    common: {
        on: 'on',
        off: 'off'
    },
    addVehicleForm: {
        names: {
            rta: 'addNewVehicleRta',
            name: 'addNewVehicleName',
            licensePlate: 'addNewVehicleLicensePlate',
            type: 'addNewVehicleType',
            weight: 'addNewVehicleWeight',
            volume: 'addNewVehicleVolume',
            startDepot: 'addNewVehicleStartDepot',
            endDepot: 'addNewVehicleEndDepot'
        }
    },
    routeChangeOptions: {
        addRoute: 'add',
        replaceRoute: 'replace'
    },
    editHistoryAction: {
        trim: 'trim',
        resequence: 'resequence',
        reassign: 'reassign',
        augment: 'augment',
        append: 'append'
    },
    notificationStatus: {
        sent: 'Sent',
        disabled: 'Disabled',
        scheduled: 'Scheduled',
        enabled: 'Enabled'
    }
} as const;

/**
 * This type guard can be used to inform the TypeScript compiler that `value` is of type `T` when `T`
 * is used to describe members of a `constants` object.
 * @param {string} path check in constants for value under path
 * @param {*} value check under path if this value is present
 * @returns {boolean} true if value is in `constants[path]`
 */
export function isValueInConstantObject<T>(
    path: keyof typeof constants,
    value: unknown
): value is T {
    return Object.values(constants[path]).some((x) => _.isEqual(value, x));
}

export default constants;
