import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import userGroupsAPI from '~/api/user-groups';

import {
    ApiUser,
    ApiUserGroup,
    UserPermissionFeatures,
    UserPermissionRoles
} from '~/api/types';

import {
    setUserGroupsForCurrentUser,
    selectUserGroupsForCurrentUser
} from '~/reducers/userGroupsForCurrentUserSlice';

import constants from '~/utils/constants';
import utils from '~/utils/general-utils';

type HookUserRolesAndPermissions = {
    /** whether the hook is ready to validate permissions */
    isReady: boolean;

    /** the user's UUID */
    userId: string;

    /** the user's name, either first & lastname or login username */
    userName: string;

    /** initializes the user for this hook */
    initUser: (userDetails: ApiUser) => void;

    /** whether the current user is an editor of the specified feature */
    isEditorRole: (
        feature: UserPermissionFeatures,
        overrideRole?: boolean
    ) => boolean;

    /** whether the current user is an editor of the specified feature */
    isViewerRole: (
        feature: UserPermissionFeatures,
        overrideRole?: boolean
    ) => boolean;

    /** whether the current user is an editor of the specified feature */
    isNoAccessRole: (
        feature: UserPermissionFeatures,
        overrideRole?: boolean
    ) => boolean;
};

type ValidatePermissionsParams = {
    /** the user groups for this user */
    userGroups: ApiUserGroup[];

    /** the feature being evaluated for this user */
    feature: UserPermissionFeatures;

    /** the expected role for this user */
    role: UserPermissionRoles;

    /** whether the defined role can be overridden (testing, feature flagging) */
    overrideRole?: boolean;
};

const getUserGroupsAssignedToUser = async (
    userId: string
): Promise<ApiUserGroup[]> => {
    const {
        data: {
            data: { successes }
        }
    } = await userGroupsAPI.getUserGroupsAssignedToUser(userId);

    return successes;
};

const validatePermissions = (params: ValidatePermissionsParams): boolean => {
    const { userGroups, feature, role, overrideRole } = params;

    return userGroups.some((userGroup: ApiUserGroup) => {
        return userGroup.client_access.some((curClient) => {
            return curClient.permission.some(
                (permission) =>
                    permission.feature === feature &&
                    (permission.role === role || overrideRole)
            );
        });
    });
};

export const useUserRolesAndPermissions = (): HookUserRolesAndPermissions => {
    const [isReady, setIsReady] = useState(false);
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');

    const userGroupsForCurrentUser = useSelector(
        selectUserGroupsForCurrentUser
    );

    const dispatch = useDispatch();

    const { refetch: userGroupsFetch } = useQuery(
        [constants.reactQueryKeys.USER_GROUPS, userId],
        async () => {
            const response = await getUserGroupsAssignedToUser(userId);

            if (!response.length) {
                setIsReady(false);
                throw new Error('No results');
            }

            setIsReady(true);
            return response;
        },
        {
            enabled: false,
            retry: 3,
            onSuccess: (data) => {
                dispatch(setUserGroupsForCurrentUser(data));
            }
        }
    );

    useEffect(() => {
        if (userId) userGroupsFetch();
    }, [userId]);

    const initUser = (userDetails: ApiUser): void => {
        const { id, firstname, lastname, username } = userDetails;
        const resolvedUserName = utils.getUserName(
            firstname,
            lastname,
            username
        );

        setUserId(id);
        setUserName(resolvedUserName);
    };

    const isEditorRole = (
        feature: UserPermissionFeatures,
        overrideRole = false
    ): boolean => {
        const params = {
            userGroups: userGroupsForCurrentUser,
            role: UserPermissionRoles.EDITOR,
            feature,
            overrideRole
        };
        return validatePermissions(params);
    };

    const isViewerRole = (
        feature: UserPermissionFeatures,
        overrideRole = false
    ): boolean => {
        const params = {
            userGroups: userGroupsForCurrentUser,
            role: UserPermissionRoles.VIEWER,
            feature,
            overrideRole
        };
        return validatePermissions(params);
    };

    const isNoAccessRole = (
        feature: UserPermissionFeatures,
        overrideRole = false
    ): boolean => {
        const params = {
            userGroups: userGroupsForCurrentUser,
            role: UserPermissionRoles.NO_ACCESS,
            feature,
            overrideRole
        };
        return validatePermissions(params);
    };

    return {
        isReady,
        userId,
        userName,
        initUser,
        isEditorRole,
        isViewerRole,
        isNoAccessRole
    };
};
