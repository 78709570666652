import axios from 'axios';
import constants from '~/utils/constants';
import {
    ApiAnalyticsAggregateDriversMetrics,
    ApiAnalyticsAggregateDriversDownload,
    AxiosApiResponse
} from './types';

/**
 * Default maximum number of downloadable reports to retrieve from AnalyticsApi.getAggregateDriversDownloads()
 */
const REPORTS_API_LIMIT = 200;

/**
 * Implementations of API methods under the /analytics path
 *
 * @category API
 */
export default class AnalyticsApi {
    /**
     * Path of the API endpoint
     */
    private static readonly path = '/analytics';

    /**
     * Key for react-query
     */
    static readonly REACT_QUERY_KEY = 'analytics';

    /**
     *
     * @returns Promise<AxiosApiResponse<ApiAnalyticsAggregateDriversMetrics>>
     */
    static getAggregateDriversMetrics(): Promise<
        AxiosApiResponse<ApiAnalyticsAggregateDriversMetrics>
    > {
        const options = {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        };
        return axios.get(`${this.path}/drivers`, options);
    }

    /**
     *
     * @param limit {number} - Max number of items to retrieve
     * @returns Promise<AxiosApiResponse<ApiAnalyticsAggregateDriversDownload[]>>
     */
    static getAggregateDriversDownloads(
        limit = REPORTS_API_LIMIT
    ): Promise<AxiosApiResponse<ApiAnalyticsAggregateDriversDownload[]>> {
        const options = {
            params: {
                limit,
                action: 'download'
            },
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        };
        return axios.get(`${this.path}/drivers`, options);
    }
}
