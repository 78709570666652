import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { resetToInitialState } from '~/reducers/common-actions';

export const defaultDataLoading = {
    areRoutesLoading: false,
    areStopsLoading: false
};

export const dataLoadingSlice = createSlice({
    name: 'dataLoading',
    initialState: defaultDataLoading,
    reducers: {
        setAreRoutesLoading: (state, action) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, areRoutesLoading: action.payload };
        },
        setAreStopsLoading: (state, action) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, areStopsLoading: action.payload };
        },
        resetDataLoading: () => {
            return defaultDataLoading;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return defaultDataLoading;
        });
    }
});

export const { setAreRoutesLoading, setAreStopsLoading, resetDataLoading } =
    dataLoadingSlice.actions;

export const selectAreRoutesLoading = (state) =>
    state.dataLoading.areRoutesLoading;
export const selectAreStopsLoading = (state) =>
    state.dataLoading.areStopsLoading;

export default dataLoadingSlice.reducer;
