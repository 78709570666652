import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePageNavigation } from '~/hooks';
import { Icon } from '~/ui';

export const Header = () => {
    const { t } = useTranslation('zoneManagement');
    const { goToPrevPage } = usePageNavigation();

    return (
        <div
            className="zonemanagement-header _jc-space-between _w-100"
            data-testid="zone-management-header"
        >
            <div
                className="zonemanagement-header-title-container _fd-column"
                data-testid="zone-management-header-title-container"
            >
                <span className="zonemanagement-header-title">
                    {t('header.title')}
                </span>
                <span className="_text-1 _pt-1">{t('header.description')}</span>
            </div>
            <button
                type="button"
                className="icon-wrapper icon-wrapper--dark"
                onClick={goToPrevPage}
                data-testid="pagenav-button"
            >
                <Icon icon="cancel" />
            </button>
        </div>
    );
};
