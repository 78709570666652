import React from 'react';
import PT from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';
import './PasswordReminderText.scss';

function PasswordReminderText({ reminderText, isPasswordValid, isDirty }) {
    function _getClassName() {
        const conditionalClasses = {
            valid: isPasswordValid
        };
        return classNames(
            'passwordinput-reminder-text _ml-3',
            conditionalClasses
        );
    }

    function _getIcon() {
        const checkMark = (
            <Icon
                icon="checkmark"
                size="xs"
                color="earth"
                data-testid="check-icon"
            />
        );
        const cancel = (
            <Icon
                icon="cancel"
                size="xs"
                color="mars"
                data-testid="cancel-icon"
            />
        );
        const greyCircle = (
            <span
                className="circle_galaxy-300 circle"
                data-testid="grey-circle"
            />
        );

        const icon =
            (isDirty && (isPasswordValid ? checkMark : cancel)) || greyCircle;
        return icon;
    }

    return (
        <div
            className="passwordinput-reminder _d-flex _ai-baseline _mb-2"
            data-testid="passwordremindertext"
        >
            {_getIcon()}
            <span className={_getClassName()}>{reminderText}</span>
        </div>
    );
}

export default PasswordReminderText;

PasswordReminderText.propTypes = {
    reminderText: PT.string,
    /** Bool to check is password matches requirements */
    isPasswordValid: PT.bool,
    isDirty: PT.bool
};
