import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { setSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';

import { isAssignmentLate } from '~/utils/assignment-utils';

import {
    ManageStopControlMenu,
    RouteCardAddress,
    RouteCardEta,
    RouteCardLabels,
    RouteCardTitle,
    StopModalMenuButton,
    RouteCardMetric,
    TooltipButton
} from '~/ui';
import './manage-stop-control-single.scss';

function ManageStopControlSingle({
    stopData,
    children,
    className,
    footerButtonTitle,
    footerButtonIcon,
    onClickFooterButton = () => {},
    ...extra
}) {
    const [isLateStatus, setIsLateStatus] = useState(false);

    const dispatch = useDispatch();

    const { delay, taskId } = stopData || {};
    const { t } = useTranslation('translation');

    useEffect(() => {
        const isLate = isAssignmentLate(delay);
        setIsLateStatus(isLate);
    }, [delay]);

    function _getClassName() {
        const defaultClassName = `managestopcontrol_single _d-flex _fd-column`;
        return classNames(defaultClassName, className);
    }

    function _getStopMetric() {
        return (
            <RouteCardMetric
                value={t('PlanStopsControl.title.late_stop')}
                iconSize="s"
                iconColor="mars"
                icon="clockFill2"
                data-testid={
                    extra['data-testid']
                        ? `${extra['data-testid']}-late`
                        : 'managestopcontrol-single-late'
                }
            />
        );
    }

    const handleClickViewMore = () => {
        dispatch(resetSelectedMapStops());
        dispatch(setSelectedTaskRowId(taskId));
    };

    if (!stopData) return null;

    return (
        <div
            className={_getClassName()}
            data-testid={extra['data-testid'] || 'managestopcontrol-single'}
        >
            <div className="managestopcontrol_main">
                {isLateStatus && _getStopMetric()}
                <RouteCardTitle
                    className="_text-2"
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-title`
                            : 'managestopcontrol-single-title'
                    }
                >
                    {stopData.stopName}
                </RouteCardTitle>
                <RouteCardAddress
                    address={stopData.location}
                    icon="pin"
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-address`
                            : 'managestopcontrol-single-address'
                    }
                />
                <RouteCardEta
                    stopData={stopData}
                    icon="clock"
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-time`
                            : 'managestopcontrol-single-time'
                    }
                />
                <RouteCardLabels
                    labels={stopData.labels}
                    icon="union"
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-labels`
                            : 'managestopcontrol-single-labels'
                    }
                />
                <TooltipButton
                    className="managestopcontrol_view-more"
                    variant={TooltipButton.variants.LINK}
                    onClick={handleClickViewMore}
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-viewmore`
                            : 'managestopcontrol-single-viewmore'
                    }
                >
                    {t('Collapsible.view_more')}
                </TooltipButton>
            </div>
            {children}
            {footerButtonTitle && (
                <ManageStopControlMenu
                    className="managestopcontrol_footer"
                    data-testid={
                        extra['data-testid']
                            ? `${extra['data-testid']}-footer`
                            : 'managestopcontrol-single-footer'
                    }
                >
                    <StopModalMenuButton
                        buttonIcon={footerButtonIcon}
                        buttonText={footerButtonTitle}
                        onClick={onClickFooterButton}
                        data-testid={
                            extra['data-testid']
                                ? `${extra['data-testid']}-footer_button`
                                : 'managestopcontrol-single-footer_button'
                        }
                    />
                </ManageStopControlMenu>
            )}
        </div>
    );
}

export { ManageStopControlSingle };
