import React, { useCallback, useState } from 'react';
import { Modal } from '../components';

export function useModal(sideEffects = { onShow: () => {}, onHide: () => {} }) {
    const { onShow, onHide } = sideEffects;
    const [isVisible, setIsVisible] = useState(false);

    const showModal = useCallback(() => {
        setIsVisible(true);
        // prevent body scroll
        document.body.style.overflow = 'hidden';
        return onShow && onShow();
    }, [setIsVisible, onShow]);

    const hideModal = useCallback(() => {
        setIsVisible(false);
        // enable back the body scroll, caveat: will not work with `modal in a modal` case
        document.body.style.overflow = 'auto';
        return onHide && onHide();
    }, [setIsVisible, onHide]);

    const modal = useCallback(
        ({
            children,
            hasCustomHeader,
            hasAutoHide,
            hasCloseButton,
            title,
            variant,
            className,
            overlayClassName,
            icon,
            iconColor,
            iconSize
        }) => (
            <>
                {isVisible && (
                    <Modal
                        hasCustomHeader={hasCustomHeader}
                        hasAutoHide={hasAutoHide}
                        hasCloseButton={hasCloseButton}
                        title={title}
                        onHide={hideModal}
                        variant={variant}
                        className={className}
                        overlayClassName={overlayClassName}
                        icon={icon}
                        iconColor={iconColor}
                        iconSize={iconSize}
                    >
                        {children}
                    </Modal>
                )}
            </>
        ),
        [isVisible]
    );

    return {
        showModal,
        hideModal,
        modal,
        isVisible
    };
}
