import { AssignmentDelay, AssignmentStatus } from '~/api/types';

/**
 * Assignment Utilities
 *
 * @category Utils
 * @module utils/assignmentUtils
 */

/**
 * Checks if the user already has sufficient admin permissions
 * @param {AssignmentStatus} code
 * @returns {string|null}
 */
export const getAssignmentCodeLabel = (
    code: AssignmentStatus
): string | null => {
    const allKeys = Object.keys(AssignmentStatus);
    const valIndex = Object.values(AssignmentStatus).indexOf(code);
    return allKeys[valIndex] || null;
};

/**
 * Checks if the user already has sufficient admin permissions
 * @param {AssignmentDelay} code
 * @returns {string|null}
 */
export const getDelayCodeLabel = (code: AssignmentDelay): string | null => {
    const allKeys = Object.keys(AssignmentDelay);
    const valIndex = Object.values(AssignmentDelay).indexOf(code);
    return allKeys[valIndex] || null;
};

/**
 * Determines whether the assignment is assigned
 * @param {number} code
 * @returns {boolean}
 * */
export const isAssignmentAssigned = (code: number): boolean => {
    return code === AssignmentStatus.ASSIGNED;
};

/**
 * Determines whether the assignment is canceled
 * @param {number} code
 * @returns {boolean}
 * */
export const isAssignmentCanceled = (code: number): boolean => {
    return code === AssignmentStatus.CANCELED;
};

/**
 * Determines whether the assignment is completed
 * @param {number} code
 * @returns {boolean}
 * */
export const isAssignmentCompleted = (code: number): boolean => {
    return code === AssignmentStatus.COMPLETED;
};

/**
 * Determines whether the assignment is in-progress
 * @param {number} code
 * @returns {boolean}
 * */
export const isAssignmentInProgress = (code: number): boolean => {
    return code === AssignmentStatus.IN_PROGRESS;
};

/**
 * Determines whether the assignment is at-risk
 * @param {number} code
 * @returns {boolean}
 * */
export const isAssignmentAtRisk = (code: number): boolean => {
    return code === AssignmentDelay.AT_RISK;
};

/**
 * Determines whether the assignment is early
 * @param {number} code
 * @returns {boolean}
 * */
export const isAssignmentEarly = (code: number): boolean => {
    return code === AssignmentDelay.EARLY;
};

/**
 * Determines whether the assignment is late
 * @param {number} code
 * @returns {boolean}
 * */
export const isAssignmentLate = (code: number): boolean => {
    return code === AssignmentDelay.LATE;
};

/**
 * Determines whether the assignment is on-time
 * @param {number} code
 * @returns {boolean}
 * */
export const isAssignmentOnTime = (code: number): boolean => {
    return code === AssignmentDelay.ON_TIME;
};
