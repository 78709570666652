import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import tasksApi from '~/api/TasksApi';

import { selectClients } from '~/reducers/clientsSlice';
import { addToast } from '~/reducers/toastsSlice';
import { addNewTask } from '~/reducers/tasksSlice';

import socketInstance from '~/utils/socket/socket-instance';
import constants from '~/utils/constants';

import { useGetAllTasks } from './useGetAllTasks';

export const useTaskPairing = () => {
    const clients = useSelector(selectClients);

    const dispatch = useDispatch();
    const { t } = useTranslation(['taskManagement', 'error']);
    const { allTasks } = useGetAllTasks();

    const _testSelectedTasks = useCallback(
        (selectedTaskIds) => {
            return selectedTaskIds.reduce(
                (tests, taskId) => {
                    if (allTasks[taskId]) {
                        const { isDelivery, isPickup, isPlanned } =
                            allTasks[taskId];
                        if (isDelivery) tests.delivery++;
                        if (isPickup) tests.pickup++;
                        if (isPlanned) tests.planned++;
                    }
                    return tests;
                },
                { delivery: 0, pickup: 0, planned: 0 }
            );
        },
        [allTasks]
    );

    const canOptimalPair = useCallback(
        (selectedTaskIds) => {
            const results = _testSelectedTasks(selectedTaskIds);
            return results.delivery > 0 && results.pickup > 0;
        },
        [_testSelectedTasks]
    );

    const canManualPair = useCallback(
        (selectedTaskIds) => {
            const results = _testSelectedTasks(selectedTaskIds);
            return (
                results.delivery === results.pickup &&
                results.delivery + results.pickup === 2 &&
                results.planned === 0
            );
        },
        [_testSelectedTasks]
    );

    const depotPairing = useCallback(async (deportPairDetails) => {
        const { selectedTaskIds, updateTaskStatesForDepotPairing } =
            deportPairDetails;
        try {
            const response = await tasksApi.generateDepotPairs({
                taskIds: selectedTaskIds
            });

            const { tasksModifiedCount, unacceptedTaskIds } =
                response.data?.data ?? {};

            dispatch(
                addToast({
                    message: t('taskManagement:depotPairingComplete', {
                        pairsCreatedCount: tasksModifiedCount,
                        tasksRemainedCount: unacceptedTaskIds.length
                    }),
                    variant: 'info'
                })
            );
            updateTaskStatesForDepotPairing();
        } catch (error) {
            dispatch(
                addToast({
                    message: t(`error:depotPairError.general`),
                    variant: 'error'
                })
            );
        }
    }, []);

    function formatErrorMessage(message) {
        switch (message) {
            case constants.apiErrorMessages.HAVE_DIFF_VEHICLE_IDS:
                return t(
                    'error:manualPairError.bothTasksShouldHaveSameVehicleEid'
                );
            case constants.apiErrorMessages.PICKUP_DELIVERY_TIME_WINDOWS:
                return t(
                    'error:timeWindowsValidationError.pickupAndDeliveryTimeWindows'
                );
            default:
                return t('error:manualPairError.general');
        }
    }

    const manualPairing = useCallback(async (manualPairDetails) => {
        const { selectedTaskIds, updateTaskStates } = manualPairDetails;
        const task = allTasks[selectedTaskIds[0]];
        const joinTask = allTasks[selectedTaskIds[1]];
        // ideally this validation should be done on api end
        if ((task && task.client) !== (joinTask && joinTask.client)) {
            dispatch(
                addToast({
                    message: t(
                        'error:manualPairError.shouldBelongToSameClient'
                    ),
                    variant: 'error'
                })
            );
        } else {
            try {
                const response = await tasksApi.joinTasks({
                    id: selectedTaskIds[0],
                    joinedTask: selectedTaskIds[1]
                });
                const newTask = response.data.data;
                dispatch(addNewTask(newTask));
                updateTaskStates();
                dispatch(
                    addToast({
                        message: t('taskManagement:manualPair.success', {
                            count: selectedTaskIds.length
                        }),
                        variant: 'info'
                    })
                );
            } catch (error) {
                const errorMessage = formatErrorMessage(
                    error.response?.data.message
                );
                dispatch(
                    addToast({
                        message: t(`error:${errorMessage}`),
                        variant: 'error'
                    })
                );
            }
        }
    }, []);

    const optimalPairing = useCallback(async (optimalPairDetails) => {
        const { selectedTaskIds } = optimalPairDetails;
        const roomsToJoin = Object.keys(clients);

        if (roomsToJoin) {
            socketInstance.leaveRooms(roomsToJoin);
        }
        socketInstance.joinRooms(roomsToJoin);
        try {
            await tasksApi.generatePairings({ taskIds: selectedTaskIds });
            dispatch(
                addToast({
                    message: t('taskManagement:pairingUnassignedTasks', {
                        total: selectedTaskIds.length
                    }),
                    variant: 'info'
                })
            );
        } catch (error) {
            console.error(error);
            dispatch(
                addToast({
                    message: t('error:optimalPairRunningError'),
                    variant: 'error'
                })
            );
        }
    }, []);

    return {
        canManualPair,
        canOptimalPair,
        depotPairing,
        manualPairing,
        optimalPairing
    };
};
