import axios from 'axios';
import constants from '~/utils/constants';
import {
    AxiosApiResponse,
    ApiInventoryItem,
    PaginationMetadata,
    PaginationParams
} from './types';

interface GetInventoryItemsParams extends PaginationParams {
    taskId?: string;
    taskIdassignmentId?: string;
    barcodeId?: string;
    signed?: boolean;

    /* Date in YYYY-MM-DD format */
    routeDate?: string;
}

type GetInventoryItemsResponseData = ApiInventoryItem[];

type GetInventoryItemsResponse = AxiosApiResponse<
    GetInventoryItemsResponseData,
    PaginationMetadata
>;

type UpdateInventoryItemResponse = AxiosApiResponse<ApiInventoryItem>;

interface DeleteInventoryItemResponseData {
    id?: string;
}

type DeleteInventoryItemResponse =
    AxiosApiResponse<DeleteInventoryItemResponseData>;

/**
 * Implementations of API methods under the `/inventoryitems` path
 *
 * @category API
 */
export default class InventoryItemsApi {
    /**
     * Path of the endpoint
     */
    private static readonly path = '/inventoryitems';

    static get(
        params: GetInventoryItemsParams = { signed: false }
    ): Promise<GetInventoryItemsResponse> {
        return axios.get(this.path, {
            params,
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }

    static updateInventoryItem(
        id: string,
        payload: ApiInventoryItem
    ): Promise<UpdateInventoryItemResponse> {
        return axios.patch(
            `${this.path}/${id}`,
            { ...payload },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }

    static deleteInventoryItem(
        id: string
    ): Promise<DeleteInventoryItemResponse> {
        return axios.delete(`${this.path}/${id}`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }
}
