import React from 'react';
import { Icon } from '~/ui';

import './zoom-control.scss';

function ZoomControl({ map, showControls }) {
    const smallZoomStep = 1;

    function zoomIn() {
        if (map.zoom < map.maxZoom) {
            map.setZoom(map.zoom + smallZoomStep);
        }
    }

    function zoomOut() {
        if (map.zoom > map.minZoom) {
            map.setZoom(map.zoom - smallZoomStep);
        }
    }

    return (
        <div
            data-testid="zoom-controls"
            className="zoom-column"
            hidden={!showControls}
        >
            <div className="zoom-column-btn-wrapper">
                <button
                    type="button"
                    className="zoom-column-in button_clear"
                    onClick={zoomIn}
                >
                    <Icon icon="addLight" size="m" color="galaxy-800" />
                </button>
            </div>
            <div className="zoom-column-divider" />
            <div className="zoom-column-btn-wrapper">
                <button
                    type="button"
                    className="zoom-column-out button_clear"
                    onClick={zoomOut}
                >
                    <Icon icon="minusLight" size="m" color="galaxy-800" />
                </button>
            </div>
        </div>
    );
}

export default ZoomControl;
