import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    selectTaskUploadResultsByClientId,
    clearTaskUploadResults
} from '~/reducers/uploadResultsSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { addProcessIndicator } from '~/reducers/processIndicatorSlice';
import constants from '~/utils/constants';
import { useIsTaskUploadInitiated } from './useIsTaskUploadInitiated';
import { getAllTasks } from '~/reducers/tasksSlice';
import { selectDate } from '~/reducers/selectedDateSlice';

export const getDefaultState = () => ({
    isUploadComplete: false,
    isUploadSuccessful: false,
    isUploaded: false,
    uploadStats: {
        status: '',
        totalCount: 0,
        currentName: '',
        failedTasks: 0,
        successfulTasks: 0
    }
});

export const getNewState = ({ latestMessage, state: currentState }) => {
    const defaultState = getDefaultState();
    const { uploadStats: defaultUploadStats } = defaultState;
    const { uploadStats: currentStateUploadStats } = currentState;
    const { totalCount = 0 } = currentStateUploadStats || {};
    const {
        status,
        stats,
        task,
        error,
        upload: { countscheduled = 0, countcreated = 0 } = {}
    } = latestMessage || {};

    if (
        [
            constants.taskUpload.status.ACCEPTED,
            constants.taskUpload.status.STATS
        ].includes(status)
    ) {
        return {
            ...defaultState,
            isUploaded: true,
            uploadStats: {
                ...defaultUploadStats,
                status,
                totalCount: stats || 1
            }
        };
    }

    if (status === constants.taskUpload.status.TASK_CREATED) {
        return {
            ...defaultState,
            isUploaded: true,
            uploadStats: {
                ...defaultUploadStats,
                status,
                totalCount: totalCount || 1,
                currentName: task
            }
        };
    }

    if (status === constants.taskUpload.status.COMPLETE) {
        const failedTasks = countscheduled - countcreated;
        const successfulTasks = countcreated;

        return {
            ...defaultState,
            isUploaded: true,
            isUploadComplete: true,
            isUploadSuccessful: true,
            uploadStats: {
                ...defaultUploadStats,
                status,
                failedTasks,
                successfulTasks,
                totalCount: failedTasks + successfulTasks
            }
        };
    }

    if (!error) {
        return {
            ...defaultState,
            uploadStats: {
                ...defaultUploadStats,
                status
            }
        };
    }

    return {
        ...defaultState,
        isUploaded: true,
        isUploadComplete: true,
        uploadStats: {
            ...defaultUploadStats,
            totalCount,
            status: constants.taskUpload.status.FAILED
        }
    };
};

export const useTaskUploadResults = () => {
    const mainClient = useSelector(selectMainClient);
    const selectedDate = useSelector(selectDate);
    const clientId = mainClient?.id;
    const uploadResults = useSelector(
        selectTaskUploadResultsByClientId(clientId)
    );
    const [state, setState] = useState(() => getDefaultState());
    const [latestMessage] = uploadResults.slice(-1);
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const { isTaskUploadInitiated, setIsTaskUploadInitiated } =
        useIsTaskUploadInitiated();

    useEffect(() => {
        if (!isTaskUploadInitiated || !latestMessage) return;

        const newState = getNewState({
            latestMessage,
            state
        });
        const {
            isUploaded,
            isUploadSuccessful,
            isUploadComplete,
            uploadStats: { successfulTasks, totalCount, status }
        } = newState;
        const inProgress = isUploaded && !isUploadComplete;
        const isError = isUploaded && isUploadComplete && !isUploadSuccessful;
        const processIndicatorState = {
            inProgress,
            error: isError,
            position: 'center',
            type: constants.processIndicator.TASK_UPLOAD
        };
        const message = isUploadSuccessful
            ? t('TaskUploaded', {
                  count: successfulTasks
              })
            : t('UploadTask', {
                  count: totalCount
              });
        setState(newState);
        dispatch(
            addProcessIndicator({
                ...processIndicatorState,
                message
            })
        );

        if (status === constants.taskUpload.status.COMPLETE) {
            setIsTaskUploadInitiated(false);
            dispatch(clearTaskUploadResults(clientId));
            dispatch(getAllTasks({ routeDate: selectedDate }));
        }
    }, [latestMessage]);
};
