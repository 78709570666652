import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

export const mainClientSlice = createSlice({
    name: 'mainClient',
    initialState: null,
    reducers: {
        setMainClient: (state, action) => {
            return action.payload;
        },
        resetMainClient: () => {
            return null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return null;
        });
    }
});

export const { setMainClient, resetMainClient } = mainClientSlice.actions;

export const selectMainClient = (state) => state.mainClient;

export const selectMainClientPreference = (preferenceName) => (state) =>
    state.mainClient?.preferences?.[preferenceName];

export const selectAllMainClientPreferences = (state) =>
    state.mainClient?.preferences;

export default mainClientSlice.reducer;
