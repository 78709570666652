import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Icon, Text } from '~/ui';
import { ResequenceModalStopList } from '~/components/MapPage/ResequenceModal';
import { mapPlanStopUtils } from '~/utils/map';
import constants from '~/utils/constants';
import {
    replaceSelectedMapStop,
    resetSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';
import { selectIsOpenUnassignedTasksDrawer } from '~/reducers/mapDrawerSettingsSlice';
import { selectClients } from '~/reducers/clientsSlice';
import { addProcessIndicator } from '~/reducers/processIndicatorSlice';
import { selectDateOnly } from '~/reducers/selectedDateSlice';
import SinglePlanEditApi from '~/api/SinglePlanEditApi';

import { ReassignModalSearch } from './ReassignModalSearch';
import { useLiveStopReassignTasks, useMapUtils } from '~/hooks';

export const ReassignModalContent = ({
    routes,
    selectedStops,
    stopIdPropertyName
}) => {
    const dispatch = useDispatch();
    const isOpenUnassignedTasksDrawer = useSelector(
        selectIsOpenUnassignedTasksDrawer
    );
    const [selectedName, setSelectedName] = useState(null);
    const [dragStartIndex, setDragStartIndex] = useState(null);
    const clients = useSelector(selectClients);
    const selectedDate = useSelector(selectDateOnly);
    const { t } = useTranslation('translation');
    const { isPlanRouteMode } = useMapUtils();
    const { reassignTasks } = useLiveStopReassignTasks();
    const [firstSelectedStop] = selectedStops;

    const selectableRoutesNames = useMemo(() => {
        const otherRoutes = routes
            .filter(
                ({ clientRouteId }) =>
                    clientRouteId !== firstSelectedStop?.clientRouteId
            )
            .map(({ routeName }) => routeName);

        return _.sortBy(otherRoutes);
    }, [routes, firstSelectedStop]);

    const selectedPayload = useMemo(() => {
        const selectedRoute = routes.find(
            ({ routeName }) => routeName === selectedName
        );

        if (!selectedRoute) {
            console.warn('Route not selected');
            return;
        }

        const taskIds = selectedStops.map((stop) => {
            // depending on the stop data, task IDs are either in the `task` or `taskId` property
            const { task: LiveStopTaskId, taskId: PlanStopTaskId } = stop;
            return PlanStopTaskId || LiveStopTaskId;
        });

        const { driverId, routeId: selectedRouteId } = selectedRoute;
        const [{ clientId, routeId: oldRouteId }] = selectedStops;

        const payload = {
            taskIds,
            driverId,
            selectedRouteId,
            oldRouteId,
            clientId
        };
        return payload;
    }, [routes, selectedStops, selectedName]);

    /**
     * @todo see possible consolidation with _handleReassignDispatchStops()
     */
    function _handleReassignStops() {
        if (isPlanRouteMode) {
            _handleReassignPlanStops();
        } else {
            _handleReassignDispatchStops();
        }
    }

    /**
     * @todo implement reassign hook once completed
     * @see [Add reassign API]{@link https://wisesys.atlassian.net/browse/AT2-437}
     * @see [Add reassign hook]{@link https://wisesys.atlassian.net/browse/AT2-438}
     */
    function _handleReassignDispatchStops() {
        const { taskIds, driverId } = selectedPayload;
        reassignTasks(taskIds, driverId);
    }

    /**
     * @todo see possible consolidation with _handleReassignDispatchStops()
     */
    function _handleReassignPlanStops() {
        if (!selectedPayload) {
            console.warn('Route not selected');
            return;
        }

        const { taskIds, driverId, selectedRouteId, oldRouteId, clientId } =
            selectedPayload;
        const selectedClient = clients[clientId];
        const { preferences: clientPreferences } = selectedClient;
        const numberOfTasks = taskIds.length;

        const processIndicatorState = {
            message: t('ReassigningStop', {
                count: numberOfTasks
            }),
            type: constants.processIndicator.REASSIGN_STOP,
            payload: numberOfTasks,
            inProgress: true,
            error: false,
            position: 'center'
        };

        (async () => {
            try {
                dispatch(addProcessIndicator(processIndicatorState));

                if (oldRouteId === constants.entityStates.UNPLANNED) {
                    await SinglePlanEditApi.augmentRoute(clientId, {
                        taskIds,
                        driverId,
                        date: selectedDate
                    });
                } else {
                    await SinglePlanEditApi.reassignTasks({
                        clientId,
                        clientPreferences,
                        oldRouteId,
                        selectedRouteId,
                        taskIds,
                        date: selectedDate,
                        selectedDriverId: driverId
                    });
                }

                dispatch(resetSelectedMapStops());
            } catch (e) {
                console.error(e);
                dispatch(
                    addProcessIndicator({
                        ...processIndicatorState,
                        inProgress: false,
                        error: true
                    })
                );
            }
        })();
    }

    const handleDrop = useCallback(
        (e, index) => {
            dispatch(
                replaceSelectedMapStop(
                    mapPlanStopUtils.processStopsReorder(
                        e,
                        selectedStops,
                        stopIdPropertyName,
                        dragStartIndex,
                        index,
                        isOpenUnassignedTasksDrawer
                    )
                )
            );
        },
        [dispatch, dragStartIndex, selectedStops, stopIdPropertyName]
    );

    const isButtonDisabled = !selectedName || selectedStops.length < 1;

    return (
        <div className="reassignmodal-content">
            <div className="stop-list">
                {selectedStops.map((selectedStop, index) => (
                    <ResequenceModalStopList
                        key={selectedStop[stopIdPropertyName]}
                        stopIdPropertyName={stopIdPropertyName}
                        selectedStop={selectedStop}
                        handleDragStart={() => setDragStartIndex(index)}
                        handleDragOver={(e) => e.preventDefault()}
                        handleDrop={(e) => handleDrop(e, index)}
                    />
                ))}
            </div>
            <div className="reassignmodal-content-search">
                <div>
                    <Icon icon="iconRouteFill" color="meteor" />
                    <Text
                        className="_text-3"
                        color="meteor"
                        variant="12-normal"
                    >
                        {t('ReassignModal.Content.title')}
                    </Text>
                </div>
                <ReassignModalSearch
                    names={selectableRoutesNames}
                    selectedName={selectedName}
                    setSelectedName={setSelectedName}
                />
                <Button
                    onClick={_handleReassignStops}
                    disabled={isButtonDisabled}
                    className="reassignmodal-content-button"
                >
                    <div className="_ai-center _jc-center">
                        <Icon icon="iconRouteFill" color="white" />
                        <Text
                            className="_text-3"
                            color="white"
                            variant="12-normal"
                        >
                            {t('PlanStopsControl.button.reassign')}
                        </Text>
                    </div>
                </Button>
            </div>
            <Text className="_text-3" color="galaxy-500" variant="12-normal">
                <Text color="meteor">Pro Tip:</Text>&nbsp;You can also drag and
                drop the selected stop to another route.
            </Text>
        </div>
    );
};
