import { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
    resetSelectedMapStops,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';
import { addProcessIndicator } from '~/reducers/processIndicatorSlice';
import { selectDispatchedDrivers } from '~/reducers/liveDriversSlice';

import DriverApi from '~/api/DriverApi';
import { useMapUtils } from './useMapUtils';

const getReorderedTaskIds = ({
    allStops,
    newStopNumber,
    selectedStops,
    isInsertAfter = true
}) => {
    const selectedStopsNumbers = new Set(
        selectedStops.map(({ stopNumber }) => stopNumber)
    );
    const reOrderedStops = [];
    let isReordered = false;

    allStops.forEach((stop) => {
        const { stopNumber } = stop;
        const canAddStop = !selectedStopsNumbers.has(stopNumber);

        if (stopNumber !== newStopNumber) {
            if (canAddStop) reOrderedStops.push(stop);

            return;
        }

        isReordered = true;

        if (canAddStop && isInsertAfter) {
            reOrderedStops.push(stop);
        }

        reOrderedStops.push(...selectedStops);

        if (canAddStop && !isInsertAfter) {
            reOrderedStops.push(stop);
        }
    });

    const taskIds = reOrderedStops
        .filter(({ isDepot, status }) => !isDepot && status === 0)
        .map(({ id }) => id);

    return {
        isReordered,
        taskIds
    };
};

export const mapStopIdsToLiveStops = ({
    dispatchedDrivers,
    selectedMapStopsIds
}) => {
    if (!selectedMapStopsIds.length) return {};

    const driver = dispatchedDrivers.find(({ schedule }) =>
        schedule.some(({ id: stopId }) => selectedMapStopsIds.includes(stopId))
    );
    const driverId = driver?.id;
    const [selectedStops, unselectedStops] = _.partition(
        driver?.schedule.map(({ name, ...others }, index) => ({
            ...others,
            stopName: name,
            stopNumber: index
        })),
        ({ id: stopId }) => selectedMapStopsIds.includes(stopId)
    );

    if (selectedStops.length !== selectedMapStopsIds.length) return {};

    const orderedSelectedStops = _.sortBy(selectedStops, ({ id: stopId }) =>
        selectedMapStopsIds.indexOf(stopId)
    );
    const [firstSelectedStop] = orderedSelectedStops;

    return {
        driver,
        driverId,
        firstSelectedStop,
        unselectedStops,
        selectedStops: orderedSelectedStops,
        allStops: _.sortBy(
            [...orderedSelectedStops, ...unselectedStops],
            'stopNumber'
        )
    };
};

export const useResequenceDriverTasks = () => {
    const dispatch = useDispatch();
    const dispatchedDrivers = useSelector(selectDispatchedDrivers);
    const selectedMapStopsIds = useSelector(selectSelectedMapStops);
    const isRequestInProgress = useRef(false);
    const { t } = useTranslation('translation');
    const { isDispatchedRouteMode } = useMapUtils();

    const {
        driverId,
        allStops = [],
        selectedStops = []
    } = useMemo(() => {
        if (!isDispatchedRouteMode) return {};

        return mapStopIdsToLiveStops({
            dispatchedDrivers,
            selectedMapStopsIds
        });
    }, [dispatchedDrivers, isDispatchedRouteMode, selectedMapStopsIds]);

    const isResequenceAllowed =
        selectedStops.length > 0 && !isRequestInProgress.current;

    const resequenceDriverTasks = useCallback(
        ({ newStopNumber, isInsertAfter = true }) => {
            if (!isResequenceAllowed) {
                console.warn('resequence is not allowed');
                return;
            }

            const { taskIds, isReordered } = getReorderedTaskIds({
                allStops,
                isInsertAfter,
                newStopNumber,
                selectedStops
            });

            if (!isReordered) {
                console.warn('tasks order did not change');
                return;
            }

            const numOfSelectedStops = selectedStops.length;
            const processIndicatorState = {
                message: t('ResequenceStop', {
                    count: numOfSelectedStops
                }),
                inProgress: true,
                error: false,
                position: 'center'
            };
            (async () => {
                try {
                    isRequestInProgress.current = true;
                    dispatch(addProcessIndicator(processIndicatorState));
                    await DriverApi.resequenceTasks(driverId, taskIds);
                    dispatch(resetSelectedMapStops());
                    isRequestInProgress.current = false;
                    dispatch(
                        addProcessIndicator({
                            ...processIndicatorState,
                            message: t('StopResequenced', {
                                count: numOfSelectedStops
                            }),
                            inProgress: false
                        })
                    );
                } catch (e) {
                    console.error(e);
                    isRequestInProgress.current = false;
                    dispatch(
                        addProcessIndicator({
                            ...processIndicatorState,
                            inProgress: false,
                            error: true
                        })
                    );
                }
            })();
        },
        [allStops, driverId, dispatch, isResequenceAllowed, selectedStops]
    );

    return {
        isResequenceAllowed,
        resequenceDriverTasks
    };
};
