import _ from 'lodash';
import { LiveDriver } from '~/data-classes';
import { isAssignmentLate } from '~/utils/assignment-utils';

export const liveRoutesDataFactory = {
    makeLiveDriver(webDriver) {
        const liveDriver = {
            id: webDriver.id,
            clientId: webDriver.client,
            routeName: webDriver.eid,
            lastLocationUpdate: webDriver.lastLocationUpdate,
            cepLocation: webDriver.location,
            latestLocationUpdate: null,
            routeCenter: null,
            profile: {
                name: webDriver.profile.name,
                initials: webDriver.profile.initials
            },
            vehicle: {
                id: webDriver.vehicle.id,
                type: webDriver.vehicle.type,
                maxWeight: webDriver.vehicle.weightCapacity,
                maxVolume: webDriver.vehicle.volumeCapacity,
                stats: {
                    volumeCapacityUsed: webDriver.stats.totalUnits,
                    weightCapacityUsed: webDriver.stats.totalWeight
                }
            },
            stats: {
                currentStop: webDriver.stats.activeAssignmentIndex,
                numStops: webDriver.stats.totalSch,
                timeRemaining: webDriver.stats.timeRemaining.ms,
                active: webDriver.stats.driverHasActiveLocation,
                numInventoryItems: webDriver.stats.numInventoryItems,
                numConfirmedInventoryItems:
                    webDriver.stats.numConfirmedInventoryItems,
                numInventoryExceptions: webDriver.stats.numInventoryExceptions,
                numAtRiskExceptions: webDriver.stats.atRiskWatch,
                numLateExceptions: webDriver.stats.lateWatch,
                isDriverComplete: webDriver.stats.isDriverComplete
            }
        };
        const { schedule, numLateExceptions } = this.makeLiveDriverScheduleJSON(
            webDriver.schedule
        );

        liveDriver.schedule = schedule;
        // override stats field that are no longer compiled upstream once
        // assignments are completed
        if (liveDriver.stats.isDriverComplete) {
            liveDriver.stats.numLateExceptions = numLateExceptions;
        }
        return new LiveDriver(liveDriver);
    },
    makeLiveDriverScheduleJSON(webSchedule) {
        return webSchedule.reduce(
            (acc, webStop) => {
                const liveStop = _.pick(webStop, [
                    'id',
                    'name',
                    'type',
                    'delay',
                    'status',
                    'stopname',
                    'completedAt',
                    'arrivalTime',
                    'serviceTimeML',
                    'hasInventoryException',
                    'location.addressLine1',
                    'location.addressLine2',
                    'location.city',
                    'location.state',
                    'location.zipcode',
                    'location.location',
                    'isCompleted',
                    'isDepot',
                    'task'
                ]);
                liveStop.labels = webStop.taskLabels;
                liveStop.serviceTime = webStop.props.serviceTime;
                liveStop.priority = webStop.props.priority;
                const lateExceptions = isAssignmentLate(liveStop.delay) ? 1 : 0;
                return {
                    schedule: [...acc.schedule, liveStop],
                    numLateExceptions: acc.numLateExceptions + lateExceptions
                };
            },
            {
                schedule: [],
                numLateExceptions: 0
            }
        );
    }
};
