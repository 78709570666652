import i18n from 'i18next';
import { isString } from 'lodash';
import constants from './constants';

/**
 * General Utilities
 *
 * @category Utils
 * @module utils/generalUtils
 *
 * @example
 * import generalUtils from '~/utils/general-utils';
 */

/**
 * Get the capitalized first letter of a string
 *
 * @param {String} str
 * @returns {string}
 */
function getCapitalizedFirstChar(str: string): string {
    return isString(str) ? str.charAt(0).toUpperCase() : '';
}

/**
 * Return user's localized full name, if unavailable, returns alternative
 *
 * @param {String} firstName
 * @param {String} lastName
 * @param {String} alternative
 * @returns {String} full name or alternative
 */
function getUserName(
    firstName: string,
    lastName: string,
    alternative = ''
): string {
    if (!firstName && !lastName) {
        return alternative;
    }
    return i18n.t('common:name.full', { firstName, lastName }).trim();
}

/**
 * Return user's localized initials, if unavailable, returns alternative
 *
 * @param {String} firstName
 * @param {String} lastName
 * @param {String} alternative
 * @returns {String} initials or alternative
 */
function getUserInitials(
    firstName: string,
    lastName: string,
    alternative = ''
): string {
    if (!firstName && !lastName) {
        return alternative;
    }
    const firstInitial = getCapitalizedFirstChar(firstName);
    const lastInitial = getCapitalizedFirstChar(lastName);
    return i18n.t('common:name.initials', { firstInitial, lastInitial }).trim();
}

/**
 * Displays a flagged feature based on provided environment variable,
 * or if provided the value of `enableFeatureFlags` in localStorage,
 * or detected hostname (local, stage or sandbox)
 *
 * @param {String} [env=null] - The target env variable
 * @example
 * // default, tests the user's hostname against a preset list
 * isFlaggedFeatureDisplayed()
 * @example
 * // tests against an env variable `REACT_APP_ENABLE_LIVE_ROUTES`
 * isFlaggedFeatureDisplayed('REACT_APP_ENABLE_LIVE_ROUTES')
 * @returns {Boolean}
 */
function isFlaggedFeatureDisplayed(env?: string | null): boolean {
    // test for env variable if provided
    // refer to: https://create-react-app.dev/docs/adding-custom-environment-variables
    if (env) {
        // dotenv typecasts values as strings
        // compare against the string `true`
        const key = env.toUpperCase() as string;
        const isFlagEnabled = process?.env[key]?.toLowerCase() === 'true';

        return isFlagEnabled as boolean;
    }

    // A way to override default feature flag settings.
    // For example: to test the UAT/Prod interface on Stage,
    // we can manually set `enableFeatureFlags: false` in Local Storage.
    // To test the Stage interface on UAT/Prod,
    // we can manually set `enableFeatureFlags: true` in Local Storage.
    const featureFlagsOverride =
        localStorage.getItem(constants.localStorageKeys.ENABLE_FEATURE_FLAGS) ||
        '';
    const featureFlagsOverrideLowercase = featureFlagsOverride.toLowerCase();
    // explicitly checking for 'false' values to allow disabling flagged features on Stage
    if (['true', 'false'].includes(featureFlagsOverrideLowercase)) {
        return featureFlagsOverrideLowercase === 'true';
    }

    // fallback to detecting hostname from a preset list
    const { hostname } = window.location;
    const isLocal = /localhost/.test(hostname) || /127\.0\.0\.1/.test(hostname);
    const isStage = /admin\.stage\.wisesys\.info/.test(hostname);
    const isSandbox =
        /admin\.sandbox\.wisesys\.info/.test(hostname) ||
        /wise-web-multi-client-mrs\.wisesys\.info/.test(hostname);

    return isLocal || isStage || isSandbox;
}

/**
 * Tests if the provided string is a valid uuid string
 *
 * @param {String} uuidValue
 * @example
 * // tests against a valid UUID string `54b8f1d7-c50a-4ab6-b2d5-c36ee6bb9053`
 * isValidUUID('54b8f1d7-c50a-4ab6-b2d5-c36ee6bb9053')
 * @returns {Boolean} true if uuidValue is valid
 */
function isValidUUID(uuidValue: string): boolean {
    return /^[a-z0-9]{8}(-[a-z0-9]{4}){3}-[a-z0-9]{12}$/.test(uuidValue);
}

/**
 * Sets a promise-based timer
 *
 * @param {number} delay - the number of milliseconds for the timer
 * @example
 * // simulates a 5 second download on click
 * const handleOnClick = async (event: MouseEvent<HTMLButtonElement>) => {
 *     setDownloadStatus('downloading');
 *     await promiseTimer(5000);
 *     setDownloadStatus('downloaded');
 * };
 * @returns {Promise} the promise timer
 */
function promiseTimer(delay: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, delay));
}

export default {
    getCapitalizedFirstChar,
    getUserName,
    getUserInitials,
    isFlaggedFeatureDisplayed,
    isValidUUID,
    promiseTimer
};
