import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    setIsOpenUnassignedTasksDrawer,
    selectIsOpenUnassignedTasksDrawer
} from '~/reducers/mapDrawerSettingsSlice';
import { selectOnDemandDispatchTaskCount } from '~/reducers/onDemandDispatchTaskCountSlice';
import { IconButton, theme } from '~/ui';
import { liveRoutesUtils } from '~/utils/live-routes-utils';
import { useMapUtils } from '~/hooks';

import './on-demand-dispatch-map-control.scss';

export default function OnDemandDispatchMapControl() {
    const showDrawerOnDemandDispatch = useSelector(
        selectIsOpenUnassignedTasksDrawer
    );
    const onDemandDispatchTaskCount = useSelector(
        selectOnDemandDispatchTaskCount
    );
    const { isDispatchedRouteMode } = useMapUtils();
    const dispatch = useDispatch();

    const shouldRender = useCallback(() => {
        return isDispatchedRouteMode && onDemandDispatchTaskCount > 1;
    }, [isDispatchedRouteMode, onDemandDispatchTaskCount]);

    function _handleClick() {
        liveRoutesUtils.deselectDriver();
        dispatch(setIsOpenUnassignedTasksDrawer(!showDrawerOnDemandDispatch));
    }

    return (
        shouldRender() && (
            <IconButton
                className="ondemanddispatchmapcontrol_marker"
                text="?"
                textColor={theme.colors.comet}
                textStyle={{
                    ...theme.text['14-medium']
                }}
                style={{
                    backgroundColor: theme.colors['galaxy-500']
                }}
                onClick={_handleClick}
            />
        )
    );
}
