import React, { useState, useEffect } from 'react';
import PT from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import {
    getAssignmentCodeLabel,
    getDelayCodeLabel
} from '~/utils/assignment-utils';

import dateUtilsConverters from '~/utils/date-utils-converters';
import { RouteCardSummary } from '~/ui';

import './routecard-statusbar.scss';

function RouteCardStatusBar({
    className,
    numStops,
    currentStopIndex,
    schedule = [],
    timeRemaining,
    driverOffline = true,
    isScheduleCompleted = false
}) {
    const [currentStopIsDepot, setCurrentStopIsDepot] = useState(false);
    const [currentStopNumber, setCurrentStopNumber] = useState(0);
    const [currentStopType, setCurrentStopType] = useState(null);
    const [currentStopFinish, setCurrentStopFinish] = useState(null);
    const [estimatedFinish, setEstimatedFinish] = useState(null);
    const [routeStatusGradient, setRouteStatusGradient] = useState(null);
    const [routeStatusPosition, setRouteStatusPosition] = useState(null);
    const [statusMetrics, setStatusMetrics] = useState([]);
    const { t } = useTranslation('translation');

    useEffect(() => {
        setCurrentStopType(
            schedule &&
                schedule[currentStopIndex] &&
                schedule[currentStopIndex].type
        );
        setCurrentStopIsDepot(
            schedule &&
                schedule[currentStopIndex] &&
                schedule[currentStopIndex].isDepot
        );
    }, [schedule, currentStopIndex]);

    useEffect(() => {
        const current =
            (isScheduleCompleted && numStops) ||
            Math.min(currentStopIndex, numStops);
        setCurrentStopNumber(current);
    }, [isScheduleCompleted, numStops, currentStopIndex]);

    useEffect(() => {
        const currentSchedule = schedule[currentStopNumber];
        if (!currentSchedule) return;

        const { completedAt } = currentSchedule;
        setCurrentStopFinish(DateTime.fromISO(completedAt).toFormat('t'));
    }, [currentStopNumber]);

    function _reduceSchedule(compiled, item, idx, stops) {
        const stopsLength = stops.length;
        const currentStop = stops.findIndex(
            (stop) => !stop.isCompleted && !stop.isCanceled
        );
        const { gradient, position, statusColor } = compiled;
        const {
            status: assignmentCode,
            delay: delayCode,
            isCanceled,
            isCompleted
        } = item;
        const currentGradient = [...gradient];
        const start = (idx / stopsLength) * 100;
        const end = ((idx + 1) / stopsLength) * 100;
        const isStopCompleted = isCompleted || isCanceled;

        const delayStatus = getDelayCodeLabel(delayCode);
        const assignmentStatus = getAssignmentCodeLabel(assignmentCode);

        const color =
            (isCanceled && `--color-status-${assignmentStatus}`) ||
            (isStopCompleted && `--color-status-${delayStatus}-completed`) ||
            `--color-status-${delayStatus}`;
        const currentBand = {
            color,
            start,
            end
        };
        const previousBand = currentGradient.pop();

        // consolidate consecutive same gradient color bands
        if (previousBand && previousBand.color === currentBand.color) {
            currentBand.start = previousBand.start;
        } else if (previousBand) currentGradient.push(previousBand);

        // update gradient
        currentGradient.push(currentBand);

        return {
            gradient: currentGradient,
            position:
                (currentStop < 0 && 100) ||
                (idx === currentStop && start) ||
                position,
            statusColor:
                (driverOffline && '--color-driver-offline') ||
                (currentStop < 0 && `--color-assignment-COMPLETED`) ||
                (idx === currentStop &&
                    `--color-assignment-${assignmentStatus}`) ||
                statusColor
        };
    }

    useEffect(() => {
        if (
            !schedule ||
            schedule.length === 0 ||
            (currentStopIsDepot && !isScheduleCompleted)
        ) {
            setRouteStatusGradient();
            setRouteStatusPosition();
            return;
        }

        // set status bar gradient and marker
        const statusBar = schedule
            .filter((item) => {
                return !item.isDepot;
            })
            .reduce(_reduceSchedule, {
                gradient: [null],
                position: null,
                statusColor: null
            });

        // flatten gradient
        const gradientBands = statusBar.gradient
            .map((band) => {
                const { color, start, end } = band;
                return `var(${color}) ${start}%, var(${color}) ${end}%`;
            })
            .join(', ');

        setRouteStatusGradient({
            background: `linear-gradient(90deg, ${gradientBands})`
        });
        setRouteStatusPosition({
            left: `${statusBar.position}%`,
            backgroundColor: `var(${statusBar.statusColor})`
        });
    }, [schedule, currentStopIsDepot, isScheduleCompleted]);

    useEffect(() => {
        const metrics = [
            {
                metric: 'stops',
                value: currentStopNumber,
                maxValue: numStops,
                icon: 'stops',
                iconColor: 'galaxy-500'
            }
        ];

        if (currentStopIsDepot && !isScheduleCompleted) {
            metrics.push({
                metric: 'depot',
                value:
                    currentStopNumber === numStops
                        ? t('returningToDepot')
                        : t('atDepot')
            });
        } else {
            metrics.push({
                metric: 'time-remaining',
                value: isScheduleCompleted
                    ? t('completedTime', { time: currentStopFinish })
                    : t('finishTime', {
                          time: estimatedFinish
                      })
            });
        }

        setStatusMetrics(metrics);
    }, [
        currentStopIsDepot,
        currentStopNumber,
        isScheduleCompleted,
        estimatedFinish,
        currentStopFinish
    ]);

    useEffect(() => {
        if (!timeRemaining) {
            setEstimatedFinish();
            return;
        }

        const duration =
            dateUtilsConverters.convertMillisecondsToHoursAndMinutes(
                timeRemaining
            );
        const eta = DateTime.local().plus(duration).toFormat('t');
        setEstimatedFinish(eta);
    }, [timeRemaining]);

    function _getClassName() {
        const defaultClassName = 'routecard-statusbar';
        return classNames(defaultClassName, className);
    }

    return (
        <div className={_getClassName()}>
            <RouteCardSummary
                className="_jc-space-between"
                metrics={statusMetrics}
            />
            <div className="gradient _p-relative" style={routeStatusGradient}>
                <div
                    className={`gradient_pointer _d-flex _ai-center _jc-center _p-absolute pointer_${currentStopType}`}
                    style={routeStatusPosition}
                    data-offline={driverOffline || null}
                />
            </div>
        </div>
    );
}

RouteCardStatusBar.propTypes = {
    /** additional css classes to attach to this component */
    className: PT.string
};

export default RouteCardStatusBar;
