import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import clientTemplateApi from '~/api/ClientTemplateApi';
import {
    selectClientTemplateData,
    setClientTemplateData
} from '~/reducers/clientTemplateDataSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';

export const useGetClientTemplate = () => {
    const [templateData, setTemplateData] = useState({});
    const mainClient = useSelector(selectMainClient);
    const dispatch = useDispatch();
    const clientemplateData = useSelector(selectClientTemplateData);

    const isTemplateDataEmpty = useMemo(() => {
        return _.isEmpty(clientemplateData);
    }, [clientemplateData]);

    const getClientTemplateData = async () => {
        try {
            const { id } = mainClient || {};
            if (!id) return;

            // API request for client integration data template
            const dataResponse = await clientTemplateApi.get(id);
            const {
                data: { data: clientData }
            } = dataResponse;
            setTemplateData(clientData);
            dispatch(setClientTemplateData(clientData));
        } catch (e) {
            console.error(e);
            setTemplateData({});
            dispatch(setClientTemplateData({}));
        }
    };

    useEffect(() => {
        if (!isTemplateDataEmpty) return setTemplateData(clientemplateData);
        getClientTemplateData();
    }, [isTemplateDataEmpty]);

    return { templateData };
};
