import React, { useMemo } from 'react';
import classNames from 'classnames';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DAYS_OF_WEEK } from '~/utils/date-utils';
import { v4 as uuidV4 } from 'uuid';

import './daysOfWeek.scss';

const DaysOfWeek = ({
    selectedWeekdays,
    size = 'small',
    onChange = () => {},
    isEditable = false,
    ...extra
}) => {
    const { t } = useTranslation('common');
    const handleOnChange = (day, checked) => {
        const newSelection = [...selectedWeekdays];
        if (!checked) {
            const index = newSelection.indexOf(day);
            newSelection.splice(index, 1);
        } else {
            newSelection.push(day);
        }
        onChange(newSelection);
    };

    const daysOfWeek = useMemo(() => {
        return Object.keys(DAYS_OF_WEEK).map((day) => {
            const uniqueId = uuidV4('dow');
            const isSelected = selectedWeekdays.includes(parseInt(day, 10));
            const isDisabled = selectedWeekdays.length === 1 && isSelected;
            const classes = classNames(
                'weekday-block',
                '_d-flex',
                '_ai-center',
                '_jc-center',
                {
                    inactive: !isSelected,
                    small: size === 'small',
                    large: size === 'large'
                }
            );
            if (isEditable) {
                return (
                    <span key={uniqueId}>
                        <label
                            htmlFor={uniqueId}
                            className="weekday-checkbox-label"
                            data-testid={
                                extra['data-testid']
                                    ? `${extra['data-testid']}-checkbox-label`
                                    : 'weekday-checkbox-label'
                            }
                        >
                            <span data-testid="weekday" className={classes}>
                                {t(`daysOfWeek.short.${DAYS_OF_WEEK[day]}`)}
                            </span>
                        </label>
                        <input
                            className="weekday-checkbox"
                            type="checkbox"
                            data-testid={
                                extra['data-testid']
                                    ? `${extra['data-testid']}-input-${day}`
                                    : `weekday-checkbox-input-${day}`
                            }
                            id={uniqueId}
                            checked={isSelected}
                            disabled={isDisabled}
                            onChange={(e) =>
                                handleOnChange(
                                    parseInt(day, 10),
                                    e.target.checked
                                )
                            }
                        />
                    </span>
                );
            }
            return (
                <span
                    data-testid={
                        extra['data-testid'] ? extra['data-testid'] : 'weekday'
                    }
                    className={classes}
                    key={uniqueId}
                >
                    {t(`daysOfWeek.short.${DAYS_OF_WEEK[day]}`)}
                </span>
            );
        });
    }, [t, selectedWeekdays]);

    return (
        <span
            className="weekday-wrapper _d-flex _ai-center"
            data-testid="daysofweek"
        >
            {daysOfWeek}
        </span>
    );
};

DaysOfWeek.propTypes = {
    size: PT.oneOf(['small', 'large']),
    selectedWeekdays: PT.arrayOf(PT.number).isRequired,
    onChange: PT.func,
    isEditable: PT.bool
};

export default DaysOfWeek;
