import {
    Polygon,
    LatLng
} from '~/components/ZoneManagementPage/MapContext/types';
import { ZoneCoordinates } from '~/components/ZoneManagementPage/types';

const googlePolygonToGeoJSONPolygon = (
    googlePolygon: Polygon | null
): ZoneCoordinates | null => {
    if (!googlePolygon) {
        return null;
    }
    const polygon = googlePolygon.getPath().getArray();
    const geoJsonPolygon: number[][] = [];
    polygon.forEach((point: LatLng) => {
        geoJsonPolygon.push([point.lng(), point.lat()]);
    });
    // GeoJson must have matching first and last points, while Google Polygon does not
    geoJsonPolygon.push(geoJsonPolygon[0]);
    return [geoJsonPolygon];
};

export const mapUtils = { googlePolygonToGeoJSONPolygon };
