import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { selectActiveClients } from '~/reducers/activeClientsSlice';
import { WebMultiClientApi } from '~/api/WebMultiClientApi';
import { useMapUtils } from '../useMapUtils';
import constants from '~/utils/constants';

/**
 * Implements a heartbeat using the WebMultiClientApi
 *
 * + When in `dispatch` map routes mode, performs a call to `WebMultiClientApi` every minute
 *   which triggers several socket messages to be received.
 * + For all other map route modes, performs 1 call on load to allow search functionality
 *
 * @category Hooks
 * @module useWebInterval
 * @param { Omit<UseQueryOptions, 'queryKey' | 'queryFn'>} reactQueryOptions - valid React Query `useQuery` options
 * @returns {UseQueryResult}
 * @example <caption>Usage</caption>
 * // import statement
 * import { useWebInterval } from '~/hooks';
 *
 * // initialize the heartbeat
 * useWebInterval();
 *
 * // initialize with options
 * const options = {
 *     refetchInterval: 10000 // refresh every 10 secs
 * };
 * useWebInterval(options);
 *
 * // trigger a manually
 * const { refetch } = usewebInterval();
 * refetch();
 *
 * @see [React Query v3, useQuery]{@link https://react-query-v3.tanstack.com/reference/useQuery}
 */
export const useWebInterval = (
    reactQueryOptions: Omit<UseQueryOptions, 'queryKey' | 'queryFn'> = {}
): UseQueryResult => {
    const [activeClientIds, setActiveClientIds] = useState<string[]>([]);
    const activeClients = useSelector(selectActiveClients);
    const { isDispatchedRouteMode } = useMapUtils();

    // get API response
    const webMultiClient = async (clientIds: string[]) => {
        const { data } = await WebMultiClientApi.get(clientIds);

        return data;
    };

    // refretches every minute for dispatch mode, otherwise fires once
    const defaultReactQueryOptions = {
        refetchInterval:
            isDispatchedRouteMode && constants.timings.GET_WEB_INTERVAL
    };

    // merge query options
    const mergedOptions: Omit<UseQueryOptions, 'queryKey' | 'queryFn'> = {
        ...defaultReactQueryOptions,
        ...reactQueryOptions
    };

    // update active client ids
    useEffect(() => {
        const keys = Object.keys(activeClients);
        setActiveClientIds(keys);
    }, [activeClients]);

    // return the full useQuery Object
    return useQuery(
        [WebMultiClientApi.REACT_QUERY_KEY, activeClientIds],
        () => webMultiClient(activeClientIds),
        mergedOptions
    );
};
