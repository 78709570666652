import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { EditRouteHistoryApi } from '~/api/EditRouteHistoryApi';
import { setEditHistoryByClient } from '~/reducers/editHistoryByClientSlice';
import { selectSchedulerId } from '~/reducers/webSolutionSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';

type HookGetEditHistory = {
    getEditHistory: () => Promise<void>;
};

/**
 * Gets client edit history
 *
 * @category Hooks
 * @module useGetEditHistory
 * @returns {HookGetEditHistory}
 * @example <caption>Usage</caption>
 * // import statement
 * import { useGetEditHistory } from '~/hooks';
 *
 * // expose methods
 * const { getEditHistory } = useGetEditHistory();
 *
 */
export const useGetEditHistory = (): HookGetEditHistory => {
    const dispatch = useDispatch();
    const schedulerId = useSelector(selectSchedulerId);
    const mainClient = useSelector(selectMainClient);

    const getEditHistory = useCallback(async () => {
        try {
            if (!schedulerId) {
                console.warn('Scheduler id is missing');
                return;
            }

            const clientId = mainClient?.id;
            const dataResponse = await EditRouteHistoryApi.getEditHistory(
                clientId,
                schedulerId
            );
            const {
                data: { data: editHistory }
            } = dataResponse;

            dispatch(
                setEditHistoryByClient({
                    clientId,
                    data: editHistory
                })
            );
        } catch (e) {
            console.error(e);
            dispatch(setEditHistoryByClient({}));
        }
    }, [mainClient, schedulerId]);

    return { getEditHistory };
};
