import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';
import { liveRoutesDataFactory } from '~/utils/data-factory';
import { locationUtils } from '~/utils/location-utils';
import {
    isAssignmentCompleted,
    isAssignmentCanceled
} from '~/utils/assignment-utils';

function _payloadToLiveDrivers(state, payload) {
    return payload.map((driver) => {
        const newDriver = liveRoutesDataFactory.makeLiveDriver(driver);
        const oldDriver = state[newDriver.clientDriverId];
        if (oldDriver && oldDriver.latestLocationUpdate) {
            newDriver.latestLocationUpdate = oldDriver.latestLocationUpdate;
        }
        return newDriver;
    });
}

function _groupByStatusAndNormalizeDrivers(drivers) {
    const grouped = drivers.reduce(
        (acc, driver) => {
            const {
                clientDriverId,
                isCompleted,
                numStops,
                isCompletedWithCanceledTasks
            } = driver;

            // @todo:
            // + remove usage of `isCompletedWithCanceledTasks` when API-1927 is resolved
            if (isCompleted || isCompletedWithCanceledTasks) {
                acc.completedDrivers[clientDriverId] = driver.toJSON();
            } else if (numStops && !isCompletedWithCanceledTasks) {
                acc.dispatchedDrivers[clientDriverId] = driver.toJSON();
            } else {
                acc.driversWithNoRoutes.push(clientDriverId);
            }
            return acc;
        },
        {
            dispatchedDrivers: {},
            completedDrivers: {},
            driversWithNoRoutes: []
        }
    );

    return grouped;
}

function _removeDeletedDrivers(liveDrivers, currentState, targetClient) {
    Object.keys(currentState).forEach((storedDriverId) => {
        if (
            storedDriverId.startsWith(targetClient) &&
            !liveDrivers[storedDriverId]
        ) {
            delete currentState[storedDriverId];
        }
    });
}

function _updateRouteCenters(state, newDrivers) {
    for (const newDriver of Object.values(newDrivers)) {
        const oldDriver = state[newDriver.id];
        if (
            oldDriver &&
            oldDriver.stats.numStops === newDriver.stats.numStops &&
            oldDriver.routeCenter
        ) {
            newDriver.routeCenter = oldDriver.routeCenter;
            return;
        }
        newDriver.routeCenter = locationUtils.getAverageCoordinates(
            newDriver.schedule.map((stop) => stop.location.location)
        );
    }
}

export const liveDriversSlice = createSlice({
    name: 'liveDrivers',
    initialState: {},
    reducers: {
        setLiveDrivers: (state, action) => {
            const { drivers } = action.payload;
            const convertedDrivers = _payloadToLiveDrivers(state, drivers);
            const { completedDrivers, dispatchedDrivers } =
                _groupByStatusAndNormalizeDrivers(convertedDrivers);
            return { ...completedDrivers, ...dispatchedDrivers };
        },
        updateLiveDrivers: (state, action) => {
            const { drivers, clientId } = action.payload;
            const convertedDrivers = _payloadToLiveDrivers(state, drivers);
            const { completedDrivers, dispatchedDrivers, driversWithNoRoutes } =
                _groupByStatusAndNormalizeDrivers(convertedDrivers);
            const liveDrivers = { ...completedDrivers, ...dispatchedDrivers };
            driversWithNoRoutes.forEach((driverId) => delete state[driverId]);
            _removeDeletedDrivers(liveDrivers, state, clientId);
            _updateRouteCenters(state, completedDrivers);
            Object.assign(state, liveDrivers);
            return state;
        },
        updateLiveDriverLatestLocationUpdate: (state, action) => {
            const allLocationUpdates = action.payload;
            for (const [driverId, locationUpdates] of Object.entries(
                allLocationUpdates
            )) {
                const driver = Object.values(state).find(
                    (stateDriver) => stateDriver.id === driverId
                );
                if (!driver) continue;
                driver.latestLocationUpdate =
                    locationUpdates[locationUpdates.length - 1];
            }
            return state;
        },
        resetLiveDrivers: () => {
            return {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {};
        });
    }
});

export const {
    setLiveDrivers,
    updateLiveDrivers,
    updateLiveDriverLatestLocationUpdate,
    resetLiveDrivers
} = liveDriversSlice.actions;

export const selectLiveDrivers = (state) => state.liveDrivers;

export const selectLiveDriverById = (id) => (state) => state.liveDrivers[id];

// @todo:
// + remove `isCompletedWithCanceledTasks` when API-1927 is resolved
const isCompletedWithCanceledTasks = (schedule) =>
    schedule.every(
        (task) =>
            isAssignmentCompleted(task.status) ||
            isAssignmentCanceled(task.status)
    );

export const selectDispatchedDrivers = (state) => {
    // @todo:
    // + remove `isCompletedWithCanceledTasks` when API-1927 is resolved
    return Object.values(state.liveDrivers).filter(
        (d) =>
            !d.stats.isDriverComplete &&
            !isCompletedWithCanceledTasks(d.schedule)
    );
};

export const selectCompletedDrivers = (state) => {
    // @todo:
    // + remove `isCompletedWithCanceledTasks` when API-1927 is resolved
    return Object.values(state.liveDrivers).filter(
        (d) =>
            d.stats.isDriverComplete || isCompletedWithCanceledTasks(d.schedule)
    );
};

export default liveDriversSlice.reducer;
