import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

export const clientTemplateDataSlice = createSlice({
    name: 'clientTemplateData',
    initialState: {},
    reducers: {
        setClientTemplateData: (state, action) => {
            return action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {};
        });
    }
});
export const { setClientTemplateData } = clientTemplateDataSlice.actions;

export const selectClientTemplateData = (state) => state.clientTemplateData;

export default clientTemplateDataSlice.reducer;
