import { useCallback, useMemo } from 'react';
import constants from '~/utils/constants';
import { theme } from '~/ui';

import {
    DrawingManager,
    LatLngLiteral,
    Map,
    Maps,
    Polygon,
    useMapContext
} from '~/components/ZoneManagementPage/MapContext';

const createNewDrawingManager = (
    map: Map,
    maps: Maps,
    strokeWeight = 3
): DrawingManager => {
    const drawingManager = new maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: false,
        polygonOptions: {
            fillColor: theme.colors.ocean,
            fillOpacity: 0.3,
            strokeColor: theme.colors.meteor,
            strokeOpacity: 1,
            strokeWeight
        }
    });

    drawingManager.setMap(map);

    return drawingManager;
};

type OnGoogleApiLoadedProps = { map: Map; maps: Maps };
type Options = {
    onGoogleApiLoaded: (props: OnGoogleApiLoadedProps) => void;
    center?: LatLngLiteral;
    defaultZoom: number;
    options: {
        disableDefaultUI: boolean;
        disableDoubleClickZoom: boolean;
        maxZoom: number;
        rotateControl: boolean;
        zoomControl: boolean;
    };
};

type OnPolygonCompleteToConsumer = (polygon: Polygon) => void;

type UseDrawingMapOptions = {
    mapCenter: LatLngLiteral;
    defaultZoom?: number;
    polygonStrokeWeight?: number;
    events?: {
        onPolygonComplete?: OnPolygonCompleteToConsumer;
    };
};

export const useDrawingMapOptions = (
    options: UseDrawingMapOptions
): Options => {
    const { mapCenter, defaultZoom, polygonStrokeWeight, events } = options;
    const { onPolygonComplete: onPolygonCompleteCallback } = events || {};
    const {
        setActivePolygon,
        setDrawingManagerInstance,
        setIsDrawingMode,
        setMapInstance,
        setMapsApi
    } = useMapContext();
    const onPolygonComplete = useCallback((polygon: Polygon) => {
        setIsDrawingMode(false);
        setActivePolygon(polygon);
        onPolygonCompleteCallback?.(polygon);
    }, []);
    const onGoogleApiLoaded = useCallback((props: OnGoogleApiLoadedProps) => {
        const { map, maps } = props || {};

        if (!map || !maps?.drawing) {
            console.warn('unable to load google map libraries');
            return;
        }

        const drawingManager = createNewDrawingManager(
            map,
            maps,
            polygonStrokeWeight
        );
        setMapInstance(map);
        setMapsApi(maps);
        setDrawingManagerInstance(drawingManager);
        maps.event.addListener(
            drawingManager,
            constants.mapEvents.POLYGON_COMPLETE,
            onPolygonComplete
        );
    }, []);

    return useMemo<Options>(() => {
        return {
            onGoogleApiLoaded,
            center: mapCenter,
            defaultZoom:
                defaultZoom || constants.mapOptionSettings.DEFAULT_ZOOM,
            options: {
                disableDefaultUI: true,
                disableDoubleClickZoom: true,
                maxZoom: constants.mapOptionSettings.MAX_ZOOM,
                rotateControl: false,
                zoomControl: false,
                tilt: 0
            }
        };
    }, []);
};
