import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    ManageStopControlMenu,
    ManageStopControlSingle,
    StopModalMenuButton
} from '~/ui';

import {
    useUnassignStops,
    useOnDemandDispatchUnassignTasks,
    useToastContext
} from '~/hooks';
import constants from '~/utils/constants';

function SingleStopControl({
    stopData,
    onSwitchView,
    disableResequencing = false,
    footerButtonTitle,
    footerButtonIcon,
    isLiveStopControl,
    onClickFooterButton = () => {}
}) {
    const { t } = useTranslation('translation');
    const { unassignStops, isUnassignStopsAllowed } = useUnassignStops();
    const { cancelTasks, unassignTasks } = useOnDemandDispatchUnassignTasks();
    const { addToast } = useToastContext();

    function _handleClickLiveStopUnassign() {
        unassignTasks({
            onSuccess: (data) => {
                const count = JSON.parse(data.config.data).taskIds.length;
                addToast({
                    message: t('PlanStopsControl.toast.onSuccessUnassign', {
                        count
                    }),
                    variant: 'success'
                });
            },
            onError: (error) => {
                if (error?.data || error?.response?.data) {
                    console.error(error);
                }
                addToast({
                    message: t('PlanStopsControl.toast.onErrorUnassign', {
                        count: selectedMapStopIds.length
                    }),
                    variant: 'error'
                });
            }
        });
    }

    function _handleClickLiveStopCancel() {
        cancelTasks({
            onSuccess: (data) => {
                const count = JSON.parse(data.config.data).taskIds.length;
                addToast({
                    message: t('PlanStopsControl.toast.onSuccessCancel', {
                        count
                    }),
                    variant: 'success'
                });
            },
            onError: (error) => {
                if (error?.data || error?.response?.data) {
                    console.error(error);
                }
                addToast({
                    message: t('PlanStopsControl.toast.onErrorCancel', {
                        count: selectedMapStopIds.length
                    }),
                    variant: 'error'
                });
            }
        });
    }

    return (
        <ManageStopControlSingle
            className="singlestopcontrol"
            stopData={stopData}
            footerButtonTitle={footerButtonTitle}
            footerButtonIcon={footerButtonIcon}
            onClickFooterButton={onClickFooterButton}
        >
            <ManageStopControlMenu>
                <StopModalMenuButton
                    buttonIcon="iconReorder"
                    buttonText={t('PlanStopsControl.button.resequence')}
                    onClick={() =>
                        onSwitchView(constants.stopsControl.views.RESEQUENCE)
                    }
                    buttonStopText={t('PlanStopsControl.button.stopText', {
                        stopIndex: stopData.stopNumber
                    })}
                    disabled={disableResequencing}
                    showButtonArrow
                />
                {/**
                 * @todo remove `disabled` once AT2-437 and AT2-438 are resolved
                 * @see [Add reassign API]{@link https://wisesys.atlassian.net/browse/AT2-437}
                 * @see [Add reassign hook]{@link https://wisesys.atlassian.net/browse/AT2-438}
                 */}
                <StopModalMenuButton
                    buttonIcon="iconRouteFill"
                    buttonText={t('PlanStopsControl.button.reassign')}
                    showButtonArrow
                    disabled={isLiveStopControl}
                    onClick={() =>
                        onSwitchView(constants.stopsControl.views.REASSIGN)
                    }
                />
                {isLiveStopControl && (
                    <StopModalMenuButton
                        onClick={_handleClickLiveStopUnassign}
                        buttonIcon="unassign"
                        buttonText={t('PlanStopsControl.button.unassign')}
                    />
                )}
                {isLiveStopControl && (
                    <StopModalMenuButton
                        onClick={_handleClickLiveStopCancel}
                        buttonIcon="iconRouteless"
                        buttonText={t('PlanStopsControl.button.cancel')}
                    />
                )}
                {!isLiveStopControl && (
                    <StopModalMenuButton
                        disabled={!isUnassignStopsAllowed}
                        onClick={unassignStops}
                        buttonIcon="iconRouteless"
                        buttonText={t('PlanStopsControl.button.unassign')}
                    />
                )}
            </ManageStopControlMenu>
        </ManageStopControlSingle>
    );
}

export default SingleStopControl;
